import { PaletteOptions } from "@mui/material/styles/createPalette";

const palette: PaletteOptions = {
  type: "admin",
  common: {
    white: "#fff",
    black: "#000",
  },
  primary: {
    main: "#3CA653",
    light: "#53E572",
  },
  secondary: {
    main: "#8D94A1",
    light: "#9D9EA0",
    contrastText: "#1a2035",
  },
  info: {
    50: "#344767",
    100: "#2c3c58",
    200: "#373737",
    main: "#079CF2",
    light: "#1FA6F2",
  },
  success: {
    main: "#3CA653",
    light: "#53E572",
  },
  warning: {
    main: "#FC993D",
    light: "#FCA656",
  },
  error: {
    main: "#FC3D3D",
    light: "#FC5656",
  },
  grey: {
    100: "#f8f9fa",
    200: "#f0f2f566",
    300: "#EDEEF2",
    400: "#C4C5C8",
    500: "#9D9EA0",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
    A100: "#57606E",
  },
  background: {
    default: "#222322",
    paper: "#373737",
    checkBox: "#43664B",
    checkBoxHover: "#3CE660",
    disabled: "#43664B",
    snackbar: "#373737",
    card: "#373737",
  },
  text: {
    primary: "#ffffffcc",
    secondary: "#ffffffcc",
    disabled: "#ccc",
  },
};

export default palette;
