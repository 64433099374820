import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DFACheckBox, DFABox, DFACard, DFADatePicker } from "Theme";

export default function ExtraData({ data, isEdit }: any) {
  const [entityStructure, setEntityStructure] = useState("");
  const [entityPersStructure, setEntityPersStructure] = useState("");
  const [isPublic, setIsPublic] = useState(
    data ? data.IPAttr.extraData.publicServantrelations : false
  );
  const [position, setPosition] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [relationshipsLevel, setRelationshipsLevel] = useState("");
  const [sourceOfFunds, setSourceOfFunds] = useState("");
  const [site, setSite] = useState("");
  const [financialPositionInfo, setFinancialPositionInfo] = useState("");
  const [businessReputationInfo, setBusinessReputationInfo] = useState("");
  const [goalReason, setGoalReason] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);
  const [amount, setAmount] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseDate, setLicenseDate] = useState(null);
  const [whomIssued, setWhomIssued] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [tel, setTel] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");

  const { t } = useTranslation();

  const isIP = data && data.commonStates.legalForm === "IP";

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={4}>
        {!isIP ? (
          <DFACard title={t("layouts.card.clientCard.entityAttr.extraData.title")}>
            <Grid container rowSpacing={2} mt="1px">
              <Grid item xs={12}>
                <TextField
                  label={t("layouts.card.clientCard.entityAttr.extraData.entityStructure")}
                  value={data ? "" : entityStructure}
                  onChange={(e) => setEntityStructure(e.target.value)}
                  disabled={!isEdit}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("layouts.card.clientCard.entityAttr.extraData.entityPersStructure")}
                  value={data ? "" : entityPersStructure}
                  onChange={(e) => setEntityPersStructure(e.target.value)}
                  disabled={!isEdit}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DFACard>
        ) : (
          <DFACard title={t("layouts.card.clientCard.IPAttr.extraData.title")}>
            <Grid container rowSpacing={2} mt="1px">
              <Grid item xs={12}>
                <DFACheckBox
                  label={t("layouts.card.clientCard.IPAttr.extraData.publicServantrelations")}
                  onChange={(e) => setIsPublic(e.target.checked)}
                  checked={isPublic}
                  disabled={!isEdit}
                />
              </Grid>
              {isPublic && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={t("layouts.card.clientCard.IPAttr.extraData.position")}
                      value={data ? data.IPAttr.extraData.position : position}
                      onChange={(e) => setPosition(e.target.value)}
                      disabled={!isEdit}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={t("layouts.card.clientCard.IPAttr.extraData.companyName")}
                      value={data ? data.IPAttr.extraData.companyName : companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      disabled={!isEdit}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={t("layouts.card.clientCard.IPAttr.extraData.companyAddress")}
                      value={data ? data.IPAttr.extraData.companyAddress : companyAddress}
                      onChange={(e) => setCompanyAddress(e.target.value)}
                      disabled={!isEdit}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={t("layouts.card.clientCard.IPAttr.extraData.relationshipsLevel")}
                      value={data ? data.IPAttr.extraData.relationshipsLevel : relationshipsLevel}
                      onChange={(e) => setRelationshipsLevel(e.target.value)}
                      disabled={!isEdit}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={t("layouts.card.clientCard.IPAttr.extraData.sourceOfFunds")}
                      value={data ? data.IPAttr.extraData.sourceOfFunds : sourceOfFunds}
                      onChange={(e) => setSourceOfFunds(e.target.value)}
                      disabled={!isEdit}
                      required
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DFACard>
        )}
      </Grid>
      <Grid item xs={4}>
        <DFACard title={t("layouts.card.clientCard.commonStates.title")}>
          <Grid container spacing={2} display="flex" mt="1px">
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-disabled"
                label={t("layouts.card.clientCard.commonStates.site")}
                disabled={!isEdit}
                value={data ? data.commonStates.site : site}
                onChange={(e) => setSite(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-disabled"
                label={t("layouts.card.clientCard.commonStates.financialPositionInfo")}
                disabled={!isEdit}
                value={data ? data.commonStates.financialPositionInfo : financialPositionInfo}
                onChange={(e) => setFinancialPositionInfo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-disabled"
                label={t("layouts.card.clientCard.commonStates.businessReputationInfo")}
                disabled={!isEdit}
                value={data ? data.commonStates.businessReputationInfo : businessReputationInfo}
                onChange={(e) => setBusinessReputationInfo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="1rem">
                {t("layouts.card.clientCard.commonStates.sourceIncome.title")}
              </Typography>
              <DFABox sx={{ display: "flex", flexWrap: "wrap" }}>
                {Object.entries(data ? data.commonStates.sourceIncome : {}).map((el: any) => (
                  <DFACheckBox
                    key={`sourceIncome-${el[0]}`}
                    label={t(
                      `layouts.card.clientCard.commonStates.sourceIncome.incomeTypes.${el[0]}`
                    )}
                    onChange={(e) => {
                      console.log(e.target.checked);
                    }}
                    checked={el[1]}
                    disabled={!isEdit}
                  />
                ))}
              </DFABox>
            </Grid>
          </Grid>
        </DFACard>
      </Grid>
      <Grid item xs={4}>
        <DFACard title={t("layouts.card.clientCard.contactInfo.title")}>
          <Grid container spacing={2} mt="1px">
            <Grid item xs={12}>
              <TextField
                label={t("layouts.card.clientCard.contactInfo.phone")}
                value={data ? data.commonStates.contacts.tel : tel}
                onChange={(e) => setTel(e.target.value)}
                disabled={!isEdit}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("layouts.card.clientCard.contactInfo.fax")}
                value={data ? data.commonStates.contacts.fax : fax}
                onChange={(e) => setFax(e.target.value)}
                disabled={!isEdit}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("layouts.card.clientCard.contactInfo.email")}
                value={data ? data.commonStates.contacts.email : email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!isEdit}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("layouts.card.clientCard.contactInfo.address")}
                value={data ? data.commonStates.contacts.address : address}
                onChange={(e) => setAddress(e.target.value)}
                disabled={!isEdit}
                fullWidth
              />
            </Grid>
          </Grid>
        </DFACard>
      </Grid>
      <Grid item xs={6}>
        <DFACard title={t("layouts.card.clientCard.license.title")}>
          <Grid container spacing={3} mt="1px">
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t("layouts.card.clientCard.license.number")}
                value={data ? data.commonStates.license.number : licenseNumber}
                onChange={(e) => setLicenseNumber(e.target.value)}
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <DFADatePicker
                label={t("layouts.card.clientCard.license.date")}
                value={data ? data.commonStates.license.date : licenseDate}
                onChange={(date) => setLicenseDate(date)}
                disableFuture
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <DFADatePicker
                label={t("layouts.card.clientCard.license.till")}
                value={data ? data.commonStates.license.endDate : endDate}
                onChange={(date) => setEndDate(date)}
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("layouts.card.clientCard.license.issuer")}
                value={data ? data.commonStates.license.whomIssued : whomIssued}
                onChange={(e) => setWhomIssued(e.target.value)}
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="1rem">
                {t("layouts.card.clientCard.license.activityList.title")}
              </Typography>
              <DFABox sx={{ display: "flex", flexWrap: "wrap" }}>
                {Object.entries(data ? data.commonStates.license.activityList : {}).map((el) => (
                  <Typography key={el[0]} variant="h6" fontWeight={600} sx={{ mr: 2 }}>
                    {t(`layouts.card.clientCard.license.activityList.activityItems.${el[0]}`)}
                  </Typography>
                ))}
              </DFABox>
            </Grid>
          </Grid>
        </DFACard>
      </Grid>
      <Grid item xs={6}>
        <DFACard title={t("layouts.card.clientCard.goals.title")}>
          <Grid container spacing={2} mt="1px">
            <Grid item xs={12}>
              <Tooltip title={t("layouts.card.clientCard.tooltips.goalReason")}>
                <TextField
                  fullWidth
                  id="outlined-disabled"
                  label={t("layouts.card.clientCard.goals.goalReason")}
                  disabled={!isEdit}
                  value={data ? data.commonStates.goals.goal : goalReason}
                  onChange={(e) => setGoalReason(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography fontSize="1rem">
                {t("layouts.card.clientCard.goals.plannedOperation.title")}
              </Typography>
              <Grid container spacing={2} mt="1px">
                <Grid item lg={8} display="flex" gap={2}>
                  <DFADatePicker
                    label={t("layouts.card.clientCard.goals.plannedOperation.from")}
                    value={data ? data.commonStates.goals.plannedOperation.startDate : startDate}
                    onChange={(date) => setStartDate(date)}
                    disabled={!isEdit}
                  />
                  -
                  <DFADatePicker
                    label={t("layouts.card.clientCard.goals.plannedOperation.till")}
                    value={data ? data.commonStates.goals.plannedOperation.finishDate : finishDate}
                    onChange={(date) => setFinishDate(date)}
                    disabled={!isEdit}
                  />
                </Grid>
                <Grid item lg={4}>
                  <Tooltip title="с ООО «ЦФА Информационная система»">
                    <TextField
                      fullWidth
                      id="outlined-disabled"
                      label={t("layouts.card.clientCard.goals.plannedOperation.amount")}
                      disabled={!isEdit}
                      value={data ? data.commonStates.goals.plannedOperation.amount : amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DFACard>
      </Grid>
    </Grid>
  );
}
