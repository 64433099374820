/*
 Developed by ST soft team
 */

import React from "react";
import { IconButton, Typography, Icon, Divider, Fade, Snackbar } from "@mui/material";
import { SnackbarProps } from "@mui/material/Snackbar/Snackbar";
import { Theme } from "@mui/material/styles";
import SnackbarIconRoot from "./SnackbarIconRoot";
import DFABox from "../DFABox";

interface IDFASnackbarProps extends SnackbarProps {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  icon: React.ReactNode;
  title: string;
  dateTime: string;
  snackContent: React.ReactNode;
  close: () => void;
}

function DFASnackbar({
  color = "info",
  icon,
  title,
  dateTime,
  snackContent,
  close,
  ...rest
}: IDFASnackbarProps) {
  return (
    <Snackbar
      TransitionComponent={Fade}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      {...rest}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
          <Icon fontSize="small">close</Icon>
        </IconButton>
      }
    >
      <DFABox
        variant="gradient"
        minWidth="21.875rem"
        maxWidth="100%"
        shadow="md"
        borderRadius="md"
        p={1}
        sx={{ backgroundColor: ({ palette }) => palette.background.snackbar }}
      >
        <DFABox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="dark"
          p={1.5}
        >
          <DFABox display="flex" alignItems="center" lineHeight={0}>
            <SnackbarIconRoot fontSize="small" ownerState={{ color }}>
              {icon}
            </SnackbarIconRoot>
            <Typography
              sx={({ palette, other }: Theme) => ({
                color: palette[color].main,
                fontWeight: other.baseTypographyProp.fontWeightMedium,
                fontSize: other.baseTypographyProp.fontSizeXS,
              })}
            >
              {title}
            </Typography>
          </DFABox>
          <DFABox display="flex" alignItems="center" lineHeight={0}>
            <Typography variant="caption" ml={1}>
              {dateTime}
            </Typography>
            <Icon
              sx={{
                color: ({ palette }: Theme) => palette.background.paper,
                fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                cursor: "pointer",
                marginLeft: 2,
                transform: "translateY(-1px)",
              }}
              onClick={close}
            >
              close
            </Icon>
          </DFABox>
        </DFABox>
        <Divider sx={{ margin: 0 }} light />
        <DFABox
          p={1.5}
          id="errorId"
          sx={({ other, palette: { text } }: Theme) => ({
            fontSize: other.baseTypographyProp.fontSizeSM,
            color: text.primary,
          })}
          component="p"
        >
          {snackContent}
        </DFABox>
      </DFABox>
    </Snackbar>
  );
}

export default DFASnackbar;
