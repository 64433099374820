// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1ocj1c7-MuiButtonBase-root-MuiIconButton-root {
    color: #ffffff80 !important;
}

.css-1ocj1c7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
    color: #ffffff20 !important;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/Sidenav/styles/style.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".css-1ocj1c7-MuiButtonBase-root-MuiIconButton-root {\n    color: #ffffff80 !important;\n}\n\n.css-1ocj1c7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {\n    color: #ffffff20 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
