import { RepresentativeStatusGql } from "@front-packages/dfa-gql-api";
import { SET_REPRESENTATIVE_ID, SET_REPRESENTATIVE_STATUS, SET_LOADING, SET_EDIT } from "./actions";

export interface ICustomerState {
  representativeID: string;
  representativeStatus: RepresentativeStatusGql;
  loading: boolean;
  isEdit: boolean;
}

const initialState: ICustomerState = {
  representativeID: null,
  representativeStatus: null,
  loading: false,
  isEdit: false,
};

export default function reducer(state: ICustomerState = initialState, action) {
  switch (action.type) {
    case SET_REPRESENTATIVE_ID: {
      return { ...state, representativeID: action.payload };
    }
    case SET_REPRESENTATIVE_STATUS: {
      return { ...state, representativeStatus: action.payload };
    }
    case SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case SET_EDIT: {
      return { ...state, isEdit: action.payload };
    }
    default: {
      return state;
    }
  }
}
