import { GetDepositOrdersListOpts } from "@front-packages/dfa-gql-api";

export const SET_REFILL_DATA = "@@refill/SET_REFILL_DATA";
export const SET_REFILL_LOADING = "@@refill/SET_REFILL_LOADING";
export const SET_REFILL_PAGE = "@@refill/SET_REFILL_PAGE";
export const SET_REFILL_COUNT = "@@refill/SET_REFILL_COUNT";
export const SET_REFILL_SEARCH_OPTS = "@@refill/SET_REFILL_SEARCH_OPTS";

export const setRefillData = (payload: object) => ({ type: SET_REFILL_DATA, payload });
export const setRefillLoading = (payload: boolean) => ({
  type: SET_REFILL_LOADING,
  payload,
});
export const setRefillPage = (payload: number) => ({ type: SET_REFILL_PAGE, payload });
export const setRefillCount = (payload: number) => ({ type: SET_REFILL_COUNT, payload });
export const setRefillSearchOpts = (payload: Partial<GetDepositOrdersListOpts>) => ({
  type: SET_REFILL_SEARCH_OPTS,
  payload,
});
