import { OfflineSigner, Registry } from "@cosmjs/proto-signing";
import { SigningStargateClient } from "@cosmjs/stargate";
import { MsgInitAcl, MsgSetQualStatus, MsgSetStatus, MsgSetBlock, MsgSetRole } from "./types/acl";
import {
  MsgHold,
  MsgTransfer,
  MsgTransferUnhold,
  TokenInformation,
  // MsgIssueUnhold,
  MsgAdd,
  MsgBurn,
  MsgInit,
} from "./types/fiat";
import {
  MsgTransferToken,
  MsgSetStatusToken,
  MsgBurnToken,
  MsgAddToken,
  MsgHoldToken,
  MsgTransferUnholdToken,
} from "./types/token";
import {
  MsgInitIssue,
  // MsgStartIssue,
  // MsgAddAmountIssue,
  // MsgRemoveAmountIssue,
  // MsgCompleteIssue,
  MsgInitTokenIssue,
} from "./types/issue";
import {
  MsgAuthorized,
  MsgStoreCode,
  MsgInstantiateContract,
  MsgExecuteContract,
  MsgMigrateContract,
  MsgUpdateAdmin,
  MsgClearAdmin,
} from "./types/wasm";

const registry = new Registry([
  ["/rr.bcpcore.acl.MsgInit", MsgInitAcl],
  ["/rr.bcpcore.acl.MsgSetQualStatus", MsgSetQualStatus],
  ["/rr.bcpcore.acl.MsgSetStatus", MsgSetStatus],
  ["/rr.bcpcore.acl.MsgSetBlock", MsgSetBlock],
  ["/rr.bcpcore.acl.MsgSetRole", MsgSetRole],
  ["/rr.bcpcore.fiat.MsgHold", MsgHold],
  ["/rr.bcpcore.fiat.MsgTransfer", MsgTransfer],
  ["/rr.bcpcore.fiat.MsgTransferUnhold", MsgTransferUnhold],
  ["/rr.bcpcore.fiat.TokenInformation", TokenInformation],
  // ["/rr.bcpcore.fiat.MsgIssueUnhold", MsgIssueUnhold],
  ["/rr.bcpcore.fiat.MsgAdd", MsgAdd],
  ["/rr.bcpcore.fiat.MsgBurn", MsgBurn],
  ["/rr.bcpcore.fiat.MsgInit", MsgInit],
  ["/rr.bcpcore.token.MsgTransfer", MsgTransferToken],
  ["/rr.bcpcore.token.MsgSetStatus", MsgSetStatusToken],
  ["/rr.bcpcore.token.MsgBurn", MsgBurnToken],
  ["/rr.bcpcore.token.MsgAdd", MsgAddToken],
  ["/rr.bcpcore.token.MsgHold", MsgHoldToken],
  ["/rr.bcpcore.token.MsgTransferUnhold", MsgTransferUnholdToken],
  ["/rr.bcpcore.issue.MsgInit", MsgInitIssue],
  // ["/rr.bcpcore.issue.MsgStart", MsgStartIssue],
  // ["/rr.bcpcore.issue.MsgAddAmount", MsgAddAmountIssue],
  // ["/rr.bcpcore.issue.MsgRemoveAmount", MsgRemoveAmountIssue],
  // ["/rr.bcpcore.issue.MsgComplete", MsgCompleteIssue],
  ["/rr.bcpcore.issue.MsgInitToken", MsgInitTokenIssue],
  ["/rr.bcpcore.wasm.MsgAuthorized", MsgAuthorized],
  ["/rr.bcpcore.wasm.MsgStoreCode", MsgStoreCode],
  ["/rr.bcpcore.wasm.MsgInstantiateContract", MsgInstantiateContract],
  ["/rr.bcpcore.wasm.MsgExecuteContract", MsgExecuteContract],
  ["/rr.bcpcore.wasm.MsgMigrateContract", MsgMigrateContract],
  ["/rr.bcpcore.wasm.MsgUpdateAdmin", MsgUpdateAdmin],
  ["/rr.bcpcore.wasm.MsgClearAdmin", MsgClearAdmin],
]);

const getClientTx = (wallet: OfflineSigner): Promise<SigningStargateClient> =>
  SigningStargateClient.connectWithSigner(process.env.REACT_APP_CORE_API, wallet, {
    registry,
  });

export default getClientTx;
