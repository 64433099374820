import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useInitialState, useInputChange } from "hooks/transactions";
import InputField, { IHolds, HandleSetHoldsType } from "./InputField";
import SelectField from "./SelectField";

export default function MsgForm() {
  const { initialStateArr } = useInitialState();

  const {
    handleChange,
    localError,
    handleDispatchLocalError,
    handleAddHolds,
    handleDispatchMsgValue,
    localValue,
  } = useInputChange();
  const [holds, setHolds] = useState<IHolds>({
    addr: "",
    amount: "",
  });

  const handleSetHolds: HandleSetHoldsType = (e, name: string) => {
    setHolds({
      ...holds,
      [name]: name === "amount" ? +e.target.value : e.target.value,
    });
    handleDispatchLocalError(e);
  };
  return initialStateArr.map((item) => {
    const val = localValue?.value;
    if (item[1]?.type?.includes("input")) {
      return (
        <Grid>
          <InputField
            inputName={item[0]}
            inputType={item[1]?.type}
            value={val}
            handleChange={handleChange}
            handleDispatchLocalError={handleDispatchLocalError}
            handleDispatchMsgValue={handleDispatchMsgValue}
            handleSetHolds={handleSetHolds}
            error={localError}
          />
        </Grid>
      );
    }
    if (item[1]?.type?.includes("Select"))
      return (
        <SelectField
          inputName={item[0]}
          inputType={item[1]?.type}
          selectNames={item[1].selectNames}
          value={val}
          handleChange={handleChange}
          handleDispatchMsgValue={handleDispatchMsgValue}
        />
      );
    if (typeof item[1] === "object") {
      return Object.entries(item[1]).map((el: any) => {
        if (el[1]?.type?.includes("input")) {
          return (
            <Grid>
              <InputField
                inputName={el[0]}
                inputType={el[1]?.type}
                parentObjKey={item[0]}
                value={val}
                handleChange={handleChange}
                handleDispatchLocalError={handleDispatchLocalError}
                handleDispatchMsgValue={handleDispatchMsgValue}
                error={localError?.[item[0]]?.[el[0]]}
                holds={holds}
                handleSetHolds={handleSetHolds}
                setHolds={setHolds}
                handleAddHolds={handleAddHolds}
              />
            </Grid>
          );
        }
        if (el[1]?.type?.includes("Select")) {
          return (
            <SelectField
              inputName={el[0]}
              inputType={el[1]?.type}
              selectNames={el[1].selectNames}
              parentObjKey={item[0]}
              value={val}
              handleChange={handleChange}
              handleDispatchMsgValue={handleDispatchMsgValue}
            />
          );
        }
        if (typeof el[1] === "object") {
          return Object.entries(el[1]).map((innerEl: any) => {
            if (innerEl[1]?.type?.includes("input"))
              return (
                <Grid mb={2} xs={12}>
                  <InputField
                    inputName={innerEl[0]}
                    inputType={innerEl[1]?.type}
                    parentObjKey={[item[0]]?.[el[0]]}
                    value={val}
                    handleChange={handleChange}
                    handleDispatchLocalError={handleDispatchLocalError}
                    handleDispatchMsgValue={handleDispatchMsgValue}
                    error={localError}
                  />
                </Grid>
              );
            if (innerEl[1]?.type?.includes("Select"))
              return <Grid sx={{ color: "red" }}>Something wrong</Grid>;
            return <Grid sx={{ color: "red" }}>Something wrong</Grid>;
          });
        }
        return <Grid sx={{ color: "red" }}>Something wrong</Grid>;
      });
    }
    return <Grid sx={{ color: "red" }}>Something wrong</Grid>;
  });
}
