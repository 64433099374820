import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import {
  PermissionsEnum,
  ModalsView,
  Permissions,
  PermissionType,
} from "@front-packages/dfa-constants";
import { AdministrationAPI } from "@front-packages/dfa-gql-api";
import { DFACard, DFADialog, DFABox, DFACheckBox, DFAChip, DFAEntityParams } from "Theme";
import { useStatuses } from "hooks/other";
import { IAdmin } from "../types";

interface IAdminCardProps {
  user: IAdmin;
  setError(error: string): void;
  onOpen(modal: ModalsView, uid: string): void;
}

type OpenModalType = {
  add: boolean;
  take: boolean;
  method: PermissionType;
};

function AdminCard({ onOpen, user, setError }: IAdminCardProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>(false);
  // TODO: Getapi заменить PermissionsEnum на енамку, когда мидл
  const [userPerm, setUserPerm] = useState<Array<PermissionsEnum>>(user.permissions as any);
  const [modal, setModal] = useState<OpenModalType>({ add: false, take: false, method: null });
  const [loading, setLoading] = useState<boolean>(false);
  const getStatusBadgeProps = useStatuses();

  const handleCloseModal = () => setModal({ add: false, take: false, method: null });
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSetPermByUid = async () => {
    const { method } = modal;
    const { response, error } = await AdministrationAPI.SetPermissionByUID({
      uid: user.uid,
      // TODO: Getapi заменить PermissionsEnum на енамку, когда мидл
      permission: method.value as any,
    });
    if (error) setError(error);
    if (response) setUserPerm((prev) => [...prev, method.value]);
    handleCloseModal();
  };
  const handleUnsetPermByUid = async () => {
    const { method } = modal;
    const { response, error } = await AdministrationAPI.UnsetPermissionByUID({
      uid: user.uid,
      // TODO: Getapi заменить PermissionsEnum на енамку, когда мидл
      permission: method.value as any,
    });
    if (error) setError(error);
    if (response) setUserPerm((prev) => prev.filter((el) => el !== method.value));
    handleCloseModal();
  };
  const handleConfirm = async () => {
    setLoading(true);
    const { add } = modal;
    if (add) await handleSetPermByUid();
    else await handleUnsetPermByUid();
    setLoading(false);
  };
  const onCheck = (e: ChangeEvent<HTMLInputElement>, method: PermissionType) => {
    if (e.target.checked) setModal({ add: true, take: false, method });
    else setModal({ add: false, take: true, method });
  };

  return (
    <>
      <DFACard>
        {/* <DFABox p={3} height="100%" variant="gradient" bgColor="dark" borderRadius="xxl"> */}
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <DFAEntityParams label={t("layouts.admins.card.name")} text={user.login} />
          </Grid>
          <Grid item xs={2}>
            <DFAEntityParams
              label={t("layouts.admins.card.status")}
              chip={
                <DFAChip
                  variant="outlined"
                  {...getStatusBadgeProps(user.status, "Admin")}
                  type={getStatusBadgeProps(user.status, "Admin").color as any}
                />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <DFAEntityParams
              label={t("layouts.admins.card.date")}
              textSize="small"
              text={new Date(user.registrationDate).toLocaleString("ru-RU")}
            />
          </Grid>
          <Grid item xs={2}>
            <DFAEntityParams
              label={t("layouts.admins.card.actions")}
              text={
                <DFABox>
                  <IconButton
                    aria-label={t("layouts.admins.buttons.userDel")}
                    color="error"
                    onClick={() => onOpen(ModalsView.deleted, user.uid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    aria-label={t("layouts.admins.buttons.userBlocked")}
                    color="warning"
                    onClick={() => onOpen(ModalsView.blocked, user.uid)}
                  >
                    <LockIcon />
                  </IconButton>
                </DFABox>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DFABox mt={2} variant="gradient" bgColor="success" borderRadius="xxl">
              <Accordion
                // TODO: потом сделать кастомный компонент и цвет брать из темы
                sx={{ bgcolor: "#222322" }}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography variant="overline" textTransform="uppercase">
                    {t("layouts.admins.card.detail")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={4}>
                      <DFAEntityParams label={t("layouts.admins.card.uid")} text={user.uid} />
                    </Grid>
                    <Grid item xs={4}>
                      <DFAEntityParams label={t("layouts.admins.card.login")} text={user.login} />
                    </Grid>
                    <Grid item xs={3}>
                      <DFAEntityParams
                        label={t("layouts.admins.card.permissions.title")}
                        text={
                          <DFABox>
                            {Permissions.map((el) => (
                              <DFACheckBox
                                key={`key-${el.value}`}
                                label={t(`layouts.admins.card.permissions.${el.description}`)}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => onCheck(e, el)}
                                checked={!!userPerm.find((perm) => perm === el.value)}
                              />
                            ))}
                          </DFABox>
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </DFABox>
          </Grid>
        </Grid>
        {/* </DFABox> */}
      </DFACard>
      <DFADialog
        type="warning"
        open={modal.add || modal.take}
        title={
          modal.add ? t("layouts.admins.card.dialog.add") : t("layouts.admins.card.dialog.take")
        }
        confirmText={
          modal.take ? t("layouts.admins.buttons.delete") : t("layouts.admins.buttons.access")
        }
        cancelText={t("layouts.admins.buttons.cancel")}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        loading={loading}
      />
    </>
  );
}

export default AdminCard;
