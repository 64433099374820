import { shallowEqual, useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { setErrorMsgValue, setMsgValue } from "reducers/transactions/action";
import useInitialState from "./useInitialState";

export type HandleChangeType = (e, name: string, type: string, parentObjKey?: string) => void;
export type HandleDispatchLocalErrorType = (e) => void;
export type HandleDispatchMsgValueType = (e, name: string) => void;
export type HandleAddHoldsType = (value: any, setValue: any, name: string) => void;

const useInputChange = () => {
  const dispatch = useDispatch();
  const {
    transaction: { msgValue, transactionType, msgType, errorMsgValue },
  } = useTypedSelector((state) => state, shallowEqual);
  const { localError, setLocalError, setLocalValue, localValue } = useInitialState();

  const values = (name: string): string[] => {
    const arr = localValue?.value && Object.keys(localValue?.value);
    return arr.filter((item) => item !== name);
  };

  const handleSetErrorMsgValue = (e, name: string) => {
    const isValuesNotEmpty = () => {
      const isNotEmptyStrValues = (vals) =>
        vals.every((item) => String(localValue?.value[item])?.length);

      const isNotEmptyArrValues = (vals) =>
        vals.filter((el) => el[0] !== name).every((item: any) => String(item[1])?.length);

      if (values(name).some((item) => item === "holds")) {
        const strValues = values(name).filter((item) => item !== "holds");
        const arrValues = Object.entries(localValue?.value?.holds[0]);
        return isNotEmptyStrValues(strValues) && isNotEmptyArrValues(arrValues);
      }
      return values(name).every((item) => String(localValue?.value[item])?.length);
    };
    if (errorMsgValue && e.target.value && isValuesNotEmpty()) dispatch(setErrorMsgValue(false));
    if (e.target.value.length === 0) dispatch(setErrorMsgValue(true));
  };

  const handleDispatchInputValue = (e, name: string, parentObjKey?: string) => {
    if (transactionType === "token" && parentObjKey) {
      setLocalValue({
        ...localValue,
        value: {
          ...localValue.value,
          [parentObjKey]: {
            ...localValue?.value?.[parentObjKey],
            [name]: e.target.value,
          },
        },
      });
    } else {
      setLocalValue({
        ...localValue,
        value: {
          ...localValue.value,
          [name]: e.target.value,
        },
      });
    }
  };

  const handleDispatchInputNumValue = (e, name: string) => {
    setLocalValue({
      ...localValue,
      value: {
        ...localValue.value,
        [name]: +e.target.value.replace(/\D/g, ""),
      },
    });
  };

  const handleDispatchBoolSelectValue = (e, name: string) => {
    if (transactionType === "acl" && msgType === "MsgSetBlock") {
      setLocalValue({
        ...localValue,
        value: {
          ...localValue?.value,
          block: {
            ...localValue?.value?.block,
            [name]: JSON.parse(e.target.value),
          },
        },
      });
    } else {
      setLocalValue({
        ...localValue,
        value: {
          ...localValue.value,
          [name]: JSON.parse(e.target.value),
        },
      });
    }
  };

  const handleDispatchNumSelectValue = (e, name: string, parentObjKey?: string) => {
    if (transactionType === "token" && parentObjKey) {
      setLocalValue({
        ...localValue,
        value: {
          ...localValue.value,
          [parentObjKey]: {
            ...localValue?.value?.[parentObjKey],
            [name]: +e.target.value,
          },
        },
      });
    } else
      setLocalValue({
        ...localValue,
        value: {
          ...localValue.value,
          [name]: +e.target.value,
        },
      });
  };

  const handleDispatchLocalError: HandleDispatchLocalErrorType = (e) => {
    if (
      transactionType === "fiat" &&
      (msgType === "MsgHold" || msgType === "MsgTransferUnhold") &&
      (e.target.name === "addr" || e.target.name === "amount") &&
      !localValue?.value?.holds[0][e.target.name]
    ) {
      setLocalError({
        ...localError,
        holds: {
          ...localError.holds,
          [e.target.name]: !e.target.value,
        },
      });
    } else {
      setLocalError({
        ...localError,
        [e.target.name]: !e.target.value,
      });
    }
  };

  const handleChange: HandleChangeType = (e, name: string, type: string, parentObjKey?: string) => {
    switch (type) {
      case "input":
        handleDispatchInputValue(e, name, parentObjKey);
        handleSetErrorMsgValue(e, name);
        break;
      case "inputNum":
        handleDispatchInputNumValue(e, name);
        handleSetErrorMsgValue(e, name);
        break;
      case "boolSelect":
        handleDispatchBoolSelectValue(e, name);
        break;
      case "numSelect":
        handleDispatchNumSelectValue(e, name, parentObjKey);
        break;
      default:
        handleDispatchInputValue(e, name, parentObjKey);
        break;
    }
  };

  const handleAddHolds: HandleAddHoldsType = (value, setValue, name) => {
    const check = !!(
      localValue?.value?.holds[0].addr?.length || localValue?.value?.holds[0].amount?.length
    );
    if (value?.addr && value?.amount) {
      setLocalValue({
        ...localValue,
        value: {
          ...localValue.value,
          holds: check ? [...localValue.value.holds, value] : [value],
        },
      });
      dispatch(
        setMsgValue({
          ...msgValue,
          value: {
            ...msgValue.value,
            holds: check ? [...msgValue.value.holds, value] : [value],
          },
        })
      );
      if (values(name).every((item) => String(localValue?.value[item])?.length)) {
        dispatch(setErrorMsgValue(false));
      } else {
        dispatch(setErrorMsgValue(true));
      }
    }
    setValue({
      addr: "",
      amount: "",
    });
  };

  const handleDispatchMsgValue: HandleDispatchMsgValueType = (e, name) => {
    dispatch(
      setMsgValue({
        ...msgValue,
        ...localValue,
      })
    );
    handleDispatchLocalError(e);
    handleSetErrorMsgValue(e, name);
  };

  return {
    handleSetErrorMsgValue,
    handleChange,
    msgValue,
    transactionType,
    msgType,
    localError,
    handleDispatchLocalError,
    handleAddHolds,
    handleDispatchMsgValue,
    localValue,
  };
};

export default useInputChange;
