import { GetDepositOrdersListOpts, DepositOrders } from "@front-packages/dfa-gql-api";
import {
  SET_REFILL_DATA,
  SET_REFILL_LOADING,
  SET_REFILL_PAGE,
  SET_REFILL_COUNT,
  SET_REFILL_SEARCH_OPTS,
} from "./action";

export interface IInitRefillState {
  refillData: DepositOrders;
  refillLoading: boolean;
  refillPage: number;
  refillCount: number;
  refillSearchOpts: Partial<GetDepositOrdersListOpts>;
}

const initialState: IInitRefillState = {
  refillData: null,
  refillLoading: false,
  refillPage: 0,
  refillCount: 10,
  refillSearchOpts: {},
};

export default function reducer(state: IInitRefillState = initialState, action) {
  switch (action.type) {
    case SET_REFILL_DATA: {
      return { ...state, refillData: action.payload };
    }
    case SET_REFILL_LOADING: {
      return { ...state, refillLoading: action.payload };
    }
    case SET_REFILL_PAGE: {
      return { ...state, refillPage: action.payload };
    }
    case SET_REFILL_COUNT: {
      return { ...state, refillCount: action.payload };
    }
    case SET_REFILL_SEARCH_OPTS: {
      return { ...state, refillSearchOpts: action.payload };
    }
    default: {
      return state;
    }
  }
}
