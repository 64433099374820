import { ChangeEvent, useState } from "react";
import { FilesType, UploadAPI, UploadFilesPropsType } from "@front-packages/dfa-gql-api";
import { UseFilesResult } from "./types";

function useFiles(): UseFilesResult {
  const [data, setData] = useState<FilesType>({});

  const setProgress = (index: number, progress: number) => {
    setData((prev) => ({
      ...prev,
      [index]: { ...prev[index], progress },
    }));
  };

  const upload = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const key = await UploadAPI.UploadingFile(e.target.files[0]);
      setData({ 0: { progress: 100, name: e.target.files[0].name, key } });
    } catch (err) {
      console.log(err);
    }
  };

  const dropUpload = async (file) => {
    try {
      const key = await UploadAPI.UploadingFile(file);
      setData({ 0: { progress: 100, name: file.name, key } });
    } catch (err) {
      console.log(err);
    }
  };

  const multipleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    const uploadProps: UploadFilesPropsType = {
      initFiles: data,
      targetFiles: e.target.files,
      lastIndex: Object.keys(data).length,
      setProgress,
    };
    const result = await UploadAPI.MultipleUploadingFiles(uploadProps);
    setData(result);
  };

  const multipleDropUpload = async (files) => {
    const uploadProps: UploadFilesPropsType = {
      initFiles: data,
      targetFiles: files,
      lastIndex: Object.keys(data).length,
      setProgress,
    };
    const result = await UploadAPI.MultipleUploadingFiles(uploadProps);
    setData(result);
  };

  const del = (key: string) => {
    const newFiles = {};
    Object.values(data)
      .filter((el) => el.key !== key)
      .forEach((el, i) => {
        newFiles[i] = el;
      });
    setData(newFiles);
  };

  const reset = () => setData({});

  return { data, multipleUpload, del, reset, multipleDropUpload, upload, dropUpload };
}

export default useFiles;
