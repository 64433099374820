import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { DFADialog } from "Theme";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { assertIsDeliverTxSuccess } from "@cosmjs/stargate";
import { useTypedSelector } from "reducers";
import {
  setMsgValue,
  setMsgType,
  setTransactionType,
  setErrorMsgValue,
} from "reducers/transactions/action";
import getClientTx from "../../../services/TxClient/TxClient";
import SelectType from "./SelectType";
import SelectMsg from "./SelectMsg";
import MsgForm from "./MsgForm";

interface ISendTx {
  open: boolean;
  handleClose(): void;
  setIsActiveNotification: (a: boolean) => void;
  setNotificationInfo: any;
  setNotificationErrorCode: any;
  setHash: any;
  setOpenAlert: any;
  getTxs(): void;
}

export default function SendTX({
  open,
  handleClose,
  setIsActiveNotification,
  setNotificationInfo,
  setNotificationErrorCode,
  setHash,
  setOpenAlert,
  getTxs,
}: ISendTx) {
  const [errorTransactionType, setErrorTransactionType] = useState<boolean>(false);
  const [errorMsgType, setErrorMsgType] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    transaction: { msgType, transactionType, msgValue, errorMsgValue },
  } = useTypedSelector((state) => state, shallowEqual);

  const resetValue = () => {
    dispatch(
      setMsgValue({
        typeUrl: "",
        value: {
          creator: JSON.parse(localStorage.getItem("activeWallet"))?.address,
        },
      })
    );
    dispatch(setTransactionType(""));
    dispatch(setMsgType(""));
    dispatch(setErrorMsgValue(true));
    setIsDisabled(true);
  };

  const handleSend = (isSuccess) => {
    setNotificationInfo({
      ...msgValue,
      bgColor: isSuccess ? "success" : "warning",
    });
    setOpenAlert(true);
    setIsActiveNotification(true);
    handleClose();
    resetValue();
    getTxs();
  };
  const sendTx = async () => {
    setIsDisabled(true);
    try {
      const activeWallet = JSON.parse(localStorage.getItem("activeWallet"));
      const serializeWallet = CryptoJS.AES.decrypt(activeWallet.wallet, "password").toString(
        CryptoJS.enc.Utf8
      );
      const wallet = await DirectSecp256k1HdWallet.deserialize(serializeWallet, "1");
      const client = await getClientTx(wallet);
      const [firstAccount] = await wallet.getAccounts();

      const fee = {
        gas: "180000",
        amount: [
          {
            denom: "stake",
            amount: "0",
          },
        ],
      };
      if (msgType === "MsgInstantiateContract" || msgType === "MsgExecuteContract") {
        // TODO: не удалять
        // const ObjToUintArray = (object) => {
        //   const str = JSON.stringify(object, null, 0);
        //   const ret = new Uint8Array(str.length);
        //   for (let i = 0; i < str.length; i++) {
        //     ret[i] = str.charCodeAt(i);
        //   }
        //   return ret;
        // };

        const JsonToUnitArray = (json) => {
          const res = new Uint8Array(json.length);
          for (let i = 0; i < json.length; i++) {
            res[i] = json.charCodeAt(i);
          }
          return res;
        };

        const wasmValue = {
          ...msgValue,
          value: {
            ...msgValue?.value,
            msg: JsonToUnitArray(msgValue?.value?.msg),
            // msg: ObjToUintArray(msgValue?.value?.msg),
          },
        };
        const feeWasm = {
          gas: "180000",
          gasPrices: "0.0stake",
          gasPrice: "0.0stake",
          gasAdjusment: 1.3,
          amount: [
            {
              denom: "stake",
              amount: "0",
            },
          ],
        };
        const result = await client.signAndBroadcast(firstAccount.address, [wasmValue], feeWasm);
        assertIsDeliverTxSuccess(result);
        setHash(result.transactionHash);
      } else {
        const result = await client.signAndBroadcast(firstAccount.address, [msgValue], fee);
        assertIsDeliverTxSuccess(result);
        setHash(result.transactionHash);
      }
      handleSend(true);
    } catch (e) {
      handleSend(false);
      setNotificationErrorCode(
        e.message.substring(e.message.search("Code"), e.message.search(";"))
      );
      throw new Error(e);
    }
  };

  const handleSetFalseErrorTransactionType = () => setErrorTransactionType(false);

  const handleCloseDialog = () => {
    handleClose();
    resetValue();
    handleSetFalseErrorTransactionType();
  };

  useEffect(() => {
    if (transactionType?.length && msgType?.length && !errorMsgValue) setIsDisabled(false);
    else setIsDisabled(true);
  }, [transactionType, msgType, msgValue, errorMsgValue]);

  return (
    <DFADialog
      open={open}
      title={t("layouts.transactions.popup.title")}
      dialogText={<Typography>{t("layouts.transactions.popup.subTitle")}</Typography>}
      confirmText={t("general.buttons.sendTransaction")}
      cancelText={t("general.buttons.cancel")}
      onClose={handleCloseDialog}
      onConfirm={sendTx}
      disabled={isDisabled}
    >
      <Grid container mt={1}>
        <SelectType error={errorTransactionType} setError={setErrorTransactionType} />
        {!transactionType ? null : <SelectMsg error={errorMsgType} setError={setErrorMsgType} />}
        {transactionType && msgType ? <MsgForm /> : null}
      </Grid>
    </DFADialog>
  );
}
