import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { colors } from "../../colors";

interface MuiTextField {
  defaultProps?: ComponentsProps["MuiTextField"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTextField"];
  variants?: ComponentsVariants["MuiTextField"];
}

const { transparent } = colors;

const textField: MuiTextField = {
  styleOverrides: {
    root: {
      backgroundColor: transparent,
      "& .MuiFormHelperText-root": {
        textTransform: "none",
      },
    },
  },
};

export default textField;
