/**
DFA Platform Admin
***
Developed by ST soft team
*/

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress, Grid } from "@mui/material";
import { ModalsView } from "@front-packages/dfa-constants";
import { AdministrationAPI } from "@front-packages/dfa-gql-api";
import { DFAAlert, DFAButton, DFADialog, DFASnackbar } from "Theme";
import { useErrors } from "hooks/errors";
import { useTypedSelector } from "reducers";
import { setBlockedPages } from "reducers/auth/action";
import { AddAdmin, AdminCard } from "./components";
import "./styleDialog.css";
import { IAdmin } from "./types";

function Admins() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const route = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "")[0];
  const { blockedPages } = useTypedSelector(({ auth }) => auth);
  const [isOpen, setIsOpen] = useState<ModalsView>(null);
  const [changeUserUid, setChangeUserUid] = useState<string>(null);
  const [adminsList, setAdminsList] = useState<Array<IAdmin>>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { setError, errorMessage } = useErrors();

  const handleCloseModal = () => {
    setIsOpen(null);
    setChangeUserUid(null);
  };
  const handleOpenModal = (modal: ModalsView, uid: string) => {
    setIsOpen(modal);
    setChangeUserUid(uid);
  };
  const handleBlockedUser = async () => {
    setLoading(true);
    const { error } = await AdministrationAPI.SetUserBlockedDate({
      uid: changeUserUid,
      time: new Date().toISOString().split("T")[0],
    });
    if (error) setError(error);
    handleCloseModal();
    setLoading(false);
  };
  const handleCloseSnackbar = () => setError("");
  const handleGetAdminList = async () => {
    setLoading(true);
    const { response, error } = await AdministrationAPI.GetPlatformAdminList();
    if (error) setError(error);
    if (response) {
      const newAdminsList: Array<IAdmin> = response.map((admin) => ({
        ...admin,
        login: admin.phoneNumber,
        name: "no info",
        registrationDate: new Date().toISOString(),
        status:
          typeof admin.blockedFrom === "string" && admin.blockedFrom.split("-")[0] === "9999"
            ? "active"
            : "blocked",
      }));
      setAdminsList(newAdminsList);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetAdminList().catch((e) => console.error(e));
    return () => {
      dispatch(setBlockedPages({ admins: false }));
    };
  }, []);

  if (blockedPages[route]) {
    return (
      <Box>
        <DFAAlert type="error" title={errorMessage} />
      </Box>
    );
  }

  return (
    <>
      <Grid container marginTop={3} marginBottom={3} gap={3}>
        <Grid item xs={12} justifyContent="flex-end" alignItems="ceter" display="flex">
          {loading && <CircularProgress color="success" sx={{ mr: 2 }} />}
          <DFAButton
            color="success"
            variant="contained"
            onClick={() => setIsOpen(ModalsView.added)}
          >
            {t("layouts.admins.buttons.add")}
          </DFAButton>
        </Grid>
        {adminsList &&
          adminsList.map((user) => (
            <Grid item xs={12} key={user.uid}>
              <AdminCard user={user} onOpen={handleOpenModal} setError={setError} />
            </Grid>
          ))}
      </Grid>
      <DFADialog
        type="warning"
        open={isOpen === ModalsView.blocked}
        title={t(`layouts.admins.dialog.titleBlock`)}
        dialogText={t(`layouts.admins.dialog.textBlock`)}
        confirmText={t("layouts.admins.buttons.block")}
        cancelText={t("layouts.authenticate.dialog.cancel")}
        onClose={handleCloseModal}
        onConfirm={handleBlockedUser}
        loading={loading}
      />
      <DFADialog
        type="error"
        open={isOpen === ModalsView.deleted}
        title={t(`layouts.admins.dialog.titleDel`)}
        dialogText={t(`layouts.admins.dialog.textDel`)}
        confirmText={t("layouts.admins.buttons.delete")}
        cancelText={t("layouts.authenticate.dialog.cancel")}
        onClose={handleCloseModal}
        onConfirm={handleCloseModal}
        loading={loading}
      />
      <AddAdmin
        open={isOpen === ModalsView.added}
        onClose={handleCloseModal}
        getAdminsList={handleGetAdminList}
        setError={setError}
      />
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errorMessage}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage}
        onClose={handleCloseSnackbar}
        close={handleCloseSnackbar}
      />
    </>
  );
}

export default Admins;
