import { Skeleton, SkeletonProps } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import React, { forwardRef, Ref } from "react";

interface ISkeleton extends SkeletonProps {
  borderRadius: string;
}

type IDFAInputProps = {
  isLoading?: boolean;
  skeletonProps?: ISkeleton;
} & TextFieldProps;

const DFAInput = forwardRef((props: IDFAInputProps, ref: Ref<HTMLDivElement>) => {
  const { isLoading, skeletonProps, ...restProps } = props;
  return isLoading ? (
    <Skeleton
      variant={skeletonProps?.variant || "rounded"}
      animation={skeletonProps?.animation || "wave"}
      sx={{
        width: skeletonProps?.width || "100%",
        height: skeletonProps?.height || "47px",
        borderRadius: skeletonProps?.borderRadius || "7px",
      }}
    />
  ) : (
    <TextField ref={ref} {...restProps} />
  );
});

export default DFAInput;
