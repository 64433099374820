/*
 Developed by ST soft team
 */

import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";

interface DFADatePickerProps {
  value: Date | null;
  label: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange?: (selectedDate: Date | null) => void;
  id?: string | null;
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
}

function DFADatePicker({
  value,
  label,
  onChange,
  disabled,
  maxDate,
  minDate,
  disableFuture,
  disablePast,
  id,
}: DFADatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        disabled={disabled === undefined ? false : disabled}
        disableFuture={!!disableFuture}
        disablePast={!!disablePast}
        label={label}
        value={value || null}
        inputFormat="dd/MM/yyyy"
        onChange={onChange}
        minDate={minDate && new Date(minDate)}
        maxDate={maxDate && new Date(maxDate)}
        renderInput={(params) => <TextField id={id || label} {...params} sx={{ width: "100%" }} />}
      />
    </LocalizationProvider>
  );
}

export default DFADatePicker;
