import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import { DFADialog } from "Theme";
import { IConfirmDialogProps } from "./dialogTypes";

function BeneficiaryOwnerDialog({ isOpen, close, confirm }: IConfirmDialogProps) {
  const { t } = useTranslation();
  return (
    <DFADialog
      open={isOpen}
      title={t("layouts.card.clientCard.representatives.beneficiaryOwnersTitle")}
      onClose={close}
      type="error"
      onConfirm={confirm}
      confirmText="Сохранить"
      cancelText="Отменить"
      width="lg"
    >
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            id="beneficiaryOwnerName"
            label={t("layouts.card.clientCard.representatives.beneficiaryName")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="beneficiaryOwnerINN"
            label={t("layouts.card.clientCard.representatives.beneficiaryINN")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="beneficiaryOwnerDesicion"
            label={t("layouts.card.clientCard.representatives.decision")}
            fullWidth
          />
        </Grid>
      </Grid>
    </DFADialog>
  );
}

export default BeneficiaryOwnerDialog;
