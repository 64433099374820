import { RepaymentStatusEnum, WalletStatusEnum } from "@front-packages/dfa-constants";
import {
  ClientStatus,
  DeactivationOrderStatus,
  DepositOrderStatus,
  MarketOrderStatusGql,
  RepresentativeStatusGql,
  TokenOrderStatusSql,
  TokenStatusGql,
  WithdrawOrderStatus,
} from "@front-packages/dfa-gql-api";
import { useTranslation } from "react-i18next";

interface IGetStatusBadgeProps {
  label: string;
  color: string;
}

type StatusType =
  | "Company"
  | "Representative"
  | "Token"
  | "Order"
  | "RedeemOrder"
  | "Withdraw"
  | "Deposit"
  | "Wallet"
  | "Admin"
  | "Deactivation"
  | "MarketOrder";

const useStatuses = () => {
  const { t } = useTranslation();
  const getStatusBadgeProps = (status: string, type?: StatusType): IGetStatusBadgeProps => {
    switch (type) {
      case "Token":
        if (status === TokenStatusGql.Draft)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Draft}`),
            color: "secondary",
          };
        if (status === TokenStatusGql.OnApproval)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.OnApproval}`),
            color: "warning",
          };
        if (status === TokenStatusGql.Signing)
          return { label: t(`general.statuses.tokens.${TokenStatusGql.Signing}`), color: "info" };
        if (status === TokenStatusGql.Initiating)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Initiating}`),
            color: "info",
          };
        if (status === TokenStatusGql.Scheduled)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Scheduled}`),
            color: "warning",
          };
        if (status === TokenStatusGql.Started)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Started}`),
            color: "primary",
          };
        if (status === TokenStatusGql.Issued)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Issued}`),
            color: "primary",
          };
        if (status === TokenStatusGql.InMarket)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.InMarket}`),
            color: "primary",
          };
        if (status === TokenStatusGql.InRedeem)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.InRedeem}`),
            color: "success",
          };
        if (status === TokenStatusGql.Redeemed)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Redeemed}`),
            color: "success",
          };
        if (status === TokenStatusGql.Canceled)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Canceled}`),
            color: "error",
          };
        if (status === TokenStatusGql.Rejected)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Rejected}`),
            color: "error",
          };
        if (status === TokenStatusGql.Refund)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Refund}`),
            color: "secondary",
          };
        if (status === TokenStatusGql.Frozen)
          return {
            label: t(`general.statuses.tokens.${TokenStatusGql.Frozen}`),
            color: "secondary",
          };
        return { label: t(`general.statuses.noLabel`), color: "error" };
      case "Order":
        if (status === TokenOrderStatusSql.Draft)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.Draft}`),
            color: "secondary",
          };
        if (status === TokenOrderStatusSql.Signing)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.Signing}`),
            color: "info",
          };
        if (status === TokenOrderStatusSql.Canceled)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.Canceled}`),
            color: "error",
          };
        if (status === TokenOrderStatusSql.Registered)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.Registered}`),
            color: "primary",
          };
        if (status === TokenOrderStatusSql.Scheduled)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.Scheduled}`),
            color: "warning",
          };
        if (status === TokenOrderStatusSql.Refund)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.Refund}`),
            color: "secondary",
          };
        if (status === TokenOrderStatusSql.RefundError)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.RefundError}`),
            color: "secondary",
          };
        if (status === TokenOrderStatusSql.OnReject)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.OnReject}`),
            color: "error",
          };
        if (status === TokenOrderStatusSql.Rejected)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.Rejected}`),
            color: "error",
          };
        if (status === TokenOrderStatusSql.Completed)
          return {
            label: t(`general.statuses.orders.${TokenOrderStatusSql.Completed}`),
            color: "success",
          };
        return { label: t(`general.statuses.noLabel`), color: "error" };
      // TODO: Не надо уже???
      case "RedeemOrder":
        if (status === RepaymentStatusEnum.draft)
          return {
            label: t(`general.statuses.redeemOrders.${RepaymentStatusEnum.draft}`),
            color: "secondary",
          };
        if (status === RepaymentStatusEnum.signing)
          return {
            label: t(`general.statuses.redeemOrders.${RepaymentStatusEnum.signing}`),
            color: "info",
          };
        if (status === RepaymentStatusEnum.initiating)
          return {
            label: t(`general.statuses.redeemOrders.${RepaymentStatusEnum.initiating}`),
            color: "info",
          };
        if (status === RepaymentStatusEnum.completed)
          return {
            label: t(`general.statuses.redeemOrders.${RepaymentStatusEnum.completed}`),
            color: "success",
          };
        if (status === RepaymentStatusEnum.canceled)
          return {
            label: t(`general.statuses.redeemOrders.${RepaymentStatusEnum.canceled}`),
            color: "error",
          };
        if (
          status === RepaymentStatusEnum.rejectedCore ||
          status === RepaymentStatusEnum.rejectedAML ||
          status === RepaymentStatusEnum.rejectedMiddle
        )
          return { label: t(`general.statuses.redeemOrders.rejected`), color: "error" };
        return { label: t(`general.statuses.noLabel`), color: "error" };
      case "Withdraw":
        // if (status === WithdrawOrderStatus.Draft)
        //   return {
        //     label: t(`general.statuses.withdraw.${WithdrawOrderStatus.Draft}`),
        //     color: "secondary",
        //   };
        if (status === WithdrawOrderStatus.Signing)
          return {
            label: t(`general.statuses.withdraw.${WithdrawOrderStatus.Signing}`),
            color: "info",
          };
        if (status === WithdrawOrderStatus.Initiating)
          return {
            label: t(`general.statuses.withdraw.${WithdrawOrderStatus.Initiating}`),
            color: "info",
          };
        if (status === WithdrawOrderStatus.PaymentPreparing)
          return {
            label: t(`general.statuses.withdraw.${WithdrawOrderStatus.PaymentPreparing}`),
            color: "info",
          };
        if (status === WithdrawOrderStatus.SentToBank)
          return {
            label: t(`general.statuses.withdraw.${WithdrawOrderStatus.SentToBank}`),
            color: "info",
          };
        if (status === WithdrawOrderStatus.Completed)
          return {
            label: t(`general.statuses.withdraw.${WithdrawOrderStatus.Completed}`),
            color: "success",
          };
        if (
          status === WithdrawOrderStatus.RejectAml ||
          status === WithdrawOrderStatus.RejectBank ||
          status === WithdrawOrderStatus.RejectCore ||
          status === WithdrawOrderStatus.RejectMiddle
        )
          return { label: t(`general.statuses.withdraw.rejected`), color: "error" };
        if (status === WithdrawOrderStatus.Cancelled)
          return {
            label: t(`general.statuses.withdraw.${WithdrawOrderStatus.Cancelled}`),
            color: "error",
          }; // TODO: cancelled??? должны исправить
        return { label: t(`general.statuses.noLabel`), color: "error" };
      case "Deposit":
        if (status === DepositOrderStatus.DraftDepositStatus)
          return {
            label: t(`general.statuses.deposit.${DepositOrderStatus.DraftDepositStatus}`),
            color: "info",
          };
        // if (status === DepositOrderStatus["2fa_required"])
        //   return {
        //     label: t(`general.statuses.deposit.${DepositOrderStatus["2fa_required"]}`),
        //     color: "secondary",
        //   };
        if (status === DepositOrderStatus.WaitingPaymentDepositStatus)
          return {
            label: t(`general.statuses.deposit.${DepositOrderStatus.WaitingPaymentDepositStatus}`),
            color: "secondary",
          };
        // if (status === DepositOrderStatus.Initiating)
        //   return {
        //     label: t(`general.statuses.deposit.${DepositOrderStatus.Initiating}`),
        //     color: "info",
        //   };
        if (status === DepositOrderStatus.CompletedDepositStatus)
          return {
            label: t(`general.statuses.deposit.${DepositOrderStatus.CompletedDepositStatus}`),
            color: "success",
          };
        if (status === DepositOrderStatus.RejectedDepositStatus)
          return { label: t(`general.statuses.deposit.rejected`), color: "error" };
        // if (status === DepositOrderStatus.Cancelled)
        //   return {
        //     label: t(`general.statuses.deposit.${DepositOrderStatus.Cancelled}`),
        //     color: "error",
        //   }; // TODO: Cancelled??? должны исправить
        return { label: t(`general.statuses.noLabel`), color: "error" };
      case "Company":
        if (status === ClientStatus.New)
          return {
            label: t(`general.statuses.company.${ClientStatus.New}`),
            color: "secondary",
          };
        if (status === ClientStatus.KybNeeded)
          return {
            label: t(`general.statuses.company.${ClientStatus.KybNeeded}`),
            color: "info",
          };
        if (status === ClientStatus.KybInProcess)
          return {
            label: t(`general.statuses.company.${ClientStatus.KybInProcess}`),
            color: "warning",
          };
        if (status === ClientStatus.KybDone)
          return {
            label: t(`general.statuses.company.${ClientStatus.KybDone}`),
            color: "success",
          };
        if (status === ClientStatus.Active)
          return {
            label: t(`general.statuses.company.${ClientStatus.Active}`),
            color: "success",
          };
        if (status === ClientStatus.Blocked)
          return {
            label: t(`general.statuses.company.${ClientStatus.Blocked}`),
            color: "error",
          };
        if (status === ClientStatus.Deleted)
          return {
            label: t(`general.statuses.company.${ClientStatus.Deleted}`),
            color: "secondary",
          };
        return { label: t(`general.statuses.noLabel`), color: "error" };
      case "Representative":
        if (status === RepresentativeStatusGql.New)
          return {
            label: t(`general.statuses.representative.${RepresentativeStatusGql.New}`),
            color: "secondary",
          };
        if (status === RepresentativeStatusGql.Invited)
          return {
            label: t(`general.statuses.representative.${RepresentativeStatusGql.Invited}`),
            color: "secondary",
          };
        if (status === RepresentativeStatusGql.KycNeeded)
          return {
            label: t(`general.statuses.representative.${RepresentativeStatusGql.KycNeeded}`),
            color: "info",
          };
        if (status === RepresentativeStatusGql.KycInProcess)
          return {
            label: t(`general.statuses.representative.${RepresentativeStatusGql.KycInProcess}`),
            color: "warning",
          };
        if (status === RepresentativeStatusGql.KycDone)
          return {
            label: t(`general.statuses.representative.${RepresentativeStatusGql.KycDone}`),
            color: "success",
          };
        if (status === RepresentativeStatusGql.Initiating)
          return {
            label: t(`general.statuses.representative.${RepresentativeStatusGql.Initiating}`),
            color: "secondary",
          };
        if (status === RepresentativeStatusGql.Active)
          return {
            label: t(`general.statuses.representative.${RepresentativeStatusGql.Active}`),
            color: "success",
          };
        if (status === RepresentativeStatusGql.Blocked)
          return {
            label: t(`general.statuses.representative.${RepresentativeStatusGql.Blocked}`),
            color: "error",
          };
        if (status === RepresentativeStatusGql.Deleted)
          return {
            label: t(`general.statuses.representative.${RepresentativeStatusGql.Deleted}`),
            color: "secondary",
          };
        return { label: t(`general.statuses.noLabel`), color: "error" };
      case "Wallet":
        if (status === WalletStatusEnum.Active)
          return {
            label: t(`general.statuses.wallet.${WalletStatusEnum.Active}`),
            color: "success",
          };
        if (status === WalletStatusEnum.Frozen)
          return {
            label: t(`general.statuses.wallet.${WalletStatusEnum.Frozen}`),
            color: "warning",
          };
        if (status === WalletStatusEnum.Blocked)
          return {
            label: t(`general.statuses.wallet.${WalletStatusEnum.Blocked}`),
            color: "error",
          };
        if (status === WalletStatusEnum.Closed)
          return { label: t(`general.statuses.wallet.${WalletStatusEnum.Closed}`), color: "error" };
        return { label: t(`general.statuses.noLabel`), color: "error" };
      case "Admin":
        if (status === "active")
          return { label: t(`general.statuses.wallet.active`), color: "success" };
        if (status === "blocked")
          return { label: t(`general.statuses.wallet.blocked`), color: "error" };
        return { label: t(`general.statuses.noLabel`), color: "error" };
      case "Deactivation":
        if (status === DeactivationOrderStatus.CompletedDeactivationOrderStatus)
          return {
            label: t(
              `general.statuses.deactivation.${DeactivationOrderStatus.CompletedDeactivationOrderStatus}`
            ),
            color: "success",
          };
        if (status === DeactivationOrderStatus.InitiatedDeactivationOrderStatus)
          return {
            label: t(
              `general.statuses.deactivation.${DeactivationOrderStatus.InitiatedDeactivationOrderStatus}`
            ),
            color: "info",
          };
        if (status === DeactivationOrderStatus.NewDeactivationOrderStatus)
          return {
            label: t(
              `general.statuses.deactivation.${DeactivationOrderStatus.NewDeactivationOrderStatus}`
            ),
            color: "info",
          };
        if (status === DeactivationOrderStatus.RejectedDeactivationOrderStatus)
          return {
            label: t(
              `general.statuses.deactivation.${DeactivationOrderStatus.RejectedDeactivationOrderStatus}`
            ),
            color: "error",
          };
        return { label: t(`general.statuses.noLabel`), color: "error" };
      case "MarketOrder":
        if (status === MarketOrderStatusGql.CompleteMarketOrderStatus)
          return {
            label: t(
              `general.statuses.marketOrder.${MarketOrderStatusGql.CompleteMarketOrderStatus}`
            ),
            color: "success",
          };
        if (status === MarketOrderStatusGql.RegisteredMarketOrderStatus)
          return {
            label: t(
              `general.statuses.marketOrder.${MarketOrderStatusGql.RegisteredMarketOrderStatus}`
            ),
            color: "info",
          };
        if (status === MarketOrderStatusGql.RejectMarketOrderStatus)
          return {
            label: t(
              `general.statuses.marketOrder.${MarketOrderStatusGql.RejectMarketOrderStatus}`
            ),
            color: "error",
          };
        if (status === MarketOrderStatusGql.SigningMarketOrderStatus)
          return {
            label: t(
              `general.statuses.marketOrder.${MarketOrderStatusGql.SigningMarketOrderStatus}`
            ),
            color: "info",
          };
        return { label: t(`general.statuses.noLabel`), color: "error" };
      default:
        return { label: t(`general.statuses.noLabel`), color: "error" };
    }
  };
  return getStatusBadgeProps;
};

export default useStatuses;
