import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Accordion, AccordionDetails, AccordionSummary, Chip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GridColDef } from "@mui/x-data-grid";
import { DFABox, DFACard, DFAEntityParams, DFATable } from "Theme";
import { Block, Tx } from "@front-packages/dfa-gql-api";
import chipProps from "./chipProps";

interface IBlockChainCardProps {
  setTxDetail(tx: Tx): void;
  block: Block;
}

function BlockChainCard({
  block: { height, id, hash, date, txs },
  setTxDetail,
}: IBlockChainCardProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSetTxDetail = (field: any) => setTxDetail(field.row);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "hash", headerName: "Hash", width: 150 },
    { field: "type", headerName: "Type", width: 150 },
    { field: "blockID", headerName: "Type", width: 150 },
    { field: "date", headerName: "Date", width: 150 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return <Chip variant="outlined" size="medium" {...chipProps(params)} />;
      },
      width: 150,
    },
    { field: "addressFrom", headerName: "Address from", width: 150 },
    { field: "addressTo", headerName: "Address to", width: 150 },
    { field: "tokenFrom", headerName: "Token from", width: 150 },
    { field: "amountFrom", headerName: "Amount from", width: 150 },
    { field: "tokenTo", headerName: "Token to", width: 150 },
    { field: "amountTo", headerName: "Amount to", width: 150 },
    { field: "addressFee", headerName: "Address fee", width: 150 },
    { field: "tokenFee", headerName: "Token fee", width: 150 },
    { field: "amountFee", headerName: "Amount fee", width: 150 },
    { field: "description", headerName: "Description", width: 150 },
  ];

  return (
    <DFACard sx={{ height: "100%" }}>
      <DFABox display="flex" justifyContent="space-between">
        <DFAEntityParams
          label={t("layouts.blockchain.card_title")}
          textSize="small"
          text={height}
        />
        <DFAEntityParams label="ID" textSize="small" text={id} />
        <DFAEntityParams
          label="Дата"
          textSize="small"
          text={new Date(date).toLocaleString("ru-RU")}
        />
      </DFABox>
      <DFABox mt={2} lineHeight={1}>
        <DFAEntityParams label="Hash" textSize="small" text={hash} />
      </DFABox>
      <DFABox mt={2} variant="gradient" bgColor="success" borderRadius="xxl">
        <Accordion
          // TODO: потом сделать кастомный компонент и цвет брать из темы
          sx={{ bgcolor: "#222322" }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="overline" textTransform="uppercase">
              Транзакции
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DFATable rows={txs} columns={columns} onCellDoubleClick={handleSetTxDetail} />
          </AccordionDetails>
        </Accordion>
      </DFABox>
    </DFACard>
  );
}

export default BlockChainCard;
