/*
DFA Platform Admin
***
Developed by ST soft team
*/

/**
  The gradientChartLine() function helps you to create a gradient color for the chart line
 */

// Material Dashboard 2 React helper helpers
import rgba from "./rgba";
// eslint-disable-next-line import/extensions,import/no-unresolved
import { IGradientChartLine } from "../typings";

const gradientChartLine: IGradientChartLine = (chart, color, opacity = 0.2) => {
  const ctx = chart.getContext("2d");
  const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
  const primaryColor = rgba(color, opacity).toString();

  gradientStroke.addColorStop(1, primaryColor);
  gradientStroke.addColorStop(0.2, "rgba(72, 72, 176, 0.0)");
  gradientStroke.addColorStop(0, "rgba(203, 12, 159, 0)");

  return gradientStroke;
};

export default gradientChartLine;
