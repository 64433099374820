import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../base";

interface MuiAvatar {
  defaultProps?: ComponentsProps["MuiAvatar"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiAvatar"];
  variants?: ComponentsVariants["MuiAvatar"];
}

const { borderRadius } = borders;

const avatar: MuiAvatar = {
  styleOverrides: {
    root: {
      transition: "all 200ms ease-in-out",
    },

    rounded: {
      borderRadius: borderRadius.lg,
    },

    img: {
      height: "auto",
    },
  },
};

export default avatar;
