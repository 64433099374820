/*
DFA Platform Admin
***
Developed by ST soft team
*/

/**
  The boxShadow() function helps you to create a box shadow for an element
 */

// Material Dashboard 2 React helper helpers
import rgba from "./rgba";
// eslint-disable-next-line import/extensions,import/no-unresolved
import { IBoxShadow } from "../typings";

const boxShadow: IBoxShadow = (offset = [], radius = [], color, opacity, inset = "") => {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${x}px ${y}px ${blur}px ${spread}px ${rgba(color, opacity)}`;
};

export default boxShadow;
