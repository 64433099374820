import React, { useEffect, useState } from "react";
import { Grid, Pagination } from "@mui/material";
import { DFACard, DFATabsBar, DFATable } from "Theme";
import { TabsLabelType } from "@front-packages/dfa-constants";
import {
  AccountOperationAPI,
  DepositOrders,
  WithdrawOrdersResponse,
} from "@front-packages/dfa-gql-api";
import useColumns from "./useColumns";
import store from "../../../../../../../../store";

interface IOrderHistoryProps {
  representativeID: string;
  setError: (value: React.SetStateAction<string>) => void;
}
function OrderHistory({ representativeID, setError }: IOrderHistoryProps) {
  const [tabValue, setTabValue] = useState<number>(0);
  const [depositState, setDepositState] = useState<DepositOrders>();
  const [withdrawState, setWithdrawState] = useState<WithdrawOrdersResponse>();
  const [isDepositLoading, setIsDepositLoading] = useState<boolean>(false);
  const [isWithdrawLoading, setIsWithdrawLoading] = useState<boolean>(false);

  const { depositColumns, withdrawalColumns } = useColumns();

  const getWithdrawOrders = async (page = 0) => {
    setIsWithdrawLoading(true);
    const { response, error } = await AccountOperationAPI.GetWithdrawOrdersList({
      opts: { page, count: 10, companyName: store.getState().adaptiveThemeStyle.breadcrumb },
    });
    if (error) {
      setError(error);
    }
    if (response) {
      setWithdrawState(response);
    }
    setIsWithdrawLoading(false);
  };

  const getDepositRequest = async (page = 0) => {
    setIsDepositLoading(true);
    const { response, error } = await AccountOperationAPI.GetDepositOrdersList({
      opts: {
        page,
        count: 10,
        representativeID,
        clientID: "",
      },
    });
    if (error) setError(error);
    if (response) {
      setDepositState(response);
    }
    setIsDepositLoading(false);
  };

  const handleDepositPage = (_, page) => getDepositRequest(page - 1);
  const handleWithdrawalPage = (_, page) => getWithdrawOrders(page - 1);

  const historyLabels: TabsLabelType[] = [
    {
      key: 0,
      text: "Пополнения",
    },
    {
      key: 1,
      text: "Выводы",
    },
  ];
  const historyTabs = {
    0: (
      <Grid container gap={2}>
        <Grid item xs={12}>
          <DFATable
            rows={depositState?.orders || []}
            columns={depositColumns}
            loading={isDepositLoading}
          />
        </Grid>
        {depositState?.totalCount > 10 && (
          <Grid item xs={12}>
            <Pagination
              count={Math.ceil(depositState.totalCount / 10)}
              shape="rounded"
              onChange={handleDepositPage}
            />
          </Grid>
        )}
      </Grid>
    ),
    1: (
      <Grid container gap={2}>
        <Grid item xs={12}>
          <DFATable
            rows={withdrawState?.withdrawOrders || []}
            columns={withdrawalColumns}
            loading={isWithdrawLoading}
          />
        </Grid>
        {withdrawState?.count > 10 && (
          <Grid item xs={12}>
            <Pagination
              count={Math.ceil(withdrawState.count / 10)}
              shape="rounded"
              onChange={handleWithdrawalPage}
            />
          </Grid>
        )}
      </Grid>
    ),
  };

  useEffect(() => {
    if (tabValue === 0) getDepositRequest();
    if (tabValue === 1) getWithdrawOrders();
  }, [tabValue]);
  return (
    <DFACard title="Поручения клиента">
      <DFATabsBar tabValue={tabValue} setTabValue={setTabValue} labels={historyLabels} />
      {historyTabs[tabValue]}
    </DFACard>
  );
}

export default OrderHistory;
