import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { GridColDef } from "@mui/x-data-grid";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography, TextField, Pagination, Skeleton } from "@mui/material";
import { trimLetter } from "@front-packages/dfa-helpers";
import { useStatuses } from "hooks/other";
import { DFAAlert, DFAButton, DFAChip, DFABox, DFADialog, DFATable } from "Theme";
import { CompaniesAPI, GetCompaniesOpts, GetCompaniesResponse } from "@front-packages/dfa-gql-api";
import { useErrors } from "hooks/errors";
import { useTypedSelector } from "reducers";
import { setBlockedPages } from "reducers/auth/action";
import { RouteNamesEnum } from "consts";
import CustomersSearchForm from "./components/CustomersSearchForm";

function Customers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const route = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "")[0];
  const navigate = useNavigate();

  const getStatusBadgeProps = useStatuses();
  const { auth } = useTypedSelector((store) => store);
  const [state, setState] = useState<GetCompaniesResponse>({ companies: [], count: 0 });
  const [page, setPage] = useState<number>(0);
  const [count] = useState<number>(10);
  const [searchButton, setSearchButton] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isCancelOpenModal, setCancelOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [inn, setInn] = useState<string>("");
  const [kpp, setKpp] = useState<string>("");
  const memoryOpts = useRef<GetCompaniesOpts>();
  const { setError, errorMessage } = useErrors();

  const getCompanies = async (options?: GetCompaniesOpts) => {
    if (options) memoryOpts.current = options;
    if (!loading) {
      setLoading(true);
      setState({ companies: [], count: 0 });
    }
    const { response, error } = await CompaniesAPI.GetCompanies({
      opts: { page, count, ...options },
    });
    if (error) setError(error);
    if (response) setState(response);
    else setState({ companies: [], count: 0 });
    setLoading(false);
  };
  const resetAndGetCompanies = async (options?: GetCompaniesOpts) => {
    setPage(0);
    await getCompanies({ page: 0, count, ...options });
  };

  const handleSetInn = (e: ChangeEvent<HTMLInputElement>) => {
    setInn(trimLetter(e.target.value, 12));
    if (e.target.value !== inn) {
      setSearchButton(true);
      setError("");
    }
  };
  const handleSetKpp = (e: ChangeEvent<HTMLInputElement>) => {
    setKpp(trimLetter(e.target.value, 9));
    if (e.target.value !== kpp) {
      setSearchButton(true);
      setError("");
    }
  };
  const handleClickOpen = () => setOpenModal(true);
  const handleCancelOpenModal = () => {
    setCancelOpenModal(false);
    setInn("");
    setKpp("");
    setOpenModal(false);
    setSearchButton(true);
    setLoading(false);
    setError("");
  };
  const handleDoubleClickCell = (params: GridCellParams) => {
    if (!loading && params.field !== "__check__")
      navigate(`${RouteNamesEnum.customersCard}?id=${params.id}`);
  };
  const handleSetPage = async (_, changePage) => {
    setPage(changePage - 1);
    await getCompanies({ ...memoryOpts.current, page: changePage - 1, count });
  };
  const addCompany = async () => {
    setLoading(true);
    const dataEnrichment = await CompaniesAPI.EnrichCompanyData({ inn, kpp });
    if (dataEnrichment.error) {
      setError(dataEnrichment.error);
      setSearchButton(false);
      setLoading(false);
      return;
    }
    const { response, error } = await CompaniesAPI.AddCompany({
      inn: dataEnrichment.response.inn,
      kpp: dataEnrichment.response.kpp,
      files: [],
    });
    if (error) {
      setError(error);
      setSearchButton(false);
      setLoading(false);
      return;
    }
    if (response) navigate(`${RouteNamesEnum.customersCard}?id=${response}`);
    setLoading(false);
  };

  useEffect(() => {
    getCompanies().catch((e) => console.error(e));
    return () => {
      dispatch(setBlockedPages({ customers: false }));
    };
  }, []);

  const cellSkeleton = (
    <Skeleton variant="rounded" animation="wave" sx={{ width: "100%", borderRadius: "7px" }} />
  );

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: t("layouts.customers.table.columns.fullName"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? cellSkeleton : <Typography>{params.value}</Typography>;
      },
    },
    {
      field: "inn",
      headerName: t("layouts.customers.table.columns.inn"),
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? cellSkeleton : <Typography>{params.value}</Typography>;
      },
    },
    {
      field: "kpp",
      headerName: t("layouts.customers.table.columns.kpp"),
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? cellSkeleton : <Typography>{params.value}</Typography>;
      },
    },
    {
      field: "ogrn",
      headerName: t("layouts.customers.table.columns.ogrn"),
      width: 155,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? cellSkeleton : <Typography>{params.value}</Typography>;
      },
    },
    {
      field: "status",
      headerName: t("layouts.customers.table.columns.status"),
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? (
          cellSkeleton
        ) : (
          <DFAChip
            {...getStatusBadgeProps(params.value, "Company")}
            type={getStatusBadgeProps(params.value, "Company").color as any}
          />
        );
      },
      width: 200,
    },
    {
      field: "representativeID",
      headerName: t("layouts.customers.table.columns.representativeId"),
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return loading ? (
          cellSkeleton
        ) : (
          <DFAChip
            type={params.value ? "success" : "warning"}
            label={params.value ? `ID: ${params.value}` : "Нет"}
          />
        );
      },
      width: 200,
    },
    {
      field: "address",
      headerName: t("layouts.customers.table.columns.address"),
      width: 230,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? cellSkeleton : <Typography>{params.value}</Typography>;
      },
    },
  ];

  if (auth.blockedPages[route]) {
    return (
      <DFABox>
        <DFAAlert type="error" title={errorMessage} />
      </DFABox>
    );
  }

  return (
    <Grid container position="relative">
      <CustomersSearchForm onSearch={resetAndGetCompanies} />
      <Grid item xs={12} mb={2} display="flex" alignItems="center">
        <DFAButton
          startIcon={<AddIcon />}
          variant="contained"
          color="success"
          onClick={handleClickOpen}
          sx={{ mr: 2 }}
          id="signUpNewClient"
        >
          {t("layouts.customers.signUpClient")}
        </DFAButton>
        <DFADialog
          open={openModal}
          onClose={() => setCancelOpenModal(true)}
          confirmText={searchButton ? "Найти компанию" : "Ввести данные вручную"}
          onConfirm={searchButton ? addCompany : () => navigate(RouteNamesEnum.customersCard)}
          title={t("layouts.card.addNewCompany.title")}
          dialogText={t("layouts.card.addNewCompany.instruction")}
          disabled={inn.length < 10 || kpp.length < 9}
          width="xs"
          confirmId="findCompany"
          cancelId="closeFindCompany"
          loading={loading}
        >
          <Grid container spacing={2} mt="1px">
            <Grid item xs={6}>
              <TextField
                fullWidth
                error={inn.length < 10 && inn.length !== 0}
                label={t("layouts.card.clientCard.entityAttr.nameAndINN.INN")}
                value={inn}
                onChange={handleSetInn}
                type="text"
                autoFocus
                id="newCustomerInn"
                helperText={inn.length < 10 && inn.length !== 0 && "Введите корректный ИНН"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                error={kpp.length < 9 && kpp.length !== 0}
                label={t("layouts.card.clientCard.entityAttr.nameAndINN.KPP")}
                value={kpp}
                onChange={handleSetKpp}
                type="text"
                id="newCustomerKpp"
                helperText={kpp.length < 9 && kpp.length !== 0 && "Введите корректный КПП"}
              />
            </Grid>
            {errorMessage && (
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  textAlign="center"
                  id="newCustomerMessage"
                  sx={{ color: ({ palette }) => palette.warning.main }}
                >
                  {errorMessage}.{" "}
                  {errorMessage === "Компания не найдена" &&
                    "Для повторного поиска начните вводить ИНН или КПП."}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DFADialog>
        <DFADialog
          open={isCancelOpenModal}
          onClose={() => setCancelOpenModal(false)}
          onConfirm={handleCancelOpenModal}
          title={t(`general.cancelDialog.title`)}
          dialogText={t(`general.cancelDialog.text`)}
          confirmText={t("general.cancelDialog.close")}
          cancelText={t("general.cancelDialog.cancel")}
          confirmId="confirmCloseForm"
          cancelId="rejectCloseForm"
        />
      </Grid>
      <Grid item xs={12}>
        <DFATable
          rows={state.companies || []}
          columns={columns}
          onCellDoubleClick={handleDoubleClickCell}
          loading={loading}
          loadingCfg={{ skeletons: true }}
        />
      </Grid>
      {!!state.count && state.count > count && (
        <Grid item xs={12}>
          <Pagination
            count={Math.ceil(state.count / count)}
            shape="rounded"
            onChange={handleSetPage}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default Customers;
