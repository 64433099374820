import { CookieNamesEnum } from "@front-packages/dfa-constants";
import { deleteCookie } from "@front-packages/dfa-helpers";
import { RouteNamesEnum } from "consts";
import { resetBlockedPages, SET_IS_AUTH } from "./action";

const authMiddleware = (store) => (next) => async (action) => {
  if (action.type === SET_IS_AUTH) {
    if (!action.payload) {
      deleteCookie(CookieNamesEnum.token);
      deleteCookie(CookieNamesEnum.refreshToken);
      deleteCookie(CookieNamesEnum.user);
      deleteCookie(CookieNamesEnum.uid);
      store.dispatch(resetBlockedPages());
      window.location.pathname = RouteNamesEnum.auth;
    }
  }
  return next(action);
};

export default authMiddleware;
