import React from "react";
import { Typography } from "@mui/material";
import { DateRange } from "@front-packages/dfa-constants";
import DFADatePicker from "../DFADatePicker";
import DFABox from "../DFABox";

interface IRangeDate {
  label?: string;
  lang?: "ru" | "en";
  rangeDate: DateRange;
  setRangeDate: (rangeDate: DateRange) => void;
}

function RangeDate({ rangeDate, setRangeDate, label, lang = "ru" }: IRangeDate) {
  const setDate = (date: Date | null, isFirst: boolean) => {
    const formatDate: Date = date
      ? (new Date(date).toISOString().split("T")[0] as any)
      : ("" as any);
    setRangeDate(
      isFirst
        ? {
            firstDate: formatDate,
            lastDate: rangeDate?.lastDate ? rangeDate.lastDate : ("" as any),
          }
        : {
            firstDate: rangeDate?.firstDate ? rangeDate.firstDate : ("" as any),
            lastDate: formatDate,
          }
    );
  };
  const setFirstDate = (date: Date | null) => setDate(date, true);
  const setLastDate = (date: Date | null) => setDate(date, false);
  return (
    <DFABox>
      {label && (
        <Typography variant="h6" fontWeight="normal" mb={1}>
          {label}
        </Typography>
      )}
      <DFABox display="flex">
        <DFABox width="45%">
          <DFADatePicker
            value={rangeDate?.firstDate || null}
            maxDate={rangeDate?.lastDate || null}
            label={lang === "ru" ? "От" : "From"}
            onChange={setFirstDate}
          />
        </DFABox>
        <DFABox sx={{ margin: "auto" }}>&mdash;</DFABox>
        <DFABox width="45%">
          <DFADatePicker
            value={rangeDate?.lastDate || null}
            minDate={rangeDate?.firstDate || null}
            label={lang === "ru" ? "До" : "To"}
            onChange={setLastDate}
          />
        </DFABox>
      </DFABox>
    </DFABox>
  );
}

export default RangeDate;
