/* eslint-disable react/prop-types */
/*
 Developed by ST soft team
 */

import React, { forwardRef } from "react";
import { CircularProgress } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";
import ButtonRoot from "./ButtonRoot";

interface IDFAButton extends ButtonProps {
  size?: "small" | "medium" | "large";
  iconOnly?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
}

const DFAButton = forwardRef<HTMLButtonElement, IDFAButton>(
  (
    {
      loading,
      color = "inherit",
      variant = "contained",
      size = "medium",
      iconOnly = false,
      children,
      ...rest
    },
    ref: React.RefObject<HTMLButtonElement>
  ) => {
    return (
      <ButtonRoot
        {...rest}
        ref={ref}
        size={size}
        ownerState={{ color, variant, size, iconOnly }}
        {...(loading && {
          disabled: true,
          startIcon: (
            <CircularProgress
              color="inherit"
              sx={{ color: ({ other: { colors } }) => colors.gradients.light }}
              size={15}
            />
          ),
        })}
      >
        {children}
      </ButtonRoot>
    );
  }
);

export default DFAButton;
