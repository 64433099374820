import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

const useColumns = () => {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: "accountNumber",
      headerName: t("layouts.card.clientCard.accsAndOperations.accountNumber"),
      width: 200,
      headerAlign: "center",
    },
    {
      field: "accountType",
      headerName: t("layouts.card.clientCard.accsAndOperations.accountType"),
      renderCell: (params) => {
        return params.value === 1 ? "Номинальный" : "Расчетный";
      },
      width: 150,
      headerAlign: "center",
    },
    {
      field: "bankName",
      headerName: t("layouts.card.clientCard.accsAndOperations.bankName"),
      width: 230,
      headerAlign: "center",
    },
    {
      field: "bik",
      headerName: t("layouts.card.clientCard.accsAndOperations.bik"),
      width: 150,
      headerAlign: "center",
    },
    {
      field: "corrAccount",
      headerName: t("layouts.card.clientCard.accsAndOperations.corrAccount"),
      width: 150,
      headerAlign: "center",
    },
    {
      field: "inn",
      headerName: t("layouts.card.clientCard.accsAndOperations.inn"),
      width: 150,
      headerAlign: "center",
    },
    {
      field: "kpp",
      headerName: t("layouts.card.clientCard.accsAndOperations.kpp"),
      width: 150,
      headerAlign: "center",
    },
    {
      field: "swiftCode",
      headerName: t("layouts.card.clientCard.accsAndOperations.swiftCode"),
      width: 150,
      headerAlign: "center",
    },
  ];
  return columns;
};

export default useColumns;
