export enum MsgSetBlockAclEnum {
  customerAddr = "customerAddr",
  block = "block",
}

export enum BlockAclEnum {
  fiatReceiveBlock = "fiatReceiveBlock",
  fiatSendBlock = "fiatSendBlock",
  fiatToBankBlock = "fiatToBankBlock",
  fiatFromBankBlock = "fiatFromBankBlock",
  tokenReceiveBlock = "tokenReceiveBlock",
  tokenSendBlock = "tokenSendBlock",
}
