export const topCardStyle = {
  height: "49%",
  marginBottom: "2%",
};

export const middleCardStyle = {
  height: "31.5%",
  marginBottom: "2%",
};

export const bottomCardStyle = {
  height: "49%",
};
