import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { colors, shadows, palette } from "../../colors";
import { linearGradient } from "../../../helpers";

interface MuiSwitch {
  defaultProps?: ComponentsProps["MuiSwitch"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiSwitch"];
  variants?: ComponentsVariants["MuiSwitch"];
}

const { gradients, transparent } = colors;
const {
  common: { white },
  grey,
} = palette;
const { borderWidth } = borders;
const { md } = shadows;

const switchButton: MuiSwitch = {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    switchBase: {
      color: gradients.success.light,

      "&:hover": {
        backgroundColor: transparent,
      },

      "&.Mui-checked": {
        color: gradients.success.light,

        "&:hover": {
          backgroundColor: transparent,
        },

        "& .MuiSwitch-thumb": {
          borderColor: `${gradients.success.light} !important`,
        },

        "& + .MuiSwitch-track": {
          backgroundColor: `${gradients.success.light} !important`,
          borderColor: `${gradients.success.light} !important`,
          opacity: 1,
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        backgroundImage: linearGradient(gradients.info.light, gradients.info.dark),
      },
    },

    thumb: {
      backgroundColor: white,
      boxShadow: md,
      border: `${borderWidth[1]} solid ${grey[400]}`,
    },

    track: {
      width: "32px",
      height: "15px",
      backgroundColor: grey[400],
      border: `${borderWidth[1]} solid ${grey[400]}`,
      opacity: 1,
    },

    checked: {},
  },
};

export default switchButton;
