import React from "react";

import Link from "@mui/material/Link";
import { Box, Typography } from "@mui/material";
import { DFAChip } from "Theme";

type LinkType = {
  name: string;
  href: string;
};

interface IFooterProps {
  company?: LinkType;
}

const defCompany = { href: "https://stsoft.ru", name: "СМАРТ" };

function Footer({ company = defCompany }: IFooterProps) {
  const { href, name } = company;

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      mt="auto"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={16}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}.
        <Link href={href} target="_blank">
          <Typography variant="caption" fontWeight="medium">
            &nbsp;{name}&nbsp;
          </Typography>
        </Link>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box mr={1}>
          <DFAChip
            type="default"
            size="small"
            label={process.env.REACT_APP_VERSION}
            icon="none"
            variant="outlined"
          />
        </Box>
        <Box mr={1}>
          <DFAChip
            type="default"
            size="small"
            label={process.env.REACT_APP_MIDDLE_API}
            icon="none"
            variant="outlined"
          />
        </Box>
        <Box>
          <DFAChip
            type="default"
            size="small"
            label={process.env.REACT_APP_CORE_API}
            icon="none"
            variant="outlined"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
