import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { boxShadow } from "../../../helpers";
import { palette } from "../../colors";

interface MuiStepIcon {
  defaultProps?: ComponentsProps["MuiStepIcon"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiStepIcon"];
  variants?: ComponentsVariants["MuiStepIcon"];
}

const { white } = palette.common;

const stepIcon: MuiStepIcon = {
  styleOverrides: {
    root: {
      background: "#9fc9ff",
      fill: "#9fc9ff",
      stroke: "#9fc9ff",
      strokeWidth: "10px",
      width: "13px",
      height: "13px",
      borderRadius: "50%",
      zIndex: 99,
      transition: "all 200ms linear",

      "&.Mui-active": {
        background: white,
        fill: white,
        stroke: white,
        borderColor: white,
        boxShadow: boxShadow([0, 0], [0, 2], white, 1),
      },

      "&.Mui-completed": {
        background: white,
        fill: white,
        stroke: white,
        borderColor: white,
        boxShadow: boxShadow([0, 0], [0, 2], white, 1),
      },
    },
  },
};

export default stepIcon;
