/*
DFA Platform Admin
***
Developed by ST soft team
*/

import React from "react";
import { shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Skeleton } from "@mui/material";
import { DFABox } from "Theme";
import { useTypedSelector } from "reducers";
import { RouteNamesEnum } from "consts";
import Footer from "../Footer";
import DashboardNavbar from "../DashboardNavbar";

interface IDefaultLayoutProps {
  children: React.ReactNode;
}

function DefaultLayout({ children }: IDefaultLayoutProps) {
  const {
    adaptiveThemeStyle: { miniSidenav, mainTitle },
    auth: { isAuth },
  } = useTypedSelector((state) => state, shallowEqual);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const key = pathname.split("/")[pathname.split("/").length - 1];
  return (
    <DFABox
      sx={({ breakpoints, transitions }) => ({
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        p: 3,
        position: "relative",
        [breakpoints.up("xl")]: {
          ...(!pathname.includes("authenticate") && {
            marginLeft: miniSidenav ? "120px" : "274px",
          }),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <DashboardNavbar />
      {isAuth && !pathname.includes("authenticate") && (
        <Grid container>
          <Grid item xs={pathname === RouteNamesEnum.checkEmission ? 12 : 8}>
            <DFABox sx={{ mt: 2, mb: 2 }}>
              {mainTitle.isLoading ? (
                <Skeleton
                  variant="rounded"
                  height={35}
                  sx={{ width: "40%", maxWidth: "500px", borderRadius: "50px" }}
                  animation="wave"
                />
              ) : (
                <Typography variant="h2">
                  {mainTitle.title ? mainTitle.title : t(`layouts.${key}.mainTitle`)}
                  {mainTitle.chip && "  "}
                  {mainTitle.chip && mainTitle.chip}
                </Typography>
              )}
            </DFABox>
          </Grid>
        </Grid>
      )}
      {children}
      <Footer />
    </DFABox>
  );
}

export default DefaultLayout;
