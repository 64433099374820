import { ITransactionsList } from "./types";
import { TransactionsListEnum, AclEnum, FiatEnum, IssueEnum, TokenEnum, WasmEnum } from "./enums";

const transactionsList: ITransactionsList = {
  [TransactionsListEnum.acl]: {
    // Test: {
    //   msg: "MsgSend",
    //   value: {
    //     recipientAddress: {
    //       value: "",
    //       type: "input",
    //     },
    //     inputAmount: {
    //       value: "",
    //       type: "input",
    //     },
    //   },
    // },
    [AclEnum.MsgInit]: {
      msg: AclEnum.MsgInit,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
        customerId: {
          value: "",
          type: "input",
        },
      },
    },
    [AclEnum.MsgSetQualStatus]: {
      msg: AclEnum.MsgSetQualStatus,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
        qualStatus: {
          value: false,
          type: "boolSelect",
          selectNames: ["false", "true"],
        },
      },
    },
    [AclEnum.MsgSetStatus]: {
      msg: AclEnum.MsgSetStatus,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
        aclStatus: {
          value: 0,
          type: "numSelect",
          selectNames: ["active", "blocked", "disabled", "UNRECOGNIZED"],
        },
      },
    },
    [AclEnum.MsgSetRole]: {
      msg: AclEnum.MsgSetRole,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
        role: {
          value: 0,
          type: "numSelect",
          selectNames: ["investor", "emitent", "validator", "admin"],
        },
      },
    },
    [AclEnum.MsgSetBlock]: {
      msg: AclEnum.MsgSetBlock,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
        block: {
          fiatReceiveBlock: {
            value: false,
            type: "boolSelect",
            selectNames: ["false", "true"],
          },
          fiatSendBlock: {
            value: false,
            type: "boolSelect",
            selectNames: ["false", "true"],
          },
          fiatToBankBlock: {
            value: false,
            type: "boolSelect",
            selectNames: ["false", "true"],
          },
          fiatFromBankBlock: {
            value: false,
            type: "boolSelect",
            selectNames: ["false", "true"],
          },
          tokenReceiveBlock: {
            value: false,
            type: "boolSelect",
            selectNames: ["false", "true"],
          },
          tokenSendBlock: {
            value: false,
            type: "boolSelect",
            selectNames: ["false", "true"],
          },
        },
      },
    },
  },
  [TransactionsListEnum.fiat]: {
    [FiatEnum.MsgHold]: {
      msg: FiatEnum.MsgHold,
      value: {
        receiverAddr: {
          value: "",
          type: "input",
        },
        operationId: {
          value: "",
          type: "input",
        },
        holdDate: {
          value: "",
          type: "inputNum",
        },
        holdType: {
          value: 0,
          type: "numSelect",
          selectNames: ["ISSUE", "OTHER", "UNRECOGNIZED"],
        },
        holds: {
          addr: {
            value: "",
            type: "input",
          },
          amount: {
            value: "",
            type: "inputNum",
          },
        },
      },
    },
    [FiatEnum.MsgTransfer]: {
      msg: FiatEnum.MsgTransfer,
      value: {
        senderAddr: {
          value: "",
          type: "input",
        },
        amount: {
          value: "",
          type: "inputNum",
        },
        receiverAddr: {
          value: "",
          type: "input",
        },
        operationId: {
          value: "",
          type: "input",
        },
      },
    },
    [FiatEnum.MsgTransferUnhold]: {
      msg: FiatEnum.MsgTransferUnhold,
      value: {
        receiverAddr: {
          value: "",
          type: "input",
        },
        operationId: {
          value: "",
          type: "input",
        },
        holds: {
          addr: {
            value: "",
            type: "input",
          },
          amount: {
            value: "",
            type: "inputNum",
          },
        },
      },
    },
    // MsgIssueUnhold: {
    //   msg: "MsgIssueUnhold",
    //   value: {
    //     receiverAddr: {
    //       value: "",
    //       type: "input",
    //     },
    //     issueId: {
    //       value: "",
    //       type: "input",
    //     },
    //     customerAddrs: {
    //       value: [""],
    //       type: "input",
    //     },
    //     issueNominal: {
    //       value: "",
    //       type: "inputNum",
    //     },
    //     tokenInfos: {
    //       ticker: {
    //         value: "",
    //         type: "input",
    //       },
    //       amount: {
    //         value: "",
    //         type: "inputNum",
    //       },
    //     },
    //     operationId: {
    //       value: "",
    //       type: "input",
    //     },
    //   },
    // },
    [FiatEnum.MsgAdd]: {
      msg: FiatEnum.MsgAdd,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
        amount: {
          value: "",
          type: "inputNum",
        },
        operationId: {
          value: "",
          type: "input",
        },
      },
    },
    [FiatEnum.MsgBurn]: {
      msg: FiatEnum.MsgBurn,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
        amount: {
          value: "",
          type: "inputNum",
        },
        operationId: {
          value: "",
          type: "input",
        },
      },
    },
    [FiatEnum.MsgInit]: {
      msg: FiatEnum.MsgInit,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
      },
    },
  },
  [TransactionsListEnum.issue]: {
    [IssueEnum.MsgInit]: {
      msg: IssueEnum.MsgInit,
      value: {
        issueId: {
          value: "",
          type: "input",
        },
        emitentAddr: {
          value: "",
          type: "input",
        },
        investStartDate: {
          value: "",
          type: "inputNum",
        },
        investFinishDate: {
          value: "",
          type: "inputNum",
        },
        minOrderLimit: {
          value: "",
          type: "inputNum",
        },
        maxOrderLimit: {
          value: "",
          type: "inputNum",
        },
        dayOrderLimit: {
          value: "",
          type: "inputNum",
        },
        maxIssueLimit: {
          value: "",
          type: "inputNum",
        },
        issueNominal: {
          value: "",
          type: "inputNum",
        },
      },
    },
    // MsgStart: {
    //   msg: "MsgStart",
    //   value: {
    //     issueId: {
    //       value: "",
    //       type: "input",
    //     },
    //   },
    // },
    // MsgAddAmount: {
    //   msg: "MsgAddAmount",
    //   value: {
    //     issueId: {
    //       value: "",
    //       type: "input",
    //     },
    //     investorAddr: {
    //       value: "",
    //       type: "input",
    //     },
    //     amount: {
    //       value: "",
    //       type: "inputNum",
    //     },
    //   },
    // },
    // MsgRemoveAmount: {
    //   msg: "MsgRemoveAmount",
    //   value: {
    //     issueId: {
    //       value: "",
    //       type: "input",
    //     },
    //     investorAddr: {
    //       value: "",
    //       type: "input",
    //     },
    //   },
    // },
    // MsgComplete: {
    //   msg: "MsgComplete",
    //   value: {
    //     issueId: {
    //       value: "",
    //       type: "input",
    //     },
    //   },
    // },
    [IssueEnum.MsgInitToken]: {
      msg: IssueEnum.MsgInitToken,
      value: {
        issueId: {
          value: "",
          type: "input",
        },
        ticker: {
          value: "",
          type: "input",
        },
        nominal: {
          value: "",
          type: "inputNum",
        },
        interest: {
          value: "",
          type: "inputNum",
        },
        amount: {
          value: "",
          type: "inputNum",
        },
        marketDate: {
          value: "",
          type: "inputNum",
        },
        redemptionDate: {
          value: "",
          type: "inputNum",
        },
        minRedemptionLimit: {
          value: "",
          type: "inputNum",
        },
      },
    },
    [IssueEnum.MsgSetStatus]: {
      msg: IssueEnum.MsgSetStatus,
      value: {
        issueId: {
          value: "",
          type: "input",
        },
        status: {
          value: "",
          type: "numSelect",
          selectNames: [
            "Sheduled",
            "Started",
            "Passed",
            "NotPassed",
            "Rejected",
            "Canceled",
            "UNRECOGNIZED",
          ],
        },
      },
    },
  },
  [TransactionsListEnum.token]: {
    [TokenEnum.MsgTransfer]: {
      msg: TokenEnum.MsgTransfer,
      value: {
        senderWalletAddr: {
          addr: {
            value: "",
            type: "input",
          },
          walletType: {
            value: 0,
            type: "numSelect",
            selectNames: ["Investment", "Emission", "UNRECOGNIZED"],
          },
        },
        receiverWalletAddr: {
          addr: {
            value: "",
            type: "input",
          },
          walletType: {
            value: 0,
            type: "numSelect",
            selectNames: ["Investment", "Emission", "UNRECOGNIZED"],
          },
        },
        ticker: {
          value: "",
          type: "input",
        },
        amount: {
          value: "",
          type: "inputNum",
        },
        operationId: {
          value: "",
          type: "input",
        },
      },
    },
    [TokenEnum.MsgSetStatus]: {
      msg: TokenEnum.MsgSetStatus,
      value: {
        ticker: {
          value: "",
          type: "input",
        },
        status: {
          value: 0,
          type: "numSelect",
          selectNames: [
            "Instantiated",
            "Minted",
            "Issued",
            "InMarket",
            "InReedem",
            "Reedemed",
            "Frozen",
            "NotActive",
            "UNRECOGNIZED",
          ],
        },
        reason: {
          value: "",
          type: "input",
        },
      },
    },
    [TokenEnum.MsgBurn]: {
      msg: TokenEnum.MsgBurn,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
        ticker: {
          value: "",
          type: "input",
        },
        amount: {
          value: "",
          type: "inputNum",
        },
        operationId: {
          value: "",
          type: "input",
        },
      },
    },
    [TokenEnum.MsgAdd]: {
      msg: TokenEnum.MsgAdd,
      value: {
        customerAddr: {
          value: "",
          type: "input",
        },
        ticker: {
          value: "",
          type: "input",
        },
        amount: {
          value: "",
          type: "inputNum",
        },
        operationId: {
          value: "",
          type: "input",
        },
      },
    },
    [TokenEnum.MsgHold]: {
      msg: TokenEnum.MsgHold,
      value: {
        senderWalletAddr: {
          addr: {
            value: "",
            type: "input",
          },
          walletType: {
            value: 0,
            type: "numSelect",
            selectNames: ["Investment", "Emission", "UNRECOGNIZED"],
          },
        },
        receiverWalletAddr: {
          addr: {
            value: "",
            type: "input",
          },
          walletType: {
            value: 0,
            type: "numSelect",
            selectNames: ["Investment", "Emission", "UNRECOGNIZED"],
          },
        },
        ticker: {
          value: "",
          type: "input",
        },
        amount: {
          value: "",
          type: "inputNum",
        },
        operationId: {
          value: "",
          type: "input",
        },
        unHoldRest: {
          value: false,
          type: "boolSelect",
          selectNames: ["false", "true"],
        },
      },
    },
    [TokenEnum.MsgTransferUnhold]: {
      msg: TokenEnum.MsgTransferUnhold,
      value: {
        senderWalletAddr: {
          addr: {
            value: "",
            type: "input",
          },
          walletType: {
            value: 0,
            type: "numSelect",
            selectNames: ["Investment", "Emission", "UNRECOGNIZED"],
          },
        },
        receiverWalletAddr: {
          addr: {
            value: "",
            type: "input",
          },
          walletType: {
            value: 0,
            type: "numSelect",
            selectNames: ["Investment", "Emission", "UNRECOGNIZED"],
          },
        },
        ticker: {
          value: "",
          type: "input",
        },
        amount: {
          value: "",
          type: "inputNum",
        },
        operationId: {
          value: "",
          type: "input",
        },
        unHoldRest: {
          value: false,
          type: "boolSelect",
          selectNames: ["false", "true"],
        },
      },
    },
  },
  [TransactionsListEnum.wasm]: {
    // MsgAuthorized: {
    //   msg: "MsgAuthorized",
    //   value: {
    //     roles: {
    //       value: [""],
    //       type: "input",
    //     },
    //   },
    // },
    // MsgStoreCode: {
    //   msg: "MsgStoreCode",
    //   value: {
    //     sender: {
    //       value: "",
    //       type: "input",
    //     },
    //     wasm_byte_code: {
    //       value: "",
    //       type: "inputNum",
    //     },
    //   },
    // },
    [WasmEnum.MsgInstantiateContract]: {
      msg: WasmEnum.MsgInstantiateContract,
      value: {
        sender: {
          value: "",
          type: "input",
        },
        admin: {
          value: "",
          type: "input",
        },
        code_id: {
          value: "",
          type: "inputNum",
        },
        label: {
          value: "",
          type: "input",
        },
        msg: {
          value: "",
          type: "input",
        },
      },
    },
    [WasmEnum.MsgExecuteContract]: {
      msg: WasmEnum.MsgExecuteContract,
      value: {
        sender: {
          value: "",
          type: "input",
        },
        contract: {
          value: "",
          type: "input",
        },
        msg: {
          value: "",
          type: "input",
        },
      },
    },
    // MsgMigrateContract: {
    //   msg: "MsgMigrateContract",
    //   value: {
    //     sender: {
    //       value: "",
    //       type: "input",
    //     },
    //     contract: {
    //       value: "",
    //       type: "input",
    //     },
    //     code_id: {
    //       value: "",
    //       type: "inputNum",
    //     },
    //     msg: {
    //       value: "",
    //       type: "inputNum",
    //     },
    //   },
    // },
    // MsgUpdateAdmin: {
    //   msg: "MsgUpdateAdmin",
    //   value: {
    //     sender: {
    //       value: "",
    //       type: "input",
    //     },
    //     new_admin: {
    //       value: "",
    //       type: "input",
    //     },
    //     contract: {
    //       value: "",
    //       type: "input",
    //     },
    //   },
    // },
    // MsgClearAdmin: {
    //   msg: "MsgClearAdmin",
    //   value: {
    //     sender: {
    //       value: "",
    //       type: "input",
    //     },
    //     contract: {
    //       value: "",
    //       type: "input",
    //     },
    //   },
    // },
  },
};

export default transactionsList;
