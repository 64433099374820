import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

import { borders } from "../../../base";

const { borderRadius } = borders;

interface MuiTableHead {
  defaultProps?: ComponentsProps["MuiTableHead"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTableHead"];
  variants?: ComponentsVariants["MuiTableHead"];
}

const tableHead: MuiTableHead = {
  styleOverrides: {
    root: {
      display: "block",
      padding: "16px 16px 0  16px",
      borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
    },
  },
};

export default tableHead;
