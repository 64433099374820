import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { InputLabel, MenuItem, FormHelperText, FormControl, Select, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { setMsgType, setMsgValue, setTransactionType } from "reducers/transactions/action";
import transactionsList from "./transactionsList";

interface ISelectMsgPorps {
  error: boolean;
  setError(b: boolean): void;
}

export default function SelectType({ error, setError }: ISelectMsgPorps) {
  const dispatch = useDispatch();
  const {
    transaction: { msgType, transactionType },
  } = useTypedSelector((state) => state, shallowEqual);
  const { t } = useTranslation();

  const getValue = () =>
    transactionType !== "wasm"
      ? {}
      : {
          creator: JSON.parse(localStorage.getItem("activeWallet"))?.address,
        };

  const handleChange = (e) => {
    dispatch(setTransactionType(e.target.value));
    setError(!e.target.value);
    if (msgType) {
      dispatch(setMsgType(""));
      dispatch(
        setMsgValue({
          typeUrl: "",
          value: getValue,
        })
      );
    }
  };

  const handleBlur = (e) => {
    setError(!e.target.value);
  };

  return (
    <Grid mb={2} item xs={12}>
      <FormControl required sx={{ width: "100%" }}>
        <InputLabel sx={{ color: error ? "#F44335" : "" }} id="select-label">
          {t("layouts.transactions.popup.transactionType")}
        </InputLabel>
        <Select
          labelId="select-label"
          id="select-input"
          value={transactionType}
          label={t("layouts.transactions.popup.transactionType")}
          onChange={handleChange}
          onBlur={handleBlur}
          sx={{ height: "45px" }}
          error={error}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.keys(transactionsList).map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: error ? "#F44335" : "" }}>
          {error ? t("layouts.transactions.popup.errors.req") : ""}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
}
