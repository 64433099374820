import { ERR_FETCH, ERR_GLOBAL } from "./actions";

export interface IInitErrState {
  errFetch: string;
  errGlobal: string;
}

const initState: IInitErrState = {
  errFetch: null,
  errGlobal: null,
};

function errFetchReducer(state = initState, action) {
  switch (action.type) {
    case ERR_FETCH:
      return { errGlobal: null, errFetch: action.payload };

    case ERR_GLOBAL:
      return { errFetch: null, errGlobal: action.payload };

    default:
      return state;
  }
}

export default errFetchReducer;
