enum MsgInitTokenIssueEnum {
  issueId = "issueId",
  ticker = "ticker",
  nominal = "nominal",
  interest = "interest",
  amount = "amount",
  marketDate = "marketDate",
  redemptionDate = "redemptionDate",
  minRedemptionLimit = "minRedemptionLimit",
}

export default MsgInitTokenIssueEnum;
