import { useDispatch } from "react-redux";
import { CookieNamesEnum, ErrorsEnum } from "@front-packages/dfa-constants";
import { AuthAPI } from "@front-packages/dfa-gql-api";
import { getCookie, setCookie } from "@front-packages/dfa-helpers";
import { setIsAuth } from "reducers/auth/action";
import { setErrFetch } from "reducers/errFetch/actions";

type AuthCheckType = () => Promise<boolean>;
interface IAuthCheck {
  check: AuthCheckType;
}

const useAuthCheck = (): IAuthCheck => {
  const dispatch = useDispatch();

  const check: AuthCheckType = async () => {
    const refreshToken = getCookie(CookieNamesEnum.refreshToken);
    const token = getCookie(CookieNamesEnum.token);
    setCookie(CookieNamesEnum.token, "null");
    const { response, error } = await AuthAPI.RefreshAccessToken({ refreshToken });
    if (error) {
      switch (error) {
        case ErrorsEnum.AccessTokenIsNotExpired: {
          setCookie(CookieNamesEnum.token, token);
          dispatch(setIsAuth(true));
          return true;
        }
        case "Failed to fetch": {
          dispatch(setErrFetch("Нет связи с сервером. Проверьте доверенные сертификаты"));
          return true;
        }
        case ErrorsEnum.FailedToExecuteOperation: {
          setCookie(CookieNamesEnum.token, token);
          dispatch(setErrFetch("Произошел сбой на сервере, попробуйте обновить страницу"));
          return true;
        }
        case ErrorsEnum.InvalidAccessToken: {
          dispatch(setIsAuth(false));
          return false;
        }
        default: {
          dispatch(setIsAuth(false));
          return false;
        }
      }
    }
    const { accessToken, refreshToken: newRefreshToken } = response;
    setCookie(CookieNamesEnum.refreshToken, newRefreshToken);
    setCookie(CookieNamesEnum.token, accessToken);
    dispatch(setIsAuth(true));
    return true;
  };

  return { check };
};

export default useAuthCheck;
