/*
DFA Platform Admin
***
Developed by ST soft team
*/

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Typography, TextField, Card } from "@mui/material";
import PhoneNumber from "material-ui-phone-number";
import { CountryPhones, DefaultCountryPhone } from "@front-packages/dfa-constants";
import { DFAAlert, DFABox, DFAButton, DFADialog } from "Theme";
import { useAuth } from "hooks/authentication";
import { useValidate } from "hooks/other";
import { RouteNamesEnum } from "consts";
import getAlertData from "./getAlertData";

function SignIn() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const { invalidPhone } = useValidate({ phone: auth.get.phoneNumber });
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [sessionID, setSessionID] = useState<string>("");
  const handleSetCode = (e) => setCode(e.target.value);
  const handleSetPass = (e) => auth.set.password(e.target.value);
  const handleLogin = async () => {
    setLoading(true);
    const { phoneNumber, password } = auth.get;
    if (phoneNumber && password && !invalidPhone) {
      const { code: FACode, sessionID: FASessionID } = await auth.login();
      if (FACode && FASessionID) {
        setCode(FACode);
        setSessionID(FASessionID);
        setIsOpenModal(true);
      }
    }
    setLoading(false);
  };
  const handleSendCode = async () => {
    setLoading(true);
    await auth.sendCode(code, sessionID, RouteNamesEnum.customers);
  };
  const handleCloseModal = () => setIsOpenModal(false);
  const handleEnter = async (e: any) => {
    if (e.nativeEvent.charCode === 13) await handleLogin();
  };
  const toSignUp = () => navigate(RouteNamesEnum.signUp);

  const isDisabled = !auth.get.phoneNumber || !auth.get.password || invalidPhone;
  const { description, header, type } = getAlertData(process.env.REACT_APP_ENVIRONMENT);

  return (
    <DFABox
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
      onKeyPress={handleEnter}
    >
      <Card sx={{ width: "500px", minHeight: "330px" }}>
        <DFABox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Typography variant="h5">{t("layouts.authenticate.mainTitle")}</Typography>
        </DFABox>
        <DFABox p={3} pb={1}>
          <DFABox component="form" role="form">
            <DFABox mb={2}>
              <PhoneNumber
                defaultCountry={DefaultCountryPhone}
                onlyCountries={CountryPhones}
                label="Введите номер телефона"
                value={auth.get.phoneNumber}
                onChange={auth.set.phoneNumber as any}
                fullWidth
                variant="outlined"
                error={invalidPhone}
                helperText={invalidPhone && "Некорректный номер телефона"}
              />
            </DFABox>
            <DFABox mb={2}>
              <TextField
                type="password"
                value={auth.get.password}
                label={t("layouts.authenticate.pass")}
                fullWidth
                onChange={handleSetPass}
              />
            </DFABox>
            <DFABox mt={4}>
              <DFAButton
                color="success"
                id="log"
                fullWidth
                onClick={handleLogin}
                disabled={isDisabled}
                loading={loading}
              >
                {t("layouts.authenticate.options.btn")}
              </DFAButton>
            </DFABox>
            <DFABox width="100%" m="auto" mt={1} color="warning">
              <Typography variant="subtitle1" textAlign="center">
                {auth.get.errorMessage}
              </Typography>
            </DFABox>
          </DFABox>
        </DFABox>
        <DFABox width="max-content" m="auto">
          <DFAButton id="new" color="success" variant="text" onClick={toSignUp}>
            {t("layouts.authenticate.createNewUser")}
          </DFAButton>
        </DFABox>
      </Card>
      <DFABox mt={5} sx={{ minWidth: "500px", maxWidth: "500px" }}>
        <DFAAlert type={type} title={header}>
          {description}
        </DFAAlert>
      </DFABox>
      <DFADialog
        open={isOpenModal}
        title={t(`layouts.authenticate.dialog.title`)}
        onClose={handleCloseModal}
        onConfirm={handleSendCode}
        confirmText={t("layouts.authenticate.dialog.access")}
        cancelText={t("layouts.authenticate.dialog.cancel")}
        dialogText={t(`layouts.authenticate.dialog.text`)}
        loading={loading}
      >
        <TextField
          title="Введите проверочный код из смс"
          autoFocus
          value={code}
          margin="dense"
          id="name"
          label={t(`layouts.authenticate.dialog.sms`)}
          type="email"
          fullWidth
          variant="outlined"
          onInput={handleSetCode}
        />
      </DFADialog>
    </DFABox>
  );
}

export default SignIn;
