enum MsgTransferUnholdTokenEnum {
  senderWalletAddr = "senderWalletAddr",
  receiverWalletAddr = "receiverWalletAddr",
  ticker = "ticker",
  amount = "amount",
  operationId = "operationId",
  unHoldRest = "unHoldRest",
}

export default MsgTransferUnholdTokenEnum;
