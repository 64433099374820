import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { linearGradient } from "../../../helpers";
import { borders } from "../../../base";
import { colors, palette } from "../../colors";

interface MuiRadio {
  defaultProps?: ComponentsProps["MuiRadio"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiRadio"];
  variants?: ComponentsVariants["MuiRadio"];
}

const { borderWidth, borderColor } = borders;
const { transparent } = colors;
const { info } = palette;
const infoColor = "main" in info ? info.main : "inherit";

const radio: MuiRadio = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
        color: transparent,
        border: `${borderWidth[1]} solid ${borderColor}`,
        borderRadius: "50%",
      },

      "&:after": {
        transition: "opacity 250ms ease-in-out",
        content: `""`,
        position: "absolute",
        width: "14px",
        height: "14px",
        borderRadius: "50%",
        backgroundImage: linearGradient(infoColor, infoColor),
        opacity: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
      },

      "&:hover": {
        backgroundColor: transparent,
      },

      "&.Mui-focusVisible": {
        border: `${borderWidth[2]} solid ${infoColor} !important`,
      },
    },

    colorPrimary: {
      color: borderColor,

      "&.Mui-checked": {
        color: infoColor,

        "& .MuiSvgIcon-root": {
          borderColor: infoColor,
        },

        "&:after": {
          opacity: 1,
        },
      },
    },

    colorSecondary: {
      color: borderColor,

      "&.Mui-checked": {
        color: infoColor,

        "& .MuiSvgIcon-root": {
          borderColor: infoColor,
        },

        "&:after": {
          opacity: 1,
        },
      },
    },
  },
};

export default radio;
