/* eslint-disable */
import { Writer, Reader } from "protobufjs/minimal";

export const protobufPackage = "rr.bcpcore.acl";

export enum AclStatus {
  active = 0,
  blocked = 1,
  disabled = 2,
  UNRECOGNIZED = -1,
}

export function aclStatusFromJSON(object: any): AclStatus {
  switch (object) {
    case 0:
    case "active":
      return AclStatus.active;
    case 1:
    case "blocked":
      return AclStatus.blocked;
    case 2:
    case "disabled":
      return AclStatus.disabled;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AclStatus.UNRECOGNIZED;
  }
}

export function aclStatusToJSON(object: AclStatus): string {
  switch (object) {
    case AclStatus.active:
      return "active";
    case AclStatus.blocked:
      return "blocked";
    case AclStatus.disabled:
      return "disabled";
    default:
      return "UNKNOWN";
  }
}

export enum Role {
  investor = 0,
  emitent = 1,
  validator = 2,
  admin = 3,
  UNRECOGNIZED = -1,
}

export function roleFromJSON(object: any): Role {
  switch (object) {
    case 0:
    case "investor":
      return Role.investor;
    case 1:
    case "emitent":
      return Role.emitent;
    case 2:
    case "validator":
      return Role.validator;
    case 3:
    case "admin":
      return Role.admin;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Role.UNRECOGNIZED;
  }
}

export function roleToJSON(object: Role): string {
  switch (object) {
    case Role.investor:
      return "investor";
    case Role.emitent:
      return "emitent";
    case Role.validator:
      return "validator";
    case Role.admin:
      return "admin";
    default:
      return "UNKNOWN";
  }
}

export interface Blocking {
  fiatReceiveBlock: boolean;
  fiatSendBlock: boolean;
  fiatToBankBlock: boolean;
  fiatFromBankBlock: boolean;
  tokenReceiveBlock: boolean;
  tokenSendBlock: boolean;
}

export interface Acl {
  customerId: string;
  role: Role;
  status: AclStatus;
  qualStatus: boolean;
  blocking: Blocking | undefined;
}

const baseBlocking: object = {
  fiatReceiveBlock: false,
  fiatSendBlock: false,
  fiatToBankBlock: false,
  fiatFromBankBlock: false,
  tokenReceiveBlock: false,
  tokenSendBlock: false,
};

export const Blocking = {
  encode(message: Blocking, writer: Writer = Writer.create()): Writer {
    if (message.fiatReceiveBlock === true) {
      writer.uint32(8).bool(message.fiatReceiveBlock);
    }
    if (message.fiatSendBlock === true) {
      writer.uint32(16).bool(message.fiatSendBlock);
    }
    if (message.fiatToBankBlock === true) {
      writer.uint32(24).bool(message.fiatToBankBlock);
    }
    if (message.fiatFromBankBlock === true) {
      writer.uint32(32).bool(message.fiatFromBankBlock);
    }
    if (message.tokenReceiveBlock === true) {
      writer.uint32(40).bool(message.tokenReceiveBlock);
    }
    if (message.tokenSendBlock === true) {
      writer.uint32(48).bool(message.tokenSendBlock);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Blocking {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseBlocking } as Blocking;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.fiatReceiveBlock = reader.bool();
          break;
        case 2:
          message.fiatSendBlock = reader.bool();
          break;
        case 3:
          message.fiatToBankBlock = reader.bool();
          break;
        case 4:
          message.fiatFromBankBlock = reader.bool();
          break;
        case 5:
          message.tokenReceiveBlock = reader.bool();
          break;
        case 6:
          message.tokenSendBlock = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Blocking {
    const message = { ...baseBlocking } as Blocking;
    if (
      object.fiatReceiveBlock !== undefined &&
      object.fiatReceiveBlock !== null
    ) {
      message.fiatReceiveBlock = Boolean(object.fiatReceiveBlock);
    } else {
      message.fiatReceiveBlock = false;
    }
    if (object.fiatSendBlock !== undefined && object.fiatSendBlock !== null) {
      message.fiatSendBlock = Boolean(object.fiatSendBlock);
    } else {
      message.fiatSendBlock = false;
    }
    if (
      object.fiatToBankBlock !== undefined &&
      object.fiatToBankBlock !== null
    ) {
      message.fiatToBankBlock = Boolean(object.fiatToBankBlock);
    } else {
      message.fiatToBankBlock = false;
    }
    if (
      object.fiatFromBankBlock !== undefined &&
      object.fiatFromBankBlock !== null
    ) {
      message.fiatFromBankBlock = Boolean(object.fiatFromBankBlock);
    } else {
      message.fiatFromBankBlock = false;
    }
    if (
      object.tokenReceiveBlock !== undefined &&
      object.tokenReceiveBlock !== null
    ) {
      message.tokenReceiveBlock = Boolean(object.tokenReceiveBlock);
    } else {
      message.tokenReceiveBlock = false;
    }
    if (object.tokenSendBlock !== undefined && object.tokenSendBlock !== null) {
      message.tokenSendBlock = Boolean(object.tokenSendBlock);
    } else {
      message.tokenSendBlock = false;
    }
    return message;
  },

  toJSON(message: Blocking): unknown {
    const obj: any = {};
    message.fiatReceiveBlock !== undefined &&
      (obj.fiatReceiveBlock = message.fiatReceiveBlock);
    message.fiatSendBlock !== undefined &&
      (obj.fiatSendBlock = message.fiatSendBlock);
    message.fiatToBankBlock !== undefined &&
      (obj.fiatToBankBlock = message.fiatToBankBlock);
    message.fiatFromBankBlock !== undefined &&
      (obj.fiatFromBankBlock = message.fiatFromBankBlock);
    message.tokenReceiveBlock !== undefined &&
      (obj.tokenReceiveBlock = message.tokenReceiveBlock);
    message.tokenSendBlock !== undefined &&
      (obj.tokenSendBlock = message.tokenSendBlock);
    return obj;
  },

  fromPartial(object: DeepPartial<Blocking>): Blocking {
    const message = { ...baseBlocking } as Blocking;
    if (
      object.fiatReceiveBlock !== undefined &&
      object.fiatReceiveBlock !== null
    ) {
      message.fiatReceiveBlock = object.fiatReceiveBlock;
    } else {
      message.fiatReceiveBlock = false;
    }
    if (object.fiatSendBlock !== undefined && object.fiatSendBlock !== null) {
      message.fiatSendBlock = object.fiatSendBlock;
    } else {
      message.fiatSendBlock = false;
    }
    if (
      object.fiatToBankBlock !== undefined &&
      object.fiatToBankBlock !== null
    ) {
      message.fiatToBankBlock = object.fiatToBankBlock;
    } else {
      message.fiatToBankBlock = false;
    }
    if (
      object.fiatFromBankBlock !== undefined &&
      object.fiatFromBankBlock !== null
    ) {
      message.fiatFromBankBlock = object.fiatFromBankBlock;
    } else {
      message.fiatFromBankBlock = false;
    }
    if (
      object.tokenReceiveBlock !== undefined &&
      object.tokenReceiveBlock !== null
    ) {
      message.tokenReceiveBlock = object.tokenReceiveBlock;
    } else {
      message.tokenReceiveBlock = false;
    }
    if (object.tokenSendBlock !== undefined && object.tokenSendBlock !== null) {
      message.tokenSendBlock = object.tokenSendBlock;
    } else {
      message.tokenSendBlock = false;
    }
    return message;
  },
};

const baseAcl: object = {
  customerId: "",
  role: 0,
  status: 0,
  qualStatus: false,
};

export const Acl = {
  encode(message: Acl, writer: Writer = Writer.create()): Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.role !== 0) {
      writer.uint32(16).int32(message.role);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.qualStatus === true) {
      writer.uint32(32).bool(message.qualStatus);
    }
    if (message.blocking !== undefined) {
      Blocking.encode(message.blocking, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Acl {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAcl } as Acl;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.customerId = reader.string();
          break;
        case 2:
          message.role = reader.int32() as any;
          break;
        case 3:
          message.status = reader.int32() as any;
          break;
        case 4:
          message.qualStatus = reader.bool();
          break;
        case 5:
          message.blocking = Blocking.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Acl {
    const message = { ...baseAcl } as Acl;
    if (object.customerId !== undefined && object.customerId !== null) {
      message.customerId = String(object.customerId);
    } else {
      message.customerId = "";
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = roleFromJSON(object.role);
    } else {
      message.role = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = aclStatusFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.qualStatus !== undefined && object.qualStatus !== null) {
      message.qualStatus = Boolean(object.qualStatus);
    } else {
      message.qualStatus = false;
    }
    if (object.blocking !== undefined && object.blocking !== null) {
      message.blocking = Blocking.fromJSON(object.blocking);
    } else {
      message.blocking = undefined;
    }
    return message;
  },

  toJSON(message: Acl): unknown {
    const obj: any = {};
    message.customerId !== undefined && (obj.customerId = message.customerId);
    message.role !== undefined && (obj.role = roleToJSON(message.role));
    message.status !== undefined &&
      (obj.status = aclStatusToJSON(message.status));
    message.qualStatus !== undefined && (obj.qualStatus = message.qualStatus);
    message.blocking !== undefined &&
      (obj.blocking = message.blocking
        ? Blocking.toJSON(message.blocking)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Acl>): Acl {
    const message = { ...baseAcl } as Acl;
    if (object.customerId !== undefined && object.customerId !== null) {
      message.customerId = object.customerId;
    } else {
      message.customerId = "";
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = object.role;
    } else {
      message.role = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.qualStatus !== undefined && object.qualStatus !== null) {
      message.qualStatus = object.qualStatus;
    } else {
      message.qualStatus = false;
    }
    if (object.blocking !== undefined && object.blocking !== null) {
      message.blocking = Blocking.fromPartial(object.blocking);
    } else {
      message.blocking = undefined;
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
