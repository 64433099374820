import { colors, palette } from "../../colors";

import { baseProperties } from "../../colors/typography";

const { info, secondary } = palette;
const { transparent } = colors;
const { fontSizeSM, fontSizeXS } = baseProperties;
const infoMain = "main" in info ? info.main : "inherit";
const secondaryMain = "main" in secondary ? secondary.main : "inherit";

const outlined = {
  base: {
    minHeight: "39px",
    color: info[50],
    borderColor: info[50],
    padding: "9px 24px",

    "&:hover": {
      opacity: 0.75,
      backgroundColor: transparent,
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: "16px !important",
    },
  },

  small: {
    minHeight: "31px",
    padding: "6px 18px",
    fontSize: fontSizeXS,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: "12px !important",
    },
  },

  large: {
    minHeight: "46px",
    padding: "12px 64px",
    fontSize: fontSizeSM,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: "22px !important",
    },
  },

  primary: {
    backgroundColor: transparent,
    borderColor: infoMain,

    "&:hover": {
      backgroundColor: transparent,
    },
  },

  secondary: {
    backgroundColor: transparent,
    borderColor: secondaryMain,

    "&:hover": {
      backgroundColor: transparent,
    },
  },
};

export default outlined;
