/*
 Developed by ST soft team
 */

import { styled } from "@mui/material/styles";

export const CheckBoxIcon = styled("span")(({ theme }) => ({
  borderRadius: "5.6px",
  width: 20,
  height: 20,
  boxShadow: "0 0 0 1px rgb(16 22 26 / 40%)",
  backgroundColor: theme.palette.background.checkBox,
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.background.checkBoxHover,
  },
  "input:disabled ~ &": {
    "&:hover": {
      background: theme.palette.background.disabled,
    },
    boxShadow: "none",
    background: theme.palette.background.disabled,
  },
}));

export const CheckedIcon = styled(CheckBoxIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.primary.main,
  },
}));
