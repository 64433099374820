/* eslint-disable */
import { HoldType, holdTypeFromJSON, holdTypeToJSON } from "../fiat/hold_type";
import { Reader, util, configure, Writer } from "protobufjs/minimal";
import Long from "long";

export const protobufPackage = "rr.bcpcore.fiat";

export interface MsgHold {
  creator: string;
  receiverAddr: string;
  operationId: string;
  holdDate: number;
  holdType: HoldType;
  holds: FiatHoldItem[];
}

export interface FiatHoldItem {
  addr: string;
  amount: number;
}

export interface MsgHoldResponse {}

export interface MsgTransfer {
  creator: string;
  senderAddr: string;
  amount: number;
  receiverAddr: string;
  operationId: string;
}

export interface MsgTransferResponse {}

export interface MsgTransferUnhold {
  creator: string;
  receiverAddr: string;
  operationId: string;
  holds: FiatTransferUnholdItem[];
}

export interface FiatTransferUnholdItem {
  addr: string;
  amount: number;
}

export interface MsgTransferUnholdResponse {}

export interface TokenInformation {
  ticker: string;
  amount: number;
}

export interface MsgAdd {
  creator: string;
  customerAddr: string;
  amount: number;
  operationId: string;
}

export interface MsgAddResponse {}

export interface MsgBurn {
  creator: string;
  customerAddr: string;
  amount: number;
  operationId: string;
}

export interface MsgBurnResponse {}

export interface MsgInit {
  creator: string;
  customerAddr: string;
}

export interface MsgInitResponse {}

const baseMsgHold: object = {
  creator: "",
  receiverAddr: "",
  operationId: "",
  holdDate: 0,
  holdType: 0,
};

export const MsgHold = {
  encode(message: MsgHold, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.receiverAddr !== "") {
      writer.uint32(18).string(message.receiverAddr);
    }
    if (message.operationId !== "") {
      writer.uint32(26).string(message.operationId);
    }
    if (message.holdDate !== 0) {
      writer.uint32(32).uint64(message.holdDate);
    }
    if (message.holdType !== 0) {
      writer.uint32(40).int32(message.holdType);
    }
    for (const v of message.holds) {
      FiatHoldItem.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgHold {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgHold } as MsgHold;
    message.holds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.receiverAddr = reader.string();
          break;
        case 3:
          message.operationId = reader.string();
          break;
        case 4:
          message.holdDate = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.holdType = reader.int32() as any;
          break;
        case 6:
          message.holds.push(FiatHoldItem.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgHold {
    const message = { ...baseMsgHold } as MsgHold;
    message.holds = [];
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.receiverAddr !== undefined && object.receiverAddr !== null) {
      message.receiverAddr = String(object.receiverAddr);
    } else {
      message.receiverAddr = "";
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    if (object.holdDate !== undefined && object.holdDate !== null) {
      message.holdDate = Number(object.holdDate);
    } else {
      message.holdDate = 0;
    }
    if (object.holdType !== undefined && object.holdType !== null) {
      message.holdType = holdTypeFromJSON(object.holdType);
    } else {
      message.holdType = 0;
    }
    if (object.holds !== undefined && object.holds !== null) {
      for (const e of object.holds) {
        message.holds.push(FiatHoldItem.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: MsgHold): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.receiverAddr !== undefined &&
      (obj.receiverAddr = message.receiverAddr);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    message.holdDate !== undefined && (obj.holdDate = message.holdDate);
    message.holdType !== undefined &&
      (obj.holdType = holdTypeToJSON(message.holdType));
    if (message.holds) {
      obj.holds = message.holds.map((e) =>
        e ? FiatHoldItem.toJSON(e) : undefined
      );
    } else {
      obj.holds = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<MsgHold>): MsgHold {
    const message = { ...baseMsgHold } as MsgHold;
    message.holds = [];
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.receiverAddr !== undefined && object.receiverAddr !== null) {
      message.receiverAddr = object.receiverAddr;
    } else {
      message.receiverAddr = "";
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    if (object.holdDate !== undefined && object.holdDate !== null) {
      message.holdDate = object.holdDate;
    } else {
      message.holdDate = 0;
    }
    if (object.holdType !== undefined && object.holdType !== null) {
      message.holdType = object.holdType;
    } else {
      message.holdType = 0;
    }
    if (object.holds !== undefined && object.holds !== null) {
      for (const e of object.holds) {
        message.holds.push(FiatHoldItem.fromPartial(e));
      }
    }
    return message;
  },
};

const baseFiatHoldItem: object = { addr: "", amount: 0 };

export const FiatHoldItem = {
  encode(message: FiatHoldItem, writer: Writer = Writer.create()): Writer {
    if (message.addr !== "") {
      writer.uint32(10).string(message.addr);
    }
    if (message.amount !== 0) {
      writer.uint32(16).uint64(message.amount);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): FiatHoldItem {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFiatHoldItem } as FiatHoldItem;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.addr = reader.string();
          break;
        case 2:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FiatHoldItem {
    const message = { ...baseFiatHoldItem } as FiatHoldItem;
    if (object.addr !== undefined && object.addr !== null) {
      message.addr = String(object.addr);
    } else {
      message.addr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    return message;
  },

  toJSON(message: FiatHoldItem): unknown {
    const obj: any = {};
    message.addr !== undefined && (obj.addr = message.addr);
    message.amount !== undefined && (obj.amount = message.amount);
    return obj;
  },

  fromPartial(object: DeepPartial<FiatHoldItem>): FiatHoldItem {
    const message = { ...baseFiatHoldItem } as FiatHoldItem;
    if (object.addr !== undefined && object.addr !== null) {
      message.addr = object.addr;
    } else {
      message.addr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    return message;
  },
};

const baseMsgHoldResponse: object = {};

export const MsgHoldResponse = {
  encode(_: MsgHoldResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgHoldResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgHoldResponse } as MsgHoldResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgHoldResponse {
    const message = { ...baseMsgHoldResponse } as MsgHoldResponse;
    return message;
  },

  toJSON(_: MsgHoldResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgHoldResponse>): MsgHoldResponse {
    const message = { ...baseMsgHoldResponse } as MsgHoldResponse;
    return message;
  },
};

const baseMsgTransfer: object = {
  creator: "",
  senderAddr: "",
  amount: 0,
  receiverAddr: "",
  operationId: "",
};

export const MsgTransfer = {
  encode(message: MsgTransfer, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.senderAddr !== "") {
      writer.uint32(18).string(message.senderAddr);
    }
    if (message.amount !== 0) {
      writer.uint32(24).uint64(message.amount);
    }
    if (message.receiverAddr !== "") {
      writer.uint32(34).string(message.receiverAddr);
    }
    if (message.operationId !== "") {
      writer.uint32(42).string(message.operationId);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgTransfer {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgTransfer } as MsgTransfer;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.senderAddr = reader.string();
          break;
        case 3:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 4:
          message.receiverAddr = reader.string();
          break;
        case 5:
          message.operationId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgTransfer {
    const message = { ...baseMsgTransfer } as MsgTransfer;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.senderAddr !== undefined && object.senderAddr !== null) {
      message.senderAddr = String(object.senderAddr);
    } else {
      message.senderAddr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.receiverAddr !== undefined && object.receiverAddr !== null) {
      message.receiverAddr = String(object.receiverAddr);
    } else {
      message.receiverAddr = "";
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    return message;
  },

  toJSON(message: MsgTransfer): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.senderAddr !== undefined && (obj.senderAddr = message.senderAddr);
    message.amount !== undefined && (obj.amount = message.amount);
    message.receiverAddr !== undefined &&
      (obj.receiverAddr = message.receiverAddr);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgTransfer>): MsgTransfer {
    const message = { ...baseMsgTransfer } as MsgTransfer;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.senderAddr !== undefined && object.senderAddr !== null) {
      message.senderAddr = object.senderAddr;
    } else {
      message.senderAddr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.receiverAddr !== undefined && object.receiverAddr !== null) {
      message.receiverAddr = object.receiverAddr;
    } else {
      message.receiverAddr = "";
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    return message;
  },
};

const baseMsgTransferResponse: object = {};

export const MsgTransferResponse = {
  encode(_: MsgTransferResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgTransferResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgTransferResponse } as MsgTransferResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgTransferResponse {
    const message = { ...baseMsgTransferResponse } as MsgTransferResponse;
    return message;
  },

  toJSON(_: MsgTransferResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgTransferResponse>): MsgTransferResponse {
    const message = { ...baseMsgTransferResponse } as MsgTransferResponse;
    return message;
  },
};

const baseMsgTransferUnhold: object = {
  creator: "",
  receiverAddr: "",
  operationId: "",
};

export const MsgTransferUnhold = {
  encode(message: MsgTransferUnhold, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.receiverAddr !== "") {
      writer.uint32(18).string(message.receiverAddr);
    }
    if (message.operationId !== "") {
      writer.uint32(26).string(message.operationId);
    }
    for (const v of message.holds) {
      FiatTransferUnholdItem.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgTransferUnhold {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgTransferUnhold } as MsgTransferUnhold;
    message.holds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.receiverAddr = reader.string();
          break;
        case 3:
          message.operationId = reader.string();
          break;
        case 4:
          message.holds.push(
            FiatTransferUnholdItem.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgTransferUnhold {
    const message = { ...baseMsgTransferUnhold } as MsgTransferUnhold;
    message.holds = [];
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.receiverAddr !== undefined && object.receiverAddr !== null) {
      message.receiverAddr = String(object.receiverAddr);
    } else {
      message.receiverAddr = "";
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    if (object.holds !== undefined && object.holds !== null) {
      for (const e of object.holds) {
        message.holds.push(FiatTransferUnholdItem.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: MsgTransferUnhold): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.receiverAddr !== undefined &&
      (obj.receiverAddr = message.receiverAddr);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    if (message.holds) {
      obj.holds = message.holds.map((e) =>
        e ? FiatTransferUnholdItem.toJSON(e) : undefined
      );
    } else {
      obj.holds = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<MsgTransferUnhold>): MsgTransferUnhold {
    const message = { ...baseMsgTransferUnhold } as MsgTransferUnhold;
    message.holds = [];
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.receiverAddr !== undefined && object.receiverAddr !== null) {
      message.receiverAddr = object.receiverAddr;
    } else {
      message.receiverAddr = "";
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    if (object.holds !== undefined && object.holds !== null) {
      for (const e of object.holds) {
        message.holds.push(FiatTransferUnholdItem.fromPartial(e));
      }
    }
    return message;
  },
};

const baseFiatTransferUnholdItem: object = { addr: "", amount: 0 };

export const FiatTransferUnholdItem = {
  encode(
    message: FiatTransferUnholdItem,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.addr !== "") {
      writer.uint32(10).string(message.addr);
    }
    if (message.amount !== 0) {
      writer.uint32(16).uint64(message.amount);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): FiatTransferUnholdItem {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFiatTransferUnholdItem } as FiatTransferUnholdItem;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.addr = reader.string();
          break;
        case 2:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FiatTransferUnholdItem {
    const message = { ...baseFiatTransferUnholdItem } as FiatTransferUnholdItem;
    if (object.addr !== undefined && object.addr !== null) {
      message.addr = String(object.addr);
    } else {
      message.addr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    return message;
  },

  toJSON(message: FiatTransferUnholdItem): unknown {
    const obj: any = {};
    message.addr !== undefined && (obj.addr = message.addr);
    message.amount !== undefined && (obj.amount = message.amount);
    return obj;
  },

  fromPartial(
    object: DeepPartial<FiatTransferUnholdItem>
  ): FiatTransferUnholdItem {
    const message = { ...baseFiatTransferUnholdItem } as FiatTransferUnholdItem;
    if (object.addr !== undefined && object.addr !== null) {
      message.addr = object.addr;
    } else {
      message.addr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    return message;
  },
};

const baseMsgTransferUnholdResponse: object = {};

export const MsgTransferUnholdResponse = {
  encode(
    _: MsgTransferUnholdResponse,
    writer: Writer = Writer.create()
  ): Writer {
    return writer;
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): MsgTransferUnholdResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseMsgTransferUnholdResponse,
    } as MsgTransferUnholdResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgTransferUnholdResponse {
    const message = {
      ...baseMsgTransferUnholdResponse,
    } as MsgTransferUnholdResponse;
    return message;
  },

  toJSON(_: MsgTransferUnholdResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<MsgTransferUnholdResponse>
  ): MsgTransferUnholdResponse {
    const message = {
      ...baseMsgTransferUnholdResponse,
    } as MsgTransferUnholdResponse;
    return message;
  },
};

const baseTokenInformation: object = { ticker: "", amount: 0 };

export const TokenInformation = {
  encode(message: TokenInformation, writer: Writer = Writer.create()): Writer {
    if (message.ticker !== "") {
      writer.uint32(10).string(message.ticker);
    }
    if (message.amount !== 0) {
      writer.uint32(16).uint64(message.amount);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): TokenInformation {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTokenInformation } as TokenInformation;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ticker = reader.string();
          break;
        case 2:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TokenInformation {
    const message = { ...baseTokenInformation } as TokenInformation;
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = String(object.ticker);
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    return message;
  },

  toJSON(message: TokenInformation): unknown {
    const obj: any = {};
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.amount !== undefined && (obj.amount = message.amount);
    return obj;
  },

  fromPartial(object: DeepPartial<TokenInformation>): TokenInformation {
    const message = { ...baseTokenInformation } as TokenInformation;
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = object.ticker;
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    return message;
  },
};

const baseMsgAdd: object = {
  creator: "",
  customerAddr: "",
  amount: 0,
  operationId: "",
};

export const MsgAdd = {
  encode(message: MsgAdd, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    if (message.amount !== 0) {
      writer.uint32(24).uint64(message.amount);
    }
    if (message.operationId !== "") {
      writer.uint32(34).string(message.operationId);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgAdd {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgAdd } as MsgAdd;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        case 3:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 4:
          message.operationId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgAdd {
    const message = { ...baseMsgAdd } as MsgAdd;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    return message;
  },

  toJSON(message: MsgAdd): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    message.amount !== undefined && (obj.amount = message.amount);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgAdd>): MsgAdd {
    const message = { ...baseMsgAdd } as MsgAdd;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    return message;
  },
};

const baseMsgAddResponse: object = {};

export const MsgAddResponse = {
  encode(_: MsgAddResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgAddResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgAddResponse } as MsgAddResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgAddResponse {
    const message = { ...baseMsgAddResponse } as MsgAddResponse;
    return message;
  },

  toJSON(_: MsgAddResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgAddResponse>): MsgAddResponse {
    const message = { ...baseMsgAddResponse } as MsgAddResponse;
    return message;
  },
};

const baseMsgBurn: object = {
  creator: "",
  customerAddr: "",
  amount: 0,
  operationId: "",
};

export const MsgBurn = {
  encode(message: MsgBurn, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    if (message.amount !== 0) {
      writer.uint32(24).uint64(message.amount);
    }
    if (message.operationId !== "") {
      writer.uint32(34).string(message.operationId);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgBurn {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgBurn } as MsgBurn;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        case 3:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 4:
          message.operationId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgBurn {
    const message = { ...baseMsgBurn } as MsgBurn;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    return message;
  },

  toJSON(message: MsgBurn): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    message.amount !== undefined && (obj.amount = message.amount);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgBurn>): MsgBurn {
    const message = { ...baseMsgBurn } as MsgBurn;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    return message;
  },
};

const baseMsgBurnResponse: object = {};

export const MsgBurnResponse = {
  encode(_: MsgBurnResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgBurnResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgBurnResponse } as MsgBurnResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgBurnResponse {
    const message = { ...baseMsgBurnResponse } as MsgBurnResponse;
    return message;
  },

  toJSON(_: MsgBurnResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgBurnResponse>): MsgBurnResponse {
    const message = { ...baseMsgBurnResponse } as MsgBurnResponse;
    return message;
  },
};

const baseMsgInit: object = { creator: "", customerAddr: "" };

export const MsgInit = {
  encode(message: MsgInit, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgInit {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgInit } as MsgInit;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgInit {
    const message = { ...baseMsgInit } as MsgInit;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    return message;
  },

  toJSON(message: MsgInit): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgInit>): MsgInit {
    const message = { ...baseMsgInit } as MsgInit;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    return message;
  },
};

const baseMsgInitResponse: object = {};

export const MsgInitResponse = {
  encode(_: MsgInitResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgInitResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgInitResponse } as MsgInitResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgInitResponse {
    const message = { ...baseMsgInitResponse } as MsgInitResponse;
    return message;
  },

  toJSON(_: MsgInitResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgInitResponse>): MsgInitResponse {
    const message = { ...baseMsgInitResponse } as MsgInitResponse;
    return message;
  },
};

/** Msg defines the Msg service. */
export interface Msg {
  Hold(request: MsgHold): Promise<MsgHoldResponse>;
  Transfer(request: MsgTransfer): Promise<MsgTransferResponse>;
  TransferUnhold(
    request: MsgTransferUnhold
  ): Promise<MsgTransferUnholdResponse>;
  Add(request: MsgAdd): Promise<MsgAddResponse>;
  Burn(request: MsgBurn): Promise<MsgBurnResponse>;
  /** this line is used by starport scaffolding # proto/tx/rpc */
  Init(request: MsgInit): Promise<MsgInitResponse>;
}

export class MsgClientImpl implements Msg {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }
  Hold(request: MsgHold): Promise<MsgHoldResponse> {
    const data = MsgHold.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.fiat.Msg", "Hold", data);
    return promise.then((data) => MsgHoldResponse.decode(new Reader(data)));
  }

  Transfer(request: MsgTransfer): Promise<MsgTransferResponse> {
    const data = MsgTransfer.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.fiat.Msg", "Transfer", data);
    return promise.then((data) => MsgTransferResponse.decode(new Reader(data)));
  }

  TransferUnhold(
    request: MsgTransferUnhold
  ): Promise<MsgTransferUnholdResponse> {
    const data = MsgTransferUnhold.encode(request).finish();
    const promise = this.rpc.request(
      "rr.bcpcore.fiat.Msg",
      "TransferUnhold",
      data
    );
    return promise.then((data) =>
      MsgTransferUnholdResponse.decode(new Reader(data))
    );
  }

  Add(request: MsgAdd): Promise<MsgAddResponse> {
    const data = MsgAdd.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.fiat.Msg", "Add", data);
    return promise.then((data) => MsgAddResponse.decode(new Reader(data)));
  }

  Burn(request: MsgBurn): Promise<MsgBurnResponse> {
    const data = MsgBurn.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.fiat.Msg", "Burn", data);
    return promise.then((data) => MsgBurnResponse.decode(new Reader(data)));
  }

  Init(request: MsgInit): Promise<MsgInitResponse> {
    const data = MsgInit.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.fiat.Msg", "Init", data);
    return promise.then((data) => MsgInitResponse.decode(new Reader(data)));
  }
}

interface Rpc {
  request(
    service: string,
    method: string,
    data: Uint8Array
  ): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (util.Long !== Long) {
  util.Long = Long as any;
  configure();
}
