import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import DFADialog from "../DFADialog";

interface IDFADialog2FA {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  setCode(code: string): void;
  code: string;
  loading?: boolean;
}

function DFADialog2FA({ open, onClose, loading, onConfirm, setCode, code }: IDFADialog2FA) {
  const { t } = useTranslation();
  const handleSetCode = (e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value);
  return (
    <DFADialog
      open={open}
      title="Введите проверочный код из СМС"
      onClose={onClose}
      onConfirm={onConfirm}
      confirmText={t("layouts.login.dialogAccess")}
      dialogText="Мы отправили Вам на телефон проверочный код для подтверждения личности."
      loading={loading}
    >
      <TextField
        sx={{ mt: 1 }}
        autoFocus
        type="number"
        value={code}
        margin="dense"
        id="name"
        label={t(`layouts.login.dialogSms`)}
        fullWidth
        variant="outlined"
        onInput={handleSetCode}
      />
    </DFADialog>
  );
}

export default DFADialog2FA;
