import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiAccordion {
  defaultProps?: ComponentsProps["MuiAccordion"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiAccordion"];
  variants?: ComponentsVariants["MuiAccordion"];
}

const accordion: MuiAccordion = {
  styleOverrides: {
    root: {
      ".MuiAccordionSummary-expandIconWrapper": {
        color: "currentcolor",
      },
    },
  },
};

export default accordion;
