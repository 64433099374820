import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { HandleChangeType, HandleDispatchMsgValueType } from "hooks/transactions/useInputChange";

interface ISelectFieldProps {
  inputName: string;
  inputType: string;
  value: any;
  selectNames: string[];
  handleChange: HandleChangeType;
  parentObjKey?: string;
  handleDispatchMsgValue: HandleDispatchMsgValueType;
}

export default function SelectField({
  inputName,
  inputType,
  value,
  handleChange,
  selectNames,
  parentObjKey,
  handleDispatchMsgValue,
}: ISelectFieldProps) {
  let inputValue;

  const getInputValue = () => {
    if (parentObjKey) {
      if (value?.[parentObjKey]?.[inputName]) inputValue = value?.[parentObjKey]?.[inputName];
      else if (value?.[parentObjKey]?.[inputName] == null) inputValue = "";
      else inputValue = String(value?.[parentObjKey]?.[inputName]);
    } else if (value?.[inputName]) inputValue = value?.[inputName];
    else if (value?.[inputName] == null) inputValue = "";
    else inputValue = String(value?.[inputName]);
  };
  getInputValue();
  return (
    <Grid mb={2} item xs={12}>
      <FormControl required sx={{ width: "100%" }}>
        <InputLabel id="select-label">{inputName}</InputLabel>
        <Select
          name={inputName}
          labelId="select-label"
          id={inputName}
          value={inputValue}
          label={inputName}
          onChange={(e) => handleChange(e, inputName, inputType, parentObjKey)}
          onBlur={(e) => handleDispatchMsgValue(e, inputName)}
          sx={{ height: "45px" }}
        >
          {selectNames?.map((selName, i) =>
            inputType === "numSelect" ? (
              <MenuItem value={selName === "UNRECOGNIZED" ? -1 : i}>{selName}</MenuItem>
            ) : (
              <MenuItem value={selName}>{selName}</MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </Grid>
  );
}
