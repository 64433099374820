enum MsgInitIssueEnum {
  issueId = "issueId",
  emitentAddr = "emitentAddr",
  investStartDate = "investStartDate",
  investFinishDate = "investFinishDate",
  minOrderLimit = "minOrderLimit",
  maxOrderLimit = "maxOrderLimit",
  dayOrderLimit = "dayOrderLimit",
  maxIssueLimit = "maxIssueLimit",
  issueNominal = "issueNominal",
}

export default MsgInitIssueEnum;
