import { RepresentativeStatusGql } from "@front-packages/dfa-gql-api";

export const SET_REPRESENTATIVE_ID = "@@customer/SET_REPRESENTATIVE_ID";
export const SET_REPRESENTATIVE_STATUS = "@@customer/SET_REPRESENTATIVE_STATUS";
export const SET_LOADING = "@@customer/SET_LOADING";
export const SET_EDIT = "@@customer/SET_EDIT";

export const setRepresentativeID = (payload: string) => ({ type: SET_REPRESENTATIVE_ID, payload });
export const setRepresentativeStatus = (payload: RepresentativeStatusGql) => ({
  type: SET_REPRESENTATIVE_STATUS,
  payload,
});
export const setLoading = (payload: boolean) => ({ type: SET_LOADING, payload });
export const setIsEdit = (payload: boolean) => ({ type: SET_EDIT, payload });
