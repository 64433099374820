/*
 Developed by ST soft team
 */

import React, { ForwardedRef, forwardRef } from "react";
import { BoxProps } from "@mui/material/Box/Box";
import BoxRoot from "./BoxRoot";

interface IBox extends BoxProps {
  src?: any;
  to?: string;
  alt?: string;
  item?: boolean;
  color?: string;
  shadow?: string;
  opacity?: number;
  variant?: string;
  bgColor?: string;
  borderRadius?: string;
  coloredShadow?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "none";
}

const MDBox = forwardRef(
  (
    {
      variant = "contained",
      bgColor = "transparent",
      color = "dark",
      opacity = 1,
      borderRadius = "none",
      shadow = "none",
      coloredShadow = "none",
      ...rest
    }: IBox,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <BoxRoot
      {...rest}
      ref={ref}
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }}
    />
  )
);

MDBox.defaultProps = {};

export default MDBox;
