import { baseProperties } from "../../colors/typography";
import { borders } from "../../../base";

const { fontWeightBold, fontSizeXS } = baseProperties;
const { borderRadius } = borders;

const root = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: fontSizeXS,
  fontWeight: fontWeightBold,
  borderRadius: borderRadius.lg,
  padding: "6.302px 16.604px",
  lineHeight: 1.4,
  backgroundSize: "150% !important",
  backgroundPositionX: "25% !important",
  transition: "all 150ms ease-in",

  "&:disabled": {
    pointerEvent: "none",
    opacity: 0.65,
  },

  "& .material-icons": {
    fontSize: "15px",
    marginTop: "-2px",
  },
};

export default root;
