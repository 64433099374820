import { palette } from "../../colors";

import { baseProperties } from "../../colors/typography";

const {
  common: { white },
  text,
  info,
  secondary,
} = palette;
const { fontSizeSM, fontSizeXS } = baseProperties;
const infoMain = "main" in info ? info.main : "inherit";
const secondaryMain = "main" in secondary ? secondary.main : "inherit";

const contained = {
  base: {
    backgroundColor: white,
    minHeight: "37px",
    color: text.primary,
    padding: "9px 24px",

    "&:hover": {
      backgroundColor: white,
    },

    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: "16px !important",
    },
  },

  small: {
    minHeight: "29px",
    padding: "6px 18px",
    fontSize: fontSizeXS,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: "12px !important",
    },
  },

  large: {
    minHeight: "44px",
    padding: "12px 64px",
    fontSize: fontSizeSM,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: "22px !important",
    },
  },

  primary: {
    backgroundColor: infoMain,

    "&:hover": {
      backgroundColor: infoMain,
    },

    "&:focus:not(:hover)": {
      backgroundColor: infoMain,
    },
  },

  secondary: {
    backgroundColor: secondaryMain,

    "&:hover": {
      backgroundColor: secondaryMain,
    },

    "&:focus:not(:hover)": {
      backgroundColor: secondaryMain,
    },
  },
};

export default contained;
