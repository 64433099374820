import { palette } from "../AdminTheme/colors";
import { IBorders } from "../typings";

const { grey } = palette;

const borders: IBorders = {
  borderColor: grey ? `${grey[300]}` : "white",

  borderWidth: {
    0: 0,
    1: "1px",
    2: "2px",
    3: "3px",
    4: "4px",
    5: "5px",
  },

  borderRadius: {
    xs: "1.6px",
    sm: "2px",
    md: "6px",
    lg: "8px",
    xl: "12px",
    xxl: "16px",
    section: "160px",
  },
};

export default borders;
