import { useStatuses } from "hooks/other";
import { DFAChip } from "Theme";
import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

const useColumns = () => {
  const getStatusBadgeProps = useStatuses();
  const { t } = useTranslation();

  const depositColumns: GridColDef[] = [
    {
      field: "companyName",
      headerName: "Компания",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "assignmentsID",
      headerName: "ID",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "Дата создания",
      renderCell: (params) => {
        return new Date(params.value.slice(0, 19)).toLocaleDateString().toString();
      },
      width: 150,
      headerAlign: "center",
    },
    {
      field: "depositStatus",
      headerName: "Статус",
      renderCell: (params) => {
        return (
          <DFAChip
            {...getStatusBadgeProps(params.value, "Deposit")}
            type={getStatusBadgeProps(params.value, "Deposit").color as any}
          />
        );
      },
      width: 150,
      headerAlign: "center",
    },
    {
      field: "walletID",
      headerName: "ID кошелька",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "sum",
      headerName: "Сумма",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "inn",
      headerName: t("layouts.card.clientCard.accsAndOperations.inn"),
      width: 150,
      headerAlign: "center",
    },
    {
      field: "kpp",
      headerName: t("layouts.card.clientCard.accsAndOperations.kpp"),
      width: 150,
      headerAlign: "center",
    },
  ];
  const withdrawalColumns: GridColDef[] = [
    {
      field: "companyName",
      headerName: "Компания",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "orderID",
      headerName: "ID",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "dateOfFiling",
      headerName: "Дата пополнения",
      renderCell: (params) => {
        return new Date(params.value.slice(0, 19)).toLocaleDateString().toString();
      },
      width: 150,
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Статус",
      renderCell: (params) => {
        return (
          <DFAChip
            {...getStatusBadgeProps(params.value, "Withdraw")}
            type={getStatusBadgeProps(params.value, "Withdraw").color as any}
          />
        );
      },
      width: 150,
      headerAlign: "center",
    },
    {
      field: "sendingMethod",
      headerName: "Метод вывода",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "walletAddress",
      headerName: "Адрес кошелька",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "bankAccountID",
      headerName: "ID аккаунта",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "sum",
      headerName: "Сумма",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "persAccOperationID",
      headerName: "ID операции",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "smartContractAddress",
      headerName: "Адрес смарт контракта",
      width: 150,
      headerAlign: "center",
    },
  ];
  return { depositColumns, withdrawalColumns };
};

export default useColumns;
