/*
DFA Platform Admin
***
Developed by ST soft team
*/

/**
  The rgba() function helps you to create a rgba color code, it uses the hexToRgb() function
  to convert the hex code into rgb for using it inside the rgba color format.
 */

// Material Dashboard 2 React helper helpers
import hexToRgb from "./hexToRgb";
// eslint-disable-next-line import/extensions,import/no-unresolved
import { IRgba } from "../typings";

const rgba: IRgba = (color, opacity) => {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
};

export default rgba;
