import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneNumber from "material-ui-phone-number";
import { Grid, TextField, Typography } from "@mui/material";
import { CountryPhones, DefaultCountryPhone, regCleanPhone } from "@front-packages/dfa-constants";
import { AdministrationAPI, AuthAPI, PermissionGql } from "@front-packages/dfa-gql-api";
import { DFABox, DFADialog } from "Theme";
import { useAuth } from "hooks/authentication";
import { useValidate } from "hooks/other";

interface IAddAdminProps {
  open: boolean;
  onClose(): void;
  getAdminsList(): void;
  setError(error: string): void;
}

function AddAdmin(props: IAddAdminProps) {
  const { open, onClose, getAdminsList, setError } = props;
  const { t } = useTranslation();
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const { invalidPhone, passValidInfo } = useValidate({
    phone: auth.get.phoneNumber,
    passwords: { mainPass: auth.get.password, verifyPass: auth.get.verifyPass },
  });

  const handleSetPass = (e: ChangeEvent<HTMLInputElement>) => auth.set.password(e.target.value);
  const handleSetName = (e: ChangeEvent<HTMLInputElement>) => auth.set.name(e.target.value);
  const handleSetSecondPass = (e: ChangeEvent<HTMLInputElement>) =>
    auth.set.verifyPass(e.target.value);
  const handleClose = () => {
    auth.set.password("");
    auth.set.verifyPass("");
    auth.set.phoneNumber("");
    auth.set.name("");
    setLoading(false);
    onClose();
  };
  const handleSignUp = async () => {
    setLoading(true);
    const { phoneNumber, password } = auth.get;
    if (
      phoneNumber &&
      password &&
      !passValidInfo.mainPass.invalid &&
      !passValidInfo.verifyPass.invalid
    ) {
      const isReg = await auth.registration();
      if (isReg) {
        const { response: uid, error: signInError } = await AuthAPI.SignIn({
          phoneNumber: auth.get.phoneNumber.replace(regCleanPhone, ""),
          password: auth.get.password,
        });
        if (signInError) {
          setError(signInError);
          handleClose();
          return;
        }
        const { error: setPermissionError } = await AdministrationAPI.SetPermissionByUID({
          uid,
          permission: PermissionGql.Administrator,
        });
        if (setPermissionError) setError(setPermissionError);
        handleClose();
        getAdminsList();
      }
    }
    setLoading(false);
  };

  return (
    <DFADialog
      open={open}
      title={t("layouts.admins.dialog.titleAdd")}
      confirmText={t("layouts.admins.buttons.reg")}
      onClose={handleClose}
      onConfirm={handleSignUp}
      isHandleEnter
      width="xs"
      disabled={
        !auth.get.phoneNumber ||
        !auth.get.password ||
        !auth.get.verifyPass ||
        passValidInfo.verifyPass.invalid ||
        passValidInfo.mainPass.invalid
      }
      loading={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="ФИО"
            label={t("layouts.admins.dialog.placeholders.name")}
            fullWidth
            value={auth.get.name}
            onChange={handleSetName}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumber
            defaultCountry={DefaultCountryPhone}
            onlyCountries={CountryPhones}
            label={t("layouts.admins.dialog.placeholders.phone")}
            value={auth.get.phoneNumber}
            onChange={auth.set.phoneNumber as any}
            fullWidth
            variant="outlined"
            error={invalidPhone}
            helperText={invalidPhone && "Некорректный номер телефона"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="new-password"
            type="password"
            value={auth.get.password}
            label={t("layouts.admins.dialog.placeholders.pass")}
            fullWidth
            onChange={handleSetPass}
            error={passValidInfo.mainPass.invalid}
            helperText={passValidInfo.mainPass.invalid && passValidInfo.mainPass.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            value={auth.get.verifyPass}
            label={t("layouts.admins.dialog.placeholders.secondPass")}
            fullWidth
            onChange={handleSetSecondPass}
            error={passValidInfo.verifyPass.invalid}
            disabled={passValidInfo.mainPass.invalid}
            helperText={passValidInfo.verifyPass.invalid && passValidInfo.verifyPass.message}
          />
        </Grid>
        <DFABox width="max-content" m="auto" mt={1} color="warning">
          <Typography variant="subtitle2">{auth.get.errorMessage}</Typography>
        </DFABox>
      </Grid>
    </DFADialog>
  );
}

export default AddAdmin;
