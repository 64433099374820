import React from "react";
import { useTranslation } from "react-i18next";
import { DFADialog } from "Theme";
import TransactionCard, {
  IBlockChainCardTransactionProps,
} from "../../transactions/components/TransactionCard";

interface DialogTxProps extends IBlockChainCardTransactionProps {
  closeDialog(): void;
}

export default function DialogTx({ closeDialog, tx }: DialogTxProps) {
  const { t } = useTranslation();

  return (
    <DFADialog
      open={!!tx}
      title={t("layouts.blockchain.info_tx")}
      cancelText={t("general.buttons.close")}
      onClose={closeDialog}
      width="lg"
    >
      {tx && <TransactionCard tx={tx} />}
    </DFADialog>
  );
}
