import React, { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CompanyStatusEnum,
  CompanyStatuses,
  DateRange,
  MenuProps,
  Roles,
  RolesEnum,
} from "@front-packages/dfa-constants";
import { trimLetter } from "@front-packages/dfa-helpers";
import { useStatuses } from "hooks/other";
import { GetCompaniesOpts } from "@front-packages/dfa-gql-api";
import { DFABox, DFAButton, DFADatePicker, DFASearchBadge } from "Theme";
import RoleEnum from "@front-packages/dfa-constants/dist/enums/Roles";

interface ICustomersSearchForm {
  onSearch(opts?: Partial<GetCompaniesOpts>): Promise<void>;
}

function CustomersSearchForm({ onSearch }: ICustomersSearchForm) {
  const { t } = useTranslation();
  const [opts, setOpts] = useState<Partial<GetCompaniesOpts>>({});
  const [badges, setBadges] = useState<Array<string>>([]);
  const [expanded, setExpanded] = useState<"panel1" | false>(false);
  const box = useRef<HTMLDivElement>(null);
  const getStatusBadgeProps = useStatuses();

  const handleSetBadge = () => {
    const newBadges = Object.keys(opts).filter((key) => {
      let isReturn = !!opts[key];
      if (key === "page" || key === "count") isReturn = false;
      if (key === "createdDateRange" || key === "companyEditDateRange")
        isReturn = !!opts[key] && (!!opts[key]?.firstDate || !!opts[key]?.lastDate);
      return isReturn;
    });
    setBadges(newBadges);
  };
  const handleOpen = (panel: "panel1") => (e: any, isExpanded: boolean) => {
    if (!box.current || (box.current && !box.current.contains(e.target)))
      setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => setExpanded(false);
  const handleSearch = async () => {
    await onSearch(opts);
    handleSetBadge();
    handleClose();
  };
  const handleSetName = (e: ChangeEvent<HTMLInputElement>) => {
    setOpts({ ...opts, name: e.target.value || undefined });
  };
  const handleSetWallet = (e: ChangeEvent<HTMLInputElement>) => {
    setOpts({ ...opts, walletAddress: e.target.value || undefined });
  };
  const handleSetInn = (v: ChangeEvent<HTMLInputElement>) => {
    const value = trimLetter(v.target.value, 12);
    setOpts({ ...opts, inn: value || undefined });
  };
  const handleSetRegDate = (date: Date | null, isFirst: boolean) => {
    const formatDate: Date = moment(date).format("YYYY-MM-DD") as any;
    const value: DateRange = {
      ...(isFirst
        ? {
            firstDate: formatDate,
            lastDate: opts?.createdDateRange?.lastDate || ("" as any),
          }
        : {
            firstDate: opts?.createdDateRange?.firstDate || ("" as any),
            lastDate: formatDate,
          }),
    };
    // TODO: Genapi as any
    setOpts({ ...opts, createdDateRange: value as any });
  };
  const handleSetRefreshDate = (date: Date | null, isFirst: boolean) => {
    const formatDate: Date = moment(date).format("YYYY-MM-DD") as any;
    const value: DateRange = {
      ...(isFirst
        ? {
            firstDate: formatDate,
            lastDate: opts?.companyEditDateRange?.lastDate || ("" as any),
          }
        : {
            firstDate: opts?.companyEditDateRange?.firstDate || ("" as any),
            lastDate: formatDate,
          }),
    };
    // TODO: Genapi as any
    setOpts({ ...opts, companyEditDateRange: value as any });
  };
  const handleSetRole = (e: SelectChangeEvent) => {
    setOpts({ ...opts, clientRole: RolesEnum[e.target.value] || undefined });
  };
  const handleSetStatus = (e: SelectChangeEvent) => {
    setOpts({ ...opts, clientStatus: (e.target.value as any) || undefined });
  };
  const handleReset = async () => {
    setOpts({});
    setBadges([]);
    await onSearch();
    await handleClose();
  };
  const handleEnter = async (e: any) => {
    if (e.nativeEvent.charCode === 13) await handleSearch();
  };
  const handleDelBadge = async (badge: string) => {
    setOpts({ ...opts, [badge]: undefined });
    setBadges(badges.filter((el) => el !== badge));
    await onSearch({ ...opts, [badge]: undefined });
  };

  const showReset =
    !!opts?.name ||
    !!opts?.inn ||
    !!opts?.companyEditDateRange ||
    !!opts?.createdDateRange ||
    !!opts?.clientRole ||
    !!opts?.clientStatus ||
    !!opts?.walletAddress;
  return (
    <>
      <DFABox
        sx={{
          position: "absolute",
          top: "-60px",
          minHeight: "70px",
          right: 0,
          zIndex: 11,
          maxWidth: "550px",
        }}
        onKeyPress={handleEnter}
      >
        <Grid container justifyContent="end" alignContent="end" height="100%" spacing={1}>
          <DFABox variant="gradient" bgColor="success" borderRadius="xxl">
            <Accordion expanded={expanded === "panel1"} onChange={handleOpen("panel1")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {expanded !== "panel1" && (
                  <DFABox display="flex" ref={box} width="100%" alignItems="center">
                    <TextField
                      sx={{ m: 0, mr: 1 }}
                      value={opts?.name || ""}
                      margin="dense"
                      id="collapse-name"
                      label={t(`layouts.searchForm.placeholders.name`)}
                      type="text"
                      fullWidth
                      variant="outlined"
                      onInput={handleSetName}
                    />
                    <TextField
                      sx={{ m: 0 }}
                      value={opts?.inn || ""}
                      margin="dense"
                      id="collapse-inn"
                      label={t(`layouts.searchForm.placeholders.inn`)}
                      type="number"
                      fullWidth
                      variant="outlined"
                      onInput={handleSetInn}
                    />
                    <DFAButton
                      sx={{ mr: 2, ml: 2 }}
                      variant="contained"
                      color="success"
                      onClick={handleSearch}
                    >
                      {t("layouts.searchForm.buttons.search")}
                    </DFAButton>
                  </DFABox>
                )}
              </AccordionSummary>
              <AccordionDetails id="search-details">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      value={opts?.name || ""}
                      margin="dense"
                      id="name"
                      label={t(`layouts.searchForm.placeholders.name`)}
                      type="text"
                      fullWidth
                      variant="outlined"
                      onInput={handleSetName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={opts?.inn || ""}
                      margin="dense"
                      id="inn"
                      label={t(`layouts.searchForm.placeholders.inn`)}
                      type="text"
                      fullWidth
                      variant="outlined"
                      onInput={handleSetInn}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ m: 0 }}
                      value={opts?.walletAddress || ""}
                      margin="dense"
                      id="wallet"
                      label={t(`layouts.searchForm.placeholders.wallet`)}
                      type="text"
                      fullWidth
                      variant="outlined"
                      onInput={handleSetWallet}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: "7px !important" }}>
                    <Typography fontSize="0.875rem">
                      {t("layouts.searchForm.placeholders.registrationDate")}
                    </Typography>
                    <DFABox display="flex">
                      <DFABox width="45%">
                        <DFADatePicker
                          // TODO: Genapi as any
                          value={
                            opts?.createdDateRange && opts?.createdDateRange.firstDate
                              ? (opts?.createdDateRange.firstDate as any)
                              : null
                          }
                          // TODO: Genapi as any
                          maxDate={
                            opts?.createdDateRange && opts?.createdDateRange.lastDate
                              ? (opts?.createdDateRange.lastDate as any)
                              : null
                          }
                          label="От"
                          onChange={(date) => handleSetRegDate(date, true)}
                        />
                      </DFABox>
                      <DFABox sx={{ margin: "auto" }}>&mdash;</DFABox>
                      <DFABox width="45%">
                        <DFADatePicker
                          // TODO: Genapi as any
                          value={
                            opts?.createdDateRange && opts?.createdDateRange.lastDate
                              ? (opts?.createdDateRange.lastDate as any)
                              : null
                          }
                          // TODO: Genapi as any
                          minDate={
                            opts?.createdDateRange && opts?.createdDateRange.firstDate
                              ? (opts?.createdDateRange.firstDate as any)
                              : null
                          }
                          label="До"
                          onChange={(date) => handleSetRegDate(date, false)}
                        />
                      </DFABox>
                    </DFABox>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: "7px !important" }}>
                    <Typography fontSize="0.875rem">
                      {t("layouts.searchForm.placeholders.refreshDate")}
                    </Typography>
                    <DFABox display="flex">
                      <DFABox width="45%">
                        <DFADatePicker
                          // TODO: Genapi as any
                          value={
                            opts?.companyEditDateRange && opts?.companyEditDateRange.firstDate
                              ? (opts?.companyEditDateRange.firstDate as any)
                              : null
                          }
                          // TODO: Genapi as any
                          maxDate={
                            opts?.companyEditDateRange && opts?.companyEditDateRange.lastDate
                              ? (opts?.companyEditDateRange.lastDate as any)
                              : null
                          }
                          label="От"
                          onChange={(date) => handleSetRefreshDate(date, true)}
                        />
                      </DFABox>
                      <DFABox sx={{ margin: "auto" }}>&mdash;</DFABox>
                      <DFABox width="45%">
                        <DFADatePicker
                          // TODO: Genapi as any
                          value={
                            opts?.companyEditDateRange && opts?.companyEditDateRange.lastDate
                              ? (opts?.companyEditDateRange.lastDate as any)
                              : null
                          }
                          // TODO: Genapi as any
                          minDate={
                            opts?.companyEditDateRange && opts?.companyEditDateRange.firstDate
                              ? (opts?.companyEditDateRange.firstDate as any)
                              : null
                          }
                          label="До"
                          onChange={(date) => handleSetRefreshDate(date, false)}
                        />
                      </DFABox>
                    </DFABox>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%", height: 44 }}>
                      <InputLabel id="roleLabel">
                        {t("layouts.searchForm.placeholders.role")}
                      </InputLabel>
                      <Select
                        labelId="roleLabel"
                        id="role"
                        value={opts?.clientRole || RoleEnum.unspecified}
                        variant="filled"
                        onChange={handleSetRole}
                        input={<OutlinedInput label={t("layouts.searchForm.placeholders.role")} />}
                        MenuProps={MenuProps}
                        style={{ lineHeight: "2.7rem" }}
                      >
                        {Roles.map((r) => (
                          <MenuItem key={r.value} value={r.value}>
                            {r.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%", height: 44 }}>
                      <InputLabel id="statusLabel">
                        {t("layouts.searchForm.placeholders.status")}
                      </InputLabel>
                      <Select
                        labelId="statusLabel"
                        id="status"
                        value={opts?.clientStatus || CompanyStatusEnum.new}
                        variant="filled"
                        onChange={handleSetStatus}
                        input={
                          <OutlinedInput label={t("layouts.searchForm.placeholders.status")} />
                        }
                        MenuProps={MenuProps}
                        style={{ lineHeight: "2.7rem" }}
                      >
                        {CompanyStatuses.map((s) => (
                          <MenuItem key={s.description} value={s.value} id={s.value}>
                            {getStatusBadgeProps(s.value, "Company").label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <DFABox display="flex" justifyContent="flex-end">
                      {showReset && (
                        <DFAButton
                          variant="text"
                          color="secondary"
                          onClick={handleReset}
                          sx={{ marginRight: "auto" }}
                          id="reset-btn"
                        >
                          {t("layouts.searchForm.buttons.reset")}
                        </DFAButton>
                      )}
                      <DFAButton
                        sx={{ marginLeft: "1rem" }}
                        variant="contained"
                        color="success"
                        id="find"
                        onClick={handleSearch}
                      >
                        {t("layouts.searchForm.buttons.search")}
                      </DFAButton>
                      <DFAButton
                        sx={{ marginLeft: "1rem" }}
                        variant="contained"
                        color="secondary"
                        id="close"
                        onClick={handleClose}
                      >
                        {t("layouts.searchForm.buttons.close")}
                      </DFAButton>
                    </DFABox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </DFABox>
        </Grid>
      </DFABox>
      <DFABox display="flex" justifyContent="flex-end" flexWrap="wrap" width="100%">
        {badges.map((badge) => (
          <DFASearchBadge key={badge} delBadge={handleDelBadge} badge={badge} />
        ))}
      </DFABox>
    </>
  );
}

export default React.memo(CustomersSearchForm);
