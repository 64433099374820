import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiStep {
  defaultProps?: ComponentsProps["MuiStep"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiStep"];
  variants?: ComponentsVariants["MuiStep"];
}

const step: MuiStep = {
  styleOverrides: {
    root: {
      padding: "0 6px",
    },
  },
};

export default step;
