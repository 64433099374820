import React from "react";
import { styled, Theme } from "@mui/material/styles";
import { Icon, IconButton, LinearProgress, Typography } from "@mui/material";
import DFABox from "../DFABox";

interface IOwnerState {
  value: string | number;
  color: "warning" | "info" | "success" | "error" | "secondary";
}

interface IStyledLinerProgress {
  ownerState?: IOwnerState;
  theme?: Theme;
}

const StyledLinearProgress = styled(LinearProgress)<IStyledLinerProgress>(
  ({ ownerState, theme }) => ({
    background: ownerState.color === "secondary" ? theme.palette.grey[300] : "",
    height: "10px",
    "& .MuiLinearProgress-bar": {
      width: `${ownerState.value}%`,
      height: "10px",
      borderRadius: "5px",
    },
  })
);

interface IProgressProps {
  value: number;
  onClick(): void;
}

function Progress({ value, onClick }: IProgressProps) {
  return (
    <DFABox display="flex">
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        color="text.secondary"
        sx={{
          minWidth: "max-content",
          display: "flex",
          alignItems: "center",
        }}
      >
        {value} %
      </Typography>
      {onClick && (
        <IconButton size="small" sx={{ p: 0, ml: 1 }} disableRipple color="error" onClick={onClick}>
          <Icon color="error" fontSize="medium">
            clear
          </Icon>
        </IconButton>
      )}
    </DFABox>
  );
}

export { StyledLinearProgress, Progress };
