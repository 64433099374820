import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Pagination } from "@mui/material";
import { DFAButton, DFACard, DFASnackbar, DFATable } from "Theme";
import {
  AccountOperationAPI,
  CreatePaymentDetailsForm,
  PaymentDetailsResponse,
} from "@front-packages/dfa-gql-api";
import PaymentDetailsModal from "./PaymentDetailsModal";
import useColumns from "./useColumns";

interface IPaymentsProps {
  companyID: string;
  representativeID: string;
  setError: (value: React.SetStateAction<string>) => void;
}
function Payments({ companyID, representativeID, setError }: IPaymentsProps) {
  const [id, setId] = useState<string>(null);
  const [paymentState, setPaymentState] = useState<PaymentDetailsResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isCreatedAcc, setIsCreatedAcc] = useState<boolean>(false);
  const { t } = useTranslation();
  const columns = useColumns();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const getPaymentState = async (page = 0) => {
    setIsLoading(true);
    const { error, response } = await AccountOperationAPI.GetPaymentDetailsList({
      opts: { companyID, page, count: 10 },
    });
    if (error) {
      setError(error);
    }
    if (response) {
      setPaymentState(response);
    }
    setIsLoading(false);
  };

  const createPaymentDetails = async (
    form: CreatePaymentDetailsForm,
    setIsLoad: (isLoad: boolean) => void
  ) => {
    setIsLoad(true);
    const { error, response } = await AccountOperationAPI.CreatePaymentDetails({
      form: {
        ...form,
        representativeID: id,
      },
    });
    if (error) {
      setError(error);
      setIsCreatedAcc(false);
      setIsLoad(false);
      handleCloseModal();
    }
    if (response) {
      setIsCreatedAcc(true);
      setIsLoad(false);
      handleCloseModal();
      await getPaymentState();
    }
  };

  const handlePage = (_, page) => getPaymentState(page - 1);

  useEffect(() => {
    getPaymentState();
  }, []);

  useEffect(() => {
    setId(representativeID);
  }, [representativeID]);
  return (
    <>
      <DFACard title="Перечень реквизитов">
        <Grid item xs={12} mt={1} display="flex" alignItems="center" gap={2}>
          <DFAButton variant="contained" color="primary" onClick={handleOpenModal}>
            Добавить счет
          </DFAButton>
        </Grid>
        <DFATable rows={paymentState?.paymentDetails || []} columns={columns} loading={isLoading} />
        <PaymentDetailsModal
          open={openModal}
          close={handleCloseModal}
          createPaymentDetails={createPaymentDetails}
          setError={setError}
        />
        {paymentState?.count > 10 && (
          <Grid item xs={12}>
            <Pagination
              count={Math.ceil(paymentState.count / 10)}
              shape="rounded"
              onChange={handlePage}
            />
          </Grid>
        )}
      </DFACard>

      <Grid item>
        <DFASnackbar
          color="success"
          icon="check"
          title="Новый счет"
          snackContent="Добавлен новый счет"
          dateTime={t("general.errors.justNow")}
          open={isCreatedAcc}
          onClose={() => setIsCreatedAcc(false)}
          close={() => setIsCreatedAcc(false)}
        />
      </Grid>
    </>
  );
}

export default Payments;
