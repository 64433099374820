import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Breadcrumbs as MuiBreadcrumbs, Icon, Skeleton, Typography } from "@mui/material";
import { useTypedSelector } from "reducers";
import { RouteNamesEnum } from "consts";

interface IBreadcrumbsProps {
  route: Array<string>;
  light: boolean;
}

function Breadcrumbs({ route, light }: IBreadcrumbsProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    auth: { isAuth },
    adaptiveThemeStyle: { breadcrumb },
  } = useTypedSelector((state) => state);

  const getBreadcrumbsTitle = (i: number, el: string) => {
    if (i === route.length - 1 && breadcrumb && breadcrumb === "Loading...")
      return (
        <Skeleton
          animation="wave"
          sx={{ minWidth: "200px", maxWidth: "500px", borderRadius: "50px", height: "25px" }}
        />
      );
    if (i === route.length - 1 && breadcrumb && breadcrumb !== "Loading...") return breadcrumb;
    return t(`layouts.${el}.mainTitle`);
  };

  return (
    <Box mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { common, grey } }) => (light ? common.white : grey[600]),
          },
        }}
      >
        <Link to={isAuth ? RouteNamesEnum.customers : RouteNamesEnum.auth}>
          <Typography
            component="span"
            variant="body2"
            sx={{
              lineHeight: 0,
              opacity: 0.5,
              color: ({ palette }) => palette.common.white,
            }}
          >
            <Icon>home</Icon>
          </Typography>
        </Link>
        {!pathname.includes("authenticate") &&
          route.map((el, i) => (
            <Link to={`/${el}`} key={el}>
              <Typography
                component="span"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                sx={{ lineHeight: 0, opacity: light ? 0.8 : 0.5 }}
              >
                {getBreadcrumbsTitle(i, el)}
              </Typography>
            </Link>
          ))}
      </MuiBreadcrumbs>
    </Box>
  );
}

export default Breadcrumbs;
