import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { typography, palette } from "../../colors";
import { baseProperties } from "../../colors/typography";
import { rgba } from "../../../helpers";

interface MuiStepLabel {
  defaultProps?: ComponentsProps["MuiStepLabel"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiStepLabel"];
  variants?: ComponentsVariants["MuiStepLabel"];
}

const { fontWeightRegular } = typography;
const { fontSizeXS } = baseProperties;
const { white } = palette.common;

const stepLabel: MuiStepLabel = {
  styleOverrides: {
    label: {
      marginTop: "8px !important",
      fontWeight: fontWeightRegular,
      fontSize: fontSizeXS,
      color: "#9fc9ff",
      textTransform: "uppercase",

      "&.Mui-active": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `${rgba(white, 0.8)} !important`,
      },

      "&.Mui-completed": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `${rgba(white, 0.8)} !important`,
      },
    },
  },
};

export default stepLabel;
