import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiButtonBase {
  defaultProps?: ComponentsProps["MuiButtonBase"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiButtonBase"];
  variants?: ComponentsVariants["MuiButtonBase"];
}

const buttonBase: MuiButtonBase = {
  defaultProps: {
    disableRipple: false,
  },
};

export default buttonBase;
