import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiIconButton {
  defaultProps?: ComponentsProps["MuiIconButton"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiIconButton"];
  variants?: ComponentsVariants["MuiIconButton"];
}

const iconButton: MuiIconButton = {
  styleOverrides: {
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
};

export default iconButton;
