/*
DFA Platform Admin
***
Developed by ST soft team
*/

// eslint-disable-next-line import/extensions,import/no-unresolved
import { ILinearGradient } from "../typings";

/**
  The linearGradient() function helps you to create a linear gradient color background
 */

const linearGradient: ILinearGradient = (color, colorState, angle = 195) => {
  return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
};

export default linearGradient;
