import React from "react";
import { Card, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import DFABox from "../DFABox";

interface IDFACardProps {
  children: React.ReactNode;
  title?: string;
  titleBadge?: React.ReactNode;
  subTitle?: string;
  sx?: SxProps<Theme>;
}

function DFACard({ sx, title, children, subTitle, titleBadge }: IDFACardProps) {
  return (
    <Card
      sx={({ palette }: Theme) => ({
        height: "100%",
        padding: "20px",
        backgroundColor: palette.background.card,
        ...(sx as any),
      })}
    >
      <DFABox display="flex" mb={title || titleBadge ? 1 : 0}>
        {title && <Typography variant="h5">{title}</Typography>}
        {titleBadge && <DFABox ml={1}>{titleBadge}</DFABox>}
      </DFABox>
      {subTitle && (
        <Typography variant="body2" mb={2}>
          {subTitle}
        </Typography>
      )}
      <DFABox>{children}</DFABox>
    </Card>
  );
}

export default DFACard;
