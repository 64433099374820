/* eslint-disable */
import Long from "long";
import { util, configure, Writer, Reader } from "protobufjs/minimal";

export const protobufPackage = "rr.bcpcore.token";

export enum WalletType {
  Investment = 0,
  Emission = 1,
  UNRECOGNIZED = -1,
}

export function walletTypeFromJSON(object: any): WalletType {
  switch (object) {
    case 0:
    case "Investment":
      return WalletType.Investment;
    case 1:
    case "Emission":
      return WalletType.Emission;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WalletType.UNRECOGNIZED;
  }
}

export function walletTypeToJSON(object: WalletType): string {
  switch (object) {
    case WalletType.Investment:
      return "Investment";
    case WalletType.Emission:
      return "Emission";
    default:
      return "UNKNOWN";
  }
}

export enum TokenStatus {
  Instantiated = 0,
  Minted = 1,
  Issued = 2,
  InMarket = 3,
  InReedem = 4,
  Reedemed = 5,
  Frozen = 6,
  NotActive = 7,
  UNRECOGNIZED = -1,
}

export function tokenStatusFromJSON(object: any): TokenStatus {
  switch (object) {
    case 0:
    case "Instantiated":
      return TokenStatus.Instantiated;
    case 1:
    case "Minted":
      return TokenStatus.Minted;
    case 2:
    case "Issued":
      return TokenStatus.Issued;
    case 3:
    case "InMarket":
      return TokenStatus.InMarket;
    case 4:
    case "InReedem":
      return TokenStatus.InReedem;
    case 5:
    case "Reedemed":
      return TokenStatus.Reedemed;
    case 6:
    case "Frozen":
      return TokenStatus.Frozen;
    case 7:
    case "NotActive":
      return TokenStatus.NotActive;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TokenStatus.UNRECOGNIZED;
  }
}

export function tokenStatusToJSON(object: TokenStatus): string {
  switch (object) {
    case TokenStatus.Instantiated:
      return "Instantiated";
    case TokenStatus.Minted:
      return "Minted";
    case TokenStatus.Issued:
      return "Issued";
    case TokenStatus.InMarket:
      return "InMarket";
    case TokenStatus.InReedem:
      return "InReedem";
    case TokenStatus.Reedemed:
      return "Reedemed";
    case TokenStatus.Frozen:
      return "Frozen";
    case TokenStatus.NotActive:
      return "NotActive";
    default:
      return "UNKNOWN";
  }
}

export interface TokenOwnership {
  amount: number;
}

export interface TokenHold {
  operationId: string;
  holdAmount: number;
  holdDate: number;
  type: boolean;
}

export interface IssuedToken {
  issueId: string;
  emitentAddr: string;
  ticker: string;
  nominal: number;
  interest: number;
  amount: number;
  marketDate: number;
  redemptionDate: number;
  minRedemptionLimit: number;
  status: TokenStatus;
  maxIssueLimit: number;
}

export interface WalletAddress {
  addr: string;
  walletType: WalletType;
}

const baseTokenOwnership: object = { amount: 0 };

export const TokenOwnership = {
  encode(message: TokenOwnership, writer: Writer = Writer.create()): Writer {
    if (message.amount !== 0) {
      writer.uint32(8).uint64(message.amount);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): TokenOwnership {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTokenOwnership } as TokenOwnership;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TokenOwnership {
    const message = { ...baseTokenOwnership } as TokenOwnership;
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    return message;
  },

  toJSON(message: TokenOwnership): unknown {
    const obj: any = {};
    message.amount !== undefined && (obj.amount = message.amount);
    return obj;
  },

  fromPartial(object: DeepPartial<TokenOwnership>): TokenOwnership {
    const message = { ...baseTokenOwnership } as TokenOwnership;
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    return message;
  },
};

const baseTokenHold: object = {
  operationId: "",
  holdAmount: 0,
  holdDate: 0,
  type: false,
};

export const TokenHold = {
  encode(message: TokenHold, writer: Writer = Writer.create()): Writer {
    if (message.operationId !== "") {
      writer.uint32(10).string(message.operationId);
    }
    if (message.holdAmount !== 0) {
      writer.uint32(16).uint64(message.holdAmount);
    }
    if (message.holdDate !== 0) {
      writer.uint32(24).uint64(message.holdDate);
    }
    if (message.type === true) {
      writer.uint32(32).bool(message.type);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): TokenHold {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTokenHold } as TokenHold;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.operationId = reader.string();
          break;
        case 2:
          message.holdAmount = longToNumber(reader.uint64() as Long);
          break;
        case 3:
          message.holdDate = longToNumber(reader.uint64() as Long);
          break;
        case 4:
          message.type = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TokenHold {
    const message = { ...baseTokenHold } as TokenHold;
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    if (object.holdAmount !== undefined && object.holdAmount !== null) {
      message.holdAmount = Number(object.holdAmount);
    } else {
      message.holdAmount = 0;
    }
    if (object.holdDate !== undefined && object.holdDate !== null) {
      message.holdDate = Number(object.holdDate);
    } else {
      message.holdDate = 0;
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = Boolean(object.type);
    } else {
      message.type = false;
    }
    return message;
  },

  toJSON(message: TokenHold): unknown {
    const obj: any = {};
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    message.holdAmount !== undefined && (obj.holdAmount = message.holdAmount);
    message.holdDate !== undefined && (obj.holdDate = message.holdDate);
    message.type !== undefined && (obj.type = message.type);
    return obj;
  },

  fromPartial(object: DeepPartial<TokenHold>): TokenHold {
    const message = { ...baseTokenHold } as TokenHold;
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    if (object.holdAmount !== undefined && object.holdAmount !== null) {
      message.holdAmount = object.holdAmount;
    } else {
      message.holdAmount = 0;
    }
    if (object.holdDate !== undefined && object.holdDate !== null) {
      message.holdDate = object.holdDate;
    } else {
      message.holdDate = 0;
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type;
    } else {
      message.type = false;
    }
    return message;
  },
};

const baseIssuedToken: object = {
  issueId: "",
  emitentAddr: "",
  ticker: "",
  nominal: 0,
  interest: 0,
  amount: 0,
  marketDate: 0,
  redemptionDate: 0,
  minRedemptionLimit: 0,
  status: 0,
  maxIssueLimit: 0,
};

export const IssuedToken = {
  encode(message: IssuedToken, writer: Writer = Writer.create()): Writer {
    if (message.issueId !== "") {
      writer.uint32(10).string(message.issueId);
    }
    if (message.emitentAddr !== "") {
      writer.uint32(18).string(message.emitentAddr);
    }
    if (message.ticker !== "") {
      writer.uint32(26).string(message.ticker);
    }
    if (message.nominal !== 0) {
      writer.uint32(32).uint64(message.nominal);
    }
    if (message.interest !== 0) {
      writer.uint32(40).uint64(message.interest);
    }
    if (message.amount !== 0) {
      writer.uint32(48).uint64(message.amount);
    }
    if (message.marketDate !== 0) {
      writer.uint32(56).uint64(message.marketDate);
    }
    if (message.redemptionDate !== 0) {
      writer.uint32(64).uint64(message.redemptionDate);
    }
    if (message.minRedemptionLimit !== 0) {
      writer.uint32(72).uint64(message.minRedemptionLimit);
    }
    if (message.status !== 0) {
      writer.uint32(80).int32(message.status);
    }
    if (message.maxIssueLimit !== 0) {
      writer.uint32(88).uint64(message.maxIssueLimit);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): IssuedToken {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseIssuedToken } as IssuedToken;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.issueId = reader.string();
          break;
        case 2:
          message.emitentAddr = reader.string();
          break;
        case 3:
          message.ticker = reader.string();
          break;
        case 4:
          message.nominal = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.interest = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 7:
          message.marketDate = longToNumber(reader.uint64() as Long);
          break;
        case 8:
          message.redemptionDate = longToNumber(reader.uint64() as Long);
          break;
        case 9:
          message.minRedemptionLimit = longToNumber(reader.uint64() as Long);
          break;
        case 10:
          message.status = reader.int32() as any;
          break;
        case 11:
          message.maxIssueLimit = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): IssuedToken {
    const message = { ...baseIssuedToken } as IssuedToken;
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = String(object.issueId);
    } else {
      message.issueId = "";
    }
    if (object.emitentAddr !== undefined && object.emitentAddr !== null) {
      message.emitentAddr = String(object.emitentAddr);
    } else {
      message.emitentAddr = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = String(object.ticker);
    } else {
      message.ticker = "";
    }
    if (object.nominal !== undefined && object.nominal !== null) {
      message.nominal = Number(object.nominal);
    } else {
      message.nominal = 0;
    }
    if (object.interest !== undefined && object.interest !== null) {
      message.interest = Number(object.interest);
    } else {
      message.interest = 0;
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.marketDate !== undefined && object.marketDate !== null) {
      message.marketDate = Number(object.marketDate);
    } else {
      message.marketDate = 0;
    }
    if (object.redemptionDate !== undefined && object.redemptionDate !== null) {
      message.redemptionDate = Number(object.redemptionDate);
    } else {
      message.redemptionDate = 0;
    }
    if (
      object.minRedemptionLimit !== undefined &&
      object.minRedemptionLimit !== null
    ) {
      message.minRedemptionLimit = Number(object.minRedemptionLimit);
    } else {
      message.minRedemptionLimit = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = tokenStatusFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.maxIssueLimit !== undefined && object.maxIssueLimit !== null) {
      message.maxIssueLimit = Number(object.maxIssueLimit);
    } else {
      message.maxIssueLimit = 0;
    }
    return message;
  },

  toJSON(message: IssuedToken): unknown {
    const obj: any = {};
    message.issueId !== undefined && (obj.issueId = message.issueId);
    message.emitentAddr !== undefined &&
      (obj.emitentAddr = message.emitentAddr);
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.nominal !== undefined && (obj.nominal = message.nominal);
    message.interest !== undefined && (obj.interest = message.interest);
    message.amount !== undefined && (obj.amount = message.amount);
    message.marketDate !== undefined && (obj.marketDate = message.marketDate);
    message.redemptionDate !== undefined &&
      (obj.redemptionDate = message.redemptionDate);
    message.minRedemptionLimit !== undefined &&
      (obj.minRedemptionLimit = message.minRedemptionLimit);
    message.status !== undefined &&
      (obj.status = tokenStatusToJSON(message.status));
    message.maxIssueLimit !== undefined &&
      (obj.maxIssueLimit = message.maxIssueLimit);
    return obj;
  },

  fromPartial(object: DeepPartial<IssuedToken>): IssuedToken {
    const message = { ...baseIssuedToken } as IssuedToken;
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = object.issueId;
    } else {
      message.issueId = "";
    }
    if (object.emitentAddr !== undefined && object.emitentAddr !== null) {
      message.emitentAddr = object.emitentAddr;
    } else {
      message.emitentAddr = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = object.ticker;
    } else {
      message.ticker = "";
    }
    if (object.nominal !== undefined && object.nominal !== null) {
      message.nominal = object.nominal;
    } else {
      message.nominal = 0;
    }
    if (object.interest !== undefined && object.interest !== null) {
      message.interest = object.interest;
    } else {
      message.interest = 0;
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.marketDate !== undefined && object.marketDate !== null) {
      message.marketDate = object.marketDate;
    } else {
      message.marketDate = 0;
    }
    if (object.redemptionDate !== undefined && object.redemptionDate !== null) {
      message.redemptionDate = object.redemptionDate;
    } else {
      message.redemptionDate = 0;
    }
    if (
      object.minRedemptionLimit !== undefined &&
      object.minRedemptionLimit !== null
    ) {
      message.minRedemptionLimit = object.minRedemptionLimit;
    } else {
      message.minRedemptionLimit = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.maxIssueLimit !== undefined && object.maxIssueLimit !== null) {
      message.maxIssueLimit = object.maxIssueLimit;
    } else {
      message.maxIssueLimit = 0;
    }
    return message;
  },
};

const baseWalletAddress: object = { addr: "", walletType: 0 };

export const WalletAddress = {
  encode(message: WalletAddress, writer: Writer = Writer.create()): Writer {
    if (message.addr !== "") {
      writer.uint32(10).string(message.addr);
    }
    if (message.walletType !== 0) {
      writer.uint32(16).int32(message.walletType);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): WalletAddress {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWalletAddress } as WalletAddress;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.addr = reader.string();
          break;
        case 2:
          message.walletType = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WalletAddress {
    const message = { ...baseWalletAddress } as WalletAddress;
    if (object.addr !== undefined && object.addr !== null) {
      message.addr = String(object.addr);
    } else {
      message.addr = "";
    }
    if (object.walletType !== undefined && object.walletType !== null) {
      message.walletType = walletTypeFromJSON(object.walletType);
    } else {
      message.walletType = 0;
    }
    return message;
  },

  toJSON(message: WalletAddress): unknown {
    const obj: any = {};
    message.addr !== undefined && (obj.addr = message.addr);
    message.walletType !== undefined &&
      (obj.walletType = walletTypeToJSON(message.walletType));
    return obj;
  },

  fromPartial(object: DeepPartial<WalletAddress>): WalletAddress {
    const message = { ...baseWalletAddress } as WalletAddress;
    if (object.addr !== undefined && object.addr !== null) {
      message.addr = object.addr;
    } else {
      message.addr = "";
    }
    if (object.walletType !== undefined && object.walletType !== null) {
      message.walletType = object.walletType;
    } else {
      message.walletType = 0;
    }
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (util.Long !== Long) {
  util.Long = Long as any;
  configure();
}
