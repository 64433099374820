import React from "react";
import { DescriptionOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { getCookie } from "@front-packages/dfa-helpers";
import { CookieNamesEnum } from "@front-packages/dfa-constants";
import DFABox from "../DFABox";
import { downloadBlobHelper } from "./downloadHelpers";

interface IDFAFileProps {
  name: string;
  url?: string;
  onDelete?(): void;
}

const styles = {
  p: 1,
  width: "135px",
  height: "135px",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  background: ({ palette }) => palette.background.default,
  padding: 0,
  position: "relative",
};

function DFAFile({ name, url, onDelete }: IDFAFileProps) {
  const getFile = async () => {
    const blobFile = await fetch(`${process.env.REACT_APP_MIDDLE_API}${url}`, {
      headers: {
        Authorization: `Bearer ${getCookie(CookieNamesEnum.token)}`,
      },
    }).then((data) => data.blob());
    downloadBlobHelper(blobFile, name);
  };
  return (
    <DFABox sx={styles}>
      {onDelete && (
        <Typography
          sx={{
            position: "absolute",
            top: 5,
            right: 15,
            color: "red",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
          onClick={onDelete}
        >
          x
        </Typography>
      )}
      <DFABox fontSize={80} display="flex" height="90px">
        {url ? (
          <DFABox sx={{ cursor: "pointer" }} onClick={getFile}>
            <DescriptionOutlined color="success" fontSize="inherit" />
          </DFABox>
        ) : (
          <DescriptionOutlined color="success" fontSize="inherit" />
        )}
      </DFABox>
      {url ? (
        <DFABox sx={{ cursor: "pointer" }} onClick={getFile}>
          <Typography
            width="100px"
            component="span"
            color="success.main"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              title={name}
              fontSize="1rem"
              component="span"
              mr={1}
              sx={{
                overflowWrap: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {name}
            </Typography>
            <FileDownloadOutlined color="success" />
          </Typography>
        </DFABox>
      ) : (
        <Typography
          width="100px"
          component="span"
          color="success.main"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            title={name}
            fontSize="1rem"
            component="span"
            mr={1}
            sx={{
              overflowWrap: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </Typography>
          {/* <FileDownloadOutlined color="success" /> */}
        </Typography>
      )}
    </DFABox>
  );
}

export default DFAFile;
