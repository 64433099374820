import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

import { borders } from "../../../base";
import { palette, shadows } from "../../colors";

interface MuiTabs {
  defaultProps?: ComponentsProps["MuiTabs"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTabs"];
  variants?: ComponentsVariants["MuiTabs"];
}

const { background, info } = palette;
const { borderRadius } = borders;
const { md } = shadows;

const tabs: MuiTabs = {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: info[200],
      borderRadius: borderRadius.xl,
      minHeight: "unset",
      padding: "4px",
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    indicator: {
      height: "100%",
      borderRadius: borderRadius.lg,
      backgroundColor: background.default,
      boxShadow: md,
      transition: "all 500ms ease",
    },
  },
};

export default tabs;
