import React from "react";
import { Typography, Grid, Skeleton, Link } from "@mui/material";
import { Token } from "@front-packages/dfa-gql-api";
import { DFAEntityParams } from "Theme";
import { RouteNamesEnum } from "consts";

interface IEmissionHeaderProps {
  issue: Token;
  isLoading?: boolean;
}

function EmissionHeader({ issue, isLoading }: IEmissionHeaderProps) {
  return (
    <Grid container>
      <Grid item xs={12} mb={1}>
        {isLoading ? (
          <Skeleton variant="rounded" animation="wave" sx={{ width: "30%", m: "8px 0" }} />
        ) : (
          <Typography variant="h6" fontWeight={400}>
            <b>Эмитент: </b>
            <Link href={`${RouteNamesEnum.customersCard}?id=${issue.emitent?.id}`}>
              <Typography component="span" variant="h6" color="success.main">
                {issue.emitent?.fullName}
              </Typography>
            </Link>
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} mb={1}>
        {isLoading ? (
          <Skeleton variant="rounded" animation="wave" sx={{ width: "60%" }} />
        ) : (
          <Typography variant="h6" fontWeight={400}>
            <b>Создан:</b> {new Date(issue.createDate).toLocaleString("ru")} пользователем c ID{" "}
            <Typography component="span" variant="h6" color="success.main">
              {issue.authorID}
            </Typography>{" "}
            (представитель эмитента{" "}
            <Typography component="span" variant="h6" color="success.main">
              {issue.issuer}
            </Typography>
            )
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} mb={1}>
        {isLoading ? (
          <Skeleton variant="rounded" animation="wave" sx={{ width: "50%", m: "8px 0" }} />
        ) : (
          <Typography variant="h6" fontWeight={400}>
            <b>Адрес смарт-контракта: </b>
            <Typography component="span" variant="h6" color="success.main">
              {issue.smartContractAddress}
            </Typography>
          </Typography>
        )}
      </Grid>
      <Grid item container spacing={4} mt={2}>
        <Grid item xs={4}>
          <DFAEntityParams
            label="Дата начала сбора заявок"
            text={
              issue.investStartDate
                ? new Date(issue.investStartDate).toLocaleString("ru")
                : "Не установлена"
            }
            textSize="medium"
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <DFAEntityParams
            label="Дата окончания сбора заявок"
            text={
              issue.investFinishDate
                ? new Date(issue.investFinishDate).toLocaleString("ru")
                : "Не установлена"
            }
            textSize="medium"
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <DFAEntityParams
            label="Плановая дата публикации выпуска"
            text={
              issue.plannedPublicDate
                ? new Date(issue.plannedPublicDate).toLocaleString("ru")
                : "Не установлена"
            }
            textSize="medium"
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <DFAEntityParams
            label="Дата вывода на вторичный рынок"
            text={
              issue.marketDate ? new Date(issue.marketDate).toLocaleString("ru") : "Не установлена"
            }
            textSize="medium"
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <DFAEntityParams
            label="Дата начала погашения токенов выпуска"
            text={
              issue.redemptionDate
                ? new Date(issue.redemptionDate).toLocaleString("ru")
                : "Не установлена"
            }
            textSize="medium"
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <DFAEntityParams
            label="Дата окончания погашения токенов выпуска"
            text={
              issue.redemptionFinishDate
                ? new Date(issue.redemptionFinishDate).toLocaleString("ru")
                : "Не установлена"
            }
            textSize="medium"
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EmissionHeader;
