/*
 Developed by ST soft team
 */

import React, { ForwardedRef, forwardRef } from "react";
import { AvatarProps } from "@mui/material/Avatar/Avatar";
import AvatarRoot from "./AvatarRoot";

interface IAvatarProps extends AvatarProps {
  bgColor?:
    | "transparent"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark";
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  shadow?: "none" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "inset";
}

const MDAvatar = forwardRef(
  (
    { bgColor = "transparent", size = "md", shadow = "none", ...rest }: IAvatarProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => <AvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
);

export default MDAvatar;
