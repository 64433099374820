import { UseFilesResult } from "hooks/files";
import React, { useRef, useState } from "react";
import { Typography } from "@mui/material";
import ImgUploadIcon from "Theme/icons/upload/imgUploadIcon";
import StyledDropZone from "./StyledDropZone";
import DFAButton from "../DFAButton";

interface IDFADropZoneProps {
  isEdit: boolean;
  files: UseFilesResult;
  multiple?: boolean;
}

function DFADropZone({ isEdit, files, multiple = false }: IDFADropZoneProps) {
  const uploadBtnRef = useRef(null);

  const [isActiveZone, setIsActiveZone] = useState<boolean>(false);
  const [isDropped, setIsDropped] = useState<boolean>(false);
  const onDragStart = (e) => {
    e.preventDefault();
    setIsActiveZone(true);
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setIsActiveZone(false);
  };
  const onDragOver = (e) => {
    e.preventDefault();
    setIsActiveZone(true);
  };
  const onDrop = (e) => {
    e.preventDefault();
    setIsActiveZone(false);
    multiple
      ? files.multipleDropUpload(e.dataTransfer.files)
      : files.dropUpload(e.dataTransfer.files[0]);
    setIsDropped(true);
  };
  return (
    <StyledDropZone
      color="success"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: !isDropped ? "pointer" : "auto",
      }}
      onDragStart={(e) => onDragStart(e)}
      onDragLeave={(e) => onDragLeave(e)}
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) => onDrop(e)}
      active={isActiveZone}
    >
      <label
        htmlFor="upload-files"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <input
          ref={uploadBtnRef}
          id="upload-files"
          type="file"
          accept="text/*, image/*, application/pdf,doc,docx"
          onChange={files.upload}
          disabled={!isEdit}
          multiple={multiple}
        />
        <DFAButton
          onClick={() => uploadBtnRef?.current?.click()}
          startIcon={<ImgUploadIcon />}
          variant="text"
          color="success"
          disabled={!isEdit}
        />
        <Typography>
          Нажмите на эту область или перетащите сюда скан паспорта представителя
        </Typography>
      </label>
    </StyledDropZone>
  );
}

export default DFADropZone;
