import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useLocation, useNavigate, Route, Routes } from "react-router-dom";
import { CssBaseline, LinearProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { CookieNamesEnum } from "@front-packages/dfa-constants";
import { DFABox, DFADialog, DFASnackbar, AdminTheme } from "Theme";
import { deleteCookie, getCookie } from "@front-packages/dfa-helpers";
import { useTranslation } from "react-i18next";
import useAuthCheck from "hooks/authentication/useCheckAuth";
import { useTypedSelector } from "reducers";
import { setMiniSidenav } from "reducers/layout/action";
import { resetBlockedPages } from "reducers/auth/action";
import { setErrGlobal } from "reducers/errFetch/actions";
import { setLoadingFalse } from "reducers/loader/actions";
import { RouteNamesEnum } from "consts";
import DefaultLayout from "./layouts/DefaultLayout";
import routes, { IRoutes } from "./routes";
import SignIn from "./pages/authentication/sign-in";
import brandWhite from "./assets/images/SMART-Logo_RGB_Admin_White.svg";
import brandDark from "./assets/images/SMART-Logo_RGB_Admin_Black.svg";
import Sidenav from "./layouts/Sidenav";
import SignUp from "./pages/authentication/sign-up";
import Permissions from "./pages/authentication/permissions";

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const authCheck = useAuthCheck();
  const { t } = useTranslation();
  const {
    adaptiveThemeStyle: { miniSidenav, direction, sidenavColor, transparentSidenav, whiteSidenav },
    auth: { isAuth },
    errors: { errFetch, errGlobal },
    loader: { loading },
  } = useTypedSelector((state) => state, shallowEqual);
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      dispatch(setMiniSidenav(false));
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      dispatch(setMiniSidenav(true));
      setOnMouseEnter(false);
    }
  };

  const handleCloseSnackbar = () => dispatch(setErrGlobal(null));

  useEffect(() => {
    if (isAuth && (pathname === RouteNamesEnum.auth || pathname === "/"))
      navigate(RouteNamesEnum.customers);
    if (!isAuth && pathname === "/") navigate(RouteNamesEnum.auth);
  });

  useEffect(() => {
    dispatch(resetBlockedPages());
    if (getCookie(CookieNamesEnum.token)) {
      if (!isAuth && pathname.includes("authenticate")) {
        deleteCookie(CookieNamesEnum.token);
        deleteCookie(CookieNamesEnum.refreshToken);
        deleteCookie(CookieNamesEnum.user);
        dispatch(setLoadingFalse());
      } else
        authCheck.check().then((resp) => {
          if (resp) dispatch(setLoadingFalse());
          else navigate(RouteNamesEnum.auth);
        });
    } else if (!getCookie(CookieNamesEnum.token)) {
      navigate(RouteNamesEnum.auth);
      dispatch(setLoadingFalse());
    }
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: Array<IRoutes>) =>
    allRoutes.map((route) => {
      if (route.route)
        return <Route path={route.route} element={route.component} key={route.key} />;
      return null;
    });

  return (
    <ThemeProvider theme={AdminTheme}>
      <CssBaseline />
      {!pathname.includes("authenticate") && !loading && !errFetch && (
        <Sidenav
          color={sidenavColor}
          brand={transparentSidenav || whiteSidenav ? brandDark : brandWhite}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      {loading && (
        <DFABox
          sx={{
            width: "99%",
            overflow: "hidden",
            m: "0 auto",
            borderRadius: "15px",
            minHeight: "6px",
          }}
        >
          {loading && <LinearProgress color="success" />}
        </DFABox>
      )}
      {!loading && !errFetch && (
        <DefaultLayout>
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={null} />
            <Route path={RouteNamesEnum.auth} element={<SignIn />} />
            <Route path={RouteNamesEnum.permissions} element={<Permissions />} />
            <Route path={RouteNamesEnum.signUp} element={<SignUp />} />
          </Routes>
        </DefaultLayout>
      )}
      <DFADialog
        type="error"
        titleColor="error"
        open={!!errFetch}
        title={<DFABox sx={{ textAlign: "center" }}>{errFetch}</DFABox>}
        onClose={() => window.location.reload()}
        width="xs"
      />
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errGlobal}
        dateTime={t("general.errors.justNow")}
        open={!!errGlobal}
        onClose={handleCloseSnackbar}
        close={handleCloseSnackbar}
      />
    </ThemeProvider>
  );
}

export default App;
