import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import { DFADialog } from "Theme";
import { IConfirmDialogProps } from "./dialogTypes";

function BeneficiaryDialog({ isOpen, close, confirm }: IConfirmDialogProps) {
  const { t } = useTranslation();
  return (
    <DFADialog
      open={isOpen}
      title={t("layouts.card.clientCard.representatives.entityBeneficiarsTitle")}
      confirmText="Сохранить"
      cancelText="Отменить"
      onClose={close}
      onConfirm={confirm}
    >
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            id="beneficiaryName"
            label={t("layouts.card.clientCard.representatives.beneficiaryName")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="beneficiaryINN"
            label={t("layouts.card.clientCard.representatives.beneficiaryINN")}
            fullWidth
          />
        </Grid>
      </Grid>
    </DFADialog>
  );
}

export default BeneficiaryDialog;
