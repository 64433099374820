/* eslint-disable */
import Long from "long";
import { util, configure, Writer, Reader } from "protobufjs/minimal";

export const protobufPackage = "rr.bcpcore.issue";

export enum IssueStatus {
  Sheduled = 0,
  Started = 1,
  Passed = 2,
  NotPassed = 3,
  Rejected = 4,
  Canceled = 5,
  UNRECOGNIZED = -1,
}

export function issueStatusFromJSON(object: any): IssueStatus {
  switch (object) {
    case 0:
    case "Sheduled":
      return IssueStatus.Sheduled;
    case 1:
    case "Started":
      return IssueStatus.Started;
    case 2:
    case "Passed":
      return IssueStatus.Passed;
    case 3:
    case "NotPassed":
      return IssueStatus.NotPassed;
    case 4:
    case "Rejected":
      return IssueStatus.Rejected;
    case 5:
    case "Canceled":
      return IssueStatus.Canceled;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IssueStatus.UNRECOGNIZED;
  }
}

export function issueStatusToJSON(object: IssueStatus): string {
  switch (object) {
    case IssueStatus.Sheduled:
      return "Sheduled";
    case IssueStatus.Started:
      return "Started";
    case IssueStatus.Passed:
      return "Passed";
    case IssueStatus.NotPassed:
      return "NotPassed";
    case IssueStatus.Rejected:
      return "Rejected";
    case IssueStatus.Canceled:
      return "Canceled";
    default:
      return "UNKNOWN";
  }
}

export interface Issue {
  issueId: string;
  emitentAddr: string;
  issueStatus: IssueStatus;
  investStartDate: number;
  investFinishDate: number;
  minOrderLimit: number;
  maxOrderLimit: number;
  dayOrderLimit: number;
  maxIssueLimit: number;
  issueNominal: number;
  tickers: string[];
  investors: string[];
}

const baseIssue: object = {
  issueId: "",
  emitentAddr: "",
  issueStatus: 0,
  investStartDate: 0,
  investFinishDate: 0,
  minOrderLimit: 0,
  maxOrderLimit: 0,
  dayOrderLimit: 0,
  maxIssueLimit: 0,
  issueNominal: 0,
  tickers: "",
  investors: "",
};

export const Issue = {
  encode(message: Issue, writer: Writer = Writer.create()): Writer {
    if (message.issueId !== "") {
      writer.uint32(10).string(message.issueId);
    }
    if (message.emitentAddr !== "") {
      writer.uint32(18).string(message.emitentAddr);
    }
    if (message.issueStatus !== 0) {
      writer.uint32(24).int32(message.issueStatus);
    }
    if (message.investStartDate !== 0) {
      writer.uint32(32).uint64(message.investStartDate);
    }
    if (message.investFinishDate !== 0) {
      writer.uint32(40).uint64(message.investFinishDate);
    }
    if (message.minOrderLimit !== 0) {
      writer.uint32(48).uint64(message.minOrderLimit);
    }
    if (message.maxOrderLimit !== 0) {
      writer.uint32(56).uint64(message.maxOrderLimit);
    }
    if (message.dayOrderLimit !== 0) {
      writer.uint32(64).uint64(message.dayOrderLimit);
    }
    if (message.maxIssueLimit !== 0) {
      writer.uint32(72).uint64(message.maxIssueLimit);
    }
    if (message.issueNominal !== 0) {
      writer.uint32(80).uint64(message.issueNominal);
    }
    for (const v of message.tickers) {
      writer.uint32(90).string(v!);
    }
    for (const v of message.investors) {
      writer.uint32(98).string(v!);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Issue {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseIssue } as Issue;
    message.tickers = [];
    message.investors = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.issueId = reader.string();
          break;
        case 2:
          message.emitentAddr = reader.string();
          break;
        case 3:
          message.issueStatus = reader.int32() as any;
          break;
        case 4:
          message.investStartDate = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.investFinishDate = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.minOrderLimit = longToNumber(reader.uint64() as Long);
          break;
        case 7:
          message.maxOrderLimit = longToNumber(reader.uint64() as Long);
          break;
        case 8:
          message.dayOrderLimit = longToNumber(reader.uint64() as Long);
          break;
        case 9:
          message.maxIssueLimit = longToNumber(reader.uint64() as Long);
          break;
        case 10:
          message.issueNominal = longToNumber(reader.uint64() as Long);
          break;
        case 11:
          message.tickers.push(reader.string());
          break;
        case 12:
          message.investors.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Issue {
    const message = { ...baseIssue } as Issue;
    message.tickers = [];
    message.investors = [];
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = String(object.issueId);
    } else {
      message.issueId = "";
    }
    if (object.emitentAddr !== undefined && object.emitentAddr !== null) {
      message.emitentAddr = String(object.emitentAddr);
    } else {
      message.emitentAddr = "";
    }
    if (object.issueStatus !== undefined && object.issueStatus !== null) {
      message.issueStatus = issueStatusFromJSON(object.issueStatus);
    } else {
      message.issueStatus = 0;
    }
    if (
      object.investStartDate !== undefined &&
      object.investStartDate !== null
    ) {
      message.investStartDate = Number(object.investStartDate);
    } else {
      message.investStartDate = 0;
    }
    if (
      object.investFinishDate !== undefined &&
      object.investFinishDate !== null
    ) {
      message.investFinishDate = Number(object.investFinishDate);
    } else {
      message.investFinishDate = 0;
    }
    if (object.minOrderLimit !== undefined && object.minOrderLimit !== null) {
      message.minOrderLimit = Number(object.minOrderLimit);
    } else {
      message.minOrderLimit = 0;
    }
    if (object.maxOrderLimit !== undefined && object.maxOrderLimit !== null) {
      message.maxOrderLimit = Number(object.maxOrderLimit);
    } else {
      message.maxOrderLimit = 0;
    }
    if (object.dayOrderLimit !== undefined && object.dayOrderLimit !== null) {
      message.dayOrderLimit = Number(object.dayOrderLimit);
    } else {
      message.dayOrderLimit = 0;
    }
    if (object.maxIssueLimit !== undefined && object.maxIssueLimit !== null) {
      message.maxIssueLimit = Number(object.maxIssueLimit);
    } else {
      message.maxIssueLimit = 0;
    }
    if (object.issueNominal !== undefined && object.issueNominal !== null) {
      message.issueNominal = Number(object.issueNominal);
    } else {
      message.issueNominal = 0;
    }
    if (object.tickers !== undefined && object.tickers !== null) {
      for (const e of object.tickers) {
        message.tickers.push(String(e));
      }
    }
    if (object.investors !== undefined && object.investors !== null) {
      for (const e of object.investors) {
        message.investors.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: Issue): unknown {
    const obj: any = {};
    message.issueId !== undefined && (obj.issueId = message.issueId);
    message.emitentAddr !== undefined &&
      (obj.emitentAddr = message.emitentAddr);
    message.issueStatus !== undefined &&
      (obj.issueStatus = issueStatusToJSON(message.issueStatus));
    message.investStartDate !== undefined &&
      (obj.investStartDate = message.investStartDate);
    message.investFinishDate !== undefined &&
      (obj.investFinishDate = message.investFinishDate);
    message.minOrderLimit !== undefined &&
      (obj.minOrderLimit = message.minOrderLimit);
    message.maxOrderLimit !== undefined &&
      (obj.maxOrderLimit = message.maxOrderLimit);
    message.dayOrderLimit !== undefined &&
      (obj.dayOrderLimit = message.dayOrderLimit);
    message.maxIssueLimit !== undefined &&
      (obj.maxIssueLimit = message.maxIssueLimit);
    message.issueNominal !== undefined &&
      (obj.issueNominal = message.issueNominal);
    if (message.tickers) {
      obj.tickers = message.tickers.map((e) => e);
    } else {
      obj.tickers = [];
    }
    if (message.investors) {
      obj.investors = message.investors.map((e) => e);
    } else {
      obj.investors = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Issue>): Issue {
    const message = { ...baseIssue } as Issue;
    message.tickers = [];
    message.investors = [];
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = object.issueId;
    } else {
      message.issueId = "";
    }
    if (object.emitentAddr !== undefined && object.emitentAddr !== null) {
      message.emitentAddr = object.emitentAddr;
    } else {
      message.emitentAddr = "";
    }
    if (object.issueStatus !== undefined && object.issueStatus !== null) {
      message.issueStatus = object.issueStatus;
    } else {
      message.issueStatus = 0;
    }
    if (
      object.investStartDate !== undefined &&
      object.investStartDate !== null
    ) {
      message.investStartDate = object.investStartDate;
    } else {
      message.investStartDate = 0;
    }
    if (
      object.investFinishDate !== undefined &&
      object.investFinishDate !== null
    ) {
      message.investFinishDate = object.investFinishDate;
    } else {
      message.investFinishDate = 0;
    }
    if (object.minOrderLimit !== undefined && object.minOrderLimit !== null) {
      message.minOrderLimit = object.minOrderLimit;
    } else {
      message.minOrderLimit = 0;
    }
    if (object.maxOrderLimit !== undefined && object.maxOrderLimit !== null) {
      message.maxOrderLimit = object.maxOrderLimit;
    } else {
      message.maxOrderLimit = 0;
    }
    if (object.dayOrderLimit !== undefined && object.dayOrderLimit !== null) {
      message.dayOrderLimit = object.dayOrderLimit;
    } else {
      message.dayOrderLimit = 0;
    }
    if (object.maxIssueLimit !== undefined && object.maxIssueLimit !== null) {
      message.maxIssueLimit = object.maxIssueLimit;
    } else {
      message.maxIssueLimit = 0;
    }
    if (object.issueNominal !== undefined && object.issueNominal !== null) {
      message.issueNominal = object.issueNominal;
    } else {
      message.issueNominal = 0;
    }
    if (object.tickers !== undefined && object.tickers !== null) {
      for (const e of object.tickers) {
        message.tickers.push(e);
      }
    }
    if (object.investors !== undefined && object.investors !== null) {
      for (const e of object.investors) {
        message.investors.push(e);
      }
    }
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (util.Long !== Long) {
  util.Long = Long as any;
  configure();
}
