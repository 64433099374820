import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { DFAButton } from "Theme";
import {
  HandleChangeType,
  HandleDispatchLocalErrorType,
  HandleDispatchMsgValueType,
  HandleAddHoldsType,
} from "hooks/transactions/useInputChange";

export interface IHolds {
  addr: string;
  amount: string;
}

export type HandleSetHoldsType = (e, name: string) => void;

interface IInputFieldProps {
  inputName: string;
  inputType: string;
  value: any;
  handleChange: HandleChangeType;
  handleDispatchLocalError: HandleDispatchLocalErrorType;
  handleDispatchMsgValue: HandleDispatchMsgValueType;
  error: any;
  holds?: IHolds;
  handleSetHolds?: HandleSetHoldsType;
  parentObjKey?: string;
  handleAddHolds?: HandleAddHoldsType;
  setHolds?: (abj: any) => void;
}

export default function InputField({
  inputName,
  inputType,
  value,
  handleChange,
  handleDispatchLocalError,
  handleDispatchMsgValue,
  error,
  parentObjKey,
  holds,
  setHolds,
  handleAddHolds,
  handleSetHolds,
}: IInputFieldProps) {
  const { t } = useTranslation();

  const onChangeHandler = (e) => {
    if (parentObjKey === "holds") return handleSetHolds(e, inputName);
    if (parentObjKey === "senderWalletAddr" || parentObjKey === "receiverWalletAddr")
      return handleChange(e, inputName, inputType, parentObjKey);
    return handleChange(e, inputName, inputType);
  };

  const onInputHandler = (e) => {
    if (parentObjKey === "holds") return handleSetHolds(e, inputName);
    return handleDispatchLocalError(e);
  };

  const onBlurHandler = (e) => {
    if (parentObjKey === "holds") return handleSetHolds(e, inputName);
    return handleDispatchMsgValue(e, inputName);
  };

  let inputValue;
  let errorValue;

  const getInputValue = () => {
    if (parentObjKey && parentObjKey !== "holds") inputValue = value?.[parentObjKey]?.[inputName];
    else if (parentObjKey && parentObjKey === "holds") inputValue = holds?.[inputName];
    else inputValue = value?.[inputName];
  };

  const getErrorValue = () => {
    if (parentObjKey) errorValue = error?.[parentObjKey]?.[inputName];
    else errorValue = error?.[inputName];
  };

  getInputValue();
  getErrorValue();

  return (
    <>
      <Grid mb={2} xs={12}>
        {(parentObjKey === "senderWalletAddr" || parentObjKey === "receiverWalletAddr") && (
          <Typography>{parentObjKey}</Typography>
        )}
        <TextField
          sx={{ minWidth: 540 }}
          value={value && inputValue}
          margin="dense"
          name={inputName}
          id={inputName}
          label={inputName}
          type="text"
          fullWidth
          variant="outlined"
          onChange={(e) => onChangeHandler(e)}
          onInput={(e) => onInputHandler(e)}
          onBlur={(e) => onBlurHandler(e)}
          error={error && errorValue}
          helperText={
            error && errorValue && `${t("layouts.transactions.popup.errors.req")} ${inputName}`
          }
        />
      </Grid>
      {parentObjKey === "holds" && inputName === "amount" && (
        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          <Grid xs={12}>
            <Typography sx={{ fontSize: "16px" }}>
              {`holds: {${value?.holds?.map((elem) => `${JSON.stringify(elem)} `)}}`}
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: "right" }} xs={12}>
            <DFAButton
              onClick={() => handleAddHolds(holds, setHolds, "holds")}
              variant="contained"
              color="success"
            >
              {t("layouts.transactions.buttons.add")}
            </DFAButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}
