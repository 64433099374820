import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { colors } from "../../colors";

interface MuiSelect {
  defaultProps?: ComponentsProps["MuiSelect"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiSelect"];
  variants?: ComponentsVariants["MuiSelect"];
}

const { transparent } = colors;

const select: MuiSelect = {
  styleOverrides: {
    select: {
      display: "grid",
      alignItems: "center",
      padding: `0 12px !important`,

      "& .Mui-selected": {
        backgroundColor: transparent,
      },
    },

    icon: {
      display: "none",
    },
  },
};

export default select;
