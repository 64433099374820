import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { palette } from "../colors";
import { baseProperties } from "../colors/typography";

interface MuiBreadcrumbs {
  defaultProps?: ComponentsProps["MuiBreadcrumbs"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiBreadcrumbs"];
  variants?: ComponentsVariants["MuiBreadcrumbs"];
}

const { grey } = palette;
const { fontSizeSM } = baseProperties;

const breadcrumbs: MuiBreadcrumbs = {
  styleOverrides: {
    li: {
      lineHeight: 0,
    },

    separator: {
      fontSize: fontSizeSM,
      color: grey[600],
    },
  },
};

export default breadcrumbs;
