/*
 Developed by ST soft team
 */

import React from "react";
import { Chip } from "@mui/material";
import {
  NewReleasesOutlined,
  WarningAmber,
  VisibilityOutlined,
  GppMaybeOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";
import { Theme, Palette } from "@mui/material/styles";

export interface IDFAChipProps {
  label?: string;
  icon?: React.ReactElement | "none";
  type?: "warning" | "info" | "success" | "error" | "dark" | "primary" | "default";
  size?: "small" | "medium";
  variant?: "outlined" | "filled";
  sx?: any;
  id?: string;
}

function DFAChip(props: IDFAChipProps) {
  const { label, icon, type = "default", size = "medium", variant = "outlined", sx, id } = props;

  const getColor = (palette: Palette): string => {
    if (type === "default") return palette?.grey[500];
    if (type === "dark") return palette?.grey.A100;
    return palette[type]?.light;
  };

  const iconStyle = ({ palette }: Theme) => {
    const iconSize = size === "small" ? "15px" : "25px";
    return {
      color: `${variant === "filled" ? palette.common.white : getColor(palette)} !important`,
      width: iconSize,
      height: iconSize,
    };
  };
  const chipColor = ({ palette }: Theme) => ({
    lineHeight: "none",
    borderColor: `${variant === "outlined" ? getColor(palette) : "transparent"} !important`,
    color: `${variant === "filled" ? palette.common.white : getColor(palette)} !important`,
    backgroundColor: `${variant === "filled" ? getColor(palette) : "transparent"} !important`,
    ...sx,
  });

  let selfIcon: React.ReactElement;
  switch (type) {
    case "default": {
      selfIcon = <NewReleasesOutlined sx={iconStyle} />;
      break;
    }
    case "info": {
      selfIcon = <VisibilityOutlined sx={iconStyle} />;
      break;
    }
    case "warning": {
      selfIcon = <WarningAmber sx={iconStyle} />;
      break;
    }
    case "success": {
      selfIcon = <CheckCircleOutline sx={iconStyle} />;
      break;
    }
    case "error": {
      selfIcon = <GppMaybeOutlined sx={iconStyle} />;
      break;
    }
    default: {
      selfIcon = null;
      break;
    }
  }
  const iconChip = icon && icon !== "none" ? icon : selfIcon;
  return (
    <Chip
      variant={variant}
      label={label}
      id={id || label}
      size={size}
      sx={chipColor}
      icon={icon === "none" ? null : iconChip}
    />
  );
}

export default DFAChip;
