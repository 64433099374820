enum IssueEnum {
  MsgTransfer = "MsgTransfer",
  MsgSetStatus = "MsgSetStatus",
  MsgBurn = "MsgBurn",
  MsgAdd = "MsgAdd",
  MsgHold = "MsgHold",
  MsgTransferUnhold = "MsgTransferUnhold",
}

export default IssueEnum;
