/*
DFA Platform Admin
***
Developed by ST soft team
*/

import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Typography, TextField, Card, Grid } from "@mui/material";
import PhoneNumber from "material-ui-phone-number";
import { CountryPhones, DefaultCountryPhone } from "@front-packages/dfa-constants";
import { DFABox, DFAButton, DFADialog } from "Theme";
import { useAuth } from "hooks/authentication";
import { useValidate } from "hooks/other";
import { RouteNamesEnum } from "consts";

function SignUp() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const { invalidPhone, passValidInfo } = useValidate({
    phone: auth.get.phoneNumber,
    passwords: { mainPass: auth.get.password, verifyPass: auth.get.verifyPass },
  });
  const [code, setCode] = useState<string>("");
  const [sessionID, setSessionID] = useState<string>("");

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSetCode = (e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value);
  const handleSetPass = (e: ChangeEvent<HTMLInputElement>) => auth.set.password(e.target.value);
  const handleSetName = (e: ChangeEvent<HTMLInputElement>) => auth.set.name(e.target.value);
  const handleSetSecondPass = (e: ChangeEvent<HTMLInputElement>) =>
    auth.set.verifyPass(e.target.value);
  const handleSignUp = async () => {
    setLoading(true);
    const { phoneNumber, password } = auth.get;
    if (
      phoneNumber &&
      password &&
      !passValidInfo.mainPass.invalid &&
      !passValidInfo.verifyPass.invalid
    ) {
      const { code: FACode, sessionID: FASessionID } = await auth.regAndLogin();
      if (FACode && FASessionID) {
        setCode(FACode);
        setSessionID(FASessionID);
        setIsOpenModal(true);
      }
    }
    setLoading(false);
  };
  const handleSendCode = () => {
    setLoading(true);
    auth.sendCode(code, sessionID, RouteNamesEnum.permissions).catch(() => setLoading(false));
  };
  const handleEnter = async (e: any) => {
    if (e.nativeEvent.charCode === 13) await handleSignUp();
  };
  const handleClose = () => setIsOpenModal(false);
  const handleBack = () => navigate(RouteNamesEnum.auth);
  return (
    <DFABox
      sx={{
        display: "flex",
        height: "calc(100vh - 130px)",
        justifyContent: "center",
        alignItems: "center",
      }}
      onKeyPress={handleEnter}
    >
      <Card sx={{ width: "500px", minHeight: "390px" }}>
        <DFABox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Typography variant="h5">{t("layouts.authenticate.signUp.title")}</Typography>
        </DFABox>
        <Grid container spacing={3} p={3}>
          <Grid item xs={12}>
            <TextField
              type="ФИО"
              label="Введите имя"
              fullWidth
              value={auth.get.name}
              onChange={handleSetName}
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneNumber
              defaultCountry={DefaultCountryPhone}
              onlyCountries={CountryPhones}
              label="Введите номер телефона"
              value={auth.get.phoneNumber}
              onChange={auth.set.phoneNumber as any}
              fullWidth
              variant="outlined"
              error={invalidPhone}
              helperText={invalidPhone && "Некорректный номер телефона"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="new-password"
              type="password"
              value={auth.get.password}
              label="Придумайте пароль"
              fullWidth
              onChange={handleSetPass}
              error={passValidInfo.mainPass.invalid}
              helperText={passValidInfo.mainPass.invalid && passValidInfo.mainPass.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              value={auth.get.verifyPass}
              label="Повторите пароль"
              fullWidth
              onChange={handleSetSecondPass}
              error={passValidInfo.verifyPass.invalid}
              disabled={passValidInfo.mainPass.invalid}
              helperText={passValidInfo.verifyPass.invalid && passValidInfo.verifyPass.message}
            />
          </Grid>
          <Grid item xs={12} mt={4}>
            <DFAButton
              color="success"
              fullWidth
              onClick={handleSignUp}
              disabled={
                !auth.get.phoneNumber ||
                !auth.get.password ||
                !auth.get.verifyPass ||
                passValidInfo.verifyPass.invalid ||
                passValidInfo.mainPass.invalid
              }
              loading={loading}
            >
              {t("layouts.authenticate.options.registration")}
            </DFAButton>
          </Grid>
          <DFABox width="max-content" m="auto" mt={1} color="warning">
            <Typography variant="subtitle1">{auth.get.errorMessage}</Typography>
          </DFABox>
        </Grid>
        <DFABox width="max-content" m="auto" mt={1} mb={1}>
          <DFAButton color="success" variant="text" onClick={handleBack}>
            {t("layouts.authenticate.signUp.back")}
          </DFAButton>
        </DFABox>
      </Card>
      <DFADialog
        open={isOpenModal}
        title={t(`layouts.authenticate.dialog.title`)}
        dialogText={t(`layouts.authenticate.dialog.text`)}
        confirmText={t("layouts.authenticate.dialog.access")}
        cancelText={t("layouts.authenticate.dialog.cancel")}
        onClose={handleClose}
        onConfirm={handleSendCode}
        disabled={!code}
        loading={loading}
      >
        <TextField
          title="Введите проверочный код из смс"
          autoFocus
          value={code}
          margin="dense"
          id="name"
          label={t(`layouts.authenticate.dialog.sms`)}
          type="email"
          fullWidth
          variant="outlined"
          onInput={handleSetCode}
        />
      </DFADialog>
    </DFABox>
  );
}

export default SignUp;
