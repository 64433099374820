import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { palette } from "../../colors";

interface MuiStepConnector {
  defaultProps?: ComponentsProps["MuiStepConnector"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiStepConnector"];
  variants?: ComponentsVariants["MuiStepConnector"];
}

const { white } = palette.common;
const { borderWidth } = borders;

const stepConnector: MuiStepConnector = {
  styleOverrides: {
    root: {
      color: "#9fc9ff",
      transition: "all 200ms linear",

      "&.Mui-active": {
        color: white,
      },

      "&.Mui-completed": {
        color: white,
      },
    },

    alternativeLabel: {
      top: "14%",
      left: "-50%",
      right: "50%",
    },

    line: {
      borderWidth: `${borderWidth[2]} !important`,
      borderColor: "currentColor",
      opacity: 0.5,
    },
  },
};

export default stepConnector;
