import React from "react";
import { Typography, Accordion, AccordionSummary, AccordionDetails, Grid } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DFABox, DFACard, DFAChip, DFAEntityParams } from "Theme";
import { Tx } from "@front-packages/dfa-gql-api";
import chipProps from "./chipProps";

export interface IBlockChainCardTransactionProps {
  tx: Tx;
}

function TransactionCard({
  tx: {
    id,
    hash,
    blockID,
    type,
    date,
    status,
    addressFrom,
    addressTo,
    tokenFrom,
    amountFrom,
    tokenTo,
    amountTo,
    addressFee,
    tokenFee,
    amountFee,
    description,
    plainJSON,
  },
}: IBlockChainCardTransactionProps) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const a = JSON.stringify(JSON.parse(plainJSON), null, 2);
  return (
    <DFACard sx={{ height: "100%" }}>
      <Grid container spacing={3}>
        <Grid item sm={1}>
          <DFAEntityParams label="ID" textSize="medium" text={id} />
        </Grid>
        <Grid item sm={7}>
          <DFAEntityParams label="Hash" textSize="small" text={hash} title={hash} />
        </Grid>
        <Grid item sm={2}>
          <DFAEntityParams
            label="Status"
            textSize="medium"
            chip={<DFAChip variant="outlined" size="medium" {...chipProps(status)} />}
          />
        </Grid>
        <Grid item sm={2}>
          <DFAEntityParams
            label="Дата"
            textSize="medium"
            text={new Date(date).toLocaleString("ru-RU")}
          />
        </Grid>
        <Grid item sm={12}>
          <DFABox mt={2} variant="gradient" bgColor="success" borderRadius="xxl">
            <Accordion
              // TODO: потом сделать кастомный компонент и цвет брать из темы
              sx={{ bgcolor: "#222322" }}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="overline" textTransform="uppercase">
                  Подробная информация
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowGap={3}>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Block" textSize="medium" text={blockID} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Type" textSize="medium" text={type} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Address from" textSize="medium" text={addressFrom} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Address to" textSize="medium" text={addressTo} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Token from" textSize="medium" text={tokenFrom} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Amount from" textSize="medium" text={amountFrom} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Token to" textSize="medium" text={tokenTo} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Amount to" textSize="medium" text={amountTo} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Address fee" textSize="medium" text={addressFee} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Token fee" textSize="medium" text={tokenFee} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Amount fee" textSize="medium" text={amountFee} />
                  </Grid>
                  <Grid item sm={4}>
                    <DFAEntityParams label="Description" textSize="medium" text={description} />
                  </Grid>
                  <Grid item sm={12}>
                    <DFAEntityParams
                      label="Plain JSON"
                      text={
                        <SyntaxHighlighter
                          language="json"
                          style={atomDark}
                          lineProps={{
                            style: { wordBreak: "break-all", whiteSpace: "pre-wrap", fontSize: 14 },
                          }}
                          wrapLines
                          showLineNumbers
                        >
                          {a}
                        </SyntaxHighlighter>
                      }
                    />
                    {/* <Typography variant="overline" textTransform="uppercase"> */}
                    {/*  Plain JSON */}
                    {/* </Typography> */}
                    {/* <Box mt={1} lineHeight={1}> */}
                    {/*  <SyntaxHighlighter */}
                    {/*    language="json" */}
                    {/*    style={atomDark} */}
                    {/*    lineProps={{ */}
                    {/*      style: { wordBreak: "break-all", whiteSpace: "pre-wrap", fontSize: 14 }, */}
                    {/*    }} */}
                    {/*    wrapLines */}
                    {/*    showLineNumbers */}
                    {/*  > */}
                    {/*    {a} */}
                    {/*  </SyntaxHighlighter> */}
                    {/* </Box> */}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </DFABox>
        </Grid>
      </Grid>
    </DFACard>
  );
}

export default TransactionCard;
