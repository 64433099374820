/*
 Developed by ST soft team
 */

import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { breakpoints } from "../../base";

interface ITabsProps {
  tabValue: number;
  setTabValue: (value: number) => void;
  labels?: {
    key: string | number;
    icon?: React.ReactElement;
    text?: string;
  }[];
}

function DFATabsBar({ tabValue, setTabValue, labels }: ITabsProps) {
  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  const handleSetTabValue = (_, newValue: number) => setTabValue(newValue);

  return (
    <Tabs
      scrollButtons
      textColor="primary"
      orientation={tabsOrientation}
      value={tabValue}
      onChange={handleSetTabValue}
    >
      {labels &&
        labels.map(({ key, icon, text }) => (
          <Tab key={`tab-${key}`} wrapped label={text} icon={icon} id={`tab-${key}`} />
        ))}
    </Tabs>
  );
}

export default DFATabsBar;
