import { styled, Theme } from "@mui/material/styles";
import { Box } from "@mui/material";

interface IStyledDropZone {
  theme?: Theme;
  bgColor?: "warning" | "info" | "success" | "error" | "secondary";
  active?: boolean;
}

const StyledDropZone = styled(Box)<IStyledDropZone>(
  ({ theme, bgColor = "success", active = false }) => ({
    background: `${theme.palette[bgColor].main}${!active ? "1F" : "6B"}`,
    borderRadius: "10px",
    padding: "20px",
    backgroundImage: `${
      active
        ? `url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: green; stroke-width: 4; stroke-dasharray: 15 10'/></svg>")`
        : ""
    }`,
    height: "150px",
  })
);

export default StyledDropZone;
