import { Theme } from "@mui/material/styles";

export const mainStyle = ({ palette, other, breakpoints }: Theme) => ({
  background: palette.info[50],
  borderRadius: other.borders.borderRadius.xl,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "max-content",
  paddingLeft: "10px",
  paddingRight: "5px",
  color: `${palette.common.white} !important`,
  ml: 1,
  ...(window.innerWidth < breakpoints.values.md ? { mt: 1 } : {}),
  cursor: "pointer",
});

export const iconCrossStyle = ({ other: { baseTypographyProp }, breakpoints }: Theme) => ({
  px: 0.5,

  "& .material-icons, .material-icons-round": {
    fontSize: `${baseTypographyProp.fontSizeSM} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});
