import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { palette } from "../../colors";

const { borderWidth } = borders;
const { grey } = palette;

interface MuiTableCell {
  defaultProps?: ComponentsProps["MuiTableCell"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTableCell"];
  variants?: ComponentsVariants["MuiTableCell"];
}

const tableCell: MuiTableCell = {
  styleOverrides: {
    root: {
      padding: "12px 16px",
      borderBottom: `${borderWidth[1]} solid ${grey[200]}`,
    },
  },
};

export default tableCell;
