import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Skeleton, Typography } from "@mui/material";
import {
  File,
  FilesAPI,
  OrdersAPI,
  TestAPI,
  Token,
  TokenOrdersResponse,
  TokenStatusGql,
  TokensAPI,
  UserClientType,
} from "@front-packages/dfa-gql-api";
import { DFABox, DFAButton, DFACard, DFAChip, DFADialog, DFAFile, DFASnackbar } from "Theme";
import { useStatuses } from "hooks/other";
import { useTranslation } from "react-i18next";
import { useErrors } from "hooks/errors";
import { setBreadcrumb, setMainTitle } from "reducers/layout/action";
import { EmissionHeader, OrderCard, PermissionCard, TokenCard } from "./components";

function Emission() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { errorMessage, setError } = useErrors();
  const [data, setData] = useState<Token>({});
  const [tokenOrders, setTokenOrder] = useState<TokenOrdersResponse>(null);
  const [tokenFiles, setTokenFiles] = useState<File[]>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [orderPage] = useState<number>(0);
  const [issueNotExist, setIssueNotExist] = useState<boolean>(false);
  const [issueStatus, setIssueStatus] = useState<TokenStatusGql>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const getStatusBadgeProps = useStatuses();

  const [loadingTestSendToRedeem, setLoadingTestSendToRedeem] = useState<boolean>(false);
  const [loadingChangeStatusToInMarket, setLoadingChangeStatusToInMarket] =
    useState<boolean>(false);

  const setSigning = () => {
    setIssueStatus(TokenStatusGql.Signing);
    setIsOpenModal(true);
  };
  const setDraft = () => {
    setIssueStatus(TokenStatusGql.Draft);
    setIsOpenModal(true);
  };
  const setRejected = () => {
    setIssueStatus(TokenStatusGql.Rejected);
    setIsOpenModal(true);
  };

  const getIssueByID = async () => {
    dispatch(setMainTitle({ isLoading: true }));
    dispatch(setBreadcrumb("Loading..."));
    const tokenID = search.split("=")[1];
    const { response, error } = await TokensAPI.GetTokenByID({ tokenID });
    if (error) setError(error);
    if (error === "failed to execute operation") {
      setIssueNotExist(true);
      dispatch(setMainTitle({ title: `Выпуск [${tokenID}]`, isLoading: false }));
      dispatch(setBreadcrumb(tokenID));
    }
    if (response) {
      setData(response);
      dispatch(
        setMainTitle({
          title: `Выпуск [${response?.ticker}]`,
          chip: (
            <DFAChip
              {...getStatusBadgeProps(response?.status, "Token")}
              type={(getStatusBadgeProps(response?.status, "Token").color as any) || "info"}
            />
          ),
          isLoading: false,
        })
      );
      dispatch(setBreadcrumb(response?.id));
    }
    setLoading(false);
  };
  const getOrdersByTokenID = async (tokenID: string) => {
    const { response, error } = await OrdersAPI.GetOrdersList({
      opts: { page: orderPage, count: 10, clientType: UserClientType.Admin, tokenID },
    });
    if (error) setError(error);
    if (response) setTokenOrder(response);
  };
  // const handleSetPage = async (_, changePage) => {
  //   setOrderPage(changePage - 1);
  //   await getOrdersByTokenID(data.id);
  // };
  const getTokenFiles = async () => {
    const tokenID = search.split("=")[1];
    const { response, error } = await FilesAPI.GetTokenFiles({ tokenID });
    if (error) setError(error);
    if (response) setTokenFiles(response);
  };
  const onSendToRedeem = async () => {
    setLoadingTestSendToRedeem(true);
    const tokenID = search.split("=")[1];
    const { error } = await TestAPI.DVPTokenManualSendToRedeem({ tokenID });
    if (error) setError(error);
  };
  const onChangeStatusToInMarket = async () => {
    setLoadingChangeStatusToInMarket(true);
    const tokenID = search.split("=")[1];
    const { error } = await TestAPI.DVPTokenChangeStatusToInMarket({ tokenID });
    if (error) setError(error);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setIssueStatus(null);
  };
  const handleCloseError = () => setError("");
  const sendStatus = async () => {
    if (!issueStatus) return;
    setLoading(true);
    const { response, error } = await TokensAPI.SetTokenStatus({
      tokenID: data.id,
      status: issueStatus,
    });
    if (error) {
      setError(error);
      setLoading(false);
    }
    if (response) {
      await getIssueByID();
      setLoading(false);
      handleCloseModal();
    }
  };
  useEffect(() => {
    const tokenID = search.split("=")[1];
    getIssueByID();
    getOrdersByTokenID(tokenID);
    return () => {
      dispatch(setMainTitle({ title: null, chip: null }));
      dispatch(setBreadcrumb(null));
    };
  }, []);
  useEffect(() => {
    getTokenFiles();
  }, []);

  useEffect(() => {
    data &&
      dispatch(
        setMainTitle({
          title: `Выпуск [${data?.ticker}]`,
          chip: (
            <DFAChip
              {...getStatusBadgeProps(data?.status, "Token")}
              type={(getStatusBadgeProps(data?.status, "Token").color as any) || "info"}
            />
          ),
        })
      );
  }, [window.localStorage.getItem("i18nextLng")]);

  useEffect(() => {
    if (loadingTestSendToRedeem) {
      const timerID = setTimeout(() => {
        window.location.reload();
      }, 60000);
      return () => clearTimeout(timerID);
    }
    return undefined;
  }, [loadingTestSendToRedeem]);

  useEffect(() => {
    if (loadingChangeStatusToInMarket) {
      const timerID = setTimeout(() => {
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timerID);
    }
    return undefined;
  }, [loadingChangeStatusToInMarket]);

  const dialogTitle =
    (issueStatus === TokenStatusGql.Signing && "Подтвердите отправку на подписание") ||
    (issueStatus === TokenStatusGql.Draft && "Подтвердите отправку на доработку") ||
    (issueStatus === TokenStatusGql.Started && "Подтвердите старт приема заявок") ||
    "";
  const dialogType = (issueStatus === TokenStatusGql.Draft && ("info" as any)) || "success";

  if (!loading && issueNotExist)
    return (
      <Grid container xs={12} md={2} lg={2}>
        <DFAButton variant="outlined" color="success" onClick={() => navigate("/emissions")}>
          {t("general.buttons.back")}
        </DFAButton>
      </Grid>
    );

  return (
    <>
      <Grid container mt={1} mb={5} spacing={3}>
        <Grid item xs={12}>
          <EmissionHeader issue={data} isLoading={loading} />
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <PermissionCard title="Объем прав" text={data?.scopeRights} isLoading={loading} />
          </Grid>
          <Grid item xs={6}>
            <PermissionCard title="Обременения" text={data?.limits} isLoading={loading} />
          </Grid>
          <Grid item xs={6}>
            <PermissionCard title="Обеспечение" text={data?.provision} isLoading={loading} />
          </Grid>
          <Grid item xs={6}>
            <PermissionCard title="Условия выпуска" text={data?.issueTerms} isLoading={loading} />
          </Grid>
        </Grid>
        {tokenFiles && tokenFiles.length ? (
          <Grid item xs={12} mb={4}>
            <>
              <Typography mb={2} variant="h4">
                Прикрепляемые файлы
              </Typography>
              <DFACard sx={{ justifyContent: "center" }}>
                <DFABox display="flex" gap={2} flexWrap="wrap" alignItems="center">
                  {tokenFiles.map((file) => (
                    <DFAFile name={file.name} key={file.id} url={file.url} />
                  ))}
                </DFABox>
              </DFACard>
            </>
          </Grid>
        ) : null}
        <Grid mb={4} item xs={12}>
          <Typography mb={2} variant="h4">
            Токен
          </Typography>
          {loading ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ width: "100%", height: "200px", borderRadius: "12px" }}
            />
          ) : (
            <TokenCard issue={data} />
          )}
        </Grid>
        {!!tokenOrders?.orders?.length && (
          <Grid item xs={12}>
            <Typography variant="h4">Заявки</Typography>
          </Grid>
        )}
        {tokenOrders &&
          tokenOrders?.orders?.map((order) => (
            <Grid item xs={6} key={order.id}>
              <OrderCard order={order} />
            </Grid>
          ))}
        {data?.status === TokenStatusGql.OnApproval && (
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "16px !important" }}
            display="flex"
            justifyContent="flex-end"
          >
            <DFAButton sx={{ mr: 1 }} variant="contained" color="success" onClick={setSigning}>
              Разрешить выпуск
            </DFAButton>
            <DFAButton sx={{ mr: 1 }} variant="contained" color="info" onClick={setDraft}>
              Вернуть на доработку
            </DFAButton>
            <DFAButton variant="contained" color="error" onClick={setRejected}>
              Отклонить
            </DFAButton>
          </Grid>
        )}
        {data?.status === TokenStatusGql.Issued && (
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "16px !important" }}
            display="flex"
            justifyContent="flex-end"
            columnGap={1}
            alignItems="center"
          >
            <Typography color="red">
              Кнопку Допуск на вторичный рынок нажимать только 1 раз!!!
            </Typography>
            <DFAButton
              variant="contained"
              color="success"
              onClick={onChangeStatusToInMarket}
              loading={loadingChangeStatusToInMarket}
              disabled={loadingChangeStatusToInMarket}
            >
              Допуск на вторичный рынок
            </DFAButton>
          </Grid>
        )}
        {(data?.status === TokenStatusGql.Issued || data?.status === TokenStatusGql.InMarket) && (
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "16px !important" }}
            display="flex"
            justifyContent="flex-end"
            columnGap={1}
            alignItems="center"
          >
            <Typography color="red">Кнопку Начать погашение нажимать только 1 раз!!!</Typography>
            <DFAButton
              variant="contained"
              color="success"
              onClick={onSendToRedeem}
              loading={loadingTestSendToRedeem}
              disabled={loadingTestSendToRedeem}
            >
              Начать погашение
            </DFAButton>
          </Grid>
        )}
      </Grid>
      <DFADialog
        open={isOpenModal}
        title={dialogTitle}
        type={dialogType}
        loading={loading}
        confirmText="Подтвердить"
        onConfirm={sendStatus}
        onClose={handleCloseModal}
      />
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errorMessage}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage}
        onClose={handleCloseError}
        close={handleCloseError}
      />
    </>
  );
}

export default Emission;
