import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { palette, colors } from "../../colors";
import { baseProperties } from "../../colors/typography";
import { rgba } from "../../../helpers";

interface MuiOutlinedInput {
  defaultProps?: ComponentsProps["MuiOutlinedInput"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiOutlinedInput"];
  variants?: ComponentsVariants["MuiOutlinedInput"];
}

const { transparent } = colors;
const { success, text, grey } = palette;
const { borderRadius } = borders;
const { fontSizeSM, fontSizeXS } = baseProperties;

const inputOutlined: MuiOutlinedInput = {
  styleOverrides: {
    root: {
      backgroundColor: transparent,
      fontSize: fontSizeSM,
      borderRadius: borderRadius.md,

      "& .MuiOutlinedInput-notchedOutline legend": {
        fontSize: "0.93rem !important",
      },

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "light" in success ? success.light : "inherit",
      },

      "&.Mui-disabled": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: rgba(grey[400], 0.7),
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: rgba(grey[400], 0.7),
        },
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "light" in success ? success.light : "inherit",
        },
      },
    },

    notchedOutline: {
      borderColor: grey[400],
    },

    input: {
      color: text.primary,
      padding: "12px",
      backgroundColor: transparent,
    },

    inputSizeSmall: {
      fontSize: fontSizeXS,
      padding: "10px",
    },

    multiline: {
      color: text.primary,
      padding: 0,
    },
  },
};

export default inputOutlined;
