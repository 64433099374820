/*
DFA Platform Admin
***
Developed by ST soft team
*/
import { BlockedPages, RESET_BLOCKED_PAGES, SET_BLOCKED_PAGES, SET_IS_AUTH } from "./action";

export interface IAuthState {
  isAuth: boolean;
  blockedPages: Required<BlockedPages>;
}

const initialState: IAuthState = {
  isAuth: false,
  blockedPages: {
    transactions: false,
    blockchain: false,
    customers: false,
    admins: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_AUTH:
      return { ...state, isAuth: action.payload };

    case SET_BLOCKED_PAGES:
      return { ...state, blockedPages: { ...state.blockedPages, ...action.payload } };

    case RESET_BLOCKED_PAGES:
      return { ...state, blockedPages: initialState.blockedPages };

    default: {
      return state;
    }
  }
}
