import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

import { borders } from "../../../base";
import { typography, palette } from "../../colors";
import { baseProperties } from "../../colors/typography";

interface MuiTab {
  defaultProps?: ComponentsProps["MuiTab"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTab"];
  variants?: ComponentsVariants["MuiTab"];
}

const { fontWeightBold, fontWeightRegular } = typography;
const { fontSizeSM } = baseProperties;
const { borderRadius } = borders;
const { white } = palette.common;

const tab: MuiTab = {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flex: "1 1 auto",
      textAlign: "center",
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: fontSizeSM,
      fontWeight: fontWeightRegular,
      textTransform: "none",
      lineHeight: "inherit",
      padding: "4px",
      borderRadius: borderRadius.lg,
      color: `${white}`,
      opacity: "1 !important",

      "&.Mui-selected": {
        fontWeight: fontWeightBold,
      },

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: "6px",
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: "6px",
      },
    },

    labelIcon: {
      paddingTop: "4px",
    },
  },
};

export default tab;
