/* eslint-disable */
import {
  TokenStatus,
  WalletAddress,
  tokenStatusFromJSON,
  tokenStatusToJSON,
} from "../token/token";
import { Reader, util, configure, Writer } from "protobufjs/minimal";
import Long from "long";

export const protobufPackage = "rr.bcpcore.token";

export interface MsgTransfer {
  creator: string;
  senderWalletAddr: WalletAddress | undefined;
  receiverWalletAddr: WalletAddress | undefined;
  ticker: string;
  amount: number;
  operationId: string;
}

export interface MsgTransferResponse {}

export interface MsgSetStatus {
  creator: string;
  ticker: string;
  status: TokenStatus;
  reason: string;
}

export interface MsgSetStatusResponse {}

export interface MsgBurn {
  creator: string;
  customerAddr: string;
  ticker: string;
  amount: number;
  operationId: string;
}

export interface MsgBurnResponse {}

export interface MsgAdd {
  creator: string;
  customerAddr: string;
  ticker: string;
  amount: number;
  operationId: string;
}

export interface MsgAddResponse {}

export interface MsgHold {
  creator: string;
  senderWalletAddr: WalletAddress | undefined;
  receiverWalletAddr: WalletAddress | undefined;
  ticker: string;
  amount: number;
  holdDate: number;
  operationId: string;
}

export interface MsgHoldResponse {}

export interface MsgTransferUnhold {
  creator: string;
  senderWalletAddr: WalletAddress | undefined;
  receiverWalletAddr: WalletAddress | undefined;
  ticker: string;
  amount: number;
  operationId: string;
  unHoldRest: boolean;
}

export interface MsgTransferUnholdResponse {}

const baseMsgTransfer: object = {
  creator: "",
  ticker: "",
  amount: 0,
  operationId: "",
};

export const MsgTransferToken = {
  encode(message: MsgTransfer, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.senderWalletAddr !== undefined) {
      WalletAddress.encode(
        message.senderWalletAddr,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.receiverWalletAddr !== undefined) {
      WalletAddress.encode(
        message.receiverWalletAddr,
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.ticker !== "") {
      writer.uint32(34).string(message.ticker);
    }
    if (message.amount !== 0) {
      writer.uint32(40).uint64(message.amount);
    }
    if (message.operationId !== "") {
      writer.uint32(50).string(message.operationId);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgTransfer {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgTransfer } as MsgTransfer;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.senderWalletAddr = WalletAddress.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.receiverWalletAddr = WalletAddress.decode(
            reader,
            reader.uint32()
          );
          break;
        case 4:
          message.ticker = reader.string();
          break;
        case 5:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.operationId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgTransfer {
    const message = { ...baseMsgTransfer } as MsgTransfer;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (
      object.senderWalletAddr !== undefined &&
      object.senderWalletAddr !== null
    ) {
      message.senderWalletAddr = WalletAddress.fromJSON(
        object.senderWalletAddr
      );
    } else {
      message.senderWalletAddr = undefined;
    }
    if (
      object.receiverWalletAddr !== undefined &&
      object.receiverWalletAddr !== null
    ) {
      message.receiverWalletAddr = WalletAddress.fromJSON(
        object.receiverWalletAddr
      );
    } else {
      message.receiverWalletAddr = undefined;
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = String(object.ticker);
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    return message;
  },

  toJSON(message: MsgTransfer): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.senderWalletAddr !== undefined &&
      (obj.senderWalletAddr = message.senderWalletAddr
        ? WalletAddress.toJSON(message.senderWalletAddr)
        : undefined);
    message.receiverWalletAddr !== undefined &&
      (obj.receiverWalletAddr = message.receiverWalletAddr
        ? WalletAddress.toJSON(message.receiverWalletAddr)
        : undefined);
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.amount !== undefined && (obj.amount = message.amount);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgTransfer>): MsgTransfer {
    const message = { ...baseMsgTransfer } as MsgTransfer;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (
      object.senderWalletAddr !== undefined &&
      object.senderWalletAddr !== null
    ) {
      message.senderWalletAddr = WalletAddress.fromPartial(
        object.senderWalletAddr
      );
    } else {
      message.senderWalletAddr = undefined;
    }
    if (
      object.receiverWalletAddr !== undefined &&
      object.receiverWalletAddr !== null
    ) {
      message.receiverWalletAddr = WalletAddress.fromPartial(
        object.receiverWalletAddr
      );
    } else {
      message.receiverWalletAddr = undefined;
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = object.ticker;
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    return message;
  },
};

const baseMsgTransferResponse: object = {};

export const MsgTransferResponse = {
  encode(_: MsgTransferResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgTransferResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgTransferResponse } as MsgTransferResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgTransferResponse {
    const message = { ...baseMsgTransferResponse } as MsgTransferResponse;
    return message;
  },

  toJSON(_: MsgTransferResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgTransferResponse>): MsgTransferResponse {
    const message = { ...baseMsgTransferResponse } as MsgTransferResponse;
    return message;
  },
};

const baseMsgSetStatus: object = {
  creator: "",
  ticker: "",
  status: 0,
  reason: "",
};

export const MsgSetStatusToken = {
  encode(message: MsgSetStatus, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.ticker !== "") {
      writer.uint32(18).string(message.ticker);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.reason !== "") {
      writer.uint32(34).string(message.reason);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetStatus {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetStatus } as MsgSetStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.ticker = reader.string();
          break;
        case 3:
          message.status = reader.int32() as any;
          break;
        case 4:
          message.reason = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgSetStatus {
    const message = { ...baseMsgSetStatus } as MsgSetStatus;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = String(object.ticker);
    } else {
      message.ticker = "";
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = tokenStatusFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.reason !== undefined && object.reason !== null) {
      message.reason = String(object.reason);
    } else {
      message.reason = "";
    }
    return message;
  },

  toJSON(message: MsgSetStatus): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.status !== undefined &&
      (obj.status = tokenStatusToJSON(message.status));
    message.reason !== undefined && (obj.reason = message.reason);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgSetStatus>): MsgSetStatus {
    const message = { ...baseMsgSetStatus } as MsgSetStatus;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = object.ticker;
    } else {
      message.ticker = "";
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.reason !== undefined && object.reason !== null) {
      message.reason = object.reason;
    } else {
      message.reason = "";
    }
    return message;
  },
};

const baseMsgSetStatusResponse: object = {};

export const MsgSetStatusResponse = {
  encode(_: MsgSetStatusResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetStatusResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetStatusResponse } as MsgSetStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgSetStatusResponse {
    const message = { ...baseMsgSetStatusResponse } as MsgSetStatusResponse;
    return message;
  },

  toJSON(_: MsgSetStatusResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgSetStatusResponse>): MsgSetStatusResponse {
    const message = { ...baseMsgSetStatusResponse } as MsgSetStatusResponse;
    return message;
  },
};

const baseMsgBurn: object = {
  creator: "",
  customerAddr: "",
  ticker: "",
  amount: 0,
  operationId: "",
};

export const MsgBurnToken = {
  encode(message: MsgBurn, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    if (message.ticker !== "") {
      writer.uint32(26).string(message.ticker);
    }
    if (message.amount !== 0) {
      writer.uint32(32).uint64(message.amount);
    }
    if (message.operationId !== "") {
      writer.uint32(42).string(message.operationId);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgBurn {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgBurn } as MsgBurn;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        case 3:
          message.ticker = reader.string();
          break;
        case 4:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.operationId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgBurn {
    const message = { ...baseMsgBurn } as MsgBurn;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = String(object.ticker);
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    return message;
  },

  toJSON(message: MsgBurn): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.amount !== undefined && (obj.amount = message.amount);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgBurn>): MsgBurn {
    const message = { ...baseMsgBurn } as MsgBurn;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = object.ticker;
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    return message;
  },
};

const baseMsgBurnResponse: object = {};

export const MsgBurnResponse = {
  encode(_: MsgBurnResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgBurnResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgBurnResponse } as MsgBurnResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgBurnResponse {
    const message = { ...baseMsgBurnResponse } as MsgBurnResponse;
    return message;
  },

  toJSON(_: MsgBurnResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgBurnResponse>): MsgBurnResponse {
    const message = { ...baseMsgBurnResponse } as MsgBurnResponse;
    return message;
  },
};

const baseMsgAdd: object = {
  creator: "",
  customerAddr: "",
  ticker: "",
  amount: 0,
  operationId: "",
};

export const MsgAddToken = {
  encode(message: MsgAdd, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    if (message.ticker !== "") {
      writer.uint32(26).string(message.ticker);
    }
    if (message.amount !== 0) {
      writer.uint32(32).uint64(message.amount);
    }
    if (message.operationId !== "") {
      writer.uint32(42).string(message.operationId);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgAdd {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgAdd } as MsgAdd;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        case 3:
          message.ticker = reader.string();
          break;
        case 4:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.operationId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgAdd {
    const message = { ...baseMsgAdd } as MsgAdd;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = String(object.ticker);
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    return message;
  },

  toJSON(message: MsgAdd): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.amount !== undefined && (obj.amount = message.amount);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgAdd>): MsgAdd {
    const message = { ...baseMsgAdd } as MsgAdd;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = object.ticker;
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    return message;
  },
};

const baseMsgAddResponse: object = {};

export const MsgAddResponse = {
  encode(_: MsgAddResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgAddResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgAddResponse } as MsgAddResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgAddResponse {
    const message = { ...baseMsgAddResponse } as MsgAddResponse;
    return message;
  },

  toJSON(_: MsgAddResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgAddResponse>): MsgAddResponse {
    const message = { ...baseMsgAddResponse } as MsgAddResponse;
    return message;
  },
};

const baseMsgHold: object = {
  creator: "",
  ticker: "",
  amount: 0,
  holdDate: 0,
  operationId: "",
};

export const MsgHoldToken = {
  encode(message: MsgHold, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.senderWalletAddr !== undefined) {
      WalletAddress.encode(
        message.senderWalletAddr,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.receiverWalletAddr !== undefined) {
      WalletAddress.encode(
        message.receiverWalletAddr,
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.ticker !== "") {
      writer.uint32(34).string(message.ticker);
    }
    if (message.amount !== 0) {
      writer.uint32(40).uint64(message.amount);
    }
    if (message.holdDate !== 0) {
      writer.uint32(48).uint64(message.holdDate);
    }
    if (message.operationId !== "") {
      writer.uint32(58).string(message.operationId);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgHold {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgHold } as MsgHold;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.senderWalletAddr = WalletAddress.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.receiverWalletAddr = WalletAddress.decode(
            reader,
            reader.uint32()
          );
          break;
        case 4:
          message.ticker = reader.string();
          break;
        case 5:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.holdDate = longToNumber(reader.uint64() as Long);
          break;
        case 7:
          message.operationId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgHold {
    const message = { ...baseMsgHold } as MsgHold;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (
      object.senderWalletAddr !== undefined &&
      object.senderWalletAddr !== null
    ) {
      message.senderWalletAddr = WalletAddress.fromJSON(
        object.senderWalletAddr
      );
    } else {
      message.senderWalletAddr = undefined;
    }
    if (
      object.receiverWalletAddr !== undefined &&
      object.receiverWalletAddr !== null
    ) {
      message.receiverWalletAddr = WalletAddress.fromJSON(
        object.receiverWalletAddr
      );
    } else {
      message.receiverWalletAddr = undefined;
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = String(object.ticker);
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.holdDate !== undefined && object.holdDate !== null) {
      message.holdDate = Number(object.holdDate);
    } else {
      message.holdDate = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    return message;
  },

  toJSON(message: MsgHold): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.senderWalletAddr !== undefined &&
      (obj.senderWalletAddr = message.senderWalletAddr
        ? WalletAddress.toJSON(message.senderWalletAddr)
        : undefined);
    message.receiverWalletAddr !== undefined &&
      (obj.receiverWalletAddr = message.receiverWalletAddr
        ? WalletAddress.toJSON(message.receiverWalletAddr)
        : undefined);
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.amount !== undefined && (obj.amount = message.amount);
    message.holdDate !== undefined && (obj.holdDate = message.holdDate);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgHold>): MsgHold {
    const message = { ...baseMsgHold } as MsgHold;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (
      object.senderWalletAddr !== undefined &&
      object.senderWalletAddr !== null
    ) {
      message.senderWalletAddr = WalletAddress.fromPartial(
        object.senderWalletAddr
      );
    } else {
      message.senderWalletAddr = undefined;
    }
    if (
      object.receiverWalletAddr !== undefined &&
      object.receiverWalletAddr !== null
    ) {
      message.receiverWalletAddr = WalletAddress.fromPartial(
        object.receiverWalletAddr
      );
    } else {
      message.receiverWalletAddr = undefined;
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = object.ticker;
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.holdDate !== undefined && object.holdDate !== null) {
      message.holdDate = object.holdDate;
    } else {
      message.holdDate = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    return message;
  },
};

const baseMsgHoldResponse: object = {};

export const MsgHoldResponse = {
  encode(_: MsgHoldResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgHoldResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgHoldResponse } as MsgHoldResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgHoldResponse {
    const message = { ...baseMsgHoldResponse } as MsgHoldResponse;
    return message;
  },

  toJSON(_: MsgHoldResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgHoldResponse>): MsgHoldResponse {
    const message = { ...baseMsgHoldResponse } as MsgHoldResponse;
    return message;
  },
};

const baseMsgTransferUnhold: object = {
  creator: "",
  ticker: "",
  amount: 0,
  operationId: "",
  unHoldRest: false,
};

export const MsgTransferUnholdToken = {
  encode(message: MsgTransferUnhold, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.senderWalletAddr !== undefined) {
      WalletAddress.encode(
        message.senderWalletAddr,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.receiverWalletAddr !== undefined) {
      WalletAddress.encode(
        message.receiverWalletAddr,
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.ticker !== "") {
      writer.uint32(34).string(message.ticker);
    }
    if (message.amount !== 0) {
      writer.uint32(40).uint64(message.amount);
    }
    if (message.operationId !== "") {
      writer.uint32(50).string(message.operationId);
    }
    if (message.unHoldRest === true) {
      writer.uint32(56).bool(message.unHoldRest);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgTransferUnhold {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgTransferUnhold } as MsgTransferUnhold;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.senderWalletAddr = WalletAddress.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.receiverWalletAddr = WalletAddress.decode(
            reader,
            reader.uint32()
          );
          break;
        case 4:
          message.ticker = reader.string();
          break;
        case 5:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.operationId = reader.string();
          break;
        case 7:
          message.unHoldRest = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgTransferUnhold {
    const message = { ...baseMsgTransferUnhold } as MsgTransferUnhold;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (
      object.senderWalletAddr !== undefined &&
      object.senderWalletAddr !== null
    ) {
      message.senderWalletAddr = WalletAddress.fromJSON(
        object.senderWalletAddr
      );
    } else {
      message.senderWalletAddr = undefined;
    }
    if (
      object.receiverWalletAddr !== undefined &&
      object.receiverWalletAddr !== null
    ) {
      message.receiverWalletAddr = WalletAddress.fromJSON(
        object.receiverWalletAddr
      );
    } else {
      message.receiverWalletAddr = undefined;
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = String(object.ticker);
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = String(object.operationId);
    } else {
      message.operationId = "";
    }
    if (object.unHoldRest !== undefined && object.unHoldRest !== null) {
      message.unHoldRest = Boolean(object.unHoldRest);
    } else {
      message.unHoldRest = false;
    }
    return message;
  },

  toJSON(message: MsgTransferUnhold): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.senderWalletAddr !== undefined &&
      (obj.senderWalletAddr = message.senderWalletAddr
        ? WalletAddress.toJSON(message.senderWalletAddr)
        : undefined);
    message.receiverWalletAddr !== undefined &&
      (obj.receiverWalletAddr = message.receiverWalletAddr
        ? WalletAddress.toJSON(message.receiverWalletAddr)
        : undefined);
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.amount !== undefined && (obj.amount = message.amount);
    message.operationId !== undefined &&
      (obj.operationId = message.operationId);
    message.unHoldRest !== undefined && (obj.unHoldRest = message.unHoldRest);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgTransferUnhold>): MsgTransferUnhold {
    const message = { ...baseMsgTransferUnhold } as MsgTransferUnhold;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (
      object.senderWalletAddr !== undefined &&
      object.senderWalletAddr !== null
    ) {
      message.senderWalletAddr = WalletAddress.fromPartial(
        object.senderWalletAddr
      );
    } else {
      message.senderWalletAddr = undefined;
    }
    if (
      object.receiverWalletAddr !== undefined &&
      object.receiverWalletAddr !== null
    ) {
      message.receiverWalletAddr = WalletAddress.fromPartial(
        object.receiverWalletAddr
      );
    } else {
      message.receiverWalletAddr = undefined;
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = object.ticker;
    } else {
      message.ticker = "";
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.operationId !== undefined && object.operationId !== null) {
      message.operationId = object.operationId;
    } else {
      message.operationId = "";
    }
    if (object.unHoldRest !== undefined && object.unHoldRest !== null) {
      message.unHoldRest = object.unHoldRest;
    } else {
      message.unHoldRest = false;
    }
    return message;
  },
};

const baseMsgTransferUnholdResponse: object = {};

export const MsgTransferUnholdResponse = {
  encode(
    _: MsgTransferUnholdResponse,
    writer: Writer = Writer.create()
  ): Writer {
    return writer;
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): MsgTransferUnholdResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseMsgTransferUnholdResponse,
    } as MsgTransferUnholdResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgTransferUnholdResponse {
    const message = {
      ...baseMsgTransferUnholdResponse,
    } as MsgTransferUnholdResponse;
    return message;
  },

  toJSON(_: MsgTransferUnholdResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<MsgTransferUnholdResponse>
  ): MsgTransferUnholdResponse {
    const message = {
      ...baseMsgTransferUnholdResponse,
    } as MsgTransferUnholdResponse;
    return message;
  },
};

/** Msg defines the Msg service. */
export interface Msg {
  Transfer(request: MsgTransfer): Promise<MsgTransferResponse>;
  SetStatus(request: MsgSetStatus): Promise<MsgSetStatusResponse>;
  Burn(request: MsgBurn): Promise<MsgBurnResponse>;
  Add(request: MsgAdd): Promise<MsgAddResponse>;
  Hold(request: MsgHold): Promise<MsgHoldResponse>;
  /** this line is used by starport scaffolding # proto/tx/rpc */
  TransferUnhold(
    request: MsgTransferUnhold
  ): Promise<MsgTransferUnholdResponse>;
}

export class MsgClientImpl implements Msg {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }
  Transfer(request: MsgTransfer): Promise<MsgTransferResponse> {
    const data = MsgTransferToken.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.token.Msg", "Transfer", data);
    return promise.then((data) => MsgTransferResponse.decode(new Reader(data)));
  }

  SetStatus(request: MsgSetStatus): Promise<MsgSetStatusResponse> {
    const data = MsgSetStatusToken.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.token.Msg", "SetStatus", data);
    return promise.then((data) =>
      MsgSetStatusResponse.decode(new Reader(data))
    );
  }

  Burn(request: MsgBurn): Promise<MsgBurnResponse> {
    const data = MsgBurnToken.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.token.Msg", "Burn", data);
    return promise.then((data) => MsgBurnResponse.decode(new Reader(data)));
  }

  Add(request: MsgAdd): Promise<MsgAddResponse> {
    const data = MsgAddToken.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.token.Msg", "Add", data);
    return promise.then((data) => MsgAddResponse.decode(new Reader(data)));
  }

  Hold(request: MsgHold): Promise<MsgHoldResponse> {
    const data = MsgHoldToken.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.token.Msg", "Hold", data);
    return promise.then((data) => MsgHoldResponse.decode(new Reader(data)));
  }

  TransferUnhold(
    request: MsgTransferUnhold
  ): Promise<MsgTransferUnholdResponse> {
    const data = MsgTransferUnholdToken.encode(request).finish();
    const promise = this.rpc.request(
      "rr.bcpcore.token.Msg",
      "TransferUnhold",
      data
    );
    return promise.then((data) =>
      MsgTransferUnholdResponse.decode(new Reader(data))
    );
  }
}

interface Rpc {
  request(
    service: string,
    method: string,
    data: Uint8Array
  ): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (util.Long !== Long) {
  util.Long = Long as any;
  configure();
}
