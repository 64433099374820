import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

import { baseProperties } from "../../colors/typography";

interface MuiDialogTitle {
  defaultProps?: ComponentsProps["MuiDialogTitle"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiDialogTitle"];
  variants?: ComponentsVariants["MuiDialogTitle"];
}

const { fontSizeXL } = baseProperties;

const dialogTitle: MuiDialogTitle = {
  styleOverrides: {
    root: {
      padding: "16px",
      fontSize: fontSizeXL,
    },
  },
};

export default dialogTitle;
