import React from "react";

export const MINI_SIDENAV = "@@theme/mini_side_nav";
export const TRANSPARENT_SIDENAV = "@@theme/transparent_side_nav";
export const WHITE_SIDENAV = "@@theme/white_side_nav";
export const TRANSPARENT_NAVBAR = "@@theme/transparent_navbar";
export const OPEN_CONFIGURATOR = "@@theme/open_configurator";
export const MODE = "@@theme/mode";
export const TITLE = "@@theme/title";
export const BREADCRUMB = "@@theme/breadcrumb";
// todo: not used
// export const SIDENAV_COLOR = "@@theme/side_nav_color";
// export const FIXED_NAVBAR = "@@theme/fixed_navbar";
// export const DIRECTION = "@@theme/directions";
// export const LAYOUT = "@@theme/layout";

export const setMiniSidenav = (value) => ({ type: MINI_SIDENAV, value });
export const setTransparentSidenav = (value) => ({ type: TRANSPARENT_SIDENAV, value });
export const setWhiteSidenav = (value) => ({ type: WHITE_SIDENAV, value });
export const setTransparentNavbar = (value) => ({ type: TRANSPARENT_NAVBAR, value });
export const setOpenConfigurator = (value) => ({ type: OPEN_CONFIGURATOR, value });
export const setDarkMode = (value: boolean) => ({ type: MODE, value });
export const setMainTitle = (value: {
  title?: string;
  chip?: React.ReactNode;
  isLoading?: boolean;
}) => ({
  type: TITLE,
  value,
});
export const setBreadcrumb = (value: string) => ({ type: BREADCRUMB, value });
// todo: not used
// export const setSidenavColor = (value) => ({ type: SIDENAV_COLOR, value });
// export const setFixedNavbar = (value) => ({ type: FIXED_NAVBAR, value });
// export const setDirection = (value) => ({ type: DIRECTION, value });
// export const setLayout = (value) => ({ type: LAYOUT, value });
