import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { DFAButton, DFASnackbar, DFATable } from "Theme";
import { useErrors } from "hooks/errors";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import ConfirmDialog from "./dialogs/Confirm";
import RepresentativeCard from "./components/Representative";
import BeneficiaryDialog from "./dialogs/Beneficiary";
import BeneficiaryOwnerDialog from "./dialogs/BeneficiaryOwner";
import { setLoading } from "../../../../reducer/actions";

enum ModalsEnum {
  beneficiary,
  beneficiaryOwner,
  deleteConfirmDialog,
}

interface IRepresentativeInfoProps {
  isNew: boolean;
}

const defaultBeneficiariesColumns: GridColDef[] = [
  { field: "clientName", headerName: null, width: 500, headerAlign: "center" },
  { field: "INN", headerName: null, width: 250, headerAlign: "center" },
];

const defaultBeneficiariesOwnerColumns: GridColDef[] = [
  { field: "clientName", headerName: null, width: 500, headerAlign: "center" },
  { field: "INN", headerName: null, width: 250, headerAlign: "center" },
  { field: "decision", headerName: null, width: 700, headerAlign: "center" },
];

export default function RepresentativeInfo({ isNew }: IRepresentativeInfoProps) {
  const dispatch = useDispatch();
  const { isEdit } = useTypedSelector((state) => state.customer);

  const { i18n, t } = useTranslation();
  const [beneficiaryColumns, setBeneficiaryColumns] = useState(defaultBeneficiariesColumns);
  const [beneficiaryOwnerColumns, setBeneficiaryOwnerColumns] = useState(
    defaultBeneficiariesOwnerColumns
  );
  const [modal, setModal] = useState<ModalsEnum>(null);
  const { setError, errorMessage } = useErrors();

  const handleBeneficiaryModal = () => setModal(ModalsEnum.beneficiary);
  const handleBeneficiaryOwnerModal = () => setModal(ModalsEnum.beneficiaryOwner);
  const handleDeleteModal = () => setModal(ModalsEnum.deleteConfirmDialog);
  const handleCloseModal = () => {
    setModal(null);
    dispatch(setLoading(false));
  };
  const handleCloseSnackbar = () => setError("");

  useEffect(() => {
    setBeneficiaryColumns(
      beneficiaryColumns.map((el) => ({
        ...el,
        headerName: t(`layouts.card.clientCard.representatives.${el.field}`),
      }))
    );
    setBeneficiaryOwnerColumns(
      beneficiaryOwnerColumns.map((el) => ({
        ...el,
        headerName: t(`layouts.card.clientCard.representatives.${el.field}`),
      }))
    );
  }, [i18n.language]);

  return (
    <>
      <Grid container spacing={2}>
        {!isNew && (
          <Grid item xs={12}>
            <RepresentativeCard />
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="end">
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h4">
                  {t("layouts.card.clientCard.representatives.IPBeneficiars")}
                </Typography>
              </Grid>
              {isEdit && (
                <Grid item>
                  <Tooltip title={t("general.tooltips.addBeneficiary")}>
                    <DFAButton
                      variant="outlined"
                      color="success"
                      iconOnly
                      onClick={handleBeneficiaryModal}
                    >
                      <AddIcon />
                    </DFAButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            {isEdit && (
              <Tooltip title={t("general.tooltips.deleteBeneficiary")}>
                <DFAButton variant="outlined" color="error" iconOnly onClick={handleDeleteModal}>
                  <DeleteIcon />
                </DFAButton>
              </Tooltip>
            )}
          </Stack>
          <DFATable columns={beneficiaryColumns} rows={[]} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="end">
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h4">
                  {t("layouts.card.clientCard.representatives.beneficiaryOwnersTitle")}
                </Typography>
              </Grid>
              {isEdit && (
                <Grid item>
                  <Tooltip title={t("general.tooltips.addBeneficiaryOwner")}>
                    <DFAButton
                      variant="outlined"
                      color="success"
                      iconOnly
                      onClick={handleBeneficiaryOwnerModal}
                    >
                      <AddIcon />
                    </DFAButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            {isEdit && (
              <Tooltip title={t("general.tooltips.deleteBeneficiaryOwner")}>
                <DFAButton variant="outlined" color="error" iconOnly onClick={handleDeleteModal}>
                  <DeleteIcon />
                </DFAButton>
              </Tooltip>
            )}
          </Stack>
          <DFATable columns={beneficiaryOwnerColumns} rows={[]} />
        </Grid>
      </Grid>
      <ConfirmDialog
        isOpen={modal === ModalsEnum.deleteConfirmDialog}
        confirm={handleCloseModal}
        close={handleCloseModal}
      />
      <BeneficiaryDialog
        isOpen={modal === ModalsEnum.beneficiary}
        confirm={handleCloseModal}
        close={handleCloseModal}
      />
      <BeneficiaryOwnerDialog
        isOpen={modal === ModalsEnum.beneficiaryOwner}
        confirm={handleCloseModal}
        close={handleCloseModal}
      />
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errorMessage}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage}
        onClose={handleCloseSnackbar}
        close={handleCloseSnackbar}
      />
    </>
  );
}
