import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { DFAAlert, DFABox, DFATable } from "Theme";
import {
  GetTokensOpts,
  UserClientType,
  SortOptionGql,
  TokenStatusGql,
} from "@front-packages/dfa-gql-api";
import { useLocation } from "react-router-dom";
import { amountWithSpaces, formatDate } from "@front-packages/dfa-helpers";
import { useErrors } from "hooks/errors";
import { useTypedSelector } from "reducers";
import FinesSearchForm from "./components/FinesSearchForm";

type Burden = {
  id: number;
  issuer: string;
  number: string;
  amount: string;
  created: Date;
  removing?: Date;
  status: string;
};

type Fine = {
  id: number;
  debtor: string;
  claimant: string;
  number: string;
  status: string;
  reason: string;
  created: Date;
  recoveryDate: Date;
  files: string;
  comment: string;
};

const burdens: Burden[] = [
  {
    id: 1,
    issuer: 'ООО "Рога и копыта"',
    number: "0005-2023-000000000001",
    amount: "1000000",
    created: new Date(),
    status: "Наложено",
  },
];

const fines: Fine[] = [
  {
    id: 1,
    debtor: 'ООО "Рога и копыта"',
    claimant: 'ООО "Ромашка"',
    number: "0007-2023-000000000001",
    files: "",
    created: new Date(),
    recoveryDate: new Date(),
    reason: "Исполнительный лист от 27.10.2022 № ФС 039317038 АРБИТРАЖНЫЙ СУД РЕСПУБЛИКИ ТАТАРСТАН",
    status: "Новая",
    comment: "Иные взыскания имущественного характера в пользу физических и юридических лиц",
  },
];

function Fines() {
  const route = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "")[0];
  const { errorMessage } = useErrors();
  const defaultOpts = {
    page: 0,
    count: 10,
    clientType: UserClientType.Admin,
    sortOption: SortOptionGql.StartDateEarly,
    tokenStatuses: [TokenStatusGql.InMarket],
  };
  const [opts] = useState<GetTokensOpts>(defaultOpts);
  const { auth } = useTypedSelector((store) => store);

  const handleSearch = async (params) => {
    console.log("handleSearch", params);
  };

  const burdensColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "№",
      width: 50,
      headerAlign: "center",
    },
    {
      field: "issuer",
      headerName: "Клиент",
      width: 250,
      headerAlign: "center",
    },
    {
      field: "number",
      headerName: "Номер обременения",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "status",
      headerName: "Статус",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "amount",
      headerName: "Сумма обременения",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => `${amountWithSpaces(params.value)} RUB`,
    },
    {
      field: "created",
      headerName: "Дата установки",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => formatDate(params.value, true),
    },
    {
      field: "removed",
      headerName: "Дата снятия",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => (params.value ? formatDate(params.value, true) : "Не установлено"),
    },
  ];

  const finesColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "№",
      width: 50,
      headerAlign: "center",
    },
    {
      field: "debtor",
      headerName: "Должник",
      width: 250,
      headerAlign: "center",
    },
    {
      field: "claimant",
      headerName: "Взыскатель",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "number",
      headerName: "Номер взыскания",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "status",
      headerName: "Статус взыскания",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "reason",
      headerName: "Исполняется на основании",
      width: 250,
      headerAlign: "center",
    },
    {
      field: "created",
      headerName: "Дата установки обременения",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => formatDate(params.value, true),
    },
    {
      field: "recoveryDate",
      headerName: "Дата обращения взыскания",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => formatDate(params.value, true),
    },
    {
      field: "files",
      headerName: "Файлы ограничения",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "comment",
      headerName: "Комментарий",
      width: 200,
      headerAlign: "center",
    },
  ];

  if (auth.blockedPages[route]) {
    return (
      <DFABox>
        <DFAAlert type="error" title={errorMessage} />
      </DFABox>
    );
  }

  return (
    <Grid container position="relative">
      <FinesSearchForm
        page={opts?.page}
        count={opts?.count}
        onSearch={handleSearch}
        defaultOpts={defaultOpts}
      />
      <h2>Обременения</h2>
      <Grid item xs={12} mb={5}>
        <DFATable rows={burdens} columns={burdensColumns} loadingCfg={{ skeletons: true }} />
      </Grid>
      <h2>Взыскания</h2>
      <Grid item xs={12} mb={5}>
        <DFATable rows={fines} columns={finesColumns} loadingCfg={{ skeletons: true }} />
      </Grid>
    </Grid>
  );
}

export default Fines;
