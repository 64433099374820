import React from "react";
import { File } from "@front-packages/dfa-gql-api";
import DFABox from "../DFABox";
import DFACard from "../DFACard";
import DFAFile from "../DFAFile";

interface IDFALoadedFilesProps {
  filesArray: File[];
}

function DFALoadedFiles({ filesArray }: IDFALoadedFilesProps) {
  return (
    <DFACard title="Загруженные файлы">
      <DFABox
        mt={2}
        sx={{
          display: "grid",
          gap: "20px",
          "grid-template-columns": "repeat(6, 1fr)",
        }}
      >
        {filesArray?.map((item) => (
          <DFAFile name={item?.name} url={item?.url} key={item?.id} />
        ))}
      </DFABox>
    </DFACard>
  );
}

export default DFALoadedFiles;
