/*
 Developed by ST soft team
*/

import React from "react";
import { InputLabel, Checkbox } from "@mui/material";
import { CheckboxProps } from "@mui/material/Checkbox/Checkbox";
import { CheckBoxIcon, CheckedIcon } from "./CheckBoxRoot";
import DFABox from "../DFABox";

interface IDFACheckBoxProps extends CheckboxProps {
  label?: string;
  maxWidth?: string;
  id?: string | null;
}

function DFACheckBox({ label, maxWidth, id, ...props }: IDFACheckBoxProps) {
  return (
    <DFABox display="flex" alignItems="center" justifyContent="space-between" sx={{ maxWidth }}>
      <InputLabel sx={{ fontWeight: 600, cursor: "pointer", userSelect: "none" }}>
        <Checkbox
          sx={{
            "&:hover": { backgroundColor: "transparent" },
          }}
          disableRipple
          color="primary"
          checkedIcon={<CheckedIcon />}
          icon={<CheckBoxIcon />}
          id={id || label || null}
          {...props}
        />
        {label && label}
      </InputLabel>
    </DFABox>
  );
}

export default DFACheckBox;
