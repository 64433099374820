/* eslint-disable */
import {
  AclStatus,
  Role,
  Blocking,
  aclStatusFromJSON,
  aclStatusToJSON,
  roleFromJSON,
  roleToJSON,
} from "../acl/acl";
import { Reader, Writer } from "protobufjs/minimal";

export const protobufPackage = "rr.bcpcore.acl";

export interface MsgInit {
  creator: string;
  customerAddr: string;
  customerId: string;
}

export interface MsgInitResponse {}

export interface MsgSetQualStatus {
  creator: string;
  customerAddr: string;
  qualStatus: boolean;
}

export interface MsgSetQualStatusResponse {}

export interface MsgSetStatus {
  creator: string;
  customerAddr: string;
  aclStatus: AclStatus;
}

export interface MsgSetStatusResponse {}

export interface MsgSetBlock {
  creator: string;
  customerAddr: string;
  block: Blocking | undefined;
}

export interface MsgSetBlockResponse {}

export interface MsgSetRole {
  creator: string;
  customerAddr: string;
  role: Role;
}

export interface MsgSetRoleResponse {}

const baseMsgInit: object = { creator: "", customerAddr: "", customerId: "" };

export const MsgInitAcl = {
  encode(message: MsgInit, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    if (message.customerId !== "") {
      writer.uint32(26).string(message.customerId);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgInit {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgInit } as MsgInit;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        case 3:
          message.customerId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgInit {
    const message = { ...baseMsgInit } as MsgInit;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    if (object.customerId !== undefined && object.customerId !== null) {
      message.customerId = String(object.customerId);
    } else {
      message.customerId = "";
    }
    return message;
  },

  toJSON(message: MsgInit): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    message.customerId !== undefined && (obj.customerId = message.customerId);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgInit>): MsgInit {
    const message = { ...baseMsgInit } as MsgInit;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    if (object.customerId !== undefined && object.customerId !== null) {
      message.customerId = object.customerId;
    } else {
      message.customerId = "";
    }
    return message;
  },
};

const baseMsgInitResponse: object = {};

export const MsgInitResponse = {
  encode(_: MsgInitResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgInitResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgInitResponse } as MsgInitResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgInitResponse {
    const message = { ...baseMsgInitResponse } as MsgInitResponse;
    return message;
  },

  toJSON(_: MsgInitResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgInitResponse>): MsgInitResponse {
    const message = { ...baseMsgInitResponse } as MsgInitResponse;
    return message;
  },
};

const baseMsgSetQualStatus: object = {
  creator: "",
  customerAddr: "",
  qualStatus: false,
};

export const MsgSetQualStatus = {
  encode(message: MsgSetQualStatus, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    if (message.qualStatus === true) {
      writer.uint32(24).bool(message.qualStatus);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetQualStatus {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetQualStatus } as MsgSetQualStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        case 3:
          message.qualStatus = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgSetQualStatus {
    const message = { ...baseMsgSetQualStatus } as MsgSetQualStatus;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    if (object.qualStatus !== undefined && object.qualStatus !== null) {
      message.qualStatus = Boolean(object.qualStatus);
    } else {
      message.qualStatus = false;
    }
    return message;
  },

  toJSON(message: MsgSetQualStatus): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    message.qualStatus !== undefined && (obj.qualStatus = message.qualStatus);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgSetQualStatus>): MsgSetQualStatus {
    const message = { ...baseMsgSetQualStatus } as MsgSetQualStatus;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    if (object.qualStatus !== undefined && object.qualStatus !== null) {
      message.qualStatus = object.qualStatus;
    } else {
      message.qualStatus = false;
    }
    return message;
  },
};

const baseMsgSetQualStatusResponse: object = {};

export const MsgSetQualStatusResponse = {
  encode(
    _: MsgSetQualStatusResponse,
    writer: Writer = Writer.create()
  ): Writer {
    return writer;
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): MsgSetQualStatusResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseMsgSetQualStatusResponse,
    } as MsgSetQualStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgSetQualStatusResponse {
    const message = {
      ...baseMsgSetQualStatusResponse,
    } as MsgSetQualStatusResponse;
    return message;
  },

  toJSON(_: MsgSetQualStatusResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<MsgSetQualStatusResponse>
  ): MsgSetQualStatusResponse {
    const message = {
      ...baseMsgSetQualStatusResponse,
    } as MsgSetQualStatusResponse;
    return message;
  },
};

const baseMsgSetStatus: object = {
  creator: "",
  customerAddr: "",
  aclStatus: 0,
};

export const MsgSetStatus = {
  encode(message: MsgSetStatus, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    if (message.aclStatus !== 0) {
      writer.uint32(24).int32(message.aclStatus);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetStatus {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetStatus } as MsgSetStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        case 3:
          message.aclStatus = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgSetStatus {
    const message = { ...baseMsgSetStatus } as MsgSetStatus;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    if (object.aclStatus !== undefined && object.aclStatus !== null) {
      message.aclStatus = aclStatusFromJSON(object.aclStatus);
    } else {
      message.aclStatus = 0;
    }
    return message;
  },

  toJSON(message: MsgSetStatus): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    message.aclStatus !== undefined &&
      (obj.aclStatus = aclStatusToJSON(message.aclStatus));
    return obj;
  },

  fromPartial(object: DeepPartial<MsgSetStatus>): MsgSetStatus {
    const message = { ...baseMsgSetStatus } as MsgSetStatus;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    if (object.aclStatus !== undefined && object.aclStatus !== null) {
      message.aclStatus = object.aclStatus;
    } else {
      message.aclStatus = 0;
    }
    return message;
  },
};

const baseMsgSetStatusResponse: object = {};

export const MsgSetStatusResponse = {
  encode(_: MsgSetStatusResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetStatusResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetStatusResponse } as MsgSetStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgSetStatusResponse {
    const message = { ...baseMsgSetStatusResponse } as MsgSetStatusResponse;
    return message;
  },

  toJSON(_: MsgSetStatusResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgSetStatusResponse>): MsgSetStatusResponse {
    const message = { ...baseMsgSetStatusResponse } as MsgSetStatusResponse;
    return message;
  },
};

const baseMsgSetBlock: object = { creator: "", customerAddr: "" };

export const MsgSetBlock = {
  encode(message: MsgSetBlock, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    if (message.block !== undefined) {
      Blocking.encode(message.block, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetBlock {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetBlock } as MsgSetBlock;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        case 3:
          message.block = Blocking.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgSetBlock {
    const message = { ...baseMsgSetBlock } as MsgSetBlock;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    if (object.block !== undefined && object.block !== null) {
      message.block = Blocking.fromJSON(object.block);
    } else {
      message.block = undefined;
    }
    return message;
  },

  toJSON(message: MsgSetBlock): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    message.block !== undefined &&
      (obj.block = message.block ? Blocking.toJSON(message.block) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgSetBlock>): MsgSetBlock {
    const message = { ...baseMsgSetBlock } as MsgSetBlock;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    if (object.block !== undefined && object.block !== null) {
      message.block = Blocking.fromPartial(object.block);
    } else {
      message.block = undefined;
    }
    return message;
  },
};

const baseMsgSetBlockResponse: object = {};

export const MsgSetBlockResponse = {
  encode(_: MsgSetBlockResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetBlockResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetBlockResponse } as MsgSetBlockResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgSetBlockResponse {
    const message = { ...baseMsgSetBlockResponse } as MsgSetBlockResponse;
    return message;
  },

  toJSON(_: MsgSetBlockResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgSetBlockResponse>): MsgSetBlockResponse {
    const message = { ...baseMsgSetBlockResponse } as MsgSetBlockResponse;
    return message;
  },
};

const baseMsgSetRole: object = { creator: "", customerAddr: "", role: 0 };

export const MsgSetRole = {
  encode(message: MsgSetRole, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.customerAddr !== "") {
      writer.uint32(18).string(message.customerAddr);
    }
    if (message.role !== 0) {
      writer.uint32(24).int32(message.role);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetRole {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetRole } as MsgSetRole;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.customerAddr = reader.string();
          break;
        case 3:
          message.role = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgSetRole {
    const message = { ...baseMsgSetRole } as MsgSetRole;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = String(object.customerAddr);
    } else {
      message.customerAddr = "";
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = roleFromJSON(object.role);
    } else {
      message.role = 0;
    }
    return message;
  },

  toJSON(message: MsgSetRole): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.customerAddr !== undefined &&
      (obj.customerAddr = message.customerAddr);
    message.role !== undefined && (obj.role = roleToJSON(message.role));
    return obj;
  },

  fromPartial(object: DeepPartial<MsgSetRole>): MsgSetRole {
    const message = { ...baseMsgSetRole } as MsgSetRole;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.customerAddr !== undefined && object.customerAddr !== null) {
      message.customerAddr = object.customerAddr;
    } else {
      message.customerAddr = "";
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = object.role;
    } else {
      message.role = 0;
    }
    return message;
  },
};

const baseMsgSetRoleResponse: object = {};

export const MsgSetRoleResponse = {
  encode(_: MsgSetRoleResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetRoleResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetRoleResponse } as MsgSetRoleResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgSetRoleResponse {
    const message = { ...baseMsgSetRoleResponse } as MsgSetRoleResponse;
    return message;
  },

  toJSON(_: MsgSetRoleResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgSetRoleResponse>): MsgSetRoleResponse {
    const message = { ...baseMsgSetRoleResponse } as MsgSetRoleResponse;
    return message;
  },
};

/** Msg defines the Msg service. */
export interface Msg {
  Init(request: MsgInit): Promise<MsgInitResponse>;
  SetRole(request: MsgSetRole): Promise<MsgSetRoleResponse>;
  SetQualStatus(request: MsgSetQualStatus): Promise<MsgSetQualStatusResponse>;
  SetStatus(request: MsgSetStatus): Promise<MsgSetStatusResponse>;
  /** this line is used by starport scaffolding # proto/tx/rpc */
  SetBlock(request: MsgSetBlock): Promise<MsgSetBlockResponse>;
}

export class MsgClientImpl implements Msg {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }
  Init(request: MsgInit): Promise<MsgInitResponse> {
    const data = MsgInitAcl.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.acl.Msg", "Init", data);
    return promise.then((data) => MsgInitResponse.decode(new Reader(data)));
  }

  SetRole(request: MsgSetRole): Promise<MsgSetRoleResponse> {
    const data = MsgSetRole.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.acl.Msg", "SetRole", data);
    return promise.then((data) => MsgSetRoleResponse.decode(new Reader(data)));
  }

  SetQualStatus(request: MsgSetQualStatus): Promise<MsgSetQualStatusResponse> {
    const data = MsgSetQualStatus.encode(request).finish();
    const promise = this.rpc.request(
      "rr.bcpcore.acl.Msg",
      "SetQualStatus",
      data
    );
    return promise.then((data) =>
      MsgSetQualStatusResponse.decode(new Reader(data))
    );
  }

  SetStatus(request: MsgSetStatus): Promise<MsgSetStatusResponse> {
    const data = MsgSetStatus.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.acl.Msg", "SetStatus", data);
    return promise.then((data) =>
      MsgSetStatusResponse.decode(new Reader(data))
    );
  }

  SetBlock(request: MsgSetBlock): Promise<MsgSetBlockResponse> {
    const data = MsgSetBlock.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.acl.Msg", "SetBlock", data);
    return promise.then((data) => MsgSetBlockResponse.decode(new Reader(data)));
  }
}

interface Rpc {
  request(
    service: string,
    method: string,
    data: Uint8Array
  ): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
