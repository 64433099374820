/*
DFA Platform Admin
***
Developed by ST soft team
*/

import React, { useState, useEffect } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Icon,
  Box,
  Theme,
  Button,
  Tooltip,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { CookieNamesEnum } from "@front-packages/dfa-constants";
import { DFAChip, DFANotification } from "Theme";
import { getCookie } from "@front-packages/dfa-helpers";
import { useAuth } from "hooks/authentication";
import { setTransparentNavbar, setMiniSidenav } from "reducers/layout/action";
import { useTypedSelector } from "reducers";
import { RouteNamesEnum } from "consts";
import { navbarContainer, navbarRow, navbarIconButton, navbarMobileMenu } from "./styles";
import Breadcrumbs from "./Breadcrumbs";
import getAlertData from "../../pages/authentication/sign-in/getAlertData";

interface IDashboardNavbarProps {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar({ absolute, light, isMini }: IDashboardNavbarProps) {
  const localstorage = window.localStorage;
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useAuth();
  const {
    adaptiveThemeStyle: { miniSidenav, transparentNavbar, fixedNavbar, isDarkMode },
    auth: { isAuth },
  } = useTypedSelector((state) => state, shallowEqual);
  const [navbarType, setNavbarType] = useState<"sticky" | "static">();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { header, type } = getAlertData(process.env.REACT_APP_ENVIRONMENT);

  const route = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "");

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      dispatch(setTransparentNavbar((fixedNavbar && window.scrollY === 0) || !fixedNavbar));
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => dispatch(setMiniSidenav(!miniSidenav));
  // const handleConfiguratorOpen = () => dispatch(setOpenConfigurator(!openConfigurator));
  // TODO temporary const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const toggleLanguage = () => {
    if (i18n.language === "en") {
      document.documentElement.setAttribute("lang", "ru");
      localstorage.setItem("i18nextLng", "ru");
      return i18n.changeLanguage("ru");
    }
    document.documentElement.setAttribute("lang", "en");
    localstorage.setItem("i18nextLng", "en");
    return i18n.changeLanguage("en");
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <DFANotification icon="email" title="Check new messages" />
      <DFANotification icon="podcasts" title="Manage Podcast sessions" />
      <DFANotification icon="shopping_cart" title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { common, text }, other }: Theme) => ({
    color: () => {
      let colorValue = light || isDarkMode ? common.white : text.primary;

      if (transparentNavbar && !light) {
        colorValue = isDarkMode ? other.helpers.rgba(text.primary, 0.6) : text.primary;
      }

      return colorValue;
    },
  });

  // TODO temporary const handleDarkMode = () => dispatch(setDarkMode(!isDarkMode));
  const redirectToPermission = () => navigate(RouteNamesEnum.permissions);

  return (
    <AppBar position={absolute ? "absolute" : navbarType} color="inherit">
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <Box color="inherit" mb={0} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs route={route} light={light} />
        </Box>
        {isMini ? null : (
          <Box sx={(theme) => navbarRow(theme, { isMini })}>
            {isAuth && (
              <Box mr={1}>
                <DFAChip type={type} label={header} icon="none" variant="filled" size="small" />
              </Box>
            )}
            <Box color={light ? "white" : "inherit"}>
              {isAuth && getCookie(CookieNamesEnum.user) && (
                <Button
                  startIcon={<SettingsIcon />}
                  variant="text"
                  color="success"
                  sx={{ color: "#53AC57", mr: "10px" }}
                  onClick={redirectToPermission}
                >
                  {getCookie(CookieNamesEnum.user)}
                </Button>
              )}
              {isAuth && (
                <Tooltip title={t("general.tooltips.openMenu")}>
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarMobileMenu}
                    onClick={handleMiniSidenav}
                  >
                    <Icon sx={iconsStyle} fontSize="medium">
                      {miniSidenav ? "menu_open" : "menu"}
                    </Icon>
                  </IconButton>
                </Tooltip>
              )}
              {/* TODO temporary {isAuth && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleOpenMenu}
                >
                  <Icon sx={iconsStyle}>notifications</Icon>
                </IconButton>
              )} */}
              {/* TODO temporary <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleDarkMode}
              >
                <Icon sx={iconsStyle}>brightness_6</Icon>
              </IconButton> */}
              <Tooltip title={t("general.tooltips.changeLang")}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={toggleLanguage}
                >
                  <Icon sx={iconsStyle}>translate</Icon>
                </IconButton>
              </Tooltip>

              {/* TODO temporary {isAuth && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <Icon sx={iconsStyle}>menu</Icon>
                </IconButton>
              )} */}
              {isAuth && (
                <Tooltip title={t("general.tooltips.logout")}>
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    id="logout"
                    sx={navbarIconButton}
                    onClick={auth.logout}
                  >
                    <Icon sx={iconsStyle}>logout</Icon>
                  </IconButton>
                </Tooltip>
              )}
              {isAuth && renderMenu()}
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default DashboardNavbar;
