import { palette } from "../AdminTheme/colors";
import ProximaNovaLight from "../assets/fonts/ProximaNova-Light.ttf";

const { info } = palette;

const globals = {
  "@font-face": {
    "font-family": "ProximaNova",
    "font-style": "normal",
    "font-display": "swap",
    "font-weight": 300,
    src: `local('ProximaNova'), local('ProximaNova-Light'), url(${ProximaNovaLight}) format('ttf')`,
    unicodeRange:
      "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
  },
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${info[100]} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${"main" in info ? info.main : info[50]} !important`,
  },
  ".MuiCalendarPicker-root .MuiSvgIcon-root": {
    color: "white",
  },
};

export default globals;
