import React from "react";
import { Icon, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

function AdminTip() {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("layouts.deactivation.createdByAdminTip")}>
      <Icon fontSize="small" style={{ marginRight: "5px" }}>
        admin_panel_settings
      </Icon>
    </Tooltip>
  );
}

export default AdminTip;
