import React, { useState } from "react";
import { Grid } from "@mui/material";
import { DFAButton, DFADialog, DFASnackbar } from "Theme";
import { TransactionsAPI, OnboardingStatusGql } from "@front-packages/dfa-gql-api";
import { delay } from "@front-packages/dfa-helpers";
import { useErrors } from "hooks/errors";

function RolesPage() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(null);
  const { setError } = useErrors();

  const checkOnboardingStatus = async (processID: string): Promise<boolean> => {
    let isCompleted = false;
    const { response, error } = await TransactionsAPI.GetOnboardingProcess({ processID });
    if (error) {
      setError(error);
      return false;
    }
    if (response.status === OnboardingStatusGql.InProgress) {
      await delay(10000);
      isCompleted = await checkOnboardingStatus(processID);
    } else {
      isCompleted = response.status === OnboardingStatusGql.Completed;
      if (response.status === OnboardingStatusGql.Completed) {
        setIsSuccess(true);
      }
      if (response.status === OnboardingStatusGql.Failed) {
        setIsSuccess(false);
      }
    }
    return isCompleted;
  };
  const onConfirm = async () => {
    setInProgress(true);
    const { response, error } = await TransactionsAPI.SmartContractsOnboarding();
    if (response) {
      await checkOnboardingStatus(response);
    }
    if (error) {
      console.log(error);
    }
    setInProgress(false);
    setIsOpen(false);
  };
  return (
    <>
      <Grid container marginTop={3} marginBottom={3} gap={3}>
        <DFAButton disabled={inProgress} color="success" onClick={() => setIsOpen(true)}>
          Пройти онбординг смарт-контрактов
        </DFAButton>
      </Grid>
      <DFADialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Прохождения онбординга для смарт-контрактов"
        type="success"
        onConfirm={onConfirm}
        loading={inProgress}
        disabled={inProgress}
        dialogText={
          !inProgress
            ? "Для прохождения онбординга для смарт-контрактов нажмите на кнопку Подтвердить и дождитесь выполнения операции"
            : "Идет прохождение онбординга для смарт-контрактов, пожалуйста подождите..."
        }
      />
      <DFASnackbar
        color="success"
        icon="success"
        title="Успешная операция"
        snackContent="Онбординг смарт-контрактов прошел успешно!"
        dateTime="Только что"
        open={isSuccess === true}
        onClose={() => setIsSuccess(null)}
        close={() => setIsSuccess(null)}
      />
      <DFASnackbar
        color="error"
        icon="error"
        title="Неудачная операция"
        snackContent="Онбординг смарт-контрактов закончился с ошибкой. Пожалуйста повторите операцию позднее"
        dateTime="Только что"
        open={isSuccess === false}
        onClose={() => setIsSuccess(null)}
        close={() => setIsSuccess(null)}
      />
    </>
  );
}

export default RolesPage;
