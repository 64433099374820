export const SET_IS_AUTH = "@@auth/set_is_auth";
export const SET_BLOCKED_PAGES = "@@auth/set_blocked_pages";
export const RESET_BLOCKED_PAGES = "@@auth/reset_blocked_pages";

export const setIsAuth = (payload: boolean) => ({ type: SET_IS_AUTH, payload });

export type BlockedPages = {
  transactions?: boolean;
  blockchain?: boolean;
  customers?: boolean;
  admins?: boolean;
};

export const setBlockedPages = (payload: BlockedPages) => ({ type: SET_BLOCKED_PAGES, payload });
export const resetBlockedPages = () => ({ type: RESET_BLOCKED_PAGES });
