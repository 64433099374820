/*
DFA Platform Admin
***
Developed by ST soft team
*/

import { Theme } from "@mui/material/styles";
import "./style.css";

interface IOwnerState {
  active?: boolean;
  transparentSidenav?: boolean;
  whiteSidenav?: boolean;
  sidenavColor?: string;
  miniSidenav?: boolean;
}

function collapseItem(theme: Theme, ownerState: IOwnerState) {
  const { palette, transitions, breakpoints, other } = theme;
  const { shadows, borders, helpers, colors } = other;
  const { active, transparentSidenav, whiteSidenav, sidenavColor } = ownerState;

  const {
    common: { white },
    info,
    grey,
  } = palette;
  const { transparent, gradients } = colors;
  const { md } = shadows;
  const { borderRadius } = borders;
  const { rgba, linearGradient } = helpers;

  return {
    background: active
      ? linearGradient(gradients[sidenavColor].light, gradients[sidenavColor].dark)
      : transparent,
    color: (transparentSidenav && !active) || (whiteSidenav && !active) ? info[50] : white,
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "8px 10px",
    margin: "1.5px 16px",
    borderRadius: borderRadius.md,
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: active && !whiteSidenav && !transparentSidenav ? md : "none",
    [breakpoints.up("xl")]: {
      transition: transitions.create(["box-shadow", "background-color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },

    "&:hover, &:focus": {
      backgroundColor: () => {
        let backgroundValue;

        if (!active) {
          backgroundValue = transparentSidenav
            ? grey[300]
            : rgba(whiteSidenav ? grey[400] : white, 0.2);
        }

        return backgroundValue;
      },
    },
  };
}

function collapseIconBox(theme: Theme) {
  const { palette, transitions, other } = theme;
  const { borders } = other;

  const {
    common: { white },
  } = palette;
  const { borderRadius } = borders;

  return {
    minWidth: "32px",
    minHeight: "32px",
    color: `${white} !important`,
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    "& svg, svg g": {
      color: `${white} !important`,
    },
  };
}

const collapseIcon = (
  {
    other: {
      colors: { gradients },
    },
    palette: {
      common: { white },
    },
  }: Theme,
  { active }: IOwnerState
) => {
  return {
    color: `${active ? white : gradients.dark.dark} !important`,
    "& span": {
      color: `${active ? white : gradients.dark.dark} !important`,
    },
  };
};

function collapseText(theme: Theme, ownerState: IOwnerState) {
  const { typography, transitions, breakpoints, other, palette } = theme;
  const { miniSidenav, transparentSidenav, active } = ownerState;

  const {
    common: { white },
  } = palette;
  const { fontWeightRegular, fontWeightLight } = typography;
  const { fontSizeSM } = other.baseTypographyProp;

  return {
    marginLeft: "10px",

    [breakpoints.up("xl")]: {
      opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "10px",
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      fontWeight: active ? fontWeightRegular : fontWeightLight,
      fontSize: fontSizeSM,
      whiteSpace: "normal",
      color: white,
    },
  };
}

export { collapseItem, collapseIconBox, collapseIcon, collapseText };
