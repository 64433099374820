/*
DFA Platform Admin
***
Developed by ST soft team
*/

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled, Theme } from "@mui/material/styles";

interface IOwnerState {
  transparentSidenav?: boolean;
  whiteSidenav?: boolean;
  miniSidenav?: boolean;
  isDarkMode?: boolean;
}

interface IStyled {
  theme?: Theme;
  ownerState?: IOwnerState;
}

export default styled(Drawer)<IStyled>(({ theme, ownerState }) => {
  const { palette, transitions, breakpoints, other } = theme;
  const { colors, shadows } = other;
  const { transparentSidenav, whiteSidenav, miniSidenav } = ownerState;

  const sidebarWidth = 250;
  const {
    common: { white },
    info,
  } = palette;
  const { transparent } = colors;
  const { xxl } = shadows;

  let backgroundValue = info[200];

  if (transparentSidenav) {
    backgroundValue = transparent;
  } else if (whiteSidenav) {
    backgroundValue = white;
  }

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    background: !whiteSidenav ? backgroundValue : white,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: backgroundValue,
    transform: "translateX(-320px)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: "96px",
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
