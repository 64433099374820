import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { DFAAlert, DFABox, DFATable } from "Theme";
import {
  GetTokensOpts,
  UserClientType,
  SortOptionGql,
  TokenStatusGql,
} from "@front-packages/dfa-gql-api";
import { useLocation } from "react-router-dom";
import { formatDate } from "@front-packages/dfa-helpers";
import { useErrors } from "hooks/errors";
import { useTypedSelector } from "reducers";
import AccessRecoverySearchForm from "./components/AccessRecoverySearchForm";

type AccessRecoveryOrder = {
  id: number;
  issuer: string;
  clientInn: string;
  representativeInn: string;
  reason: string;
  created: Date;
  status: string;
};

const orders: AccessRecoveryOrder[] = [
  {
    id: 1,
    clientInn: "3322110033",
    representativeInn: "3322110033",
    created: new Date(),
    issuer: "TEST",
    reason: "Телефон",
    status: "Новая",
  },
  {
    id: 2,
    clientInn: "3322110033",
    representativeInn: "3322110033",
    created: new Date(),
    issuer: "ТЕСТ",
    reason: "Ключ",
    status: "Новая",
  },
];

function AccessRecovery() {
  const route = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "")[0];
  const { t } = useTranslation();
  const { errorMessage } = useErrors();
  const defaultOpts = {
    page: 0,
    count: 10,
    clientType: UserClientType.Admin,
    sortOption: SortOptionGql.StartDateEarly,
    tokenStatuses: [TokenStatusGql.InMarket],
  };
  const [opts] = useState<GetTokensOpts>(defaultOpts);
  const { auth } = useTypedSelector((store) => store);

  const handleSearch = async (params) => {
    console.log("handleSearch", params);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("layouts.access-recovery.table.columns.number"),
      width: 50,
      headerAlign: "center",
    },
    {
      field: "issuer",
      headerName: t("layouts.access-recovery.table.columns.issuer"),
      headerAlign: "center",
      width: 250,
    },
    {
      field: "clientInn",
      headerName: t("layouts.access-recovery.table.columns.clientInn"),
      headerAlign: "center",
      width: 150,
    },
    {
      field: "representativeInn",
      headerName: t("layouts.access-recovery.table.columns.representativeInn"),
      width: 200,
      headerAlign: "center",
    },
    {
      field: "reason",
      headerName: t("layouts.access-recovery.table.columns.reason"),
      width: 200,
      headerAlign: "center",
    },
    {
      field: "created",
      headerName: t("layouts.access-recovery.table.columns.created"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => formatDate(params.value, true),
    },
    {
      field: "status",
      headerName: t("layouts.access-recovery.table.columns.status"),
      width: 200,
      headerAlign: "center",
    },
  ];

  if (auth.blockedPages[route]) {
    return (
      <DFABox>
        <DFAAlert type="error" title={errorMessage} />
      </DFABox>
    );
  }

  return (
    <Grid container position="relative">
      <AccessRecoverySearchForm
        page={opts?.page}
        count={opts?.count}
        onSearch={handleSearch}
        defaultOpts={defaultOpts}
      />
      <Grid item xs={12} mt={5}>
        <DFATable rows={orders} columns={columns} loadingCfg={{ skeletons: true }} />
      </Grid>
    </Grid>
  );
}

export default AccessRecovery;
