import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { palette } from "../../colors";

interface MuiFormLabel {
  defaultProps?: ComponentsProps["MuiFormLabel"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiFormLabel"];
  variants?: ComponentsVariants["MuiFormLabel"];
}

const { text } = palette;

const formLabel: MuiFormLabel = {
  styleOverrides: {
    root: {
      color: text.primary,
    },
  },
};

export default formLabel;
