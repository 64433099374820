import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { linearGradient } from "../../../helpers";
import { borders } from "../../../base";
import { colors, palette } from "../../colors";

interface MuiCheckbox {
  defaultProps?: ComponentsProps["MuiCheckbox"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiCheckbox"];
  variants?: ComponentsVariants["MuiCheckbox"];
}

const { borderWidth, borderColor } = borders;
const { success } = palette;
const { transparent } = colors;

const checkbox: MuiCheckbox = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: "20px",
        height: "20px",
        color: transparent,
        border: `${borderWidth[1]} solid ${borderColor}`,
        borderRadius: "5.6px",
      },

      "&:hover": {
        backgroundColor: transparent,
      },

      "&.Mui-focusVisible": {
        border: `${borderWidth[2]} solid ${
          "main" in success ? success.main : "inherit"
        } !important`,
      },
    },

    colorPrimary: {
      color: borderColor,

      "&.Mui-checked": {
        color: "main" in success ? success.main : "inherit",

        "& .MuiSvgIcon-root": {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
            "main" in success ? success.main : "inherit",
            "main" in success ? success.main : "inherit"
          )}`,
          borderColor: "main" in success ? success.main : "inherit",
        },
      },
    },

    colorSecondary: {
      color: borderColor,

      "& .MuiSvgIcon-root": {
        color: "main" in success ? success.main : "inherit",
        "&.Mui-checked": {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
            "main" in success ? success.main : "inherit",
            "main" in success ? success.main : "inherit"
          )}`,
          borderColor: "main" in success ? success.main : "inherit",
        },
      },
    },
  },
};

export default checkbox;
