import { legacy_createStore as createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import authMiddleware from "./reducers/auth/authMiddleware";
import errFetchMiddleware from "./reducers/errFetch/middleware";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(authMiddleware, errFetchMiddleware),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  )
);

export default store;
