/*
 Developed by ST soft team
 */

import React from "react";
import { Alert, AlertTitle } from "@mui/lab";

interface IDFAAlert {
  type?: "info" | "error" | "success" | "warning";
  title?: string;
  children?: React.ReactNode;
}

function DFAAlert({ title, type = "success", children }: IDFAAlert) {
  return (
    <Alert
      severity={type}
      variant="filled"
      sx={({ palette }) => ({
        background: `linear-gradient(90deg, ${palette[type].main}, ${palette[type].light})`,
        color: palette.common.white,
        borderRadius: 3,
      })}
    >
      {title && (
        <AlertTitle sx={({ palette }) => ({ color: palette.common.white })}>{title}</AlertTitle>
      )}
      {children && children}
    </Alert>
  );
}

export default DFAAlert;
