import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ErrorsEnum } from "@front-packages/dfa-constants";
import { useTranslation } from "react-i18next";
import { setBlockedPages, setIsAuth } from "reducers/auth/action";
import { setErrGlobal } from "reducers/errFetch/actions";
import { IUseErrorsResult } from "./types";

const useErrors = (): IUseErrorsResult => {
  const dispatch = useDispatch();
  const page = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "")[0];
  const [errorMessage, setMessage] = useState<string>(null);
  const [error, setError] = useState<any>("");
  const { t } = useTranslation();

  useEffect(() => {
    const err = error.split(":")[0];
    switch (err) {
      case "Failed to fetch": {
        dispatch(setErrGlobal("Нет связи с сервером. Проверьте доверенные сертификаты"));
        setMessage("Нет связи с сервером. Проверьте доверенные сертификаты");
        break;
      }
      case "no data": {
        setMessage(t("general.errors.noData"));
        break;
      }
      case "scany": {
        setMessage(t("general.errors.scany"));
        break;
      }
      case "incompleteEnter": {
        setMessage(t("general.errors.incompleteEnter"));
        break;
      }
      case ErrorsEnum.FailedToExecuteOperation: {
        dispatch(setErrGlobal(`${t("general.errors.scany")}`));
        break;
      }
      case ErrorsEnum.InvalidINN: {
        setMessage(t("general.errors.invalidINN"));
        break;
      }
      case ErrorsEnum.NoResultByData: {
        setMessage(t("general.errors.noResultByData"));
        break;
      }
      case ErrorsEnum.InvalidAccessToken: {
        setMessage(t("general.errors.invalidAccessToken"));
        break;
      }
      case ErrorsEnum.InvalidBIK: {
        setMessage(t("general.errors.invalidBIK"));
        break;
      }
      case ErrorsEnum.InvalidEmail: {
        setMessage(t("general.errors.invalidEmail"));
        break;
      }
      case ErrorsEnum.InvalidKPP: {
        setMessage(t("general.errors.invalidKPP"));
        break;
      }
      case ErrorsEnum.InvalidCountryCode: {
        setMessage(t("general.errors.invalidCountryCode"));
        break;
      }
      case ErrorsEnum.InvalidFormatDate: {
        setMessage(t("general.errors.invalidFormatDate"));
        break;
      }
      case ErrorsEnum.InvalidOGRN: {
        setMessage(t("general.errors.invalidOGRN"));
        break;
      }
      case ErrorsEnum.InvalidOKPO: {
        setMessage(t("general.errors.invalidOKPO"));
        break;
      }
      case ErrorsEnum.InvalidPhoneNumber: {
        setMessage(t("general.errors.invalidPhoneNumber"));
        break;
      }
      case ErrorsEnum.InvalidSNILS: {
        setMessage(t("general.errors.invalidSNILS"));
        break;
      }
      case ErrorsEnum.InvalidVerificationCodeOrPhoneDoesntBelongToUser: {
        setMessage(t("general.errors.invalidVerificationCodeOrPhoneDoesntBelongToUser"));
        break;
      }
      case ErrorsEnum.CompanyNotFound: {
        setMessage(t("general.errors.companyNotFound"));
        break;
      }
      case ErrorsEnum.CompanyExists: {
        setMessage(t("general.errors.companyExists"));
        break;
      }
      case ErrorsEnum.CompanyWithThisINNAlreadyExists: {
        setMessage(t("general.errors.companyWithThisINNAlreadyExists"));
        break;
      }
      case ErrorsEnum.IncorrectPhoneNumberOrPassword: {
        setMessage(t("general.errors.incorrectPhoneNumberOrPassword"));
        break;
      }
      case ErrorsEnum.UserWithThisPhoneNumberAlreadyExists: {
        setMessage(t("general.errors.userWithThisPhoneNumberAlreadyExists"));
        break;
      }
      case ErrorsEnum.YouCannotSetThisStatus: {
        setMessage(t("general.errors.youCannotSetThisStatus"));
        break;
      }
      case ErrorsEnum.UserAlreadyBlockedByDate: {
        setMessage(t("general.errors.userAlreadyBlockedByDate"));
        break;
      }
      case ErrorsEnum.NoPermission: {
        setMessage(t("general.errors.noPermission"));
        dispatch(setBlockedPages({ [page]: true }));
        break;
      }
      case ErrorsEnum.Unauthorized: {
        setMessage(t("general.errors.unauthorized"));
        setTimeout(() => {
          dispatch(setIsAuth(false));
        }, 10000);
        break;
      }
      case ErrorsEnum.RepresentativeWithThisPhoneNumberOrEmailAlreadyExists: {
        setMessage(t("general.errors.representativeWithThisPhoneNumberOrEmailAlreadyExists"));
        break;
      }
      case ErrorsEnum.RepresentativeAlreadyHasTiedCompany: {
        setMessage(t("general.errors.representativeWithThisPhoneNumberOrEmailAlreadyExists"));
        break;
      }
      case ErrorsEnum.RepresentativeStatusIsNotExists: {
        setMessage(t("general.errors.representativeStatusIsNotExists"));
        break;
      }
      case ErrorsEnum.CompanyOgrnHasAlreadyExists: {
        setMessage(t("general.errors.companyOgrnHasAlreadyExists"));
        break;
      }
      default:
        setMessage(null);
    }
  }, [error]);

  return { errorMessage, setError };
};

export default useErrors;
