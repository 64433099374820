import { LOADED, LOADING } from "./actions";

export interface IInitLoaderState {
  loading: boolean;
}

const initState: IInitLoaderState = {
  loading: true,
};

function loaderReducer(state = initState, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };

    case LOADED:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default loaderReducer;
