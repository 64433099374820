import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { Fade } from "@mui/material";
import { borders } from "../../base";
import { typography, palette } from "../colors";

import { baseProperties } from "../colors/typography";

interface MuiTooltip {
  defaultProps?: ComponentsProps["MuiTooltip"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTooltip"];
  variants?: ComponentsVariants["MuiTooltip"];
}
const {
  common: { black, white },
} = palette;
const { fontWeightRegular } = typography;
const { fontSizeSM } = baseProperties;
const { borderRadius } = borders;

const tooltip: MuiTooltip = {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: "200px",
      backgroundColor: black,
      color: white,
      fontSize: fontSizeSM,
      fontWeight: fontWeightRegular,
      textAlign: "center",
      borderRadius: borderRadius.md,
      opacity: 0.7,
      padding: "5px 8px 4px",
    },

    arrow: {
      color: black,
    },
  },
};

export default tooltip;
