/*
 Developed by ST soft team
 */

import Icon from "@mui/material/Icon";
import { styled, Theme } from "@mui/material/styles";

interface IOwnerState {
  color: string;
}

interface IStyled {
  theme?: Theme;
  ownerState?: IOwnerState;
}

export default styled(Icon)<IStyled>(({ theme, ownerState }) => {
  const { other } = theme;
  const { color } = ownerState;
  const { transparent, gradients } = other.colors;
  const { linearGradient } = other.helpers;
  const { fontSizeLG } = other.baseTypographyProp;

  return {
    backgroundImage: linearGradient(gradients[color].light, gradients[color].dark),
    WebkitTextFillColor: transparent,
    WebkitBackgroundClip: "text",
    marginRight: "8px",
    fontSize: fontSizeLG,
    transform: "translateY(-2px)",
  };
});
