import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DFABox, DFAButton, DFASearchBadge } from "Theme";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

interface IAccessRecoverySearchForm {
  onSearch(opts): Promise<void>;
  defaultOpts: Record<string, unknown>;
  page: number;
  count: number;
}

function FinesSearchForm({ onSearch, defaultOpts, page, count }: IAccessRecoverySearchForm) {
  const { t } = useTranslation();
  const [opts, setOpts] = useState(defaultOpts);
  const [badges, setBadges] = useState<Array<string>>([]);
  const [expanded, setExpanded] = useState<"panel1" | false>(false);
  const box = useRef<HTMLDivElement>(null);
  const accordion = useRef<HTMLDivElement>(null);

  const handleSetBadge = () => {
    const newBadges = Object.keys(opts).filter((key) => {
      let isReturn = !!opts[key];
      if (key === "page" || key === "count" || key === "clientType") isReturn = false;
      return isReturn;
    });
    setBadges(newBadges);
  };
  const handleOpen = (panel: "panel1") => (e: any, isExpanded: boolean) => {
    if (
      (!box.current || (box.current && !box.current.contains(e.target))) &&
      accordion.current.contains(e.target)
    )
      setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => setExpanded(false);
  const handleSearch = async () => {
    await onSearch(opts);
    handleSetBadge();
    handleClose();
  };
  const setFilter = (e: ChangeEvent<HTMLInputElement>) =>
    setOpts({ ...opts, ticker: e.target.value || undefined });
  const handleReset = async () => {
    setOpts(defaultOpts);
    setBadges([]);
    await onSearch(defaultOpts);
    handleClose();
  };
  const handleEnter = async (e: any) => {
    if (e.nativeEvent.charCode === 13) await handleSearch();
  };
  const handleDelBadge = async (badge: string) => {
    setOpts({ ...opts, [badge]: undefined });
    setBadges(badges.filter((el) => el !== badge));
    await onSearch({ ...opts, [badge]: undefined });
  };

  useEffect(() => {
    setOpts({ ...opts, count });
  }, [count]);
  useEffect(() => {
    setOpts({ ...opts, page });
  }, [page]);

  const filterField = (
    <TextField
      sx={{ m: 0, mr: 1 }}
      value={opts?.ticker || ""}
      margin="dense"
      id="collapse-name"
      label="Поиск по наименованию и ИНН клиента или ИНН представителя"
      type="text"
      fullWidth
      variant="outlined"
      onInput={setFilter}
    />
  );

  const showReset = !!opts?.ticker || !!opts?.issuer || !!opts?.startDate || !!opts?.completionDate;
  return (
    <DFABox
      sx={{
        borderRadius: "15px",
        position: "absolute",
        top: "-70px",
        minHeight: "70px",
        right: 0,
        zIndex: 11,
        width: "40%",
      }}
      onKeyPress={handleEnter}
    >
      <DFABox
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          width: "100%",
          position: "absolute",
          right: 0,
          bottom: "80px",
        }}
      >
        {badges.map((badge) => (
          <DFASearchBadge key={badge} delBadge={handleDelBadge} badge={badge} />
        ))}
      </DFABox>
      <Accordion
        expanded={expanded === "panel1"}
        sx={{
          borderRadius: "10px !important",
          m: "0 !important",
          boxShadow: ({ shadows }: Theme) => (expanded === "panel1" ? shadows[8] : shadows[1]),
        }}
        onChange={handleOpen("panel1")}
      >
        <AccordionSummary
          ref={accordion}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {expanded !== "panel1" && (
            <DFABox display="flex" ref={box} ml={1} mr={1} width="100%" alignItems="center">
              {filterField}
              <DFAButton
                sx={{ marginLeft: "1rem" }}
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                {t("layouts.searchForm.buttons.search")}
              </DFAButton>
            </DFABox>
          )}
        </AccordionSummary>
        <AccordionDetails id="search-details">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {filterField}
            </Grid>
            <Grid item xs={12}>
              <DFABox display="flex" justifyContent="flex-end">
                {showReset && (
                  <DFAButton
                    variant="text"
                    color="secondary"
                    onClick={handleReset}
                    sx={{ marginRight: "auto" }}
                    id="reset-btn"
                  >
                    {t("layouts.searchForm.buttons.reset")}
                  </DFAButton>
                )}
                <DFAButton
                  sx={{ marginLeft: "1rem" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                >
                  {t("layouts.searchForm.buttons.search")}
                </DFAButton>
                <DFAButton
                  sx={{ marginLeft: "1rem" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  {t("layouts.searchForm.buttons.close")}
                </DFAButton>
              </DFABox>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </DFABox>
  );
}

export default React.memo(FinesSearchForm);
