import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { colors, shadows } from "../../colors";
import { linearGradient } from "../../../helpers";

interface MuiStepper {
  defaultProps?: ComponentsProps["MuiStepper"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiStepper"];
  variants?: ComponentsVariants["MuiStepper"];
}

const { transparent, gradients } = colors;
const { borderRadius } = borders;
const { colored } = shadows;

const stepper: MuiStepper = {
  styleOverrides: {
    root: {
      background: linearGradient(gradients.info.light, gradients.info.dark),
      padding: "24px 0 16px",
      borderRadius: borderRadius.lg,
      boxShadow: colored.info,

      "&.MuiPaper-root": {
        backgroundColor: transparent,
      },
    },
  },
};

export default stepper;
