import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { palette, colors } from "../../colors";
import { baseProperties } from "../../colors/typography";

const { inputBorderColor } = colors;
const { info } = palette;
const { fontSizeSM } = baseProperties;
const { borderWidth } = borders;

interface MuiInput {
  defaultProps?: ComponentsProps["MuiInput"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiInput"];
  variants?: ComponentsVariants["MuiInput"];
}

const input: MuiInput = {
  styleOverrides: {
    root: {
      fontSize: fontSizeSM,
      color: info[100],

      "&:hover:not(.Mui-disabled):before": {
        borderBottom: `${borderWidth[1]} solid ${inputBorderColor}`,
      },

      "&:before": {
        borderColor: inputBorderColor,
      },

      "&:after": {
        borderColor: "main" in info ? info.main : "inherit",
      },
    },
  },
};

export default input;
