import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { baseProperties } from "../../colors/typography";
import { borders } from "../../../base";
import { palette } from "../../colors";
import { rgba } from "../../../helpers";

interface MuiMenuItem {
  defaultProps?: ComponentsProps["MuiMenuItem"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiMenuItem"];
  variants?: ComponentsVariants["MuiMenuItem"];
}
const { info, common } = palette;
const { borderRadius } = borders;
const { fontSizeSM } = baseProperties;

const menuItem: MuiMenuItem = {
  styleOverrides: {
    root: {
      minWidth: "160px",
      minHeight: "unset",
      padding: "4.8px 16px",
      borderRadius: borderRadius.md,
      fontSize: fontSizeSM,
      color: rgba(common.white, 0.8),
      transition: "background-color 300ms ease, color 300ms ease",

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: info[100],
        color: common.white,
      },
    },
  },
};

export default menuItem;
