import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiListItem {
  defaultProps?: ComponentsProps["MuiListItem"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiListItem"];
  variants?: ComponentsVariants["MuiListItem"];
}

const listItem: MuiListItem = {
  defaultProps: {
    disableGutters: true,
  },

  styleOverrides: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};

export default listItem;
