import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { GridEventListener } from "@mui/x-data-grid/models/events";
import { Theme } from "@mui/material/styles";
import { Skeleton } from "@mui/material";

interface ICustomersTableProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  checkboxSelection?: boolean;
  onCellDoubleClick?: GridEventListener<"cellClick">;
  loading?: boolean;
  loadingCfg?: { skeletons?: boolean; rows?: number };
}

const styles = ({ palette, other }: Theme) => ({
  borderColor: other.helpers.rgba(palette.grey[500], 0.4),
  "& .MuiDataGrid-iconButtonContainer .MuiButtonBase-root": {
    color: "#fff",
  },
  "& .MuiDataGrid-columnHeader--filledGroup": {
    whiteSpace: "normal",
    lineHeight: "20px",
    paddingLeft: "6px",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderColor: other.helpers.rgba(palette.grey[500], 0.4),
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: palette.background.paper,
  },
  "& .MuiDataGrid-footerContainer": {
    borderColor: other.helpers.rgba(palette.grey[500], 0.4),
    backgroundColor: palette.background.paper,
    minHeight: "35px",
  },
  "& .MuiDataGrid-cell": {
    borderColor: other.helpers.rgba(palette.grey[500], 0.4),
    cursor: "pointer",
  },
  "& .MuiDataGrid-virtualScroller": {
    borderColor: other.helpers.rgba(palette.grey[500], 0.4),
  },
  ".css-1wvrz87-MuiButtonBase-root-MuiCheckbox-root.Mui-checked .MuiSvgIcon-root": {
    borderColor: palette.success.main,
    backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),linear-gradient(195deg, ${palette.success.main}, ${palette.success.main})`,
  },
});

function DFATable({
  rows,
  columns,
  onCellDoubleClick,
  loading,
  loadingCfg = {},
  checkboxSelection = false,
}: ICustomersTableProps) {
  const { skeletons = false, rows: loadingRowsCount = 5 } = loadingCfg;

  const loadingRows = skeletons
    ? Array(loadingRowsCount)
        .fill("")
        .map((row, index) => ({ id: `${index}` }))
    : [];

  columns = skeletons
    ? columns.map((column) => ({
        ...column,
        renderCell: (params) => {
          if (skeletons && loading)
            return (
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ width: "100%", borderRadius: "7px" }}
              />
            );
          return column.renderCell?.(params);
        },
      }))
    : columns;

  return (
    <Box sx={{ pt: 1, pb: 1 }}>
      <div style={{ height: "max-content", width: "100%" }}>
        <DataGridPro
          autoHeight
          rows={skeletons && loading ? loadingRows : rows}
          columns={columns}
          loading={!skeletons && (loading || !rows)}
          rowHeight={45}
          onCellDoubleClick={onCellDoubleClick}
          checkboxSelection={checkboxSelection}
          disableSelectionOnClick
          showCellRightBorder
          sx={styles}
          disableColumnMenu
          hideFooterRowCount={loading}
        />
      </div>
    </Box>
  );
}

export default DFATable;
