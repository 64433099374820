/*
DFA Platform Admin
***
Developed by ST soft team
*/

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { List, Divider, Icon, Typography } from "@mui/material";
import { DFABox } from "Theme";
import { setMiniSidenav, setTransparentSidenav, setWhiteSidenav } from "reducers/layout/action";
import { useTypedSelector } from "reducers";
import SidenavCollapse from "./SidenavCollapse";
import SidenavRoot from "./SidenavRoot";
import routes from "../../routes";

interface ISidenav {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  brand?: any;
  [k: string]: any;
}

function Sidenav({ brand = "", ...rest }: ISidenav) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    adaptiveThemeStyle: { miniSidenav, transparentSidenav, whiteSidenav, isDarkMode },
  } = useTypedSelector((state) => state);
  const { pathname } = useLocation();
  const collapseName = pathname.replace("/", "");

  const closeSidenav = () => dispatch(setMiniSidenav(true));

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      dispatch(setMiniSidenav(window.innerWidth < 1200));
      dispatch(setTransparentSidenav(window.innerWidth < 1200 ? false : transparentSidenav));
      dispatch(setWhiteSidenav(window.innerWidth < 1200 ? false : whiteSidenav));
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, pathname, transparentSidenav, whiteSidenav]);

  // Render all the routes from the routes.ts (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, icon, key, route }) => {
    if (type === "collapse") {
      return (
        <NavLink key={key} to={route}>
          <SidenavCollapse
            name={t(`layouts.${key}.mainTitle`)}
            icon={icon}
            active={key === collapseName}
          />
        </NavLink>
      );
    }
    if (type === "title") {
      return (
        <Typography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {t(`layouts.${key}.mainTitle`)}
        </Typography>
      );
    }
    if (type === "divider") {
      return (
        <Divider
          key={key}
          light={
            (!isDarkMode && !whiteSidenav && !transparentSidenav) ||
            (isDarkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }
    return null;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, isDarkMode }}
      sx={{ position: "relative", overflow: "hidden" }}
    >
      <DFABox pt={3} pb={1} px={4} textAlign="center">
        <DFABox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <Typography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </Typography>
        </DFABox>
        <DFABox sx={{ display: "flex", flexDirection: "column" }}>
          <DFABox component={NavLink} to="/" display="flex" alignItems="center">
            {brand && <DFABox component="img" src={brand} alt="Brand" width="100%" />}
          </DFABox>
          <DFABox flexDirection="column" display="flex" textAlign="left" width="100%">
            <Typography mt={2} sx={{ textTransform: "lowercase" }} variant="overline">
              {process.env.REACT_APP_VERSION.replace(
                `-${process.env.REACT_APP_ENVIRONMENT.toLowerCase()}`,
                ""
              ).replace("v", "версия ") || "version error"}
            </Typography>
          </DFABox>
        </DFABox>
      </DFABox>
      <Divider
        light={
          (!isDarkMode && !whiteSidenav && !transparentSidenav) ||
          (isDarkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List
        className="invisible-scrollbar"
        sx={{ overflowY: "auto", overflowX: "clip", scrollbarWidth: "thin" }}
      >
        {renderRoutes}
      </List>
      {/* <DFABox mt="auto" flexDirection="column" display="flex" textAlign="center" width="100%">
        <Typography mt={2} sx={{ textTransform: "lowercase" }} variant="overline">
          {process.env.REACT_APP_VERSION.replace(
            `-${process.env.REACT_APP_ENVIRONMENT.toLowerCase()}`,
            ""
          ).replace("v", "версия ") || "version error"}
        </Typography>
      </DFABox> */}
    </SidenavRoot>
  );
}

export default Sidenav;
