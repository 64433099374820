/* eslint-disable */
import {
  IssueStatus,
  issueStatusFromJSON,
  issueStatusToJSON,
} from "../issue/issue";
import { Reader, util, configure, Writer } from "protobufjs/minimal";
import Long from "long";

export const protobufPackage = "rr.bcpcore.issue";

export interface MsgInit {
  creator: string;
  issueId: string;
  emitentAddr: string;
  investStartDate: number;
  investFinishDate: number;
  minOrderLimit: number;
  maxOrderLimit: number;
  dayOrderLimit: number;
  maxIssueLimit: number;
  issueNominal: number;
}

export interface MsgInitResponse {}

export interface MsgInitToken {
  creator: string;
  issueId: string;
  ticker: string;
  nominal: number;
  interest: number;
  amount: number;
  marketDate: number;
  redemptionDate: number;
  minRedemptionLimit: number;
}

export interface MsgInitTokenResponse {}

export interface MsgSetStatus {
  creator: string;
  issueId: string;
  status: IssueStatus;
}

export interface MsgSetStatusResponse {}

const baseMsgInit: object = {
  creator: "",
  issueId: "",
  emitentAddr: "",
  investStartDate: 0,
  investFinishDate: 0,
  minOrderLimit: 0,
  maxOrderLimit: 0,
  dayOrderLimit: 0,
  maxIssueLimit: 0,
  issueNominal: 0,
};

export const MsgInitIssue = {
  encode(message: MsgInit, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.issueId !== "") {
      writer.uint32(18).string(message.issueId);
    }
    if (message.emitentAddr !== "") {
      writer.uint32(26).string(message.emitentAddr);
    }
    if (message.investStartDate !== 0) {
      writer.uint32(32).uint64(message.investStartDate);
    }
    if (message.investFinishDate !== 0) {
      writer.uint32(40).uint64(message.investFinishDate);
    }
    if (message.minOrderLimit !== 0) {
      writer.uint32(48).uint64(message.minOrderLimit);
    }
    if (message.maxOrderLimit !== 0) {
      writer.uint32(56).uint64(message.maxOrderLimit);
    }
    if (message.dayOrderLimit !== 0) {
      writer.uint32(64).uint64(message.dayOrderLimit);
    }
    if (message.maxIssueLimit !== 0) {
      writer.uint32(72).uint64(message.maxIssueLimit);
    }
    if (message.issueNominal !== 0) {
      writer.uint32(80).uint64(message.issueNominal);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgInit {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgInit } as MsgInit;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.issueId = reader.string();
          break;
        case 3:
          message.emitentAddr = reader.string();
          break;
        case 4:
          message.investStartDate = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.investFinishDate = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.minOrderLimit = longToNumber(reader.uint64() as Long);
          break;
        case 7:
          message.maxOrderLimit = longToNumber(reader.uint64() as Long);
          break;
        case 8:
          message.dayOrderLimit = longToNumber(reader.uint64() as Long);
          break;
        case 9:
          message.maxIssueLimit = longToNumber(reader.uint64() as Long);
          break;
        case 10:
          message.issueNominal = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgInit {
    const message = { ...baseMsgInit } as MsgInit;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = String(object.issueId);
    } else {
      message.issueId = "";
    }
    if (object.emitentAddr !== undefined && object.emitentAddr !== null) {
      message.emitentAddr = String(object.emitentAddr);
    } else {
      message.emitentAddr = "";
    }
    if (
      object.investStartDate !== undefined &&
      object.investStartDate !== null
    ) {
      message.investStartDate = Number(object.investStartDate);
    } else {
      message.investStartDate = 0;
    }
    if (
      object.investFinishDate !== undefined &&
      object.investFinishDate !== null
    ) {
      message.investFinishDate = Number(object.investFinishDate);
    } else {
      message.investFinishDate = 0;
    }
    if (object.minOrderLimit !== undefined && object.minOrderLimit !== null) {
      message.minOrderLimit = Number(object.minOrderLimit);
    } else {
      message.minOrderLimit = 0;
    }
    if (object.maxOrderLimit !== undefined && object.maxOrderLimit !== null) {
      message.maxOrderLimit = Number(object.maxOrderLimit);
    } else {
      message.maxOrderLimit = 0;
    }
    if (object.dayOrderLimit !== undefined && object.dayOrderLimit !== null) {
      message.dayOrderLimit = Number(object.dayOrderLimit);
    } else {
      message.dayOrderLimit = 0;
    }
    if (object.maxIssueLimit !== undefined && object.maxIssueLimit !== null) {
      message.maxIssueLimit = Number(object.maxIssueLimit);
    } else {
      message.maxIssueLimit = 0;
    }
    if (object.issueNominal !== undefined && object.issueNominal !== null) {
      message.issueNominal = Number(object.issueNominal);
    } else {
      message.issueNominal = 0;
    }
    return message;
  },

  toJSON(message: MsgInit): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.issueId !== undefined && (obj.issueId = message.issueId);
    message.emitentAddr !== undefined &&
      (obj.emitentAddr = message.emitentAddr);
    message.investStartDate !== undefined &&
      (obj.investStartDate = message.investStartDate);
    message.investFinishDate !== undefined &&
      (obj.investFinishDate = message.investFinishDate);
    message.minOrderLimit !== undefined &&
      (obj.minOrderLimit = message.minOrderLimit);
    message.maxOrderLimit !== undefined &&
      (obj.maxOrderLimit = message.maxOrderLimit);
    message.dayOrderLimit !== undefined &&
      (obj.dayOrderLimit = message.dayOrderLimit);
    message.maxIssueLimit !== undefined &&
      (obj.maxIssueLimit = message.maxIssueLimit);
    message.issueNominal !== undefined &&
      (obj.issueNominal = message.issueNominal);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgInit>): MsgInit {
    const message = { ...baseMsgInit } as MsgInit;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = object.issueId;
    } else {
      message.issueId = "";
    }
    if (object.emitentAddr !== undefined && object.emitentAddr !== null) {
      message.emitentAddr = object.emitentAddr;
    } else {
      message.emitentAddr = "";
    }
    if (
      object.investStartDate !== undefined &&
      object.investStartDate !== null
    ) {
      message.investStartDate = object.investStartDate;
    } else {
      message.investStartDate = 0;
    }
    if (
      object.investFinishDate !== undefined &&
      object.investFinishDate !== null
    ) {
      message.investFinishDate = object.investFinishDate;
    } else {
      message.investFinishDate = 0;
    }
    if (object.minOrderLimit !== undefined && object.minOrderLimit !== null) {
      message.minOrderLimit = object.minOrderLimit;
    } else {
      message.minOrderLimit = 0;
    }
    if (object.maxOrderLimit !== undefined && object.maxOrderLimit !== null) {
      message.maxOrderLimit = object.maxOrderLimit;
    } else {
      message.maxOrderLimit = 0;
    }
    if (object.dayOrderLimit !== undefined && object.dayOrderLimit !== null) {
      message.dayOrderLimit = object.dayOrderLimit;
    } else {
      message.dayOrderLimit = 0;
    }
    if (object.maxIssueLimit !== undefined && object.maxIssueLimit !== null) {
      message.maxIssueLimit = object.maxIssueLimit;
    } else {
      message.maxIssueLimit = 0;
    }
    if (object.issueNominal !== undefined && object.issueNominal !== null) {
      message.issueNominal = object.issueNominal;
    } else {
      message.issueNominal = 0;
    }
    return message;
  },
};

const baseMsgInitResponse: object = {};

export const MsgInitResponse = {
  encode(_: MsgInitResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgInitResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgInitResponse } as MsgInitResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgInitResponse {
    const message = { ...baseMsgInitResponse } as MsgInitResponse;
    return message;
  },

  toJSON(_: MsgInitResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgInitResponse>): MsgInitResponse {
    const message = { ...baseMsgInitResponse } as MsgInitResponse;
    return message;
  },
};

const baseMsgInitToken: object = {
  creator: "",
  issueId: "",
  ticker: "",
  nominal: 0,
  interest: 0,
  amount: 0,
  marketDate: 0,
  redemptionDate: 0,
  minRedemptionLimit: 0,
};

export const MsgInitTokenIssue = {
  encode(message: MsgInitToken, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.issueId !== "") {
      writer.uint32(18).string(message.issueId);
    }
    if (message.ticker !== "") {
      writer.uint32(26).string(message.ticker);
    }
    if (message.nominal !== 0) {
      writer.uint32(32).uint64(message.nominal);
    }
    if (message.interest !== 0) {
      writer.uint32(40).uint64(message.interest);
    }
    if (message.amount !== 0) {
      writer.uint32(48).uint64(message.amount);
    }
    if (message.marketDate !== 0) {
      writer.uint32(56).uint64(message.marketDate);
    }
    if (message.redemptionDate !== 0) {
      writer.uint32(64).uint64(message.redemptionDate);
    }
    if (message.minRedemptionLimit !== 0) {
      writer.uint32(72).uint64(message.minRedemptionLimit);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgInitToken {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgInitToken } as MsgInitToken;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.issueId = reader.string();
          break;
        case 3:
          message.ticker = reader.string();
          break;
        case 4:
          message.nominal = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.interest = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 7:
          message.marketDate = longToNumber(reader.uint64() as Long);
          break;
        case 8:
          message.redemptionDate = longToNumber(reader.uint64() as Long);
          break;
        case 9:
          message.minRedemptionLimit = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgInitToken {
    const message = { ...baseMsgInitToken } as MsgInitToken;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = String(object.issueId);
    } else {
      message.issueId = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = String(object.ticker);
    } else {
      message.ticker = "";
    }
    if (object.nominal !== undefined && object.nominal !== null) {
      message.nominal = Number(object.nominal);
    } else {
      message.nominal = 0;
    }
    if (object.interest !== undefined && object.interest !== null) {
      message.interest = Number(object.interest);
    } else {
      message.interest = 0;
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.marketDate !== undefined && object.marketDate !== null) {
      message.marketDate = Number(object.marketDate);
    } else {
      message.marketDate = 0;
    }
    if (object.redemptionDate !== undefined && object.redemptionDate !== null) {
      message.redemptionDate = Number(object.redemptionDate);
    } else {
      message.redemptionDate = 0;
    }
    if (
      object.minRedemptionLimit !== undefined &&
      object.minRedemptionLimit !== null
    ) {
      message.minRedemptionLimit = Number(object.minRedemptionLimit);
    } else {
      message.minRedemptionLimit = 0;
    }
    return message;
  },

  toJSON(message: MsgInitToken): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.issueId !== undefined && (obj.issueId = message.issueId);
    message.ticker !== undefined && (obj.ticker = message.ticker);
    message.nominal !== undefined && (obj.nominal = message.nominal);
    message.interest !== undefined && (obj.interest = message.interest);
    message.amount !== undefined && (obj.amount = message.amount);
    message.marketDate !== undefined && (obj.marketDate = message.marketDate);
    message.redemptionDate !== undefined &&
      (obj.redemptionDate = message.redemptionDate);
    message.minRedemptionLimit !== undefined &&
      (obj.minRedemptionLimit = message.minRedemptionLimit);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgInitToken>): MsgInitToken {
    const message = { ...baseMsgInitToken } as MsgInitToken;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = object.issueId;
    } else {
      message.issueId = "";
    }
    if (object.ticker !== undefined && object.ticker !== null) {
      message.ticker = object.ticker;
    } else {
      message.ticker = "";
    }
    if (object.nominal !== undefined && object.nominal !== null) {
      message.nominal = object.nominal;
    } else {
      message.nominal = 0;
    }
    if (object.interest !== undefined && object.interest !== null) {
      message.interest = object.interest;
    } else {
      message.interest = 0;
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.marketDate !== undefined && object.marketDate !== null) {
      message.marketDate = object.marketDate;
    } else {
      message.marketDate = 0;
    }
    if (object.redemptionDate !== undefined && object.redemptionDate !== null) {
      message.redemptionDate = object.redemptionDate;
    } else {
      message.redemptionDate = 0;
    }
    if (
      object.minRedemptionLimit !== undefined &&
      object.minRedemptionLimit !== null
    ) {
      message.minRedemptionLimit = object.minRedemptionLimit;
    } else {
      message.minRedemptionLimit = 0;
    }
    return message;
  },
};

const baseMsgInitTokenResponse: object = {};

export const MsgInitTokenResponse = {
  encode(_: MsgInitTokenResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgInitTokenResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgInitTokenResponse } as MsgInitTokenResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgInitTokenResponse {
    const message = { ...baseMsgInitTokenResponse } as MsgInitTokenResponse;
    return message;
  },

  toJSON(_: MsgInitTokenResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgInitTokenResponse>): MsgInitTokenResponse {
    const message = { ...baseMsgInitTokenResponse } as MsgInitTokenResponse;
    return message;
  },
};

const baseMsgSetStatus: object = { creator: "", issueId: "", status: 0 };

export const MsgSetStatus = {
  encode(message: MsgSetStatus, writer: Writer = Writer.create()): Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.issueId !== "") {
      writer.uint32(18).string(message.issueId);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetStatus {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetStatus } as MsgSetStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.issueId = reader.string();
          break;
        case 3:
          message.status = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgSetStatus {
    const message = { ...baseMsgSetStatus } as MsgSetStatus;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = "";
    }
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = String(object.issueId);
    } else {
      message.issueId = "";
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = issueStatusFromJSON(object.status);
    } else {
      message.status = 0;
    }
    return message;
  },

  toJSON(message: MsgSetStatus): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.issueId !== undefined && (obj.issueId = message.issueId);
    message.status !== undefined &&
      (obj.status = issueStatusToJSON(message.status));
    return obj;
  },

  fromPartial(object: DeepPartial<MsgSetStatus>): MsgSetStatus {
    const message = { ...baseMsgSetStatus } as MsgSetStatus;
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = "";
    }
    if (object.issueId !== undefined && object.issueId !== null) {
      message.issueId = object.issueId;
    } else {
      message.issueId = "";
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    return message;
  },
};

const baseMsgSetStatusResponse: object = {};

export const MsgSetStatusResponse = {
  encode(_: MsgSetStatusResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgSetStatusResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgSetStatusResponse } as MsgSetStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgSetStatusResponse {
    const message = { ...baseMsgSetStatusResponse } as MsgSetStatusResponse;
    return message;
  },

  toJSON(_: MsgSetStatusResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgSetStatusResponse>): MsgSetStatusResponse {
    const message = { ...baseMsgSetStatusResponse } as MsgSetStatusResponse;
    return message;
  },
};

/** Msg defines the Msg service. */
export interface Msg {
  Init(request: MsgInit): Promise<MsgInitResponse>;
  InitToken(request: MsgInitToken): Promise<MsgInitTokenResponse>;
  /** this line is used by starport scaffolding # proto/tx/rpc */
  SetStatus(request: MsgSetStatus): Promise<MsgSetStatusResponse>;
}

export class MsgClientImpl implements Msg {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }
  Init(request: MsgInit): Promise<MsgInitResponse> {
    const data = MsgInitIssue.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.issue.Msg", "Init", data);
    return promise.then((data) => MsgInitResponse.decode(new Reader(data)));
  }

  InitToken(request: MsgInitToken): Promise<MsgInitTokenResponse> {
    const data = MsgInitTokenIssue.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.issue.Msg", "InitToken", data);
    return promise.then((data) =>
      MsgInitTokenResponse.decode(new Reader(data))
    );
  }

  SetStatus(request: MsgSetStatus): Promise<MsgSetStatusResponse> {
    const data = MsgSetStatus.encode(request).finish();
    const promise = this.rpc.request("rr.bcpcore.issue.Msg", "SetStatus", data);
    return promise.then((data) =>
      MsgSetStatusResponse.decode(new Reader(data))
    );
  }
}

interface Rpc {
  request(
    service: string,
    method: string,
    data: Uint8Array
  ): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (util.Long !== Long) {
  util.Long = Long as any;
  configure();
}
