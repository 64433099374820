import React from "react";
import { green, red } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { DFAChip } from "Theme";

type StatusBadgeType = {
  (type: "low" | "warn" | "danger", label: string): React.ReactNode;
};

const statusBadge: StatusBadgeType = (type, label) => {
  let icon = <CheckIcon fontSize="medium" style={{ fill: green[500] }} />;
  let chipType: any = "success";
  if (type === "danger") {
    chipType = "error";
    icon = <ErrorOutlineIcon fontSize="medium" style={{ fill: red[500] }} />;
  }
  if (type === "warn") {
    chipType = "warning";
    icon = undefined;
  }
  return <DFAChip variant="outlined" size="medium" label={label} type={chipType} icon={icon} />;
};

export default statusBadge;
