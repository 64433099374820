/*
 Developed by ST soft team
 */

import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DFAButton from "../DFAButton";

interface IDFADialogProps {
  open: boolean;
  onClose(): void;
  onConfirm?(): void;
  title?: React.ReactNode;
  titleColor?: "error" | "warning" | "success";
  type?: "error" | "warning" | "success";
  dialogText?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  width?: "xs" | "sm" | "md" | "lg" | "xl";
  isHandleEnter?: boolean;
  loading?: boolean;
  confirmId?: string;
  cancelId?: string;
}

function DFADialog(props: IDFADialogProps) {
  const {
    open,
    dialogText,
    onClose,
    onConfirm,
    isHandleEnter,
    children,
    title,
    type,
    confirmText,
    cancelText,
    disabled,
    width,
    titleColor,
    loading,
    confirmId,
    cancelId,
  } = props;
  const { t } = useTranslation();

  const handleEnter = (e: any) => {
    if (isHandleEnter && !disabled && e.nativeEvent.charCode === 13) onConfirm();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={width} onKeyPress={handleEnter}>
      {title && (
        <DialogTitle color={titleColor} sx={{ p: "1rem 1rem 0" }}>
          {typeof title === "string" ? (
            <Box sx={{ fontSize: "1.25rem", lineHeight: "1.1" }}>{title}</Box>
          ) : (
            title
          )}
        </DialogTitle>
      )}
      {dialogText && (
        <DialogContentText sx={{ fontSize: "1rem", lineHeight: "1.1", p: "1rem 1rem 0" }}>
          {dialogText}
        </DialogContentText>
      )}
      {children && <DialogContent sx={{ p: "1rem 1rem 0 !important" }}>{children}</DialogContent>}
      <DialogActions>
        {onConfirm && (
          <DFAButton
            variant="contained"
            color={type || "primary"}
            onClick={onConfirm}
            disabled={disabled || false}
            loading={loading}
            id={confirmId || "access"}
          >
            {confirmText || t("general.buttons.access")}
          </DFAButton>
        )}
        <DFAButton
          variant="contained"
          color={!onConfirm && type ? type : "secondary"}
          onClick={onClose}
          id={cancelId || "close"}
        >
          {cancelText || t("general.buttons.close")}
        </DFAButton>
      </DialogActions>
    </Dialog>
  );
}

export default DFADialog;
