import { createTheme, Theme } from "@mui/material/styles";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import { colors, palette, shadows, typography } from "./colors";
import { baseProperties } from "./colors/typography";
import { breakpoints, globals, borders } from "../base";
import { boxShadow, gradientChartLine, hexToRgb, linearGradient, pxToRem, rgba } from "../helpers";

import {
  accordion,
  appBar,
  autocomplete,
  avatar,
  checkbox,
  container,
  formControlLabel,
  formLabel,
  icon,
  iconButton,
  input,
  inputLabel,
  inputOutlined,
  select,
  svgIcon,
  switchButton,
  tableCell,
  tableContainer,
  tableHead,
  textField,
  radio,
  stepIcon,
  stepper,
  step,
  stepConnector,
  stepLabel,
  breadcrumbs,
  buttonBase,
  divider,
  linearProgress,
  link,
  popover,
  sidenav,
  slider,
  tooltip,
  button,
  cardContent,
  cardMedia,
  card,
  dialog,
  dialogTitle,
  dialogContent,
  dialogContentText,
  dialogActions,
  list,
  listItem,
  listItemText,
  menu,
  menuItem,
  tabs,
  tab,
} from "./components";

const options: ThemeOptions = {
  breakpoints,
  typography,
  palette,
  other: {
    borders,
    colors,
    shadows,
    baseTypographyProp: baseProperties,
    helpers: {
      rgba,
      hexToRgb,
      boxShadow,
      pxToRem,
      linearGradient,
      gradientChartLine,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...container,
      },
    },
    MuiTabs: { ...tabs },
    MuiTab: { ...tab },
    MuiMenu: { ...menu },
    MuiMenuItem: { ...menuItem },
    MuiList: { ...list },
    MuiListItem: { ...listItem },
    MuiListItemText: { ...listItemText },
    MuiDialog: { ...dialog },
    MuiDialogTitle: { ...dialogTitle },
    MuiDialogContent: { ...dialogContent },
    MuiDialogContentText: { ...dialogContentText },
    MuiDialogActions: { ...dialogActions },
    MuiCard: { ...card },
    MuiCardMedia: { ...cardMedia },
    MuiCardContent: { ...cardContent },
    MuiButton: { ...button },
    MuiTooltip: { ...tooltip },
    MuiSlider: { ...slider },
    MuiDrawer: { ...sidenav },
    MuiPopover: { ...popover },
    MuiLink: { ...link },
    MuiLinearProgress: { ...linearProgress },
    MuiDivider: { ...divider },
    MuiButtonBase: { ...buttonBase },
    MuiBreadcrumbs: { ...breadcrumbs },
    MuiIcon: { ...icon },
    MuiSvgIcon: { ...svgIcon },
    MuiIconButton: { ...iconButton },
    MuiStepper: { ...stepper },
    MuiStep: { ...step },
    MuiAvatar: { ...avatar },
    MuiAppBar: { ...appBar },
    MuiStepConnector: { ...stepConnector },
    MuiStepLabel: { ...stepLabel },
    MuiStepIcon: { ...stepIcon },
    MuiTableContainer: { ...tableContainer },
    MuiSelect: { ...select },
    MuiTableHead: { ...tableHead },
    MuiTableCell: { ...tableCell },
    MuiInput: { ...input },
    MuiInputLabel: { ...inputLabel },
    MuiOutlinedInput: { ...inputOutlined },
    MuiFormControlLabel: { ...formControlLabel },
    MuiAutocomplete: { ...autocomplete },
    MuiTextField: { ...textField },
    MuiFormLabel: { ...formLabel },
    MuiRadio: { ...radio },
    MuiCheckbox: { ...checkbox },
    MuiSwitch: { ...switchButton },
    MuiAccordion: { ...accordion },
  },
};
const theme: Theme = createTheme(options);

export default theme;
