import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import Flag from "react-world-flags";
import PhoneNumber from "material-ui-phone-number";
import {
  CountryCodes,
  CountryPhones,
  DefaultCountryPhone,
  MenuProps,
} from "@front-packages/dfa-constants";
import {
  DFAButton,
  DFACard,
  DFAChip,
  DFADatePicker,
  DFASnackbar,
  DFAInput,
  DFALoadedFiles,
  DFABox,
} from "Theme";
import { useStatuses } from "hooks/other";
import { RepresentativeStatusGql } from "@front-packages/dfa-gql-api";
import { useTypedSelector } from "reducers";
import useRepresentativeData from "./useRepresentativeData";
import DropFileArea from "./DropFileArea";

function RepresentativeCard() {
  const { representativeStatus, isEdit, loading } = useTypedSelector((state) => state.customer);
  const {
    representativeData,
    setRepresentativeData,
    isAllFieldsNotEmpty,
    files,
    inviteRepresentative,
    errorMessage,
    handleSnackbar,
    success,
    changeRepresentativeStatus,
    getRepresentativeByCompanyID,
    handleSave,
  } = useRepresentativeData();
  const { t } = useTranslation();
  const getStatusBadgeProps = useStatuses();

  useEffect(() => {
    getRepresentativeByCompanyID();
  }, []);

  useEffect(() => {
    if (representativeStatus === RepresentativeStatusGql.Invited) inviteRepresentative();
  }, [representativeStatus]);

  return (
    <>
      <DFABox>
        <DFACard
          title={t("layouts.card.clientCard.representatives.representativeTitle")}
          titleBadge={
            !loading ? (
              <FormControl>
                <Select
                  value={representativeStatus || "New"}
                  variant="filled"
                  onChange={(e) =>
                    changeRepresentativeStatus(e.target.value as RepresentativeStatusGql)
                  }
                  input={
                    <OutlinedInput className="border-none" sx={{ border: "none !important" }} />
                  }
                  MenuProps={MenuProps}
                  style={{ border: "none" }}
                >
                  {Object.values(RepresentativeStatusGql).map((s) => (
                    <MenuItem key={s} value={s}>
                      <DFAChip
                        {...getStatusBadgeProps(s, "Representative")}
                        type={getStatusBadgeProps(s, "Representative").color as any}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ width: "100px", height: "32px", borderRadius: "20px", ml: 1 }}
              />
            )
          }
        >
          <Grid container spacing={2} mt={0.1}>
            <Grid item xs={4}>
              <DFAInput
                disabled={!isEdit}
                id="clientSurname"
                label={t("layouts.card.clientCard.representatives.clientSurname")}
                fullWidth
                autoFocus
                required
                value={representativeData.middleName}
                onChange={(e) => setRepresentativeData.middleName(e.target.value)}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <DFAInput
                disabled={!isEdit}
                id="clientName"
                label={t("layouts.card.clientCard.representatives.clientName")}
                fullWidth
                required
                value={representativeData.firstName}
                onChange={(e) => setRepresentativeData.firstName(e.target.value)}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <DFAInput
                disabled={!isEdit}
                id="clientPatronymic"
                label={t("layouts.card.clientCard.representatives.clientPatronymic")}
                fullWidth
                value={representativeData.lastName}
                onChange={(e) => setRepresentativeData.lastName(e.target.value)}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={2}>
              {!loading ? (
                <DFADatePicker
                  disabled={!isEdit}
                  label={`${t("layouts.card.clientCard.representatives.clientBirthday")}*`}
                  disableFuture
                  id="customerBirthday"
                  value={representativeData.birthday as any}
                  onChange={(date) => setRepresentativeData.birthday(date)}
                />
              ) : (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ width: "100%", height: "47px", borderRadius: "7px" }}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              {!loading ? (
                <FormControl fullWidth>
                  <InputLabel>
                    {`${t("layouts.card.clientCard.representatives.citizenship")}*`}
                  </InputLabel>
                  <Select
                    disabled={!isEdit}
                    id="clientCountryCode"
                    required
                    label={t("layouts.card.clientCard.representatives.citizenship")}
                    sx={{ height: "3.15em", display: "flex !important" }}
                    value={representativeData.countryCode}
                    onChange={(e) => setRepresentativeData.countryCode(e.target.value)}
                  >
                    {CountryCodes[window.localStorage.getItem("i18nextLng") || "ru"].map((el) => (
                      <MenuItem key={el.value} value={el.value} sx={{ display: "flex", gap: 1 }}>
                        <Flag code={el.value} height="16" />
                        {el.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ width: "100%", height: "47px", borderRadius: "7px" }}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              <DFAInput
                disabled={!isEdit}
                id="clientINN"
                label={t("layouts.card.clientCard.representatives.INN")}
                fullWidth
                required
                value={representativeData.inn}
                onChange={(e) => setRepresentativeData.inn(e.target.value)}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={2}>
              {!loading ? (
                <PhoneNumber
                  disabled={!isEdit}
                  defaultCountry={DefaultCountryPhone}
                  onlyCountries={CountryPhones}
                  id="clientPhoneNumber"
                  required
                  label={t("layouts.card.clientCard.representatives.clientPhone")}
                  value={representativeData.phoneNumber}
                  onChange={(phone) => setRepresentativeData.phoneNumber(phone)}
                  fullWidth
                  variant="outlined"
                />
              ) : (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ width: "100%", height: "47px", borderRadius: "7px" }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <DFAInput
                disabled={!isEdit}
                id="clientEmail"
                label={t("layouts.card.clientCard.representatives.clientEmail")}
                fullWidth
                required
                value={representativeData.email}
                onChange={(e) => setRepresentativeData.email(e.target.value)}
                isLoading={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0.1}>
            <Grid item xs={12}>
              <Typography variant="h5">Паспортные данные представителя</Typography>
            </Grid>
            <Grid item xs={2}>
              <DFAInput
                disabled={!isEdit}
                id="pass-serial"
                label={t("layouts.card.clientCard.representatives.passport.serial")}
                fullWidth
                required
                value={representativeData.passport.serial}
                onChange={(e) => setRepresentativeData.passport.serial(e.target.value)}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <DFAInput
                disabled={!isEdit}
                id="pass-number"
                label={t("layouts.card.clientCard.representatives.passport.number")}
                fullWidth
                required
                value={representativeData.passport.number}
                onChange={(e) => setRepresentativeData.passport.number(e.target.value)}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={2}>
              {!loading ? (
                <DFADatePicker
                  disabled={!isEdit}
                  label={t("layouts.card.clientCard.representatives.passport.dateOfIssue")}
                  disableFuture
                  id="pass-dateOfIssue"
                  value={representativeData.passport.dateOfIssue as any}
                  onChange={(date) => setRepresentativeData.passport.dateOfIssue(date)}
                />
              ) : (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ width: "100%", height: "47px", borderRadius: "7px" }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <DFAInput
                disabled={!isEdit}
                id="pass-code"
                label={t("layouts.card.clientCard.representatives.passport.code")}
                fullWidth
                required
                value={representativeData.passport.code}
                onChange={(e) => setRepresentativeData.passport.code(e.target.value)}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <DFAInput
                disabled={!isEdit}
                id="pass-issuedBy"
                label={t("layouts.card.clientCard.representatives.passport.issuedBy")}
                fullWidth
                required
                value={representativeData.passport.issuedBy}
                onChange={(e) => setRepresentativeData.passport.issuedBy(e.target.value)}
                isLoading={loading}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
          {!representativeData?.associatedFiles?.length ||
          (isEdit && representativeStatus === RepresentativeStatusGql.New) ? (
            <Grid item xs={12} mt={2} mb={2}>
              <DropFileArea files={files} isEdit={isEdit} />
            </Grid>
          ) : null}
          {isEdit && (
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <DFAButton
                onClick={handleSave}
                variant="contained"
                color="success"
                loading={loading}
                id="saveRepresentative"
                disabled={
                  representativeData?.associatedFiles?.length
                    ? !isAllFieldsNotEmpty()
                    : !(isAllFieldsNotEmpty() && !!Object.entries(files.data).length)
                }
                sx={{ mt: 1 }}
              >
                {t("general.buttons.save")}
              </DFAButton>
            </Grid>
          )}
        </DFACard>
        {representativeData?.associatedFiles?.length ? (
          <Grid item xs={12} mt={2}>
            <DFALoadedFiles filesArray={representativeData?.associatedFiles} />
          </Grid>
        ) : null}
      </DFABox>
      <DFASnackbar
        color={success ? "success" : "error"}
        icon="error"
        title={success ? t("general.notifications.success") : t("general.errors.errorTitle")}
        snackContent={errorMessage || success}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage || !!success}
        close={handleSnackbar}
        id="addRepresentativeError"
      />
    </>
  );
}

export default RepresentativeCard;
