import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiIcon {
  defaultProps?: ComponentsProps["MuiIcon"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiIcon"];
  variants?: ComponentsVariants["MuiIcon"];
}

const icon: MuiIcon = {
  defaultProps: {
    baseClassName: "material-icons-round",
    fontSize: "inherit",
  },

  styleOverrides: {
    fontSizeInherit: {
      fontSize: "inherit !important",
    },

    fontSizeSmall: {
      fontSize: "20px !important",
    },

    fontSizeLarge: {
      fontSize: "36px !important",
    },
  },
};

export default icon;
