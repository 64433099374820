import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ChipProps } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { blue, green, orange, pink, red } from "@mui/material/colors";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

function chipProps(params: GridRenderCellParams): ChipProps {
  if (params.value === "inProgress") {
    return {
      icon: <SyncIcon fontSize="medium" style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: blue[500],
      },
    };
  }
  if (params.value === "onHold") {
    return {
      icon: <DoDisturbIcon fontSize="medium" style={{ fill: orange[500] }} />,
      label: params.value,
      style: {
        borderColor: orange[500],
      },
    };
  }
  if (params.value === "completed") {
    return {
      icon: <CheckIcon fontSize="medium" style={{ fill: green[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500],
      },
    };
  }
  if (params.value === "failed") {
    return {
      icon: <ErrorOutlineIcon fontSize="medium" style={{ fill: red[500] }} />,
      label: params.value,
      style: {
        borderColor: red[500],
      },
    };
  }
  return {
    icon: <QuestionMarkIcon fontSize="medium" style={{ fill: pink[500] }} />,
    label: params.value,
    style: {
      borderColor: pink[500],
    },
  };
}

export default chipProps;
