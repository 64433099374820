import { useEffect, useState } from "react";
import moment from "moment";
import { BlocksAPI, QueryGetBlocksArgs, TransactionsAPI } from "@front-packages/dfa-gql-api";
import { IUseErrorsResult, useErrors } from "../errors";

type UseBlocksTxVarType<TValue> = {
  value: TValue;
  setValue(value: any): void;
};

type VariablesType = [
  UseBlocksTxVarType<Date | null>,
  UseBlocksTxVarType<number>,
  UseBlocksTxVarType<number>
];

interface IUseBlocksTxResultT<TState> {
  variables: VariablesType;
  state: TState;
  error: IUseErrorsResult;
  loading: boolean;
  getState(): Promise<void>;
}

interface IUseBlocksTxProps<TState> {
  initState: TState;
  isBlocks: boolean;
}

let timerId;
function useBlocksTx<T>({ initState, isBlocks }: IUseBlocksTxProps<T>): IUseBlocksTxResultT<T> {
  const storageDate = window.localStorage.getItem("transactions_date");
  const [date, setDate] = useState<Date | null>(storageDate ? new Date(storageDate) : new Date());
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [state, setState] = useState<T>(initState);
  const error = useErrors();

  const fetchState = async (variables?: QueryGetBlocksArgs): Promise<T> => {
    setLoading(true);
    if (isBlocks) {
      const { response, error: queryError } = await BlocksAPI.GetBlocks(variables);
      if (queryError) {
        error.setError(queryError);
        return null;
      }
      return response as any;
    }
    const { response, error: queryError } = await TransactionsAPI.GetTxs(variables);
    if (queryError) {
      error.setError(queryError);
      return null;
    }
    return response as any;
  };

  const handleSetCount = async (e) => {
    setCount(+e.target.value);
    setPage(0);
    const resp = await fetchState({
      date: moment(date).format("yyyy-MM-DD"),
      count: +e.target.value,
      page: 0,
    });
    if (resp) setState(resp);
    setLoading(false);
  };

  const handleSetPage = async (changePage) => {
    setPage(changePage - 1);
    const resp = await fetchState({
      date: moment(date).format("yyyy-MM-DD"),
      page: changePage - 1,
      count,
    });
    if (resp) setState(resp);
    setLoading(false);
  };

  const handleChangeDate = async (selectedDate: Date | null) => {
    window.localStorage.setItem("transactions_date", selectedDate.toString());
    setDate(selectedDate);
    const resp = await fetchState({
      date: moment(selectedDate).format("yyyy-MM-DD"),
      count,
      page: 0,
    });
    if (resp) setState(resp);
    setLoading(false);
  };

  const getState = async () => {
    const resp = await fetchState({
      date: moment(date).format("yyyy-MM-DD"),
      page,
      count,
    });
    if (resp) setState(resp);
    setLoading(false);
  };

  const getStateWithInterval = async () => {
    clearInterval(timerId);
    await getState();
    timerId = setInterval(getState, 100000);
  };

  useEffect(() => {
    getStateWithInterval().catch((e) => console.error(e));
    return () => clearInterval(timerId);
  }, []);

  return {
    state,
    error,
    loading,
    getState: getStateWithInterval,
    variables: [
      {
        value: date,
        setValue: handleChangeDate,
      },
      { value: page, setValue: handleSetPage },
      {
        value: count,
        setValue: handleSetCount,
      },
    ],
  };
}

export default useBlocksTx;
