import { GetWithdrawOrdersOpts, WithdrawOrdersResponse } from "@front-packages/dfa-gql-api";
import {
  SET_WITHDRAWAL_DATA,
  SET_WITHDRAWAL_LOADING,
  SET_WITHDRAWAL_PAGE,
  SET_WITHDRAWAL_COUNT,
  SET_WITHDRAWAL_SEARCH_OPTS,
} from "./action";

export interface IInitWithdrawalState {
  withdrawalData: WithdrawOrdersResponse;
  withdrawalLoading: boolean;
  withdrawalPage: number;
  withdrawalCount: number;
  withdrawalSearchOpts: Partial<GetWithdrawOrdersOpts>;
}

const initialState: IInitWithdrawalState = {
  withdrawalData: null,
  withdrawalLoading: false,
  withdrawalPage: 0,
  withdrawalCount: 10,
  withdrawalSearchOpts: {},
};

export default function reducer(state: IInitWithdrawalState = initialState, action) {
  switch (action.type) {
    case SET_WITHDRAWAL_DATA: {
      return { ...state, withdrawalData: action.payload };
    }
    case SET_WITHDRAWAL_LOADING: {
      return { ...state, withdrawalLoading: action.payload };
    }
    case SET_WITHDRAWAL_PAGE: {
      return { ...state, withdrawalPage: action.payload };
    }
    case SET_WITHDRAWAL_COUNT: {
      return { ...state, withdrawalCount: action.payload };
    }
    case SET_WITHDRAWAL_SEARCH_OPTS: {
      return { ...state, withdrawalSearchOpts: action.payload };
    }
    default: {
      return state;
    }
  }
}
