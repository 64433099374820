import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { rgba } from "../../../helpers";
import { baseProperties } from "../../colors/typography";
import { palette } from "../../colors";

interface MuiDialogContentText {
  defaultProps?: ComponentsProps["MuiDialogContentText"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiDialogContentText"];
  variants?: ComponentsVariants["MuiDialogContentText"];
}

const { fontSizeMD } = baseProperties;
const { white } = palette.common;

const dialogContentText: MuiDialogContentText = {
  styleOverrides: {
    root: {
      fontSize: fontSizeMD,
      color: rgba(white, 0.8),
    },
  },
};

export default dialogContentText;
