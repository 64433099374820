import React from "react";
import { Skeleton, Typography } from "@mui/material";
import { DFABox, DFACard, DFAFile } from "Theme";
import { File } from "@front-packages/dfa-gql-api";

interface IPermissionCard {
  title: string;
  text: string;
  files?: Array<File>;
  isLoading?: boolean;
}

function PermissionCard({ title, text, files, isLoading }: IPermissionCard) {
  return (
    <DFACard sx={{ p: 3 }} title={title} subTitle={text}>
      {isLoading && <Skeleton variant="rounded" animation="wave" sx={{ width: "60%" }} />}
      {files && files.length ? (
        <>
          <Typography variant="h6" mt={3} mb={2} color="success.main" textTransform="uppercase">
            Прикрепляемые файлы
          </Typography>
          <DFABox display="flex" gap={2} flexWrap="wrap">
            {files.map((file) => (
              <DFAFile name={file.name} key={file.id} url={file.url} />
            ))}
          </DFABox>
        </>
      ) : null}
    </DFACard>
  );
}

export default PermissionCard;
