/*
DFA Platform Admin
***
Developed by ST soft team
*/

// eslint-disable-next-line import/extensions,import/no-unresolved
import { IPxToRem } from "../typings";

/**
  The pxToRem() function helps you to convert a px unit into a rem unit,
 */

const pxToRem: IPxToRem = (px, basePx = 16) => {
  return `${px / basePx}rem`;
};

export default pxToRem;
