import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateRange, MenuProps } from "@front-packages/dfa-constants";
import { DFABox, DFAButton, DFACheckBox, DFAChip, DFARangeDate, DFASearchBadge } from "Theme";
import { Theme } from "@mui/material/styles";
import { GetTokensOpts, UserClientType, TokenStatusGql } from "@front-packages/dfa-gql-api";
import { horizontalScroll, hiddenScrollBar } from "@front-packages/dfa-helpers";
import { useStatuses } from "hooks/other";
import { useTranslation } from "react-i18next";

interface IEmissionsSearchForm {
  onSearch(opts: GetTokensOpts): Promise<void>;
  page: number;
  count: number;
}

function EmissionsSearchForm({ onSearch, page, count }: IEmissionsSearchForm) {
  const { i18n } = useTranslation();
  const [opts, setOpts] = useState<GetTokensOpts>({
    page,
    count,
    clientType: UserClientType.Admin,
  });
  const [badges, setBadges] = useState<Array<string>>([]);
  const [expanded, setExpanded] = useState<"panel1" | false>(false);
  const box = useRef<HTMLDivElement>(null);
  const accordion = useRef<HTMLDivElement>(null);
  const scrollBox1 = useRef<HTMLDivElement>(null);
  const scrollBox2 = useRef<HTMLDivElement>(null);
  const getStatusBadgeProps = useStatuses();

  const handleSetBadge = () => {
    const newBadges = Object.keys(opts).filter((key) => {
      let isReturn = !!opts[key];
      if (key === "page" || key === "count" || key === "clientType") isReturn = false;
      return isReturn;
    });
    setBadges(newBadges);
  };
  const handleOpen = (panel: "panel1") => (e: any, isExpanded: boolean) => {
    if (
      (!box.current || (box.current && !box.current.contains(e.target))) &&
      accordion.current.contains(e.target)
    )
      setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => setExpanded(false);
  const handleSearch = async () => {
    await onSearch(opts);
    handleSetBadge();
    handleClose();
  };
  const setTicker = (e: ChangeEvent<HTMLInputElement>) =>
    setOpts({ ...opts, ticker: e.target.value || undefined });
  const setStatus = (e: SelectChangeEvent) => {
    const tokenStatuses = e.target.value?.length ? (e.target.value as any) : undefined;
    return setOpts({ ...opts, tokenStatuses });
  };
  const setStartDate = (startDate: DateRange) => setOpts({ ...opts, startDate: startDate as any });
  const setCompletionDate = (completionDate: DateRange) =>
    setOpts({ ...opts, completionDate: completionDate as any });
  const handleReset = async () => {
    setOpts({ page: 0, count: 10, clientType: UserClientType.Admin });
    setBadges([]);
    await onSearch({ page: 0, count: 10, clientType: UserClientType.Admin });
    handleClose();
  };
  const handleEnter = async (e: any) => {
    if (e.nativeEvent.charCode === 13) await handleSearch();
  };
  const handleDelBadge = async (badge: string) => {
    setOpts({ ...opts, [badge]: undefined });
    setBadges(badges.filter((el) => el !== badge));
    await onSearch({ ...opts, [badge]: undefined });
  };
  const handleScrollBox1 = (e: any) => horizontalScroll(e, scrollBox1);
  const handleScrollBox2 = (e: any) => horizontalScroll(e, scrollBox2);

  useEffect(() => {
    setOpts({ ...opts, count });
  }, [count]);
  useEffect(() => {
    setOpts({ ...opts, page });
  }, [page]);

  const showReset = !!opts?.ticker || !!opts?.issuer || !!opts?.startDate || !!opts?.completionDate;
  return (
    <DFABox
      sx={{
        borderRadius: "15px",
        position: "absolute",
        top: "-70px",
        minHeight: "70px",
        right: 0,
        zIndex: 11,
        width: "40%",
      }}
      onKeyPress={handleEnter}
    >
      <DFABox
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          width: "100%",
          position: "absolute",
          right: 0,
          bottom: "80px",
        }}
      >
        {badges.map((badge) => (
          <DFASearchBadge key={badge} delBadge={handleDelBadge} badge={badge} />
        ))}
      </DFABox>
      <Accordion
        expanded={expanded === "panel1"}
        sx={{
          borderRadius: "10px !important",
          m: "0 !important",
          boxShadow: ({ shadows }: Theme) => (expanded === "panel1" ? shadows[8] : shadows[1]),
        }}
        onChange={handleOpen("panel1")}
      >
        <AccordionSummary
          ref={accordion}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {expanded !== "panel1" && (
            <DFABox display="flex" ref={box} ml={1} mr={1} width="100%" alignItems="center">
              <TextField
                sx={{ m: 0, mr: 1 }}
                value={opts?.ticker || ""}
                margin="dense"
                id="collapse-name"
                label="Поиск по тикеру"
                type="text"
                fullWidth
                variant="outlined"
                onInput={setTicker}
              />
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="roleLabel">Статус выпуска</InputLabel>
                <Select
                  multiple
                  labelId="roleLabel"
                  id="role"
                  value={opts?.tokenStatuses || []}
                  variant="filled"
                  onChange={setStatus}
                  input={<OutlinedInput label="Статус выпуска" />}
                  MenuProps={MenuProps}
                  style={{ lineHeight: "2.938rem" }}
                  renderValue={(selected: any) => (
                    <DFABox
                      ref={scrollBox1}
                      onWheel={handleScrollBox1}
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        padding: "16px 0 7px",
                        ...hiddenScrollBar,
                      }}
                    >
                      {selected.map((status) => (
                        <DFAChip
                          key={status}
                          size="small"
                          {...getStatusBadgeProps(status, "Token")}
                          type={getStatusBadgeProps(status, "Token").color as any}
                        />
                      ))}
                    </DFABox>
                  )}
                >
                  {Object.values(TokenStatusGql).map((s) => (
                    <MenuItem key={s} value={s}>
                      <DFACheckBox
                        checked={!!opts?.tokenStatuses && opts.tokenStatuses.indexOf(s) > -1}
                      />
                      {getStatusBadgeProps(s, "Token").label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DFAButton
                sx={{ marginLeft: "1rem" }}
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Искать
              </DFAButton>
            </DFABox>
          )}
        </AccordionSummary>
        <AccordionDetails id="search-details">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                sx={{ m: 0 }}
                autoFocus
                value={opts?.ticker || ""}
                margin="dense"
                id="name"
                label="Поиск по тикеру"
                type="text"
                fullWidth
                variant="outlined"
                onInput={setTicker}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="roleLabel">Статус выпуска</InputLabel>
                <Select
                  multiple
                  labelId="roleLabel"
                  id="role"
                  value={opts?.tokenStatuses || []}
                  variant="filled"
                  onChange={setStatus}
                  input={<OutlinedInput label="Статус выпуска" />}
                  MenuProps={MenuProps}
                  style={{ lineHeight: "2.938rem" }}
                  renderValue={(selected: any) => (
                    <DFABox
                      ref={scrollBox2}
                      onWheel={handleScrollBox2}
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        padding: "16px 0 7px",
                        ...hiddenScrollBar,
                      }}
                    >
                      {selected.map((status) => (
                        <DFAChip
                          key={status}
                          size="small"
                          {...getStatusBadgeProps(status, "Token")}
                          type={getStatusBadgeProps(status, "Token").color as any}
                        />
                      ))}
                    </DFABox>
                  )}
                >
                  {Object.values(TokenStatusGql).map((s) => (
                    <MenuItem key={s} value={s}>
                      <DFACheckBox
                        checked={opts?.tokenStatuses && opts.tokenStatuses.indexOf(s) > -1}
                      />
                      {s}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <DFARangeDate
                lang={i18n.language as any}
                label="Дата старта"
                rangeDate={(opts?.startDate as any) || null}
                setRangeDate={setStartDate}
              />
            </Grid>
            <Grid item xs={12}>
              <DFARangeDate
                lang={i18n.language as any}
                label="Дата завершения"
                setRangeDate={setCompletionDate}
                rangeDate={(opts?.completionDate as any) || null}
              />
            </Grid>
            <Grid item xs={12}>
              <DFABox display="flex" justifyContent="flex-end">
                {showReset && (
                  <DFAButton
                    variant="text"
                    color="secondary"
                    onClick={handleReset}
                    sx={{ marginRight: "auto" }}
                    id="reset-btn"
                  >
                    Сбросить
                  </DFAButton>
                )}
                <DFAButton
                  sx={{ marginLeft: "1rem" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                >
                  Искать
                </DFAButton>
                <DFAButton
                  sx={{ marginLeft: "1rem" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  Закрыть
                </DFAButton>
              </DFABox>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </DFABox>
  );
}

export default React.memo(EmissionsSearchForm);
