/*
 Developed by ST soft team
 */

import React from "react";
import Button from "@mui/material/Button";
import { styled, Theme } from "@mui/material/styles";
import { ButtonProps } from "@mui/material/Button/Button";

interface IOwnerButton {
  size?: "small" | "medium" | "large";
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  iconOnly?: boolean;
  children?: React.ReactNode;
}

interface IButtonStyled {
  theme?: Theme;
  ownerState?: IOwnerButton;
}

export default styled(Button)<IButtonStyled>(({ theme, ownerState }) => {
  const { palette, other } = theme;
  const { helpers, shadows, colors } = other;
  const { color, variant, size, iconOnly } = ownerState;

  const {
    common: { white },
  } = palette;
  const { transparent, gradients } = colors;
  const { boxShadow, linearGradient, rgba } = helpers;
  const { colored } = shadows;

  const outlinedStyles = () => {
    const boxShadowValue = palette[color]
      ? boxShadow([0, 0], [0, 3.2], palette[color].main, 0.5)
      : boxShadow([0, 0], [0, 3.2], white, 0.5);

    return {
      background: transparent,
      color: palette[color].main,
      border: "1px solid transparent",
      borderColor: palette[color].main,
      "&:hover": {
        color: palette[color].light,
        borderColor: palette[color].light,
        background: transparent,
        boxShadow: boxShadowValue,
      },
      "&:focus:not(:hover)": {
        color: palette[color].light,
        background: transparent,
        boxShadow: boxShadowValue,
      },
      "&:active:not(:hover)": {
        opacity: 0.85,
        backgroundColor: palette[color].light,
        color: palette[color].light,
        borderColor: palette[color].light,
      },

      "&:disabled": {
        color: rgba(palette[color].main, 0.8),
        borderColor: rgba(palette[color].main, 0.8),
      },
    };
  };

  const containedStyles = () => {
    const backgroundValue = !gradients[color]
      ? white
      : linearGradient(gradients[color].light, gradients[color].dark);
    const boxShadowValue = colored[color]
      ? `${boxShadow([0, 3], [3, 0], palette[color].main, 0.15)}, ${boxShadow(
          [0, 3],
          [1, -2],
          palette[color].main,
          0.2
        )}, ${boxShadow([0, 1], [5, 0], palette[color].main, 0.15)}`
      : "none";

    return {
      background: backgroundValue,
      color: white,
      boxShadow: boxShadowValue,
      "&:hover": {
        color: white,
        boxShadow: colored[color]
          ? `${boxShadow([0, 14], [26, -12], palette[color].main, 0.4)}, ${boxShadow(
              [0, 4],
              [23, 0],
              palette[color].main,
              0.15
            )}, ${boxShadow([0, 8], [10, -5], palette[color].main, 0.2)}`
          : "none",
      },
      "&:focus:not(:hover)": {
        color: white,
        boxShadow: boxShadowValue,
      },
      "&:disabled": {
        background: rgba(palette[color].main, 0.8),
        color: white,
      },
    };
  };

  const textStyles = () => ({
    color: palette[color] ? palette[color].main : white,
    "&:hover": {
      color: palette[color].light,
    },
    "&:focus:not(:hover)": {
      color: palette[color].light,
    },
    "&:disabled": {
      color: rgba(palette[color].main, 0.8),
    },
  });

  const iconOnlyStyles = () => {
    let sizeValue = "38px";
    let paddingValue = "11px 11px 10px";
    if (size === "small") {
      sizeValue = "25.4px";
      paddingValue = "4.5xp";
    }
    if (size === "large") {
      sizeValue = "52px";
      paddingValue = "16px";
    }

    return {
      width: sizeValue,
      minWidth: sizeValue,
      height: sizeValue,
      minHeight: sizeValue,
      padding: paddingValue,
      "& .material-icons": {
        marginTop: 0,
      },
      "&:hover, &:focus, &:active": {
        transform: "none",
      },
    };
  };

  return {
    ...(variant === "contained" && containedStyles()),
    ...(variant === "outlined" && outlinedStyles()),
    ...(variant === "text" && textStyles()),
    ...(iconOnly && iconOnlyStyles()),
  };
});
