/**
 DFA Platform Admin
 ***
 Developed by ST soft team
 */

import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Alert,
  IconButton,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { countList, MenuProps } from "@front-packages/dfa-constants";
import { DFAAlert, DFAButton, DFASnackbar, DFADatePicker } from "Theme";
import { TxsResponse } from "@front-packages/dfa-gql-api";
import useBlocksTx from "hooks/txAndBlocks";
import { useTypedSelector } from "reducers";
import { setBlockedPages } from "reducers/auth/action";
import SendTX from "./components/SendTX";
import TransactionCard from "./components/TransactionCard";
import InfoCard from "./components/InfoCard";

function Transactions() {
  const dispatch = useDispatch();
  const {
    state,
    loading,
    getState,
    error: { errorMessage, setError },
    variables: [date, page, count],
  } = useBlocksTx<TxsResponse>({ initState: { count: 0, txs: null }, isBlocks: false });
  const { blockedPages } = useTypedSelector(({ auth }) => auth);
  const route = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "")[0];
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [isActiveNotification, setIsActiveNotification] = useState<boolean>(false);
  const [notificationInfo, setNotificationInfo] = useState<any>({});
  const [notificationErrorCode, setNotificationErrorCode] = useState<string>("");
  const [hash, setHash] = useState<string>("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(
    () => () => {
      dispatch(setBlockedPages({ transactions: false }));
    },
    []
  );

  const handleCloseSnackbar = () => setError("");

  if (blockedPages[route]) {
    return (
      <Box>
        <DFAAlert type="error" title={errorMessage} />
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sx={!openAlert ? { paddingTop: "0 !important" } : {}}>
          <Collapse in={openAlert}>
            <Alert
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              color={notificationInfo?.bgColor === "warning" ? "error" : "success"}
            >
              <InfoCard
                isActiveNotification={isActiveNotification}
                notificationInfo={notificationInfo}
                notificationErrorCode={notificationErrorCode}
                hash={hash}
              />
            </Alert>
          </Collapse>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth sx={{ height: 50 }}>
            <InputLabel id="countCardsLabel">{t("layouts.transactions.per_page_count")}</InputLabel>
            <Select
              labelId="countCardsLabel"
              id="countCardsSelect"
              value={count.value}
              variant="filled"
              onChange={count.setValue}
              input={<OutlinedInput label={t("layouts.transactions.per_page_count")} />}
              MenuProps={MenuProps}
              style={{ lineHeight: "2rem", height: 45 }}
            >
              {countList.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <DFADatePicker
            label={t("layouts.transactions.date")}
            value={date.value}
            onChange={date.setValue}
          />
        </Grid>
        <Grid item xs={2}>
          {loading && <CircularProgress color="success" />}
        </Grid>
        <Grid item xs={6} textAlign="end">
          <DFAButton variant="contained" onClick={handleClickOpen} color="success">
            {t("general.buttons.sendTransaction")}
          </DFAButton>
          <SendTX
            getTxs={getState}
            open={open}
            handleClose={handleClose}
            setIsActiveNotification={setIsActiveNotification}
            setNotificationInfo={setNotificationInfo}
            setNotificationErrorCode={setNotificationErrorCode}
            setHash={setHash}
            setOpenAlert={setOpenAlert}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="left" direction="row">
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {state.txs &&
            state.txs.map((transaction) => (
              <Grid key={transaction.id} item xs={12}>
                <TransactionCard tx={transaction} />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" marginTop={5} marginBottom={5}>
        {state.count > count.value && (
          <Grid item xs={12}>
            <Pagination
              count={Math.ceil(state.count / count.value)}
              page={page.value + 1}
              shape="rounded"
              onChange={(_, currentPage) => page.setValue(currentPage)}
            />
          </Grid>
        )}
      </Grid>
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errorMessage}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage}
        onClose={handleCloseSnackbar}
        close={handleCloseSnackbar}
      />
    </Box>
  );
}

export default Transactions;
