import { useReducer } from "react";
import { GetCompanyInfoAdmin } from "@front-packages/dfa-gql-api";
import { reducer, initState } from "./reducer";
import { IReducer, Result, SetAllType, SetFieldType } from "../types";

function useCompanyInfo(): Result<GetCompanyInfoAdmin> {
  const [data, dispatch] = useReducer<IReducer<GetCompanyInfoAdmin>>(reducer, initState);

  const setAllInfo: SetAllType<GetCompanyInfoAdmin> = (value) =>
    dispatch({ type: "all", payload: value });
  const setInfoField: SetFieldType<GetCompanyInfoAdmin> = (value) =>
    dispatch({ type: "field", payload: value });

  return [data, setInfoField, setAllInfo];
}

export default useCompanyInfo;
