/*
DFA Platform Admin
***
Developed by ST soft team
*/

import React from "react";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import { DFABox } from "Theme";

// Custom styles for the SidenavCollapse
import { useTypedSelector } from "reducers";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "./styles/sidenavCollapse";

interface ISidenavCollapseProps {
  icon: React.ReactNode;
  name: string;
  active: boolean;
  [k: string]: any;
}

function SidenavCollapse({ icon, name, active = true, ...rest }: ISidenavCollapseProps) {
  const controller = useTypedSelector((state) => state.adaptiveThemeStyle);
  const { miniSidenav, transparentSidenav, whiteSidenav, sidenavColor } = controller;

  return (
    <ListItem component="li">
      <DFABox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            sidenavColor,
          })
        }
      >
        <ListItemIcon sx={collapseIconBox}>
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />
      </DFABox>
    </ListItem>
  );
}

export default SidenavCollapse;
