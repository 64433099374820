import { GetWithdrawOrdersOpts } from "@front-packages/dfa-gql-api";

export const SET_WITHDRAWAL_DATA = "@@withdrawal/SET_WITHDRAWAL_DATA";
export const SET_WITHDRAWAL_LOADING = "@@withdrawal/SET_WITHDRAWAL_LOADING";
export const SET_WITHDRAWAL_PAGE = "@@withdrawal/SET_WITHDRAWAL_PAGE";
export const SET_WITHDRAWAL_COUNT = "@@withdrawal/SET_WITHDRAWAL_COUNT";
export const SET_WITHDRAWAL_SEARCH_OPTS = "@@withdrawal/SET_WITHDRAWAL_SEARCH_OPTS";

export const setWithdrawalData = (payload: object) => ({ type: SET_WITHDRAWAL_DATA, payload });
export const setWithdrawalLoading = (payload: boolean) => ({
  type: SET_WITHDRAWAL_LOADING,
  payload,
});
export const setWithdrawalPage = (payload: number) => ({ type: SET_WITHDRAWAL_PAGE, payload });
export const setWithdrawalCount = (payload: number) => ({ type: SET_WITHDRAWAL_COUNT, payload });
export const setWithdrawalSearchOpts = (payload: Partial<GetWithdrawOrdersOpts>) => ({
  type: SET_WITHDRAWAL_SEARCH_OPTS,
  payload,
});
