import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { baseProperties } from "../../colors/typography";
import { borders } from "../../../base";
import { palette, shadows } from "../../colors";

interface MuiMenu {
  defaultProps?: ComponentsProps["MuiMenu"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiMenu"];
  variants?: ComponentsVariants["MuiMenu"];
}

const { md } = shadows;
const { fontSizeSM } = baseProperties;
const { text, info } = palette;
const { borderRadius } = borders;

const menu: MuiMenu = {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    paper: {
      minWidth: "160px",
      boxShadow: md,
      padding: "16px 8px",
      fontSize: fontSizeSM,
      color: text.primary,
      textAlign: "left",
      backgroundColor: `${info[200]} !important`,
      borderRadius: borderRadius.md,
    },
  },
};

export default menu;
