import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CompanyTypes, TxStatusDict } from "@front-packages/dfa-constants";
import { delay, trimLetter } from "@front-packages/dfa-helpers";
import {
  DFABox,
  DFAButton,
  DFACard,
  DFACheckBox,
  DFADatePicker,
  DFADialog,
  DFAInput,
  DFALinearProgress,
  DFALoadedFiles,
} from "Theme";
import {
  ClientEntType,
  CompaniesAPI,
  GetCompanyInfoAdmin,
  RepresentativeRole,
  RoleGql,
  TokensAPI,
  TransactionsAPI,
} from "@front-packages/dfa-gql-api";
import { UseFilesResult } from "hooks/files";
import { useErrors } from "hooks/errors";
import { useTypedSelector } from "reducers";
import { bottomCardStyle, middleCardStyle } from "./styles";
import { SetFieldType } from "../../types";

interface ICompanyInfoProps {
  data: GetCompanyInfoAdmin;
  setField: SetFieldType<GetCompanyInfoAdmin>;
  files: UseFilesResult;
  isLoading: boolean;
  saveCompanyData(): Promise<void>;
}

function CompanyInfo({ data, setField, files, isLoading, saveCompanyData }: ICompanyInfoProps) {
  const { isEdit } = useTypedSelector((state) => state.customer);

  const { t } = useTranslation();
  const { setError } = useErrors();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [txHash, setTxHash] = useState<string>(null);
  const [isProgressTx, setIsProgressTx] = useState<boolean>(false);
  const [validAbbr, setValidAbbr] = useState<string>("");

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setValidAbbr("");
  };
  const handleSetRole = async (abb: string, role: RoleGql) => {
    const edit = await CompaniesAPI.CompanyEditForm({
      companyID: data.id,
      form: { shortDesignationNameIssuer: abb },
      files: [],
    });
    if (edit.error) {
      setError(edit.error);
      setIsProgressTx(false);
    }
    if (edit.response) {
      const companyInfo = await CompaniesAPI.GetCompanyInfoAdmin({ companyID: data.id });
      if (companyInfo.error) {
        setError(edit.error);
        setIsProgressTx(false);
      }
      if (
        companyInfo.response &&
        companyInfo.response.ownerID &&
        companyInfo.response.wallet.address
      ) {
        const { error, response } = await TransactionsAPI.ACLSetRole({
          representativeOwnerID: companyInfo.response.ownerID,
          customerAddress: companyInfo.response.wallet.address,
          role,
        });
        if (error) {
          setError(error);
          setIsProgressTx(false);
        }
        if (response) setTxHash(response.result.hash);
      } else {
        setValidAbbr("Данный представитель не прошел онбординг");
        setIsProgressTx(false);
      }
    }
  };
  const confirm = async () => {
    setIsProgressTx(true);
    if (checked) {
      await handleSetRole(data.shortDesignationNameIssuer, RoleGql.Emitent).then(() =>
        saveCompanyData()
      );
    } else await handleSetRole("", RoleGql.Investor);
  };
  const handleOpenModal = (e: ChangeEvent<HTMLInputElement>) => {
    setIsOpenModal(true);
    setChecked(e.target.checked);
  };
  const handleSetAbb = (e: ChangeEvent<HTMLInputElement>) => {
    if ((e.target.value.length < 3 || e.target.value.length >= 5) && e.target.value.length > 0)
      setValidAbbr("Краткое наименование должно состоять из 3 или 4 символов");
    else if (/[^a-zA-Z]/.test(e.target.value))
      setValidAbbr("Краткое наименование должно содержать только латинские буквы");
    else setValidAbbr("");
    setField({ shortDesignationNameIssuer: e.target.value.toUpperCase() });
  };

  const checkTxStatus = async (): Promise<boolean> => {
    let isCompleted = false;
    const { response, error } = await TransactionsAPI.GetTxLogByHash({ hash: txHash });
    if (error) {
      setError(error);
      setIsProgressTx(false);
      return false;
    }
    if (response.status === TxStatusDict.InProgress || response.status === TxStatusDict.OnHold) {
      await delay(5000);
      isCompleted = await checkTxStatus();
    } else {
      isCompleted = response.status === TxStatusDict.Completed;
      handleCloseModal();
      setIsProgressTx(false);
    }
    return isCompleted;
  };

  const setTickerSymbols = async () => {
    await TokensAPI.SetCompanyTickerSymbols({
      companyID: data.id,
      ticker: data.shortDesignationNameIssuer,
    });
  };

  useEffect(() => {
    if (txHash)
      checkTxStatus().then(async (isCompleted) => {
        if (isCompleted) {
          await setTickerSymbols();
          setTxHash(null);
          window.location.reload();
        }
      });
  }, [txHash]);

  const isIP = data.entityType === ClientEntType.Physical;
  return (
    <>
      <Grid container columnSpacing={1.5}>
        <Grid item xs={6}>
          <DFACard title={t("layouts.card.clientCard.commonStates.title")}>
            <Grid container spacing={2} mt="1px" id="company-info__clientInfo">
              <Grid item xs={6}>
                <DFAInput
                  fullWidth
                  label={t("layouts.card.clientCard.entityAttr.nameAndINN.INN")}
                  value={data.inn || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setField({ inn: trimLetter(e.target.value, 12) })
                  }
                  id="company-inn"
                  disabled={!isEdit}
                  autoFocus
                  isLoading={isLoading}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <DFAInput
                  fullWidth
                  label={t("layouts.card.clientCard.entityAttr.nameAndINN.KPP")}
                  value={data.kpp || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setField({ kpp: trimLetter(e.target.value, 9) })
                  }
                  id="company-kpp"
                  disabled={!isEdit}
                  isLoading={isLoading}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <DFAInput
                  fullWidth
                  label={t("layouts.card.clientCard.entityAttr.nameAndINN.FullName")}
                  value={data.fullName || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setField({ fullName: e.target.value })
                  }
                  id="company-fullName"
                  disabled={!isEdit}
                  isLoading={isLoading}
                  required
                />
              </Grid>
              {!isIP && (
                <>
                  <Grid item xs={12}>
                    <DFAInput
                      fullWidth
                      label={t("layouts.card.clientCard.entityAttr.nameAndINN.name")}
                      value={data.shortName || ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setField({ shortName: e.target.value })
                      }
                      id="company-name"
                      disabled={!isEdit}
                      isLoading={isLoading}
                      required
                    />
                  </Grid>
                  {!data.isResident && (
                    <Grid item xs={12}>
                      <DFAInput
                        fullWidth
                        label={t("layouts.card.clientCard.entityAttr.nameAndINN.foreignName")}
                        value={data.foreignName || ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setField({ foreignName: e.target.value })
                        }
                        id="company-foreignName"
                        disabled={!isEdit}
                        isLoading={isLoading}
                      />
                    </Grid>
                  )}
                </>
              )}
              {isIP && (
                <>
                  <Grid item xs={6}>
                    <DFADatePicker
                      label={t("layouts.card.clientCard.IPAttr.nameAndINN.birthDate")}
                      value={data.birthday as any}
                      onChange={(date) => setField({ birthday: date })}
                      id="company-info__ip-birthday"
                      disabled={!isEdit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DFAInput
                      fullWidth
                      label={t("layouts.card.clientCard.IPAttr.nameAndINN.citizenship")}
                      // todo: после появления поля в базе добавить реальные value и onChange
                      value="mock citizenship"
                      // onChange={setCitizenship}
                      disabled={!isEdit}
                      id="company-info__ip-citizenship"
                      isLoading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DFAInput
                      fullWidth
                      label={t("layouts.card.clientCard.IPAttr.nameAndINN.name")}
                      value={data.shortName || ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setField({ shortName: e.target.value })
                      }
                      id="company-info__ip-name"
                      disabled={!isEdit}
                      isLoading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DFAInput
                      fullWidth
                      label={t("layouts.card.clientCard.IPAttr.nameAndINN.snils")}
                      value={data.snils || ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setField({ snils: trimLetter(e.target.value) })
                      }
                      id="company-info__ip-snils"
                      disabled={!isEdit}
                      isLoading={isLoading}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                {!isLoading ? (
                  <FormControl fullWidth>
                    <InputLabel id="company-type">
                      {`${t("layouts.card.clientCard.entityAttr.profileInfo.title")}*`}
                    </InputLabel>
                    <Select
                      sx={{ height: "3em" }}
                      value={data.entityType}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setField({ entityType: e.target.value as ClientEntType })
                      }
                      label={t("layouts.card.clientCard.entityAttr.profileInfo.title")}
                      disabled={!isEdit}
                      required
                    >
                      {CompanyTypes.map((el) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{ width: "100%", height: "47px", borderRadius: "7px" }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <DFAInput
                  fullWidth
                  label={t("layouts.card.clientCard.entityAttr.address.stringAddress")}
                  value={data.address || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setField({ address: e.target.value })
                  }
                  id="company-address"
                  disabled={!isEdit}
                  isLoading={isLoading}
                  required
                />
              </Grid>
            </Grid>
          </DFACard>
        </Grid>

        {isIP && (
          <Grid item xs={6}>
            <DFACard
              sx={{ ...middleCardStyle, marginBottom: 0 }}
              title={t("layouts.card.clientCard.IPAttr.StateRegData.title")}
            >
              <Grid container spacing={2} mt="1px" id="company-info__ip-stateRegData">
                <Grid item xs={12} lg={6}>
                  <DFAInput
                    fullWidth
                    label={t("layouts.card.clientCard.IPAttr.StateRegData.OGRNIP")}
                    value={data.ogrn || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setField({ ogrn: trimLetter(e.target.value, 15) })
                    }
                    id="company-info__ip-ogrnip"
                    disabled={!isEdit}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DFAInput
                    fullWidth
                    label={t("layouts.card.clientCard.IPAttr.StateRegData.StateRegPlace")}
                    // todo: после появления поля в базе добавить реальные value и onChange
                    value="mock state place of registration"
                    // onChange={(e: ChangeEvent<HTMLInputElement>) => setRegPlace(e, false)}
                    id="company-info__ip-stateRegPlace"
                    disabled={!isEdit}
                    isLoading={isLoading}
                  />
                </Grid>
              </Grid>
            </DFACard>
          </Grid>
        )}

        <Grid item xs={6} display="flex" flexDirection="column">
          <DFACard
            sx={{ flex: 2, mb: 1.5 }}
            title={t("layouts.card.clientCard.commonStates.clientOptions.title")}
          >
            <Grid container mt="1px" id="company-info__clientParams">
              <Grid item xs={12}>
                {!isLoading ? (
                  <DFACheckBox
                    label={t("layouts.card.clientCard.commonStates.clientOptions.isRusResident")}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setField({ isResident: e.target.checked })
                    }
                    checked={data.isResident}
                    disabled={!isEdit}
                  />
                ) : (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{
                      width: "50%",
                      minWidth: "100px",
                      height: "20px",
                      borderRadius: "7px",
                      mb: 3,
                      mt: 2,
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {!isLoading ? (
                  <DFACheckBox
                    label={t("layouts.card.clientCard.commonStates.clientOptions.isCreditOrg")}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setField({ isCreditOrganization: e.target.checked })
                    }
                    checked={data.isCreditOrganization}
                    disabled={!isEdit}
                  />
                ) : (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{
                      width: "50%",
                      minWidth: "100px",
                      height: "20px",
                      borderRadius: "7px",
                      mb: 3,
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {!isLoading ? (
                  <DFACheckBox
                    label="Допустить к размещению выпусков ЦФА"
                    onChange={handleOpenModal}
                    checked={data.representativeRole === RepresentativeRole.Emitent}
                    disabled={!isEdit}
                  />
                ) : (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{
                      width: "50%",
                      minWidth: "100px",
                      height: "20px",
                      borderRadius: "7px",
                      mb: 3,
                    }}
                  />
                )}
              </Grid>
              {data.representativeRole === RepresentativeRole.Emitent && (
                <Grid item xs={12} mt={1}>
                  <DFAInput
                    fullWidth
                    label="Краткое наименование эмитента"
                    value={data.shortDesignationNameIssuer || ""}
                    onChange={handleSetAbb}
                    disabled={!isEdit}
                    helperText={validAbbr}
                    error={!!validAbbr}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
            </Grid>
          </DFACard>
          {!isIP ? (
            <DFACard
              sx={bottomCardStyle}
              title={t("layouts.card.clientCard.entityAttr.stateRegData.title")}
            >
              <Grid container spacing={2} mt="1px" id="company-info__stateRegData">
                <Grid item xs={12} lg={6}>
                  <DFAInput
                    fullWidth
                    label={t("layouts.card.clientCard.entityAttr.stateRegData.OGRN")}
                    value={data.ogrn || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setField({ ogrn: trimLetter(e.target.value, 13) })
                    }
                    id="company-ogrn"
                    disabled={!isEdit}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DFAInput
                    fullWidth
                    label={t("layouts.card.clientCard.entityAttr.stateRegData.RegPlace")}
                    // todo: после появления поля в базе добавить реальные value и onChange
                    value="mock state place of registration"
                    // onChange={(e: ChangeEvent<HTMLInputElement>) => setRegPlace(e, false)}
                    id="company-regPlace"
                    disabled={!isEdit}
                    isLoading={isLoading}
                  />
                </Grid>
              </Grid>
            </DFACard>
          ) : (
            <DFACard sx={{ flex: 1 }} title={t("layouts.card.clientCard.IPAttr.idData.title")}>
              <Grid container spacing={2} mt="1px" id="company-data__">
                <Grid item lg={6} xs={12}>
                  <DFAInput
                    fullWidth
                    label={t("layouts.card.clientCard.IPAttr.idData.serial")}
                    // todo: после появления поля в базе добавить реальные value и onChange
                    value="mock serial number"
                    // onChange={setSerial}
                    id="company-info__ip-passport"
                    disabled={!isEdit}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <DFADatePicker
                    label={t("layouts.card.clientCard.IPAttr.idData.issueDate")}
                    // todo: после появления поля в базе добавить реальные value и onChange
                    value={null}
                    id="company-info__ip-birthday"
                    disabled={!isEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DFAInput
                    fullWidth
                    label={t("layouts.card.clientCard.IPAttr.idData.issuerName")}
                    // todo: после появления поля в базе добавить реальные value и onChange
                    value="mock issue name"
                    id="company-info__ip-issuerName"
                    disabled={!isEdit}
                    isLoading={isLoading}
                  />
                </Grid>
              </Grid>
            </DFACard>
          )}
        </Grid>
        <Grid item xs={12} mt={2}>
          <DFACard>
            <label htmlFor="upload-files">
              <input
                id="upload-files"
                multiple
                type="file"
                accept="text/*, image/*, application/pdf,doc,docx"
                onChange={files.multipleUpload}
                disabled={!isEdit}
              />
              <DFAButton
                onClick={() => document.getElementById("upload-files").click()}
                startIcon={<CloudUploadIcon />}
                variant="text"
                color="success"
                disabled={!isEdit}
              >
                Загрузить файлы компании
              </DFAButton>
            </label>
            {Object.entries(files.data).map((file, i) => (
              <DFABox key={file[0]} item display="flex" alignItems="center" mt={i !== 0 ? 1 : 0}>
                <DFALinearProgress
                  value={file[1].progress}
                  label={file[1].name}
                  type={file[1].error ? "error" : undefined}
                  onClick={() => files.del(file[1].key)}
                />
              </DFABox>
            ))}
          </DFACard>
        </Grid>
        <Grid item xs={12} mt={2}>
          <DFALoadedFiles filesArray={data?.associatedFiles} />
        </Grid>
      </Grid>
      <DFADialog
        open={isOpenModal}
        title='Подтвердите установку роли "Эмитент"'
        onConfirm={confirm}
        onClose={handleCloseModal}
        width="md"
        loading={isProgressTx}
        disabled={
          !!validAbbr ||
          !data.shortDesignationNameIssuer ||
          data?.shortDesignationNameIssuer?.length < 3 ||
          data?.shortDesignationNameIssuer?.length >= 5
        }
      >
        {data.representativeRole !== RepresentativeRole.Emitent && (
          <TextField
            fullWidth
            label="Краткое наименование эмитента"
            value={data.shortDesignationNameIssuer || ""}
            onChange={handleSetAbb}
            disabled={!isEdit}
            helperText={validAbbr}
            error={!!validAbbr}
          />
        )}
      </DFADialog>
    </>
  );
}

export default CompanyInfo;
