import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { palette } from "../../colors";
import { baseProperties } from "../../colors/typography";

interface MuiInputLabel {
  defaultProps?: ComponentsProps["MuiInputLabel"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiInputLabel"];
  variants?: ComponentsVariants["MuiInputLabel"];
}
const { text, success } = palette;
const { fontSizeSM, fontSizeMD, fontSizeXS } = baseProperties;

const inputLabel: MuiInputLabel = {
  styleOverrides: {
    root: {
      fontSize: fontSizeSM,
      color: text.primary,
      lineHeight: 0.9,

      "&.Mui-focused": {
        color: "light" in success ? success.light : "inherit",
      },

      "&.MuiInputLabel-shrink": {
        lineHeight: 1.5,
        fontSize: fontSizeMD,

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: "0.85em",
        },
      },
    },

    sizeSmall: {
      fontSize: fontSizeXS,
      lineHeight: 1.625,

      "&.MuiInputLabel-shrink": {
        lineHeight: 1.6,
        fontSize: fontSizeSM,

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: "0.72em",
        },
      },
    },
  },
};

export default inputLabel;
