export enum MsgHoldFiatEnum {
  receiverAddr = "receiverAddr",
  operationId = "operationId",
  holdDate = "holdDate",
  holdType = "holdType",
  holds = "holds",
}

export enum HoldsEnum {
  addr = "addr",
  amount = "amount",
}
