import React from "react";
import * as T from "./action";

export interface IThemeStyle {
  miniSidenav: boolean;
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  sidenavColor: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  openConfigurator: boolean;
  direction: string;
  layout: boolean | string;
  isDarkMode: boolean;
  breadcrumb: string;
  mainTitle: {
    title: string;
    chip: React.ReactNode;
    isLoading?: boolean;
  };
}

const initialState: IThemeStyle = {
  miniSidenav: false,
  transparentSidenav: false,
  whiteSidenav: false,
  sidenavColor: "success",
  transparentNavbar: true,
  fixedNavbar: true,
  openConfigurator: false,
  direction: "ltr",
  layout: "customers",
  isDarkMode: true,
  breadcrumb: null,
  mainTitle: {
    title: null,
    chip: null,
    isLoading: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case T.MINI_SIDENAV: {
      return { ...state, miniSidenav: action.value };
    }
    case T.TRANSPARENT_SIDENAV: {
      return { ...state, transparentSidenav: action.value };
    }
    case T.WHITE_SIDENAV: {
      return { ...state, whiteSidenav: action.value };
    }
    case T.TRANSPARENT_NAVBAR: {
      return { ...state, transparentNavbar: action.value };
    }
    case T.OPEN_CONFIGURATOR: {
      return { ...state, openConfigurator: action.value };
    }
    case T.MODE: {
      return { ...state, isDarkMode: action.value };
    }
    case T.BREADCRUMB: {
      return { ...state, breadcrumb: action.value };
    }
    case T.TITLE: {
      return { ...state, mainTitle: { ...state.mainTitle, ...action.value } };
    }
    // todo: not used
    // case T.SIDENAV_COLOR: {
    //   return { ...state, sidenavColor: action.value };
    // }
    // case T.FIXED_NAVBAR: {
    //   return { ...state, fixedNavbar: action.value };
    // }
    // case T.DIRECTION: {
    //   return { ...state, direction: action.value };
    // }
    // case T.LAYOUT: {
    //   return { ...state, layout: action.value };
    // }
    default:
      return state;
  }
}
