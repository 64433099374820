import { UseFilesResult } from "hooks/files";
import React from "react";
import { DFABox, DFADropZone, DFAFile, DFALinearProgress } from "Theme";
import { Grid, Typography } from "@mui/material";

interface IDropFileAreaProps {
  isEdit: boolean;
  files: UseFilesResult;
}

function DropFileArea({ isEdit, files }: IDropFileAreaProps) {
  const deleteFile = (key: string) => files.del(key);
  return (
    <Grid container spacing={2}>
      <Grid item xs={Object.entries(files.data).length ? 10 : 12}>
        <DFADropZone files={files} isEdit={isEdit} />
      </Grid>
      {Object.entries(files.data).map((file) => (
        <Grid key={file[0]} item xs={2} display="flex" alignItems="center" minWidth="150px">
          {!file[1].name && (
            <DFABox sx={{ display: "flex", flexDirection: "column" }}>
              <DFALinearProgress
                value={file[1].progress}
                label={file[1].name}
                type={file[1].error ? "error" : undefined}
                onClick={() => files.del(file[1].key)}
              />
              <Typography>Файлы загружаются...</Typography>
            </DFABox>
          )}
          {file[1].name && <DFAFile name={file[1].name} onDelete={() => deleteFile(file[1].key)} />}
        </Grid>
      ))}
    </Grid>
  );
}

export default DropFileArea;
