import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { TokenTypeEnum } from "@front-packages/dfa-constants";
import { DFAChip, DFADialog, DFAFile } from "Theme";
import { FilesAPI, File, TokenOrder } from "@front-packages/dfa-gql-api";
import { useStatuses } from "hooks/other";
import { useErrors } from "hooks/errors";
import { RouteNamesEnum } from "consts";

interface IOrderViewDialog {
  open: boolean;
  onClose(): void;
  order: TokenOrder;
  withIssueLink?: boolean;
}

function OrderViewDialog({ open, order, onClose, withIssueLink = true }: IOrderViewDialog) {
  const [orderFiles, setOrderFiles] = useState<File[]>(null);
  const getStatusBadgeProps = useStatuses();
  const { setError } = useErrors();
  const nav = useNavigate();
  const goToToken = () => nav(`${RouteNamesEnum.checkEmission}?id=${order.tokenID}`);
  const getOrderFiles = async () => {
    const { response, error } = await FilesAPI.GetOrderFiles({ orderID: order?.id });
    if (error) setError(error);
    if (response) setOrderFiles(response);
  };
  useEffect(() => {
    order && getOrderFiles();
  }, [order]);
  if (!order) return null;
  return (
    <DFADialog
      open={open}
      onClose={onClose}
      onConfirm={withIssueLink && goToToken}
      confirmText={withIssueLink && "Информация о выпуске"}
    >
      <Grid container rowSpacing={2} p={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" mr={1}>
            Информация о заявке
          </Typography>
          <b>
            <DFAChip
              size="small"
              variant="filled"
              icon="none"
              {...getStatusBadgeProps(order?.status, "Order")}
              type={getStatusBadgeProps(order?.status, "Order").color as any}
            />
          </b>
          {/* <DFAButton onClick={goToToken} sx={{ p: 0 }} variant="text" color="success">
            Информация о выпуске
          </DFAButton> */}
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography variant="body1">
            <b>Подана:</b>&nbsp;&nbsp;
            {order?.createDate
              ? new Date(order?.createDate).toLocaleString("ru", { dateStyle: "short" })
              : "Нет данных"}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} display="flex">
          <Typography variant="body1">
            <b>Обработана:</b>&nbsp;&nbsp;
            {new Date(order.handlingDate || mock.cd).toLocaleString("ru", { dateStyle: "short" })}
          </Typography>
        </Grid> */}
        <Grid item xs={12} display="flex">
          <Typography variant="body1">
            <b>Инвестор:</b>&nbsp;&nbsp;
            {order?.investor?.fullName ? order.investor.fullName : "Нет данных"}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography component="div" variant="body1" display="flex">
            <b>Представитель инвестора:</b>&nbsp;&nbsp;
            <Typography color="success.main">
              {/* TODO: Добавить ФИО представителя */}
              {order?.investor?.representativeID ? order.investor.representativeID : "Нет данных"}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography variant="body1">
            <b>Адрес:</b>&nbsp;&nbsp;
            {order?.investor?.address ? order.investor.address : "Нет данных"}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography component="div" variant="body1">
            <b>Тикер:</b>&nbsp;&nbsp;
            {order.token.ticker.toUpperCase()}
            &nbsp;&nbsp;
            <b>
              <DFAChip
                size="small"
                type="success"
                icon="none"
                variant="filled"
                label={TokenTypeEnum[order.token.tokenType] || TokenTypeEnum.TokenDVP}
              />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography variant="body1">
            <b>Номинал:</b>&nbsp;&nbsp;
            {order.token.nominal} RUB
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography variant="body1">
            <b>Количество:</b>&nbsp;&nbsp;
            {order.amount} ШТ
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography variant="body1">
            <b>Сумма заявки:</b>&nbsp;&nbsp;
            {order.amount * order.token.nominal || 0} RUB
          </Typography>
        </Grid>
        {orderFiles && orderFiles.length && (
          <Grid item xs={12} display="flex" columnGap={1}>
            {orderFiles.map((file) => (
              <DFAFile name={file?.name} url={file?.url} />
            ))}
          </Grid>
        )}
      </Grid>
    </DFADialog>
  );
}

export default OrderViewDialog;
