import React from "react";
import { Grid } from "@mui/material";
import { DFACard, DFAEntityParams } from "Theme";
import { Token } from "@front-packages/dfa-gql-api";

interface ITokenCardProps {
  issue: Token;
}

function TokenCard({ issue }: ITokenCardProps) {
  return (
    <DFACard sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <DFAEntityParams label="тикер" text={issue.ticker} textSize="large" />
        </Grid>
        <Grid item xs={2}>
          <DFAEntityParams label="тип" text={issue.tokenType} textSize="large" />
        </Grid>
        <Grid item xs={2}>
          <DFAEntityParams label="номинал" text={issue.nominal} textSize="large" />
        </Grid>
        <Grid item xs={2}>
          <DFAEntityParams
            label="количество"
            text={issue.maxIssueLimit / issue.nominal}
            textSize="large"
          />
        </Grid>
        <Grid item xs={2}>
          <DFAEntityParams label="объем выпуска" text={issue.maxIssueLimit} textSize="large" />
        </Grid>

        <Grid item xs={4}>
          <DFAEntityParams
            label="мин. процент выкупа"
            text={issue.minIssueLimit}
            textSize="large"
          />
        </Grid>
        <Grid item xs={2}>
          <DFAEntityParams label="вид прав" text={issue.rightsType} textSize="large" />
        </Grid>
        <Grid item xs={2}>
          <DFAEntityParams
            label="Минимальная сумма заявки"
            text={issue.minOrderLimit}
            textSize="large"
          />
        </Grid>
        <Grid item xs={2}>
          <DFAEntityParams
            label="Максимальная сумма заявки"
            text={issue.maxOrderLimit}
            textSize="large"
          />
        </Grid>
        <Grid item xs={2}>
          <DFAEntityParams label="Процент дохода" text={issue.interest} textSize="large" />
        </Grid>
      </Grid>
    </DFACard>
  );
}

export default TokenCard;
