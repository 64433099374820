const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export default breakpoints;
//
// type BreakpointsCSSType = {
//   SM: string;
//   MD: string;
//   LG: string;
//   XL: string;
// };
//
// export const BreakpointsCSS: BreakpointsCSSType = {
//   SM: `@media (max-width: ${breakpoints.values.sm}px)`,
//   MD: `@media (max-width: ${breakpoints.values.md}px)`,
//   LG: `@media (max-width: ${breakpoints.values.lg}px)`,
//   XL: `@media (max-width: ${breakpoints.values.xl}px)`,
// };
