import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { ErrorsEnum, TabsLabelType } from "@front-packages/dfa-constants";
import { DFABox, DFAButton, DFAChip, DFADialog, DFASnackbar, DFATabsBar } from "Theme";
import {
  ClientStatus,
  CompaniesAPI,
  GetCompanyInfoAdmin,
  MutationChangeCompanyStatusArgs,
  RepresentativesAPI,
  RepresentativeStatusGql,
  DeactivationAPI,
} from "@front-packages/dfa-gql-api";
import { useStatuses } from "hooks/other";
import { useErrors } from "hooks/errors";
import { useFiles } from "hooks/files";
import { setBreadcrumb, setMainTitle } from "reducers/layout/action";
import { useTypedSelector } from "reducers";
import { RouteNamesEnum } from "consts";
import ExtraData from "./components/ExtraData";
import RepresentativeCard from "./components/RepresentativeInfo/components/Representative";
import ServiceInfo from "./components/ServiceInfo";
import { CompanyInfo } from "./components";
import useCompanyInfo from "./useCompanyInfo";
import { initState as initCompanyState } from "./useCompanyInfo/reducer";
import AccsAndOperations from "./components/AccsAndOperations";
import {
  setRepresentativeID,
  setRepresentativeStatus,
  setIsEdit,
  setLoading,
} from "../../reducer/actions";

function CustomerCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { t } = useTranslation();
  const { isEdit, loading } = useTypedSelector((state) => state.customer);

  const [isNew, setIsNew] = useState<boolean>(false);
  const [modals, setModals] = useState<{
    blocked: boolean;
    active: boolean;
    deactivation: boolean;
  }>({
    blocked: false,
    active: false,
    deactivation: false,
  });
  const { representativeID, representativeStatus } = useTypedSelector((state) => state.customer);
  const [isCompanyDataLoading, setIsCompanyDataLoading] = useState<boolean>(false);
  const [isLoadStatus, setIsLoadStatus] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isCancelOpenModal, setCancelOpenModal] = useState<boolean>(false);
  const [isRepresentativeNotVerify, setIsRepresentativeNotVerify] = useState<string>("");
  const [company, setCompanyField, setAllCompanyInfo] = useCompanyInfo();
  const [isCompanyExist, setIsCompanyExist] = useState<boolean>(true);
  const initCompanyData = useRef<GetCompanyInfoAdmin>(initCompanyState);
  const companyFiles = useFiles();
  const { errorMessage, setError } = useErrors();
  const getStatusBadgeProps = useStatuses();

  const handleCloseModal = () => {
    setModals({ blocked: false, active: false, deactivation: false });
    dispatch(setLoading(false));
    setIsLoadStatus(false);
  };
  const handleOpenActiveModal = () => setModals({ ...modals, active: true });
  const handleOpenBlockedModal = () => setModals({ ...modals, blocked: true });
  const handleOpenDeactivationModal = () => setModals({ ...modals, deactivation: true });
  const handleCloseError = () => {
    if (isRepresentativeNotVerify) setIsRepresentativeNotVerify("");
    setError("");
  };
  const goToList = () => navigate(RouteNamesEnum.customers);
  const handleCancel = () => {
    if (isNew) goToList();
    else if (!isEdit) {
      dispatch(setIsEdit(!isEdit));
    } else {
      setCancelOpenModal(true);
    }
  };
  const handleCancelOpenModal = () => {
    setAllCompanyInfo(initCompanyData.current);
    setCancelOpenModal(false);
    dispatch(setIsEdit(false));
  };
  const getCompanyByID = async () => {
    setIsCompanyDataLoading(true);
    dispatch(setMainTitle({ isLoading: true }));
    dispatch(setBreadcrumb("Loading..."));
    const companyID = search.split("=")[1];
    const { response, error } = await CompaniesAPI.GetCompanyInfoAdmin({ companyID });
    if (error) {
      setIsCompanyExist(false);
      setError(error);
      dispatch(setBreadcrumb(null));
      dispatch(setMainTitle({ title: "Регистрация клиента", isLoading: true }));
    }
    if (response) {
      initCompanyData.current = response;
      dispatch(setRepresentativeID(response.representativeID));
      setAllCompanyInfo({
        ...response,
        id: companyID,
      });
      if (!response.representativeID) {
        setTabValue(2);
        dispatch(setIsEdit(true));
      }
      dispatch(
        setMainTitle({
          title: `${response.inn} / ${response.fullName}`,
          chip: (
            <DFAChip
              {...getStatusBadgeProps(response.status, "Company")}
              type={getStatusBadgeProps(response.status, "Company").color as any}
            />
          ),
          isLoading: false,
        })
      );
      dispatch(setBreadcrumb(response.fullName));
      setIsCompanyDataLoading(false);
    }
  };
  const saveCompanyData = async () => {
    dispatch(setLoading(true));
    if (isNew) {
      const { response, error } = await CompaniesAPI.AddCompany({
        inn: company.inn,
        kpp: company.kpp,
        files: [],
      });
      if (error) {
        setError(error);
        dispatch(setLoading(false));
      }
      if (response) {
        navigate(`${RouteNamesEnum.customersCard}?id=${response}`);
        window.location.reload();
      }
    } else {
      const { response, error } = await CompaniesAPI.CompanyEditForm({
        companyID: company.id,
        form: {
          ...(company.shortName && { shortName: company.shortName }),
          ...(company.fullName && { fullName: company.fullName }),
          ...(company.foreignName && { foreignName: company.foreignName }),
          ...(company.kpp && { kpp: company.kpp }),
          ...(company.snils && { snils: company.snils }),
          ...(company.ogrn && { ogrn: company.ogrn }),
          ...(company.birthday && { birthday: company.birthday }),
          ...(company.okpo && { okpo: company.okpo }),
          ...(company.countryCode && { countryCode: company.countryCode }),
          ...(company.entityType && { entityType: company.entityType }),
          ...(company.businessEntityType && { businessEntityType: company.businessEntityType }),
          isResident: company.isResident,
          isCreditOrganization: company.isCreditOrganization,
          legalEntityStructure: "",
        } as any,
        files: Object.values(companyFiles?.data).map((el) => {
          return { key: el.key, name: el.name };
        }),
      });
      if (error) {
        setError(error);
        dispatch(setLoading(false));
      }
      if (response) {
        await getCompanyByID();
        dispatch(setIsEdit(false));
        dispatch(setLoading(false));
        companyFiles.reset();
      }
    }
  };
  const changeRepresentativeStatus = async (s: RepresentativeStatusGql) => {
    if (representativeID) {
      const { error, response } = await RepresentativesAPI.ChangeRepresentativeStatus({
        representativeID,
        status: s,
      });
      if (error) {
        setError(error);
        return;
      }
      if (response) {
        dispatch(setRepresentativeStatus(s));
      }
    } else {
      setError(ErrorsEnum.YouCannotSetThisStatus);
    }
  };
  const deactivateCompany = async () => {
    setIsLoadStatus(true);
    const { response, error } = await DeactivationAPI.CreateDeactivationOrder({
      form: { clientID: company.id },
    });
    if (error) {
      setError(error);
    }
    if (response) {
      handleCloseModal();
    }
    setIsLoadStatus(false);
  };
  const changeCompanyStatus = async () => {
    setIsLoadStatus(true);
    let representativeS: RepresentativeStatusGql = null;
    const variables: MutationChangeCompanyStatusArgs = {
      companyID: company.id,
      status: null,
    };
    switch (company.status) {
      case ClientStatus.New: {
        variables.status = ClientStatus.KybNeeded;
        break;
      }
      case ClientStatus.KybNeeded: {
        variables.status = ClientStatus.KybInProcess;
        break;
      }
      case ClientStatus.KybInProcess: {
        if (modals.active) {
          variables.status = ClientStatus.KybDone;
          if (representativeID) representativeS = RepresentativeStatusGql.Invited;
        } else {
          variables.status = ClientStatus.Blocked;
        }
        break;
      }
      case ClientStatus.KybDone: {
        if (representativeStatus === RepresentativeStatusGql.New) {
          variables.status = ClientStatus.Active;
          representativeS = RepresentativeStatusGql.Invited;
        }
        if (representativeStatus === RepresentativeStatusGql.Initiating) {
          variables.status = ClientStatus.Active;
          representativeS = RepresentativeStatusGql.Active;
        }
        break;
      }
      case ClientStatus.Blocked: {
        variables.status = ClientStatus.Active;
        representativeS = RepresentativeStatusGql.Active;
        break;
      }
      case ClientStatus.Active: {
        representativeS = RepresentativeStatusGql.Blocked;
        variables.status = ClientStatus.Blocked;
        break;
      }
      default:
        break;
    }
    if (!variables.status) {
      setIsLoadStatus(false);
      handleCloseModal();
      setIsRepresentativeNotVerify("Представитель не завершил регистрацию");
      return;
    }
    const { response, error } = await CompaniesAPI.ChangeCompanyStatus(variables);
    if (error) setError(error);
    if (response) {
      if (representativeS) {
        await changeRepresentativeStatus(representativeS);
      }
      dispatch(
        setMainTitle({
          chip: (
            <DFAChip
              {...getStatusBadgeProps(variables.status, "Company")}
              type={getStatusBadgeProps(variables.status, "Company").color as any}
            />
          ),
        })
      );
      // TODO: Genapi as ClientStatus. Надо доработать типизацию на мидле
      setCompanyField({ status: variables.status as ClientStatus });
    }
    handleCloseModal();
  };

  useEffect(() => {
    if (search) {
      dispatch(setIsEdit(false));
      getCompanyByID();
    } else {
      setIsNew(true);
      dispatch(setIsEdit(true));
    }
    return () => {
      dispatch(setMainTitle({ title: null, chip: null, isLoading: false }));
      dispatch(setBreadcrumb(null));
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setRepresentativeStatus(null));
    };
  }, []);

  const tabsProps = {
    data: null,
    isEdit,
    handlers: null,
  };
  const tabs = {
    0: (
      <CompanyInfo
        isLoading={isCompanyDataLoading}
        files={companyFiles}
        data={company}
        setField={setCompanyField}
        saveCompanyData={saveCompanyData}
      />
    ),
    1: <ExtraData {...tabsProps} />,
    2: !isNew && <RepresentativeCard />,
    3: <ServiceInfo {...tabsProps} />,
    4: <AccsAndOperations companyID={company.id} representativeID={representativeID} />,
  };
  const labelTabs: TabsLabelType[] = [
    {
      key: 0,
      text: t("layouts.card.tabsTitles.clientInfo"),
    },
    {
      key: 1,
      text: t("layouts.card.tabsTitles.extraData"),
    },
    {
      key: 2,
      text: t("layouts.card.tabsTitles.affilatesData"),
    },
    {
      key: 3,
      text: t("layouts.card.tabsTitles.serviceData"),
    },
    {
      key: 4,
      text: t("layouts.card.tabsTitles.accsAndOperations"),
    },
    {
      key: 5,
      text: t("layouts.card.tabsTitles.orders"),
    },
  ];

  const showBlockedBtn =
    company.status === ClientStatus.KybInProcess || company.status === ClientStatus.Active;
  const showBtn =
    company.status === ClientStatus.New ||
    company.status === ClientStatus.KybNeeded ||
    company.status === ClientStatus.KybInProcess ||
    company.status === ClientStatus.Blocked ||
    (company.status === ClientStatus.KybDone &&
      representativeStatus === RepresentativeStatusGql.Initiating);

  return (
    <>
      <DFABox sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "space-between" }}>
        <DFAButton
          startIcon={<ArrowBackIcon />}
          variant="outlined"
          color="success"
          sx={(theme: Theme) => ({ color: theme.palette.success.main })}
          onClick={goToList}
        >
          Назад к списку
        </DFAButton>
        <DFABox sx={{ display: "flex", gap: 2 }}>
          {/* {(isLoadStatus || loading) && <CircularProgress color="success" />} */}
          {(isLoadStatus || loading || isCompanyDataLoading) && (
            <CircularProgress color="success" />
          )}
          {!isCompanyDataLoading && search && (
            <>
              {showBtn && (
                <DFAButton
                  variant="contained"
                  color={company.status === ClientStatus.KybDone ? "info" : "success"}
                  onClick={handleOpenActiveModal}
                  id="saveCompanyData"
                  sx={{ display: isCompanyExist ? "block" : "none" }}
                >
                  {company.status === ClientStatus.New && "Отправить на проверку"}
                  {company.status === ClientStatus.KybNeeded && "Начать проверку"}
                  {company.status === ClientStatus.KybInProcess && "Проверка пройдена"}
                  {company.status === ClientStatus.Blocked && "Актививровать"}
                  {company.status === ClientStatus.KybDone &&
                    representativeStatus === RepresentativeStatusGql.Initiating &&
                    "Завершить регистрацию компании"}
                </DFAButton>
              )}
              {company.status === ClientStatus.Active && (
                <DFAButton
                  variant="outlined"
                  color="error"
                  onClick={handleOpenDeactivationModal}
                  id="blockCompany"
                  sx={{ display: isCompanyExist ? "block" : "none" }}
                >
                  Деактивировать
                </DFAButton>
              )}
              {showBlockedBtn && (
                <DFAButton
                  variant="contained"
                  color="error"
                  onClick={handleOpenBlockedModal}
                  id="blockCompany"
                  sx={{ display: isCompanyExist ? "block" : "none" }}
                >
                  {company.status === ClientStatus.Active ? "Заблокировать" : "Отклонить проверку"}
                </DFAButton>
              )}
            </>
          )}
          {!isNew && (
            <DFAButton
              variant="outlined"
              color="warning"
              onClick={handleCancel}
              id="editCompanyData"
              sx={{ display: isCompanyExist ? "block" : "none" }}
            >
              {isEdit ? t("general.buttons.cancel") : t("general.buttons.edit")}
            </DFAButton>
          )}
          <DFADialog
            open={isCancelOpenModal}
            onClose={() => setCancelOpenModal(false)}
            onConfirm={handleCancelOpenModal}
            title={t(`general.cancelDialog.title`)}
            dialogText={t(`general.cancelDialog.text`)}
            confirmText={t("general.cancelDialog.close")}
            cancelText={t("general.cancelDialog.cancel")}
          />
        </DFABox>
      </DFABox>
      <Box sx={{ width: "100%", marginBottom: isEdit ? 1 : 5 }}>
        <Box sx={{ marginBottom: 2 }}>
          {isCompanyExist ? (
            <DFATabsBar tabValue={tabValue} setTabValue={setTabValue} labels={labelTabs} />
          ) : null}
        </Box>
        {isCompanyExist && tabs[tabValue]}
      </Box>
      {isEdit && tabValue !== 2 && (
        <DFABox sx={{ display: "flex", justifyContent: "flex-end", mt: "auto", mb: 4 }}>
          <DFAButton
            variant="contained"
            color="success"
            id="saveCompanyData"
            onClick={saveCompanyData}
            loading={loading}
          >
            {isNew ? t("general.buttons.register") : t("general.buttons.save")}
          </DFAButton>
        </DFABox>
      )}
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errorMessage || isRepresentativeNotVerify}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage || !!isRepresentativeNotVerify}
        onClose={handleCloseError}
        close={handleCloseError}
      />
      <DFADialog
        loading={isLoadStatus}
        type={modals.active ? "success" : "error"}
        open={modals.active || modals.blocked}
        title={
          (company.status === ClientStatus.New && "Подтвердите отправку компании на проверку") ||
          (company.status === ClientStatus.KybNeeded && "Подтвердите начало проверки компании") ||
          (company.status === ClientStatus.KybInProcess &&
            modals.active &&
            "Подтвердите успешное прохождение проверки компании") ||
          (company.status === ClientStatus.KybInProcess &&
            modals.blocked &&
            "Подтвердите отклонение проверки компании") ||
          (company.status === ClientStatus.KybDone &&
            "Подтвердите завершение регистрации компании") ||
          (company.status === ClientStatus.Blocked && "Подтвердите активацию клиента") ||
          (company.status === ClientStatus.Active && "Подтвердите блокировку клиента")
        }
        onClose={handleCloseModal}
        onConfirm={changeCompanyStatus}
      >
        {company.status === ClientStatus.KybInProcess &&
          modals.active &&
          "При подтверждении успешного прохождения проверки, представителю будет отправлено приглашение на регистрацию в личном кабинете Платформы"}
      </DFADialog>
      <DFADialog
        loading={isLoadStatus}
        type="error"
        open={modals.deactivation}
        title="Деактировать учетную запись"
        onClose={handleCloseModal}
        onConfirm={deactivateCompany}
      >
        Подтвердите деактивацию компании
      </DFADialog>
    </>
  );
}

export default CustomerCard;
