import { IColors } from "../../typings";

const colors: IColors = {
  gradients: {
    primary: {
      dark: "#3CA653",
      light: "#53E572",
    },

    secondary: {
      dark: "#8D94A1",
      light: "#9D9EA0",
    },

    info: {
      dark: "#079CF2",
      light: "#1FA6F2",
    },

    success: {
      dark: "#3CA653",
      light: "#53E572",
    },

    warning: {
      dark: "#FC993D",
      light: "#FCA656",
    },
    error: {
      dark: "#FC3D3D",
      light: "#FC5656",
    },

    light: {
      light: "#EBEFF4",
      dark: "#CED4DA",
    },

    dark: {
      light: "#373737",
      dark: "#222322",
    },
  },

  socialMediaColors: {
    facebook: {
      light: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      light: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      light: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      light: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      light: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      light: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      light: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      light: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      light: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      light: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      light: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      light: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",
  transparent: "transparent",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;
