/*
 Developed by ST soft team
 */

import Avatar from "@mui/material/Avatar";
import { styled, Theme } from "@mui/material/styles";

interface IOwnerState {
  shadow: any;
  bgColor: any;
  size: any;
}

interface IStyledBox {
  theme?: Theme;
  ownerState?: IOwnerState;
}

export default styled(Avatar)<IStyledBox>(({ theme, ownerState }) => {
  const { palette, other, typography } = theme;
  const { colors, helpers, baseTypographyProp, shadows } = other;
  const { shadow, bgColor, size } = ownerState;

  const { white } = palette.common;
  const { gradients, transparent } = colors;
  const { linearGradient } = helpers;
  const { fontSizeMD, fontSizeSM, fontSizeXS } = baseTypographyProp;
  const { fontWeightRegular } = typography;

  // backgroundImage value
  const backgroundValue =
    bgColor === "transparent"
      ? transparent
      : linearGradient(gradients[bgColor].main, gradients[bgColor].state);

  // size value
  let sizeValue;

  switch (size) {
    case "xs":
      sizeValue = {
        width: "24px",
        height: "24px",
        fontSize: fontSizeXS,
      };
      break;
    case "sm":
      sizeValue = {
        width: "36px",
        height: "36px",
        fontSize: fontSizeSM,
      };
      break;
    case "lg":
      sizeValue = {
        width: "58px",
        height: "58px",
        fontSize: fontSizeSM,
      };
      break;
    case "xl":
      sizeValue = {
        width: "74px",
        height: "74px",
        fontSize: fontSizeMD,
      };
      break;
    case "xxl":
      sizeValue = {
        width: "110px",
        height: "110px",
        fontSize: fontSizeMD,
      };
      break;
    default: {
      sizeValue = {
        width: "48px",
        height: "48px",
        fontSize: fontSizeMD,
      };
    }
  }

  return {
    background: backgroundValue,
    color: white,
    fontWeight: fontWeightRegular,
    boxShadow: shadows[shadow],
    ...sizeValue,
  };
});
