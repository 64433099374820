/**
 DFA Platform Admin
 ***
 Developed by ST soft team
 */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  CircularProgress,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { countList, MenuProps } from "@front-packages/dfa-constants";
import { DFAAlert, DFASnackbar, DFADatePicker } from "Theme";
import { Tx, BlocksResponse } from "@front-packages/dfa-gql-api";
import useBlocksTx from "hooks/txAndBlocks";
import { useTypedSelector } from "reducers";
import { setBlockedPages } from "reducers/auth/action";
import BlockChainCard from "./components/BlockChainCard";
import DialogTx from "./components/DialogTx";

function BlockChain() {
  const dispatch = useDispatch();
  const {
    variables: [date, page, count],
    state,
    error: { errorMessage, setError },
    loading,
  } = useBlocksTx<BlocksResponse>({ initState: { count: 0, blocks: null }, isBlocks: true });
  const [txDetail, setTxDetail] = useState<Tx>(null);
  const { blockedPages } = useTypedSelector(({ auth }) => auth);
  const { t } = useTranslation();
  const route = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "")[0];
  useEffect(
    () => () => {
      dispatch(setBlockedPages({ blockchain: false }));
    },
    []
  );

  const handleCloseSnackbar = () => setError("");

  if (blockedPages[route]) {
    return (
      <Box>
        <DFAAlert type="error" title={errorMessage} />
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={2}>
          <FormControl sx={{ height: 50 }} fullWidth>
            <InputLabel id="countCardsLabel">
              {t("layouts.blockchain.blocks_per_page_count")}
            </InputLabel>
            <Select
              labelId="countCardsLabel"
              id="countCardsSelect"
              value={count.value}
              variant="filled"
              onChange={count.setValue}
              input={<OutlinedInput label={t("layouts.blockchain.blocks_per_page_count")} />}
              MenuProps={MenuProps}
              style={{ lineHeight: "2rem", height: 45 }}
            >
              {countList.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <DFADatePicker
            label={t("layouts.transactions.date")}
            value={date.value}
            onChange={date.setValue}
          />
        </Grid>
        <Grid item xs={2}>
          {loading && <CircularProgress color="success" />}
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="left" direction="row">
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {state.blocks &&
            state.blocks.map((block) => (
              <Grid key={block.id} item xs={6}>
                <BlockChainCard block={block} setTxDetail={setTxDetail} />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" marginTop={5} marginBottom={5}>
        <Grid item xs={12}>
          {state.count > count.value && (
            <Pagination
              count={Math.ceil(state.count / count.value)}
              page={page.value + 1}
              shape="rounded"
              onChange={(_, currentPage) => page.setValue(currentPage)}
            />
          )}
        </Grid>
      </Grid>
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errorMessage}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage}
        onClose={handleCloseSnackbar}
        close={handleCloseSnackbar}
      />
      <DialogTx tx={txDetail} closeDialog={() => setTxDetail(null)} />
    </Box>
  );
}

export default BlockChain;
