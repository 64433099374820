import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiListItemText {
  defaultProps?: ComponentsProps["MuiListItemText"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiListItemText"];
  variants?: ComponentsVariants["MuiListItemText"];
}

const listItemText: MuiListItemText = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};

export default listItemText;
