import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiCardContent {
  defaultProps?: ComponentsProps["MuiCardContent"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiCardContent"];
  variants?: ComponentsVariants["MuiCardContent"];
}

const cardContent: MuiCardContent = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      padding: "8px 24px 24px",
    },
  },
};

export default cardContent;
