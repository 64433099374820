import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CookieNamesEnum, regCleanPhone } from "@front-packages/dfa-constants";
import { setCookie } from "@front-packages/dfa-helpers";
import { AuthAPI, MfaParams, UserClientType } from "@front-packages/dfa-gql-api";
import { setIsAuth } from "reducers/auth/action";
import { setLoadingFalse, setLoadingTrue } from "reducers/loader/actions";
import { useErrors } from "../errors";

type LoginType = () => Promise<MfaParams>;
type LogoutType = () => Promise<void>;
type ValueType = (str: string) => void;
type SendCodeType = (code: string, sessionID: string, to: string) => Promise<void>;

interface IAuth {
  (): {
    get: {
      phoneNumber: string;
      password: string;
      verifyPass: string;
      name: string;
      errorMessage: string;
      uid: string;
    };
    set: {
      password: ValueType;
      verifyPass: ValueType;
      phoneNumber: ValueType;
      name: ValueType;
    };
    login: LoginType;
    logout: LogoutType;
    sendCode: SendCodeType;
    registration: () => Promise<boolean>;
    regAndLogin: LoginType;
  };
}

const useAuth: IAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [uid, setUid] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [verifyPass, setVerifyPass] = useState<string>("");

  const { setError, errorMessage } = useErrors();

  const registration = async (): Promise<boolean> => {
    const { response, error } = await AuthAPI.SignUp({
      password,
      phoneNumber: phoneNumber.replace(regCleanPhone, ""),
      isRepresentative: false,
      fullName: name,
    });
    if (error) {
      setError(error);
      return false;
    }
    return response;
  };

  const sendCode: SendCodeType = async (code, sessionID, to) => {
    const {
      response: { accessToken, refreshToken },
      error,
    } = await AuthAPI.Verify2FACode({
      phoneNumber: phoneNumber.replace(regCleanPhone, ""),
      code,
      uid,
      clientType: UserClientType.Admin,
      sessionID,
    });
    if (error) {
      setError(error);
      return;
    }
    setCookie(CookieNamesEnum.token, accessToken);
    setCookie(CookieNamesEnum.refreshToken, refreshToken);
    setCookie(CookieNamesEnum.user, phoneNumber);
    dispatch(setIsAuth(true));
    navigate(to);
  };

  const login: LoginType = async () => {
    const SignIn = await AuthAPI.SignIn({
      password,
      phoneNumber: phoneNumber.replace(regCleanPhone, ""),
    });
    if (SignIn?.error) {
      setError(SignIn.error);
      return {
        code: "",
        sessionID: "",
      };
    }
    setUid(SignIn.response);
    const Send2FACode = await AuthAPI.Send2FACode({
      phoneNumber: phoneNumber.replace(regCleanPhone, ""),
      uid: SignIn.response,
    });
    if (Send2FACode?.error) setError(Send2FACode.error);
    if (SignIn?.error || Send2FACode?.error)
      return {
        code: "",
        sessionID: "",
      };
    return Send2FACode.response;
  };

  const logout: LogoutType = async () => {
    dispatch(setLoadingTrue());
    const { response, error } = await AuthAPI.SignOut();
    if (error) {
      setError(error);
      dispatch(setLoadingFalse());
      return;
    }
    if (response) dispatch(setIsAuth(false));
  };

  const regAndLogin: LoginType = async () => {
    const isReg = await registration();
    if (isReg) return login();
    return {
      code: "",
      sessionID: "",
    };
  };

  return {
    get: { phoneNumber, password, errorMessage, name, verifyPass, uid },
    set: {
      password: setPassword,
      phoneNumber: setPhoneNumber,
      name: setName,
      verifyPass: setVerifyPass,
    },
    login,
    logout,
    sendCode,
    registration,
    regAndLogin,
  };
};

export default useAuth;
