import React, { useState } from "react";
import { Grid } from "@mui/material";
import { DFACard, DFAChip, DFAEntityParams } from "Theme";
import { Theme } from "@mui/material/styles";
import { TokenOrder, TokenOrderStatusSql } from "@front-packages/dfa-gql-api";
import { useStatuses } from "hooks/other";
import { OrderViewDialog } from "components";

interface IOrderCard {
  order: TokenOrder;
}

function OrderCard({ order }: IOrderCard) {
  const getStatusBadgeProps = useStatuses();
  const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);
  const onOpenInfoModal = () => setIsOpenInfoModal(true);
  const onCloseInfoModal = () => setIsOpenInfoModal(false);
  return (
    <>
      <DFACard
        sx={{ cursor: "pointer", "&:hover": { boxShadow: ({ shadows }: Theme) => shadows[6] } }}
      >
        <Grid container rowSpacing={2} onClick={onOpenInfoModal}>
          <Grid item xs={6}>
            <DFAEntityParams label="Тикер" text={order.ticker} textSize="large" />
          </Grid>
          <Grid item xs={3.5}>
            <DFAEntityParams
              label="Тип токена"
              chip={
                <DFAChip label={order.token.tokenType} variant="filled" icon="none" type="info" />
              }
            />
          </Grid>
          <Grid item xs={2.5}>
            <DFAEntityParams
              label="Статус"
              chip={
                <DFAChip
                  variant="filled"
                  icon="none"
                  {...getStatusBadgeProps(order?.status, "Order")}
                  type={(getStatusBadgeProps(order?.status, "Order").color as any) || "info"}
                />
              }
              textSize="large"
            />
          </Grid>
          <Grid item xs={3}>
            <DFAEntityParams
              label="Создана"
              text={new Date(order.createDate).toLocaleString("ru", {
                dateStyle: "short",
              })}
              textSize="large"
            />
          </Grid>
          <Grid item xs={3}>
            <DFAEntityParams
              label="исполнена"
              text={order.status === TokenOrderStatusSql.Completed ? "Да" : "Нет"}
              textSize="large"
            />
          </Grid>
          <Grid item xs={3.5}>
            <DFAEntityParams label="Количество" text={order.amount} textSize="large" />
          </Grid>
          <Grid item xs={2.5}>
            <DFAEntityParams label="Сумма" text={order.amount * order.nominal} textSize="large" />
          </Grid>
        </Grid>
      </DFACard>
      <OrderViewDialog
        open={isOpenInfoModal}
        onClose={onCloseInfoModal}
        order={order}
        withIssueLink={false}
      />
    </>
  );
}

export default OrderCard;
