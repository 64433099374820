import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import {
  Token,
  TokensAPI,
  MarketAPI,
  ExtendedMarketOrders,
  MarketOrderStatusGql,
  MarketOrderTypeGql,
  ExtendedMarketOrder,
} from "@front-packages/dfa-gql-api";
import { amountWithSpaces, formatDate } from "@front-packages/dfa-helpers";
import {
  DFABox,
  DFAButton,
  DFAChip,
  DFADialog,
  DFAEntityParams,
  DFASnackbar,
  DFATable,
} from "Theme";
import { useStatuses } from "hooks/other";
import { useTranslation } from "react-i18next";
import { useErrors } from "hooks/errors";
import { setBreadcrumb, setMainTitle } from "reducers/layout/action";
import { RouteNamesEnum } from "consts";
import { GridColDef } from "@mui/x-data-grid";

function SecondaryMarketToken() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { errorMessage, setError } = useErrors();
  const [data, setData] = useState<Token>({});
  const [sellOrders, setSellOrders] = useState<ExtendedMarketOrders>(null);
  const [buyOrders, setBuyOrders] = useState<ExtendedMarketOrders>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSellOrders, setLoadingSellOrders] = useState<boolean>(true);
  const [loadingBuyOrders, setLoadingBuyOrders] = useState<boolean>(true);
  const [issueNotExist, setIssueNotExist] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [order, setOrder] = useState<ExtendedMarketOrder>(null);
  const getStatusBadgeProps = useStatuses();

  const getSellMarketOrders = async (tokenID) => {
    setLoadingSellOrders(true);
    const { response, error } = await MarketAPI.GetMarketOrders({
      opts: {
        page: 0,
        count: 10,
        tokenTicker: tokenID,
        orderStatus: MarketOrderStatusGql.RegisteredMarketOrderStatus,
        orderType: MarketOrderTypeGql.SellMarketOrderType,
      },
    });
    if (error) setError(error);
    if (response) {
      setSellOrders(response);
    }
    setLoadingSellOrders(false);
  };

  const getBuyMarketOrders = async (tokenID) => {
    setLoadingBuyOrders(true);
    const { response, error } = await MarketAPI.GetMarketOrders({
      opts: {
        page: 0,
        count: 10,
        tokenTicker: tokenID,
        orderStatus: MarketOrderStatusGql.RegisteredMarketOrderStatus,
        orderType: MarketOrderTypeGql.BuyMarketOrderType,
      },
    });
    if (error) setError(error);
    if (response) {
      setBuyOrders(response);
    }
    setLoadingBuyOrders(false);
  };

  const getIssueByID = async () => {
    dispatch(setMainTitle({ isLoading: true }));
    dispatch(setBreadcrumb("Loading..."));
    const tokenID = search.split("=")[1];
    const { response, error } = await TokensAPI.GetTokenByID({ tokenID });
    if (error) setError(error);
    if (error === "failed to execute operation") {
      setIssueNotExist(true);
      dispatch(setMainTitle({ title: `Выпуск [${tokenID}]`, isLoading: false }));
      dispatch(setBreadcrumb(tokenID));
    }
    if (response) {
      const tickerId = response.ticker;
      setData(response);
      dispatch(
        setMainTitle({
          title: `Выпуск [${tickerId}]`,
          chip: <DFAChip label={`${response?.interest} %`} type="primary" />,
          isLoading: false,
        })
      );
      dispatch(setBreadcrumb(response?.id));
      getSellMarketOrders(tickerId);
      getBuyMarketOrders(tickerId);
    }
    setLoading(false);
  };

  const onDialogClose = () => {
    setOrder(null);
  };

  const handleDoubleClickCell = (params) => {
    console.log(params);

    setOrder(params.row);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  const handleCloseError = () => setError("");
  useEffect(() => {
    getIssueByID();
    return () => {
      dispatch(setMainTitle({ title: null, chip: null }));
      dispatch(setBreadcrumb(null));
    };
  }, []);

  useEffect(() => {
    data &&
      dispatch(
        setMainTitle({
          title: `Выпуск [${data?.ticker}]`,
          chip: (
            <DFAChip
              {...getStatusBadgeProps(data?.status, "Token")}
              type={(getStatusBadgeProps(data?.status, "Token").color as any) || "info"}
            />
          ),
        })
      );
  }, [window.localStorage.getItem("i18nextLng")]);

  const orderColumns: GridColDef[] = [
    {
      field: "fiatPrice",
      headerName: "Цена",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "tokenAmount",
      headerName: "Кол-во",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "fiatSum",
      headerName: "Сумма",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "expireDate",
      headerName: "Действует до",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => formatDate(params.value, true),
    },
    {
      field: "status",
      headerName: "Статус",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        const status = getStatusBadgeProps(params.value, "MarketOrder");
        return <DFAChip label={status.label} type={status.color as any} />;
      },
    },
  ];

  if (!loading && issueNotExist)
    return (
      <Grid container xs={12} md={2} lg={2}>
        <DFAButton
          variant="outlined"
          color="success"
          onClick={() => navigate(RouteNamesEnum.secondaryMarketToken)}
        >
          {t("general.buttons.back")}
        </DFAButton>
      </Grid>
    );

  return (
    <>
      <Grid container>
        <Grid item container spacing={4} mt={2}>
          <Grid item xs={3}>
            <DFAEntityParams
              label={t("layouts.emissions.table.columns.nominal")}
              textSize="medium"
              isLoading={loading}
              text={`${data.nominal} RUB`}
            />
          </Grid>
          <Grid item xs={3}>
            <DFAEntityParams
              label={t("layouts.emissions.table.columns.issuer")}
              textSize="medium"
              isLoading={loading}
              text={
                <Link href={`${RouteNamesEnum.customersCard}?id=${data.emitent?.id}`}>
                  <Typography component="span" variant="h6" color="success.main">
                    {data.emitent?.fullName}
                  </Typography>
                </Link>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <DFAEntityParams
              label="Дата погашения"
              text={data.redemptionDate ? formatDate(data.redemptionDate) : "Не установлена"}
              textSize="medium"
              isLoading={loading}
            />
          </Grid>
          <Grid item xs={3}>
            <DFAEntityParams
              label="Объем выпуска"
              textSize="medium"
              isLoading={loading}
              text={`${amountWithSpaces(String(data.amount * data.nominal))} RUB`}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={1} mb={5} spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h4">Заявки на покупку</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4">Заявки на продажу</Typography>
        </Grid>
        <Grid item xs={6}>
          <DFATable
            rows={!loadingBuyOrders && buyOrders.MarketOrders}
            columns={orderColumns}
            onCellDoubleClick={handleDoubleClickCell}
            loading={loadingBuyOrders}
            loadingCfg={{ skeletons: true, rows: 3 }}
          />
        </Grid>
        <Grid item xs={6}>
          <DFATable
            rows={!loadingSellOrders && sellOrders.MarketOrders}
            columns={orderColumns}
            onCellDoubleClick={handleDoubleClickCell}
            loading={loadingSellOrders}
            loadingCfg={{ skeletons: true, rows: 3 }}
          />
        </Grid>
      </Grid>
      <DFADialog
        open={isOpenModal}
        title="Заявка"
        type="success"
        loading={loading}
        onClose={handleCloseModal}
      />
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errorMessage}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage}
        onClose={handleCloseError}
        close={handleCloseError}
      />
      <Dialog open={!!order} onClose={onDialogClose} maxWidth="sm">
        <DialogTitle color="warning" sx={{ p: "1rem 1rem 0" }}>
          <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
            <DFABox>
              {order?.orderType === MarketOrderTypeGql.BuyMarketOrderType
                ? "Заявка на покупку"
                : "Заявка на продажу"}
            </DFABox>
            <DFAChip
              size="small"
              variant="filled"
              icon="none"
              {...getStatusBadgeProps(order?.status, "MarketOrder")}
              type={getStatusBadgeProps(order?.status, "MarketOrder").color as any}
            />
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ p: "1rem 1rem 0 !important" }}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <DFAEntityParams
                label="Дата создания заявки"
                text={order?.createDate ? formatDate(data?.createDate, true) : "Не установлена"}
              />
            </Grid>
            <Grid item xs={12}>
              <DFAEntityParams label="Тикер" text={data?.ticker} />
            </Grid>
            <Grid item xs={12}>
              <DFAEntityParams label="Эмитент" text={data?.issuer} />
            </Grid>
            <Grid item xs={12}>
              <DFAEntityParams label="Номинал" text={`${data?.nominal}`} />
            </Grid>
            <Grid item xs={12}>
              <DFAEntityParams label="Ставка дохода" text={`${data?.interest} %`} />
            </Grid>
            <Grid item xs={12}>
              <DFAEntityParams
                label="Дата погашения"
                text={data?.redemptionDate ? formatDate(data?.redemptionDate) : "Не установлена"}
              />
            </Grid>
            <Grid item xs={12}>
              <DFAEntityParams label="Количество токенов" text={`${order?.tokenAmount}`} />
            </Grid>
            <Grid item xs={12}>
              <DFAEntityParams label="Цена за единицу" text={`${order?.fiatPrice} RUB`} />
            </Grid>
            <Grid item xs={12}>
              <DFAEntityParams label="Сумма заявки" text={`${order?.fiatSum} RUB`} />
            </Grid>
            {order?.status !== MarketOrderStatusGql.CompleteMarketOrderStatus &&
              order?.status !== MarketOrderStatusGql.RejectMarketOrderStatus && (
                <Grid item xs={12}>
                  <DFAEntityParams
                    label="Действует до"
                    text={order?.expireDate ? formatDate(order?.expireDate) : "Не установлено"}
                  />
                </Grid>
              )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DFAButton variant="contained" color="secondary" onClick={onDialogClose} id="close">
            {t("general.buttons.close")}
          </DFAButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SecondaryMarketToken;
