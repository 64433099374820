import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../base";
import { shadows, palette } from "../colors";
import { boxShadow } from "../../helpers";

interface MuiSlider {
  defaultProps?: ComponentsProps["MuiSlider"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiSlider"];
  variants?: ComponentsVariants["MuiSlider"];
}
const { grey, common, info } = palette;
const { borderRadius, borderWidth } = borders;
const { sliderBoxShadow } = shadows;
const colorInfo = "main" in info ? info.main : "inherit";

const slider: MuiSlider = {
  styleOverrides: {
    root: {
      width: "100%",

      "& .MuiSlider-active, & .Mui-focusVisible": {
        boxShadow: "none !important",
      },

      "& .MuiSlider-valueLabel": {
        color: common.black,
      },
    },

    rail: {
      height: "2px",
      background: grey[200],
      borderRadius: borderRadius.sm,
      opacity: 1,
    },

    track: {
      background: colorInfo,
      height: "2px",
      position: "relative",
      border: "none",
      borderRadius: borderRadius.lg,
      zIndex: 1,
    },

    thumb: {
      width: "14px",
      height: "14px",
      backgroundColor: common.white,
      zIndex: 10,
      boxShadow: sliderBoxShadow.thumb,
      border: `${borderWidth[1]} solid ${colorInfo}`,
      transition: "all 200ms linear",

      "&:hover": {
        boxShadow: "none",
      },

      "&:active": {
        transform: "translate(-50%, -50%) scale(1.4)",
      },

      "&.Mui-active": { boxShadow: boxShadow([0, 0], [0, 14], colorInfo, 0.16) },
    },
  },
};

export default slider;
