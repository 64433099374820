import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { palette, shadows } from "../../colors";
import { rgba } from "../../../helpers";

interface MuiCard {
  defaultProps?: ComponentsProps["MuiCard"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiCard"];
  variants?: ComponentsVariants["MuiCard"];
}

const { common, info } = palette;
const { borderWidth, borderRadius } = borders;
const { md } = shadows;

const card: MuiCard = {
  styleOverrides: {
    root: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minWidth: 0,
      wordWrap: "break-word",
      backgroundImage: "none",
      backgroundColor: info[200],
      backgroundClip: "border-box",
      border: `${borderWidth[0]} solid ${rgba(common.black, 0.125)}`,
      borderRadius: borderRadius.xl,
      boxShadow: md,
      overflow: "visible",
    },
  },
};

export default card;
