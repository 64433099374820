import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { typography, palette } from "../../colors";

import { baseProperties } from "../../colors/typography";

interface MuiFormControlLabel {
  defaultProps?: ComponentsProps["MuiFormControlLabel"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiFormControlLabel"];
  variants?: ComponentsVariants["MuiFormControlLabel"];
}

const { info } = palette;
const { fontWeightBold } = typography;
const { fontSizeSM } = baseProperties;

const formControlLabel: MuiFormControlLabel = {
  styleOverrides: {
    root: {
      display: "block",
      minHeight: "24px",
      marginBottom: "2px",
    },

    label: {
      display: "inline-block",
      fontSize: fontSizeSM,
      fontWeight: fontWeightBold,
      color: info[100],
      lineHeight: 1,
      transform: `translateY(1px)`,
      marginLeft: "4px",

      "&.Mui-disabled": {
        color: info[100],
      },
    },
  },
};

export default formControlLabel;
