import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { shadows, palette } from "../../colors";

const { white } = palette.common;
const { md } = shadows;
const { borderRadius } = borders;

interface MuiTableContainer {
  defaultProps?: ComponentsProps["MuiTableContainer"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTableContainer"];
  variants?: ComponentsVariants["MuiTableContainer"];
}

const tableContainer: MuiTableContainer = {
  styleOverrides: {
    root: {
      backgroundColor: white,
      boxShadow: md,
      borderRadius: borderRadius.xl,
    },
  },
};

export default tableContainer;
