import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { InputLabel, MenuItem, FormHelperText, FormControl, Select, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { setMsgType, setMsgValue, setErrorMsgValue } from "reducers/transactions/action";
import transactionsList from "./transactionsList";

interface ISelectMsgPorps {
  error: boolean;
  setError(b: boolean): void;
}

export default function SelectMsg({ error, setError }: ISelectMsgPorps) {
  const dispatch = useDispatch();
  const {
    transaction: { msgType, transactionType },
  } = useTypedSelector((state) => state, shallowEqual);
  const { t } = useTranslation();

  const getValue = () =>
    transactionType !== "wasm"
      ? {}
      : {
          creator: JSON.parse(localStorage.getItem("activeWallet"))?.address,
        };

  const handleChange = (e) => {
    dispatch(setMsgType(e.target.value));
    setError(!e.target.value);
    dispatch(
      setMsgValue({
        typeUrl: "",
        value: getValue,
      })
    );
    dispatch(setErrorMsgValue(true));
  };

  const handleBlur = (e) => {
    setError(!e.target.value);
  };

  return (
    <Grid mb={2} item xs={12}>
      <FormControl required sx={{ width: "100%" }}>
        <InputLabel sx={{ color: error ? "#F44335" : "" }} id="select-label">
          {t("layouts.transactions.popup.msgType")}
        </InputLabel>
        {Object.entries(transactionsList).map((transaction) =>
          transactionType === transaction[0] ? (
            <Select
              labelId="select-label"
              id="select-input"
              value={msgType || ""}
              label={t("layouts.transactions.popup.msgType")}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ height: "45px" }}
              error={!!error}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.keys(transaction[1]).map((msg) => (
                <MenuItem value={msg}>{msg}</MenuItem>
              ))}
            </Select>
          ) : null
        )}
        <FormHelperText sx={{ color: error ? "#F44335" : "" }}>
          {error ? t("layouts.transactions.popup.errors.req") : ""}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
}
