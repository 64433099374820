import React, { ChangeEvent, useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DFAButton, DFADialog } from "Theme";
import {
  AccountOperationAPI,
  AccountType,
  BankDetails,
  CreatePaymentDetailsForm,
} from "@front-packages/dfa-gql-api";
import { trimLetter } from "@front-packages/dfa-helpers";

type FieldType = "bik" | "accountType" | "inn" | "kpp" | "accountNumber";
interface IPaymentDetailsProps {
  createPaymentDetails(
    form: CreatePaymentDetailsForm,
    setIsLoad: (isLoad: boolean) => void
  ): Promise<void>;
  open: boolean;
  close(): void;
  setError: (value: React.SetStateAction<string>) => void;
}

function PaymentDetailsModal({
  createPaymentDetails,
  open,
  close,
  setError,
}: IPaymentDetailsProps) {
  const [form, setForm] = useState<CreatePaymentDetailsForm>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingBankDetails, setIsLoadingBankDetails] = useState<boolean>(false);
  const [bankDetails, setBankDetails] = useState<BankDetails>(null);
  const [bik, setBik] = useState<string>("");

  const getBankDetails = async () => {
    setIsLoadingBankDetails(true);
    const { error, response } = await AccountOperationAPI.GetBankDetails({
      bik,
    });
    if (error) {
      setError(error);
      setIsLoadingBankDetails(false);
    }
    if (response) {
      setBankDetails(response);
      setIsLoadingBankDetails(false);
      setForm({ ...form, inn: response.inn, kpp: response.kpp });
    }
  };

  const handleChange = (field: FieldType, e: ChangeEvent<HTMLInputElement>) => {
    if (field === "bik") setBik(e.target.value);
    else if (field === "inn") setForm({ ...form, [field]: trimLetter(e.target.value, 12) });
    else if (field === "kpp") setForm({ ...form, [field]: trimLetter(e.target.value, 9) });
    else if (field === "accountNumber")
      setForm({ ...form, [field]: trimLetter(e.target.value, 20) });
    // TODO: Genapi
    else setForm({ ...form, [field]: e.target.value as AccountType });
  };
  useEffect(() => {
    setBik("");
    setForm(null);
    setBankDetails(null);
  }, [open]);
  return (
    <DFADialog
      title="Банковские реквизиты"
      open={open}
      onClose={close}
      onConfirm={async () => {
        await createPaymentDetails(form, setIsLoading);
        setForm(null);
        close();
      }}
      disabled={!form || Object.keys(form).length !== 4 || Object.values(form).includes("")}
      loading={isLoading}
    >
      <Grid
        container
        spacing={2}
        mt="1px"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="БИК банка"
            value={bik || ""}
            type="number"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("bik", e)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DFAButton
            variant="contained"
            color="success"
            id="saveCompanyData"
            onClick={getBankDetails}
            loading={isLoadingBankDetails}
            disabled={!bik}
          >
            Найти
          </DFAButton>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Наименование банка"
            value={bankDetails?.bankName || ""}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="ИНН"
            value={form?.inn || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("inn", e)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="КПП"
            value={form?.kpp || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("kpp", e)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Номер счета"
            value={form?.accountNumber || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("accountNumber", e)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Тип счета</InputLabel>
            <Select
              sx={{ height: "3em" }}
              value={form?.accountType || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("accountType", e)}
              label="Тип счета"
              required
            >
              <MenuItem value={AccountType.Nominal}>Номинальный</MenuItem>
              <MenuItem value={AccountType.Estimated}>Расчетный</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </DFADialog>
  );
}

export default PaymentDetailsModal;
