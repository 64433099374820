import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { shadows } from "../../colors";

interface MuiDialog {
  defaultProps?: ComponentsProps["MuiDialog"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiDialog"];
  variants?: ComponentsVariants["MuiDialog"];
}
const { borderRadius } = borders;
const { xxl } = shadows;

const dialog: MuiDialog = {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.lg,
      boxShadow: xxl,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export default dialog;
