import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { rgba } from "../../../helpers";
import { baseProperties } from "../../colors/typography";
import { borders } from "../../../base";
import { palette } from "../../colors";

interface MuiDialogContent {
  defaultProps?: ComponentsProps["MuiDialogContent"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiDialogContent"];
  variants?: ComponentsVariants["MuiDialogContent"];
}

const { fontSizeMD } = baseProperties;
const { white } = palette.common;
const { borderWidth, borderColor } = borders;

const dialogContent: MuiDialogContent = {
  styleOverrides: {
    root: {
      padding: "16px",
      fontSize: fontSizeMD,
      color: rgba(white, 0.8),
    },

    dividers: {
      borderTop: `${borderWidth[1]} solid ${rgba(borderColor, 0.6)}`,
      borderBottom: `${borderWidth[1]} solid ${rgba(borderColor, 0.6)}`,
    },
  },
};

export default dialogContent;
