import { TypedUseSelectorHook, useSelector } from "react-redux";
import { combineReducers } from "redux";
import adaptiveStyleReducer, { IThemeStyle } from "./layout/reducer";
import authReducer, { IAuthState } from "./auth/reducer";
import transactionTypeReducer, { ITransactionsState } from "./transactions/reducer";
import errFetchReducer, { IInitErrState } from "./errFetch/reducers";
import loaderReducer, { IInitLoaderState } from "./loader/reducer";
import withdrawalReducer, { IInitWithdrawalState } from "./withdrawal/reducer";
import refillReducer, { IInitRefillState } from "./refill/reducer";
import customerReducer, { ICustomerState } from "../pages/customers/reducer/reducer";

export interface RootState {
  adaptiveThemeStyle: IThemeStyle;
  auth: IAuthState;
  transaction: ITransactionsState;
  errors: IInitErrState;
  loader: IInitLoaderState;
  withdrawal: IInitWithdrawalState;
  refill: IInitRefillState;
  customer: ICustomerState;
}

const rootReducer = combineReducers<RootState>({
  adaptiveThemeStyle: adaptiveStyleReducer,
  auth: authReducer,
  transaction: transactionTypeReducer,
  errors: errFetchReducer,
  loader: loaderReducer,
  withdrawal: withdrawalReducer,
  refill: refillReducer,
  customer: customerReducer,
});

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
