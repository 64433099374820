import React from "react";
import { Icon, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DFABox from "../DFABox";
import { iconCrossStyle, mainStyle } from "./style";

interface IDFASearchBadgeProps {
  badge: string;
  delBadge(badge: string): void;
}

function DFASearchBadge({ badge, delBadge }: IDFASearchBadgeProps) {
  const { t } = useTranslation();

  const handleDelBadge = () => delBadge(badge);

  return (
    <DFABox
      key={`badge-${badge}`}
      color="text"
      sx={mainStyle}
      onClick={handleDelBadge}
      id={`badge-${badge}`}
    >
      <Typography sx={{ fontSize: ({ other }) => other.baseTypographyProp.fontSizeSM }}>
        {t(`general.badges.${badge}`)}
      </Typography>
      <IconButton size="small" disableRipple color="inherit" sx={iconCrossStyle}>
        <Icon>close</Icon>
      </IconButton>
    </DFABox>
  );
}

export default DFASearchBadge;
