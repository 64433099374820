import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import { DFAAlert, DFABox, DFAChip, DFATable } from "Theme";
import {
  GetTokensResponse,
  GetTokensOpts,
  TokensAPI,
  UserClientType,
  SortOptionGql,
} from "@front-packages/dfa-gql-api";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "@front-packages/dfa-helpers";
import { useStatuses } from "hooks/other";
import { Skeleton, Typography } from "@mui/material";
import { useErrors } from "hooks/errors";
import { setBlockedPages } from "reducers/auth/action";
import { useTypedSelector } from "reducers";
import { RouteNamesEnum } from "consts";
import EmissionsSearchForm from "./components/EmissionsSearchForm";

function Emissions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "")[0];
  const { t } = useTranslation();
  const [state, setState] = useState<GetTokensResponse>({
    tokens: [],
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const { setError, errorMessage } = useErrors();
  const [opts, setOpts] = useState<GetTokensOpts>({
    page: 0,
    count: 10,
    clientType: UserClientType.Admin,
    sortOption: SortOptionGql.StartDateEarly,
  });
  const { auth } = useTypedSelector((store) => store);

  const getStatusBadgeProps = useStatuses();

  const getIssues = async (options?: GetTokensOpts) => {
    if (!loading) {
      setLoading(true);
      setState({ tokens: [], count: 0 });
    }
    const { response, error } = await TokensAPI.GetTokens({ opts: options || opts });
    if (error) setError(error);
    if (response) setState(response);
    else setState({ tokens: [], count: 0 });
    setLoading(false);
  };

  const handleSetPage = async (_, changePage) => {
    setOpts({ ...opts, page: changePage - 1 });
    await getIssues({ ...opts, page: changePage - 1 });
  };

  const handleDoubleClickCell = (params: GridCellParams) => {
    if (!loading && params.field !== "__check__")
      navigate(`${RouteNamesEnum.checkEmission}?id=${params.id}`);
  };

  useEffect(() => {
    getIssues().catch((e) => console.error(e));
    return () => {
      dispatch(setBlockedPages({ customers: false }));
    };
  }, []);

  const cellSkeleton = (
    <Skeleton variant="rounded" animation="wave" sx={{ width: "100%", borderRadius: "7px" }} />
  );

  const columns: GridColDef[] = [
    {
      field: "ticker",
      headerName: t("layouts.emissions.table.columns.ticker"),
      width: 260,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? cellSkeleton : <Typography>{params.value}</Typography>;
      },
    },
    {
      field: "issuer",
      headerName: "Эмитент",
      headerAlign: "center",
      width: 250,
      renderCell: (params) => {
        return loading ? cellSkeleton : <Typography>{params.value}</Typography>;
      },
    },
    {
      field: "createDate",
      headerName: t("layouts.emissions.table.columns.createDate"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? (
          cellSkeleton
        ) : (
          <Typography>{params.value ? formatDate(params.value, true) : ""}</Typography>
        );
      },
    },
    {
      field: "investStartDate",
      headerName: t("layouts.emissions.table.columns.investStartDate"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? (
          cellSkeleton
        ) : (
          <Typography>{params.value ? formatDate(params.value, true) : ""}</Typography>
        );
      },
    },
    {
      field: "investFinishDate",
      headerName: t("layouts.emissions.table.columns.investFinishDate"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? (
          cellSkeleton
        ) : (
          <Typography>{params.value ? formatDate(params.value, true) : ""}</Typography>
        );
      },
    },
    {
      field: "status",
      headerName: t("layouts.emissions.table.columns.status"),
      headerAlign: "center",
      width: 160,
      renderCell: (params) => {
        return loading ? (
          cellSkeleton
        ) : (
          <DFAChip
            {...getStatusBadgeProps(params?.value, "Token")}
            type={(getStatusBadgeProps(params?.value, "Token").color as any) || "info"}
          />
        );
      },
    },
    {
      field: "releaseDate",
      headerName: t("layouts.emissions.table.columns.releaseDate"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? (
          cellSkeleton
        ) : (
          <Typography>{params.value ? formatDate(params.value) : ""}</Typography>
        );
      },
    },
    {
      field: "plannedPublicDate",
      headerName: t("layouts.emissions.table.columns.plannedPublicDate"),
      width: 230,
      headerAlign: "center",
      renderCell: (params) => {
        return loading ? (
          cellSkeleton
        ) : (
          <Typography>{params.value ? formatDate(params.value, true) : ""}</Typography>
        );
      },
    },
  ];

  if (auth.blockedPages[route]) {
    return (
      <DFABox>
        <DFAAlert type="error" title={errorMessage} />
      </DFABox>
    );
  }

  return (
    <Grid container position="relative">
      <EmissionsSearchForm page={opts?.page} count={opts?.count} onSearch={getIssues} />
      <Grid item xs={12} mt={5}>
        <DFATable
          rows={state.tokens || []}
          columns={columns}
          onCellDoubleClick={handleDoubleClickCell}
          loading={loading}
          loadingCfg={{ skeletons: true }}
        />
      </Grid>
      {!!state.count && state.count > opts.count && (
        <Grid item xs={12}>
          <Pagination
            count={Math.ceil(state.count / opts.count)}
            shape="rounded"
            onChange={handleSetPage}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default Emissions;
