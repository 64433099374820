/*
 Developed by ST soft team
 */

import React, { useState } from "react";
import { MenuItem, Link, Typography, Icon, Stack, Box } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

interface INotificationProps {
  icon?: React.ReactNode;
  title: React.ReactNode;
  dismissible?: boolean;
  color?: "warning" | "error" | "success" | "info" | "secondary" | "primary";
}

function DFANotification(props: INotificationProps) {
  const { icon, title, dismissible, color = "secondary" } = props;
  const [display, setDisplay] = useState<boolean>(true);

  if (!display) return null;

  return (
    <MenuItem
      sx={({ palette }) => ({
        display: "block",
        backgroundColor: palette[color].main,
        "&:hover": { backgroundColor: palette[color].main },
        "&:focus": { backgroundColor: palette[color].light },
      })}
      className="notification-hover"
      onClick={() => (dismissible ? setDisplay(false) : null)}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="end">
        <Box component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1}>
          <Typography variant="body1" color="secondary" lineHeight={0.75}>
            <Icon>{icon}</Icon>
          </Typography>
          <Typography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
            {title}
          </Typography>
        </Box>
        {dismissible && (
          <Box>
            <CloseIcon onClick={() => setDisplay(false)} />
          </Box>
        )}
      </Stack>
    </MenuItem>
  );
}

export default DFANotification;
