import React from "react";
import { Skeleton, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import DFABox from "../DFABox";

interface IDFAEntityParamsProps {
  label: string;
  text?: string | React.ReactNode;
  title?: string;
  textSize?: "small" | "medium" | "large";
  chip?: React.ReactNode;
  isLoading?: boolean;
  color?: "warning" | "info" | "success" | "error" | "dark" | "default";
}

type Height = "1rem" | "1.25rem" | "1.5rem";

function DFAEntityParams({
  label,
  chip,
  text,
  textSize = "small",
  title,
  isLoading,
  color,
}: IDFAEntityParamsProps) {
  let variant: Variant = "h6";
  let skeletonHeight: Height = "1rem";
  if (textSize === "medium") {
    variant = "h5";
    skeletonHeight = "1.25rem";
  }
  if (textSize === "large") {
    variant = "h4";
    skeletonHeight = "1.5rem";
  }
  return (
    <DFABox lineHeight={1}>
      <Typography variant="subtitle1" mb={1}>
        {label}
      </Typography>
      {isLoading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ width: "60%", height: skeletonHeight }}
        />
      ) : (
        <DFABox display="flex">
          {text && typeof text === "string" && (
            <Typography
              title={title}
              color={color}
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              variant={variant}
              fontWeight={600}
              mr={chip ? 2 : 0}
            >
              {text}
            </Typography>
          )}
          {text && typeof text !== "string" && text}
          {chip && chip}
        </DFABox>
      )}
    </DFABox>
  );
}

export default DFAEntityParams;
