import React, { useEffect, useRef, useState } from "react";
import { Card, Pagination, TextField, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { formatDate } from "@front-packages/dfa-helpers";
import { DFABox, DFAButton, DFACheckBox, DFAChip, DFASnackbar, DFATable } from "Theme";
import { useTranslation } from "react-i18next";
import { useErrors } from "hooks/errors";
import {
  DeactivationAPI,
  DeactivationOrder,
  DeactivationOrderStatus,
  GetDeactivationOrderListOpts,
} from "@front-packages/dfa-gql-api";
import { useStatuses } from "hooks/other";
import DeactivationDetailsModal from "./DeactivationDetailsModal";
import AdminTip from "./AdminTip";

interface Creator {
  name: string;
  id: string;
  isAdmin: boolean;
}
interface CustomDeactivationOrder extends DeactivationOrder {
  creator: Creator;
}

interface DeactivationOrders {
  orders: CustomDeactivationOrder[];
  totalCount: number;
}

function Deactivation() {
  const defaultOrderStatuses = [
    DeactivationOrderStatus.InitiatedDeactivationOrderStatus,
    DeactivationOrderStatus.NewDeactivationOrderStatus,
    DeactivationOrderStatus.RejectedDeactivationOrderStatus,
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [clientFilter, setClientFilter] = useState<string>("");
  const box = useRef<HTMLDivElement>(null);
  const [count] = useState<number>(10);
  const [data, setData] = useState<DeactivationOrders>({ totalCount: 0, orders: [] });
  const [activeOrder, setActiveOrder] = useState<DeactivationOrder>(null);
  const { setError, errorMessage } = useErrors();
  const { t } = useTranslation();
  const memoryOpts = useRef<GetDeactivationOrderListOpts>({
    page: page || 0,
    count: 10,
    orderStatus: defaultOrderStatuses,
    sortDescent: true,
  });
  const getStatusBadgeProps = useStatuses();

  const getOrders = async (options?: GetDeactivationOrderListOpts) => {
    if (options) memoryOpts.current = options;
    if (!loading) {
      setLoading(true);
      setData({ totalCount: 0, orders: [] });
    }
    const { response, error } = await DeactivationAPI.GetDeactivationOrderList({
      opts: memoryOpts.current,
    });
    if (error) {
      setError(error);
    }
    if (response) {
      const customResponse: DeactivationOrders = {
        totalCount: response.totalCount,
        orders: response.orders.map((value) => ({
          ...value,
          creator: {
            id: value.creatorID,
            name: value.creatorName,
            isAdmin: value.IsCreatedByAdmin,
          },
        })),
      };
      customResponse.orders.push();
      setData(customResponse);
      setError("");
    } else {
      setData({ orders: [], totalCount: 0 });
    }
    setLoading(false);
  };
  const onDialogClose = () => {
    setActiveOrder(null);
  };
  const handleDialogSuccess = async () => {
    setActiveOrder(null);
    await getOrders();
  };
  const handleSetPage = async (_, changePage) => {
    setPage(changePage - 1);
    await getOrders({ ...memoryOpts.current, page: changePage - 1, count });
  };
  const handleShowCompleted = async (_, showCompleted) => {
    const orderStatus = [...defaultOrderStatuses];
    if (showCompleted) {
      orderStatus.push(DeactivationOrderStatus.CompletedDeactivationOrderStatus);
    }
    await getOrders({ ...memoryOpts.current, orderStatus });
  };
  const handleClientFilter = (e) => setClientFilter(e.target.value);
  const handleSearch = async () => {
    await getOrders({ ...memoryOpts.current, companyName: clientFilter });
  };
  useEffect(() => {
    getOrders().catch((e) => console.error(e));
  }, []);

  const columns: GridColDef[] = [
    {
      field: "regNumber",
      headerName: t("layouts.deactivation.tabsTitles.id"),
      width: 250,
      headerAlign: "center",
    },
    {
      field: "createDate",
      headerName: t("layouts.deactivation.tabsTitles.createDate"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>{params.value ? formatDate(params.value, true) : ""}</Typography>
      ),
    },
    {
      field: "companyName",
      headerName: t("layouts.deactivation.tabsTitles.client"),
      width: 200,
      headerAlign: "center",
    },
    {
      field: "creator",
      headerName: t("layouts.deactivation.tabsTitles.creator"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.value.isAdmin && <AdminTip />}
          <Typography>{params.value.name}</Typography>
        </>
      ),
    },
    {
      field: "status",
      headerName: t("layouts.deactivation.tabsTitles.orderStatus"),
      headerAlign: "center",
      width: 200,
      renderCell: (params) => {
        const status = getStatusBadgeProps(params.value, "Deactivation");
        return <DFAChip label={status.label} type={status.color as any} />;
      },
    },
  ];
  return (
    <DFABox position="relative">
      <Card
        sx={{
          borderRadius: "4px",
          position: "absolute",
          top: "-60px",
          minHeight: "70px",
          right: 0,
          zIndex: 11,
          width: "40%",
          padding: "12px 16px",
        }}
      >
        <DFABox display="flex" ref={box} width="100%" alignItems="center">
          <TextField
            sx={{ m: 0, mr: 1 }}
            margin="dense"
            id="collapse-name"
            label={t("layouts.deactivation.filters.searchByClient")}
            type="text"
            fullWidth
            variant="outlined"
            onInput={handleClientFilter}
          />
          <DFAButton variant="contained" color="primary" onClick={handleSearch}>
            {t("layouts.deactivation.filters.searchButton")}
          </DFAButton>
        </DFABox>
      </Card>
      <DFACheckBox
        onChange={handleShowCompleted}
        label={t("layouts.deactivation.filters.showCompleted")}
      />
      <DFABox mt={5}>
        <DFATable
          rows={data.orders ? data.orders : []}
          columns={columns}
          onCellDoubleClick={(params) => !loading && setActiveOrder(params.row)}
          loading={loading}
          loadingCfg={{ skeletons: true }}
        />
      </DFABox>
      <DFABox mt={5}>
        {!!data.totalCount && (
          <Pagination
            count={Math.ceil(data.totalCount / count)}
            shape="rounded"
            onChange={handleSetPage}
          />
        )}
      </DFABox>
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errorMessage}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage}
        onClose={() => setError("")}
        close={() => setError("")}
      />
      {activeOrder && (
        <DeactivationDetailsModal
          deactivationOrderID={activeOrder?.id}
          ClientID={activeOrder.clientID}
          onClose={onDialogClose}
          onSuccess={handleDialogSuccess}
        />
      )}
    </DFABox>
  );
}

export default Deactivation;
