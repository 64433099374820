import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DFAAlert, DFABox, DFAButton, DFAChip, DFAEntityParams } from "Theme";
import {
  DeactivationAPI,
  DeactivationOrder,
  DeactivationOrderStatus,
  TransactionsAPI,
} from "@front-packages/dfa-gql-api";
import { useStatuses } from "hooks/other";
import { useTranslation } from "react-i18next";
import { amountWithSpaces, formatDate } from "@front-packages/dfa-helpers";
import { useErrors } from "hooks/errors";
import AdminTip from "./AdminTip";

interface IDeactivationDetailsProps {
  deactivationOrderID: string;
  ClientID: string;
  onClose(): void;
  onSuccess(): void;
}

interface IBalance {
  fiat: string;
  fiatHold: string;
  tokens: number;
  tokenHolds: number;
}

function DeactivationDetailsModal({
  deactivationOrderID,
  ClientID,
  onClose,
  onSuccess,
}: IDeactivationDetailsProps) {
  const { t } = useTranslation();
  const [isLoadingDeactivation, setLoadingDeactivation] = useState<boolean>(false);
  const [isLoadingRejection, setLoadingRejection] = useState<boolean>(false);
  const [isLoadingOrder, setLoadingOrder] = useState<boolean>(false);
  const [isLoadingBalance, setLoadingBalance] = useState<boolean>(false);
  const [isBalanceError, setBalanceError] = useState<boolean>(false);
  const [isBalanceNotEmpty, setBalanceNotEmpty] = useState<boolean>(false);
  const [balance, setBalance] = useState<IBalance>(null);
  const [comment, setComment] = useState<string>("");
  const [order, setOrder] = useState<DeactivationOrder>(null);
  const { setError } = useErrors();
  const getStatusBadgeProps = useStatuses();

  const getDeactivationOrder = async () => {
    setLoadingOrder(true);
    const { response, error } = await DeactivationAPI.GetDeactivationOrderByID({
      deactivationOrderID,
    });
    if (error) {
      setOrder(null);
      setComment("");
      setError(error);
    }
    if (response) {
      setOrder(response);
      setComment(response.comment);
    }
    setLoadingOrder(false);
  };

  const getBalance = async () => {
    setLoadingBalance(true);
    setBalanceError(false);
    const { response, error } = await TransactionsAPI.GetClientBalance({
      ClientID,
    });
    if (error) {
      setBalance(null);
      setBalanceError(true);
      setError(error);
    }
    if (response) {
      const { fiat, token } = response;
      const newBalance = {
        fiat: fiat.balance.amount,
        fiatHold: fiat.holds.reduce((sum, el) => sum + +el.amount, 0).toString(),
        tokens: token.balance.length,
        tokenHolds: token.holds.length,
      };
      setBalance(newBalance);
      if (
        newBalance.tokenHolds +
          newBalance.tokenHolds +
          Number(newBalance.fiat) +
          Number(newBalance.fiatHold) !==
        0
      ) {
        setBalanceNotEmpty(true);
      }
    }
    setLoadingBalance(false);
  };
  const handleDeactivation = async () => {
    setLoadingDeactivation(true);
    const { response, error } = await DeactivationAPI.ScAddrDeactivationInstantiate({
      deactivationOrderID: order.id,
      comment,
    });
    if (error) {
      setError(error);
    }
    if (response) {
      setError("");
      onSuccess();
    }
    setLoadingDeactivation(false);
  };

  const handleRejection = async () => {
    setLoadingRejection(true);
    const { response, error } = await DeactivationAPI.RejectDeactivationOrder({
      deactivationOrderID: order.id,
      comment,
    });
    if (error) {
      setError(error);
    }
    if (response) {
      setError("");
      onSuccess();
    }
    setLoadingRejection(false);
  };

  useEffect(() => {
    Promise.all([getDeactivationOrder(), getBalance()]).catch((e) => console.error(e));
  }, []);
  return (
    <Dialog onClose={onClose} maxWidth="sm" open>
      <DialogTitle color="warning" sx={{ p: "1rem 1rem 0" }}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <DFABox>{t("layouts.deactivation.details.mainTitle")}</DFABox>
          {!isLoadingOrder && (
            <DFAChip
              size="small"
              variant="filled"
              icon="none"
              {...getStatusBadgeProps(order?.status, "Deactivation")}
              type={getStatusBadgeProps(order?.status, "Deactivation").color as any}
            />
          )}
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: "1rem 1rem 0 !important" }}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            {!!isBalanceError && (
              <DFAAlert type="error" title={t("general.errors.errorTitle")}>
                {t("layouts.deactivation.details.errorWhileFetchingBalance")}
              </DFAAlert>
            )}
            {order?.status === DeactivationOrderStatus.NewDeactivationOrderStatus &&
              balance &&
              isBalanceNotEmpty && (
                <DFAAlert type="error" title={t("general.errors.errorTitle")}>
                  {t("layouts.deactivation.details.balanceIsNotEmpty")}
                </DFAAlert>
              )}
          </Grid>
          <Grid item xs={12}>
            <DFAEntityParams
              label={t("layouts.deactivation.details.orderNumber")}
              text={order?.regNumber}
              textSize="medium"
              isLoading={isLoadingOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <DFAEntityParams
              label={t("layouts.deactivation.tabsTitles.creator")}
              text={
                <>
                  {order?.IsCreatedByAdmin && <AdminTip />}{" "}
                  <Typography>{order?.creatorName}</Typography>
                </>
              }
              textSize="medium"
              isLoading={isLoadingOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <DFAEntityParams
              label={t("layouts.deactivation.tabsTitles.createDate")}
              text={order?.createDate ? formatDate(order.createDate, true) : ""}
              textSize="medium"
              isLoading={isLoadingOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <DFAEntityParams
              label={t("layouts.deactivation.tabsTitles.client")}
              text={order?.companyName}
              textSize="medium"
              isLoading={isLoadingOrder}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5">{t("layouts.deactivation.details.balance")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <DFAEntityParams
              label={t("layouts.deactivation.details.tokens")}
              text={
                balance?.tokens >= 0
                  ? `${balance.tokens} ${t("layouts.deactivation.details.count")}`
                  : "нет данных"
              }
              textSize="medium"
              isLoading={isLoadingBalance}
              color={balance?.tokens > 0 ? "error" : "success"}
            />
          </Grid>
          <Grid item xs={12}>
            <DFAEntityParams
              label={t("layouts.deactivation.details.tokensHold")}
              text={
                balance?.tokenHolds >= 0
                  ? `${balance.tokenHolds} ${t("layouts.deactivation.details.count")}`
                  : "нет данных"
              }
              textSize="medium"
              isLoading={isLoadingBalance}
              color={balance?.tokenHolds > 0 ? "error" : "success"}
            />
          </Grid>
          <Grid item xs={12}>
            <DFAEntityParams
              label={t("layouts.deactivation.details.fiat")}
              text={
                balance?.fiat && +balance.fiat >= 0
                  ? `${amountWithSpaces(balance.fiat)} ${t(
                      "layouts.deactivation.details.fiatSymbol"
                    )}`
                  : "нет данных"
              }
              textSize="medium"
              isLoading={isLoadingBalance}
              color={balance?.fiat && +balance.fiat > 0 ? "error" : "success"}
            />
          </Grid>
          <Grid item xs={12}>
            <DFAEntityParams
              label={t("layouts.deactivation.details.fiatHold")}
              text={
                balance?.fiatHold && +balance.fiatHold >= 0
                  ? `${amountWithSpaces(balance.fiatHold)} ${t(
                      "layouts.deactivation.details.fiatSymbol"
                    )}`
                  : "нет данных"
              }
              textSize="medium"
              isLoading={isLoadingBalance}
              color={balance?.fiatHold && +balance.fiatHold > 0 ? "error" : "success"}
            />
          </Grid>

          {order?.status === DeactivationOrderStatus.NewDeactivationOrderStatus && (
            <Grid item xs={12}>
              <TextField
                sx={{ m: 0, mr: 1 }}
                margin="dense"
                id="collapse-name"
                label={t("layouts.deactivation.details.commentTextField")}
                type="text"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={comment}
                required
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>
          )}
          {order?.status !== DeactivationOrderStatus.NewDeactivationOrderStatus && (
            <>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h5">{t("layouts.deactivation.details.comment")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ m: 0, mr: 1 }}
                  margin="dense"
                  id="collapse-name"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={comment}
                  disabled
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {!isLoadingBalance &&
          !isBalanceError &&
          order?.status === DeactivationOrderStatus.NewDeactivationOrderStatus && (
            <>
              <DFAButton
                variant="contained"
                color="warning"
                onClick={handleRejection}
                disabled={!comment}
                loading={isLoadingRejection}
              >
                {t("general.buttons.reject")}
              </DFAButton>
              {!isBalanceNotEmpty && (
                <DFAButton
                  variant="contained"
                  color="error"
                  onClick={handleDeactivation}
                  disabled={isLoadingBalance || isBalanceError || !comment}
                  loading={isLoadingDeactivation}
                >
                  {t("general.buttons.access")}
                </DFAButton>
              )}
            </>
          )}
        <DFAButton variant="contained" color="secondary" onClick={onClose} id="close">
          {t("general.buttons.close")}
        </DFAButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeactivationDetailsModal;
