import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../base";
import { colors, shadows } from "../colors";

interface MuiPopover {
  defaultProps?: ComponentsProps["MuiPopover"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiPopover"];
  variants?: ComponentsVariants["MuiPopover"];
}
const { transparent } = colors;
const { lg } = shadows;
const { borderRadius } = borders;

const popover: MuiPopover = {
  styleOverrides: {
    paper: {
      backgroundColor: transparent,
      boxShadow: lg,
      padding: "8px",
      borderRadius: borderRadius.md,
    },
  },
};

export default popover;
