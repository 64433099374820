import { colors, palette } from "../../colors";

import { baseProperties } from "../../colors/typography";

const { text, info, secondary } = palette;
const { transparent } = colors;
const { fontSizeXS, fontSizeSM } = baseProperties;
const infoMain = "main" in info ? info.main : "inherit";
const secondaryMain = "main" in secondary ? secondary.main : "inherit";

const buttonText = {
  base: {
    backgroundColor: transparent,
    minHeight: "37px",
    color: text.primary,
    boxShadow: "none",
    padding: "9px 24px",

    "&:hover": {
      backgroundColor: transparent,
      boxShadow: "none",
    },

    "&:focus": {
      boxShadow: "none",
    },

    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
      boxShadow: "none",
    },

    "&:disabled": {
      boxShadow: "none",
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: "16px !important",
    },
  },

  small: {
    minHeight: "29px",
    padding: "6px 18px",
    fontSize: fontSizeXS,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: "12px !important",
    },
  },

  large: {
    minHeight: "44px",
    padding: "12px 64px",
    fontSize: fontSizeSM,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: "22px !important",
    },
  },

  primary: {
    color: infoMain,

    "&:hover": {
      color: infoMain,
    },

    "&:focus:not(:hover)": {
      color: infoMain,
      boxShadow: "none",
    },
  },

  secondary: {
    color: secondaryMain,

    "&:hover": {
      color: secondaryMain,
    },

    "&:focus:not(:hover)": {
      color: secondaryMain,
      boxShadow: "none",
    },
  },
};

export default buttonText;
