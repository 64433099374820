// eslint-disable-next-line import/prefer-default-export
export enum RouteNamesEnum {
  customers = "/customers",
  customersCard = "/customers/card",
  admins = "/admins",
  representatives = "/representatives",
  wallets = "/wallets",
  documents = "/documents",
  orderPaymentsHistory = "/history",
  agreements = "/agreements",
  accounts = "/personal-accounts",
  emissions = "/emissions",
  checkEmission = "/emissions/check-emission",
  operations = "/operations",
  blockchain = "/blockchain",
  transactions = "/transactions",
  auth = "/authenticate/sign-in",
  invite = "/authenticate/invite",
  signUp = "/authenticate/sign_up",
  permissions = "/authenticate/set_permissions",
  uiKit = "/uikit",
  profile = "/profile",
  issues = "/issues",
  onBoarding = "/on-boarding",
  repayment = "/repayment",
  roles = "/roles",
  deactivation = "/deactivation",
  orders = "/orders",
  secondaryMarket = "/secondary-market",
  secondaryMarketToken = "/secondary-market/token",
  accessRecovery = "/access-recovery",
  restrictions = "/restrictions",
  fines = "/fines",
  smartContracts = "/smart-contracts",
  validators = "/validators",
  nominalAccount = "/nominal-account",
  businessReference = "/business-reference",
}
