import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

import { borders } from "../../../base";

interface MuiCardMedia {
  defaultProps?: ComponentsProps["MuiCardMedia"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiCardMedia"];
  variants?: ComponentsVariants["MuiCardMedia"];
}

const { borderRadius } = borders;

const cardMedia: MuiCardMedia = {
  styleOverrides: {
    root: {
      borderRadius: borderRadius.xl,
      margin: "16px 16px 0",
    },

    media: {
      width: "auto",
    },
  },
};

export default cardMedia;
