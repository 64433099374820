import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../../base";
import { colors, palette, shadows } from "../../colors";
import { baseProperties } from "../../colors/typography";

interface MuiAutocomplete {
  defaultProps?: ComponentsProps["MuiAutocomplete"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiAutocomplete"];
  variants?: ComponentsVariants["MuiAutocomplete"];
}

const { lg } = shadows;
const { fontSizeSM } = baseProperties;
const { transparent, gradients } = colors;
const { text, common, info } = palette;
const { borderRadius } = borders;

const autocomplete: MuiAutocomplete = {
  styleOverrides: {
    popper: {
      boxShadow: lg,
      padding: "8px",
      fontSize: fontSizeSM,
      color: text.primary,
      textAlign: "left",
      backgroundColor: `${common.white} !important`,
      borderRadius: borderRadius.md,
    },

    paper: {
      boxShadow: "none",
      backgroundColor: transparent,
    },

    option: {
      padding: "4.8px 16px",
      borderRadius: borderRadius.md,
      fontSize: fontSizeSM,
      color: text.primary,
      transition: "background-color 300ms ease, color 300ms ease",

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: info[50],
        color: info[100],
      },

      '&[aria-selected="true"]': {
        backgroundColor: `${info[50]} !important`,
        color: `${info[100]} !important`,
      },
    },

    noOptions: {
      fontSize: fontSizeSM,
      color: text.primary,
    },

    groupLabel: {
      color: info[100],
    },

    loading: {
      fontSize: fontSizeSM,
      color: text.primary,
    },

    tag: {
      display: "flex",
      alignItems: "center",
      height: "auto",
      padding: "4px",
      backgroundColor: gradients.dark.dark,
      color: common.white,

      "& .MuiChip-label": {
        lineHeight: 1.2,
        padding: "0 10px 0 4px",
      },

      "& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus": {
        color: common.white,
        marginRight: 0,
      },
    },
  },
};

export default autocomplete;
