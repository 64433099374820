import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { formatDate } from "@front-packages/dfa-helpers";
import { DFABox, DFACheckBox, DFADatePicker, DFACard } from "Theme";
import statusBadge from "./StatusBadge";

export default function ServiceInfo({ data, isEdit }: any) {
  const [rationale, setRationale] = useState("");
  const [result, setResult] = useState("");
  const [massCheckRes, setMassCheckRes] = useState("");
  const [receivingEmployee, setReceivingEmployee] = useState("");
  const [, setIsAutocomplete] = useState(false);
  const [filledEmployee, setFilledEmployee] = useState("");
  const [status, setStatus] = useState("first status");
  const [role] = useState("first role");

  const { t } = useTranslation();

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={7.8}>
        <DFACard
          title={t("layouts.card.clientCard.riskAssessments.title")}
          titleBadge={statusBadge("low", "Низкие риски")}
        >
          <Grid container rowSpacing={2} mt="1px">
            {/* <Grid item xs={12}> */}
            {/*  <Typography variant="h5" display="flex" justifyContent="space-between"> */}
            {/*    {t("layouts.card.clientCard.riskAssessments.title")} */}
            {/*    {data && */}
            {/*      statusBadge(data.commonStates.riskAssessments.level as any, "Низкие риски")} */}
            {/*  </Typography> */}
            {/* </Grid> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={10}
                label={t("layouts.card.clientCard.riskAssessments.rationale")}
                value={data ? data.commonStates.riskAssessments.rationale : rationale}
                onChange={(e) => setRationale(e.target.value)}
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Typography variant="h6" fontWeight={600}>
                {t("layouts.card.clientCard.riskAssessments.isNoFATF")}
              </Typography>
              {data &&
                statusBadge(
                  data.commonStates.riskAssessments.isNoFATF ? "danger" : "low",
                  data.commonStates.riskAssessments.isNoFATF ? "Да" : "Нет"
                )}
            </Grid>
          </Grid>
        </DFACard>
      </Grid>
      <Grid item xs={4.2}>
        <DFACard title={t("layouts.card.clientCard.profileInfo.title")}>
          <Grid container spacing={2} mt="1px">
            <Grid item xs={12}>
              <DFABox width="100%">
                <DFACheckBox
                  label={t("layouts.card.clientCard.profileInfo.isAutocomplete")}
                  onChange={(e) => setIsAutocomplete(e.target.checked)}
                  checked={data ? data.commonStates.profileInfo.isAutocomplete : false}
                  disabled={!isEdit}
                />
              </DFABox>
            </Grid>
            <Grid item xs={6}>
              <DFADatePicker
                label={t("layouts.card.clientCard.profileInfo.startDate")}
                value={data ? data.commonStates.profileInfo.startDate : null}
                onChange={(date) => console.log(date)}
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <DFADatePicker
                label={t("layouts.card.clientCard.profileInfo.endDate")}
                value={data ? data.commonStates.profileInfo.endDate : null}
                onChange={(date) => console.log(date)}
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <DFADatePicker
                label={t("layouts.card.clientCard.profileInfo.profileCreationDate")}
                value={data ? data.commonStates.profileInfo.issueDate : null}
                onChange={(date) => console.log(date)}
                disableFuture
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <DFADatePicker
                label={t("layouts.card.clientCard.profileInfo.updatesDate")}
                value={data ? data.commonStates.profileInfo.refreshDate : null}
                onChange={(date) => console.log(date)}
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{t("layouts.searchForm.placeholders.status")}</InputLabel>
                <Select
                  sx={{ height: "3em" }}
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label={t("layouts.searchForm.placeholders.status")}
                  disabled={!isEdit}
                >
                  <MenuItem value="first status">first status</MenuItem>
                  <MenuItem value="second status">second status</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{t("layouts.searchForm.placeholders.role")}</InputLabel>
                <Select
                  sx={{ height: "3em" }}
                  value={role}
                  onChange={(e) => console.log(e.target.value)}
                  label={t("layouts.searchForm.placeholders.role")}
                  disabled={!isEdit}
                >
                  <MenuItem value="first role">first role</MenuItem>
                  <MenuItem value="second role">second role</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("layouts.card.clientCard.profileInfo.recivingEmployee")}
                value={data ? data.commonStates.profileInfo.receivingEmployee : receivingEmployee}
                onChange={(e) => setReceivingEmployee(e.target.value)}
                disabled={!isEdit}
              />
            </Grid>
            {data && !data.commonStates.profileInfo.isAutocomplete && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("layouts.card.clientCard.profileInfo.filledEmployee")}
                  value={data ? data.commonStates.profileInfo.filledEmployee : filledEmployee}
                  onChange={(e) => setFilledEmployee(e.target.value)}
                  disabled={!isEdit}
                  required
                />
              </Grid>
            )}
          </Grid>
        </DFACard>
      </Grid>
      <Grid item xs={6}>
        <DFACard
          title={t("layouts.card.clientCard.extremistActivity.title")}
          titleBadge={statusBadge("danger", "Проверка не пройдена")}
        >
          <Grid container rowSpacing={2} mt="1px">
            <Grid item xs={6}>
              <DFABox display="flex">
                <Typography mr={1}>
                  {t("layouts.card.clientCard.extremistActivity.checkDate")}
                </Typography>
                <Typography>
                  {data ? formatDate(data.commonStates.extremistActivity.date) : ""}
                </Typography>
              </DFABox>
              <DFABox display="flex">
                <Typography mr={1}>
                  {t("layouts.card.clientCard.extremistActivity.docNumber")}
                </Typography>
                <Typography>{data && data.commonStates.extremistActivity.document}</Typography>
              </DFABox>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              {data &&
                statusBadge(
                  !data.commonStates.extremistActivity.isInfo ? "danger" : "low",
                  t("layouts.card.clientCard.extremistActivity.isInfo")
                )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={8}
                label={t("layouts.card.clientCard.extremistActivity.checkResult")}
                value={data ? data.commonStates.extremistActivity.result : result}
                onChange={(e) => setResult(e.target.value)}
                disabled={!isEdit}
              />
            </Grid>
            {/* todo: Странные поля, требуется уточнение */}
            {/* {!data.commonStates.extremistActivity.isInfo && ( */}
            {/*  <> */}
            {/* <Grid item xs={12}> */}
            {/*  <Typography>{t("layouts.card.clientCard.tooltips.entityList")}</Typography> */}
            {/* </Grid> */}
            {/* <Grid item xs={4}> */}
            {/*  <Typography fontSize="1rem"> */}
            {/*    {t("layouts.card.clientCard.extremistActivity.docNumber")} */}
            {/*  </Typography> */}
            {/*  <Typography fontSize="1rem"> */}
            {/*    {data.commonStates.extremistActivity.document} */}
            {/*  </Typography> */}
            {/* </Grid> */}
            {/* <Grid item xs={4}> */}
            {/*  <Typography fontSize="1rem"> */}
            {/*    {t("layouts.card.clientCard.extremistActivity.date")} */}
            {/*  </Typography> */}
            {/*  <Typography fontSize="1rem"> */}
            {/*    {formatDate(data.commonStates.extremistActivity.date)} */}
            {/*  </Typography> */}
            {/* </Grid> */}
            {/*    <Grid item xs={12}> */}
            {/*      <Typography>{t("layouts.card.clientCard.tooltips.publicDecision")}</Typography> */}
            {/*    </Grid> */}
            {/*    <Grid item xs={4}> */}
            {/*      <Typography fontSize="1rem"> */}
            {/*        {t("layouts.card.clientCard.extremistActivity.docNumber")} */}
            {/*      </Typography> */}
            {/*      <Typography fontSize="1rem"> */}
            {/*        {data.commonStates.extremistActivity.document} */}
            {/*      </Typography> */}
            {/*    </Grid> */}
            {/*    <Grid item xs={4}> */}
            {/*      <Typography fontSize="1rem"> */}
            {/*        {t("layouts.card.clientCard.extremistActivity.date")} */}
            {/*      </Typography> */}
            {/*      <Typography fontSize="1rem"> */}
            {/*        {formatDate(data.commonStates.extremistActivity.date)} */}
            {/*      </Typography> */}
            {/*    </Grid> */}
            {/*  </> */}
            {/* )} */}
          </Grid>
        </DFACard>
      </Grid>
      <Grid item xs={6}>
        <DFACard title={t("layouts.card.clientCard.massWeaponsDestruction.title")}>
          <Grid container rowSpacing={2} mt="1px">
            <Grid item xs={6}>
              <DFABox display="flex">
                <Typography mr={1}>
                  {t("layouts.card.clientCard.massWeaponsDestruction.checkDate")}
                </Typography>
                <Typography>
                  {data ? formatDate(data.commonStates.massWeaponsDestruction.date) : ""}
                </Typography>
              </DFABox>
              <DFABox display="flex">
                <Typography mr={1}>
                  {t("layouts.card.clientCard.massWeaponsDestruction.docNumber")}
                </Typography>
                <Typography>{data && data.commonStates.massWeaponsDestruction.document}</Typography>
              </DFABox>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              {data &&
                statusBadge(
                  data.commonStates.massWeaponsDestruction.isInfo ? "danger" : "low",
                  t("layouts.card.clientCard.massWeaponsDestruction.isInfo")
                )}
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <TextField
                fullWidth
                multiline
                rows={7}
                label={t("layouts.card.clientCard.massWeaponsDestruction.checkRes")}
                value={data ? data.commonStates.massWeaponsDestruction.result : massCheckRes}
                onChange={(e) => setMassCheckRes(e.target.value)}
                disabled={!isEdit}
              />
            </Grid>
          </Grid>
        </DFACard>
      </Grid>
    </Grid>
  );
}
