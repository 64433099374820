import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { colors, palette } from "../colors";
import { rgba } from "../../helpers";

interface MuiDivider {
  defaultProps?: ComponentsProps["MuiDivider"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiDivider"];
  variants?: ComponentsVariants["MuiDivider"];
}

const { transparent } = colors;
const { info, common } = palette;

const divider: MuiDivider = {
  styleOverrides: {
    root: {
      backgroundColor: transparent,
      backgroundImage: `linear-gradient(to right, ${rgba(info[100], 0)}, ${rgba(
        info[100],
        0.4
      )}, ${rgba(info[100], 0)}) !important`,
      height: "1px",
      margin: "16px 0",
      borderBottom: "none",
      opacity: 0.25,
    },

    vertical: {
      backgroundColor: transparent,
      backgroundImage: `linear-gradient(to bottom, ${rgba(info[100], 0)}, ${rgba(
        info[100],
        0.4
      )}, ${rgba(info[100], 0)}) !important`,
      width: "1px",
      height: "100%",
      margin: "0 16px",
      borderRight: "none",
    },

    light: {
      backgroundColor: transparent,
      backgroundImage: `linear-gradient(to right, ${rgba(common.white, 0)}, ${common.white}, ${rgba(
        common.white,
        0
      )}) !important`,

      "&.MuiDivider-vertical": {
        backgroundImage: `linear-gradient(to bottom, ${rgba(common.white, 0)}, ${
          common.white
        }, ${rgba(common.white, 0)}) !important`,
      },
    },
  },
};

export default divider;
