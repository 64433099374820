import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import {
  ClientStatus,
  DeactivationOrderStatus,
  DepositOrderStatus,
  MarketOrderStatusGql,
  RepresentativeStatusGql,
  TokenOrderStatusSql,
  TokenStatusGql,
  WithdrawOrderStatus,
} from "@front-packages/dfa-gql-api";

export const resources = {
  ru: {
    translation: {
      service: {
        search: "Поиск",
      },
      general: {
        buttons: {
          create: "Создать",
          delete: "Удалить",
          close: "Закрыть",
          cancel: "Отмена",
          back: "Назад",
          next: "Далее",
          submit: "Отправить",
          upload: "Выбрать файл",
          access: "Подтвердить",
          sendTransaction: "Отправить транзакцию",
          edit: "Редактировать",
          register: "Зарегистрировать на платформе",
          save: "Сохранить",
          reject: "Отклонить",
        },
        notifications: {
          success: "Успешно",
          representativeRegistred: "Представитель успешно зарегистрирован!",
          representativeEdited: "Представитель успешно отредактирован!",
        },
        errors: {
          errorTitle: "Ошибка!",
          justNow: "только что",
          failedToFetch: "Нет связи с сервером. Проверьте доверенные сертификаты",
          noData: "Нет данных для отображения, но мы уже работаем на этим",
          scany: "Обращение к несуществующей таблице или столбцу в DB",
          incompleteEnter: "Коррректно заполните все доступные поля",
          failedToExecuteOperation:
            "На сервере произошла непредвиденная ошибка. Попробуйте повторить запрос позже",
          invalidINN: "Невалидный ИНН",
          noResultByData: "Нет результатов",
          invalidAccessToken: "Невалидный токен авторизации",
          InvalidBIK: "Невалидный БИК",
          invalidEmail: "Невалидный email",
          invalidKPP: "Невалидный КПП",
          invalidCountryCode: "Невалидный код страны",
          invalidFormatDate: "Невалидная дата",
          invalidOGRN: "Невалидный ОГРН",
          invalidOKPO: "Невалидный ОКПО",
          invalidPhoneNumber: "Невалидный номер телефона",
          invalidSNILS: "Невалидный СНИЛС",
          invalidVerificationCodeOrPhoneDoesntBelongToUser: "Не верный проверочный код из СМС",
          companyNotFound: "Компания не была найдена",
          companyExists: "Компания уже зарегистрирована на Платформе",
          companyOgrnHasAlreadyExists: "Компания с таким ОГРН уже зарегистрирована на Платформе",
          companyWithThisINNAlreadyExists: "Компания с таким ИНН уже зарегистрирована на Платформе",
          incorrectPhoneNumberOrPassword: "Неверный номер телефона или пароль",
          userWithThisPhoneNumberAlreadyExists:
            "Пользователь с таким номером телефона уже зарегистрирован",
          youCannotSetThisStatus: "Вы не можете установить данный статус",
          userAlreadyBlockedByDate: "Пользователь заблокирован",
          noPermission: "Нет прав доступа",
          unauthorized: "Неавторизован",
          representativeWithThisPhoneNumberOrEmailAlreadyExists:
            "Представитель с таким номером телефона или email уже существует",
          representativeStatusIsNotExists:
            "Данный представитель уже зарегистрирован в другой компании",
        },
        tooltips: {
          deleteBeneficiary: "Удалить выгодоприобретателя",
          addBeneficiary: "Добавить выгодоприобретателя",
          deleteBeneficiaryOwner: "Удалить сведения о бенеф. владельце",
          addBeneficiaryOwner: "Добавить сведения о бенеф. владельце",
          logout: "Выйти из системы",
          changeLang: "Сменить язык",
          openMenu: "Открыть меню",
        },
        dateTime: {
          now: "Только что",
        },
        badges: {
          name: "Наименование",
          inn: "ИНН",
          walletAddress: "Кошелек",
          createdDateRange: "Дата регистрации",
          companyEditDateRange: "Дата обновления",
          clientRole: "Роль",
          clientStatus: "Статус",
          status: "Статус",
          orderStatuses: "Статус",
          tokenStatuses: "Статус",
          ticker: "Тикер",
          publishDate: "Дата публикации",
          handlingDate: "Дата обработки",
          hideComplete: "Скрыть завершенные",
          hideCanceled: "Скрыть отклоненные",
          companyName: "Наименование компании",
          representativeID: "ID представителя",
          sum: "Сумма операции",
          createDate: "Дата создания",
          date: "Дата",
          from: "От",
          to: "До",
          startDate: "Дата начала",
          completionDate: "Дата завершения",
        },
        cancelDialog: {
          title: "Подтвердите действие",
          text: "Вы точно хотите закрыть форму?",
          close: "Закрыть",
          cancel: "Отмена",
        },
        statuses: {
          tokens: {
            [TokenStatusGql.Draft]: "новый",
            [TokenStatusGql.OnApproval]: "на проверке",
            [TokenStatusGql.Signing]: "на подписании",
            [TokenStatusGql.Initiating]: "инициализация",
            [TokenStatusGql.Scheduled]: "запланирован",
            [TokenStatusGql.Started]: "приём заявок",
            [TokenStatusGql.Issued]: "выпущен",
            [TokenStatusGql.InMarket]: "торговля",
            [TokenStatusGql.Frozen]: "заморожен",
            [TokenStatusGql.InRedeem]: "на погашении",
            [TokenStatusGql.Redeemed]: "погашен",
            [TokenStatusGql.Canceled]: "отменен",
            [TokenStatusGql.Rejected]: "отклонен",
            // [TokenStatusGql.]: "на отклонении",
            // unknown: "Unknown",
            [TokenStatusGql.Refund]: "возврат",
          },
          orders: {
            [TokenOrderStatusSql.Draft]: "новая",
            [TokenOrderStatusSql.Signing]: "на подписании",
            // initiating: "инициализация",
            [TokenOrderStatusSql.Scheduled]: "запланирована",
            [TokenOrderStatusSql.Completed]: "исполнена",
            [TokenOrderStatusSql.Rejected]: "отклонена",
            [TokenOrderStatusSql.OnReject]: "на отклонении",
            [TokenOrderStatusSql.Canceled]: "отменена",
            [TokenOrderStatusSql.Refund]: "возврат",
            [TokenOrderStatusSql.RefundError]: "ошибка возврата",
            [TokenOrderStatusSql.Registered]: "зарегистрирована",
          },
          redeemOrders: {
            draft: "новая",
            signing: "на подписании",
            initiating: "отправлена",
            completed: "исполнена",
            rejected: "отклонена",
            canceled: "отменена",
          },
          withdraw: {
            // draft: "новый",
            [WithdrawOrderStatus.Signing]: "на подписании",
            [WithdrawOrderStatus.Initiating]: "инициализация",
            [WithdrawOrderStatus.PaymentPreparing]: "подготовка платежа",
            [WithdrawOrderStatus.SentToBank]: "отправлено",
            [WithdrawOrderStatus.RejectAml]: "отклонено",
            [WithdrawOrderStatus.RejectBank]: "отклонено",
            [WithdrawOrderStatus.RejectCore]: "отклонено",
            [WithdrawOrderStatus.RejectMiddle]: "отклонено",
            [WithdrawOrderStatus.Cancelled]: "отменено",
            [WithdrawOrderStatus.Completed]: "исполнено",
          },
          deposit: {
            [DepositOrderStatus.DraftDepositStatus]: "новый",
            // "2fa_required": "подтверждение",
            [DepositOrderStatus.WaitingPaymentDepositStatus]: "ожидает исполнения",
            // initiating: "инициализация",
            [DepositOrderStatus.CompletedDepositStatus]: "исполнено",
            [DepositOrderStatus.RejectedDepositStatus]: "отклонено",
            // cancelled: "отменено",
          },
          company: {
            [ClientStatus.New]: "новый",
            [ClientStatus.KybNeeded]: "ожидает проверки",
            [ClientStatus.KybInProcess]: "на проверке",
            [ClientStatus.KybDone]: "проверен",
            [ClientStatus.Active]: "активен",
            [ClientStatus.Blocked]: "заблокирован",
            [ClientStatus.Deleted]: "удалён",
          },
          representative: {
            [RepresentativeStatusGql.New]: "новый",
            [RepresentativeStatusGql.Invited]: "приглашен",
            [RepresentativeStatusGql.KycNeeded]: "ожидает проверки",
            [RepresentativeStatusGql.KycInProcess]: "идет проверка",
            [RepresentativeStatusGql.KycDone]: "проверен",
            [RepresentativeStatusGql.Initiating]: "инициализация",
            [RepresentativeStatusGql.Active]: "активен",
            [RepresentativeStatusGql.Blocked]: "заблокирован",
            [RepresentativeStatusGql.Deleted]: "удалён",
          },
          wallet: {
            active: "активный",
            frozen: "заморожен",
            blocked: "заблокирован",
            closed: "закрыт",
          },
          deactivation: {
            [DeactivationOrderStatus.CompletedDeactivationOrderStatus]: "исполнено",
            [DeactivationOrderStatus.InitiatedDeactivationOrderStatus]: "инициализировано",
            [DeactivationOrderStatus.NewDeactivationOrderStatus]: "новый",
            [DeactivationOrderStatus.RejectedDeactivationOrderStatus]: "отклонено",
          },
          marketOrder: {
            [MarketOrderStatusGql.CompleteMarketOrderStatus]: "исполнено",
            [MarketOrderStatusGql.RegisteredMarketOrderStatus]: "зарегистрировано",
            [MarketOrderStatusGql.RejectMarketOrderStatus]: "отклонено",
            [MarketOrderStatusGql.SigningMarketOrderStatus]: "новый",
          },
          noLabel: "нет лэйбла",
        },
      },
      layouts: {
        card: {
          mainTitle: "Регистрация клиента",
          tabsTitles: {
            clientInfo: "Сведения о клиенте",
            extraData: "Дополнительные сведения",
            affilatesData: "Данные представителей",
            serviceData: "Служебная информация",
            accsAndOperations: "Счета и операции",
            orders: "Заявки",
          },
          addNewCompany: {
            title: "Зарегистрировать нового клиента",
            instruction: "Введите ИНН/КПП для поиска компании",
          },
          clientCard: {
            tooltips: {
              goalReason: "с ООО «ЦФА Информационная система»",
              goalPeriod: "Указать период и сумму",
              entityList: "Перечень ЮЛ и ФЛ",
              publicDecision: "Решение межведомственного координационного органа",
            },
            commonStates: {
              title: "Сведения о клиенте",
              clientOptions: {
                title: "Параметры клиента",
                isRusResident: "Является резидентом РФ",
                isCreditOrg: "Является кредитной организацией",
              },
              site: "Сайт организации",
              financialPositionInfo: "Сведения (документы) о финансовом положении",
              businessReputationInfo: "Сведения о деловой репутации клиента",
              sourceIncome: {
                title:
                  "Сведения об источниках происхождения денежных средств и (или) иного имущества клиента",
                incomeTypes: {
                  check1: "first",
                  check2: "second",
                  check3: "third",
                },
              },
            },
            goals: {
              title:
                "Сведения о целях установления и предполагаемом характере деловых отношений с ООО «ЦФА Информационная система",
              goalReason: "Цель и предполагаемый характер деловых отношений",
              plannedOperation: {
                title: "Сведения о планируемых операциях в течение определенного периода",
                from: "С",
                till: "ПО",
                amount: "Сумма",
              },
            },
            license: {
              title:
                "Сведения о лицензии на право осуществления деятельности, подлежащей лицензированию",
              number: "Номер",
              date: "Дата выдачи лицензии",
              issuer: "Кем выдана",
              till: "Срок действия",
              activityList: {
                title: "Перечень видов лицензируемой деятельности",
                activityItems: {
                  first: "FirstActivity",
                  second: "SecondActivity",
                  third: "3",
                },
              },
            },
            contactInfo: {
              title: "Контактная информация",
              phone: "Телефон",
              fax: "Факс",
              email: "Эл. почта",
              address: "Адрес",
            },
            riskAssessments: {
              title: "Сведения об оценке рисков",
              level: "Уровень риска клиента",
              rationale: "Обоснование уровня риска клиента",
              isNoFATF: "Клиент принадлежит к государству не выполняющему требования ФАТФ",
            },
            representatives: {
              title: "Сведения о представителях, бенефициарных владельцах и выгодоприобретателях",
              representiveTitle: "Сведения о представителях клиента",
              IPTitle: "Сведения о представителях клиента",
              representativeTitle: "Сведения о представителе",
              clientFullInfo: "Личные данные представителя",
              clientName: "Имя",
              status: "Статус",
              clientSurname: "Фамилия",
              clientPatronymic: "Отчество",
              clientBirthday: "Дата рождения",
              clientContacts: "Контактные данные",
              clientPhone: "Телефон",
              clientEmail: "Email",
              citizenship: "Гражданство",
              INN: "ИНН",
              beneficiaryName: "Наименование клиента/ФИО клиента",
              beneficiaryINN: "ИНН клиента",
              clientAgentProofTitle:
                "Сведения подтверждающие наличие полномочий представителя клиента",
              docName: "Наименование документа",
              issueDate: "Дата выдачи",
              validTill: "Срок действия",
              docNumber: "Номер документ",
              attachment: "Прикрепляемый файл",
              clientAttachmentTitle: "Прикрепляемые файлы",
              entityBeneficiars: "Сведения о выгодоприобретателях юридического лица",
              IPBeneficiars: "Сведения о выгодоприобретателях клиента",
              entityBeneficiarsTitle: "Сведения о выгодоприобретателях",
              beneficiaryOwnersTitle:
                "Сведения о бенефициарном владельце (бенефициарных владельцах) клиента",
              decision:
                "Обоснование решения о признании физического лица бенефициарным владельцем клиента",
              passport: {
                serial: "Серия",
                number: "Номер",
                dateOfIssue: "Дата выдачи",
                code: "Код подразделения",
                issuedBy: "Кем выдан",
              },
            },
            extremistActivity: {
              title:
                "Сведения о результатах проверки наличия (отсутствия) в отношении клиента информации его причастности к экстремистской деятельности или терроризму",
              isInfo: "Есть сведения о причастности",
              checkDate: "Дата проверки",
              checkResult: "Результат проверки",
              docNumber: "Номер документа",
              date: "Дата документа",
            },
            massWeaponsDestruction: {
              title:
                "Сведения о результатах проверки наличия (отсутствия) в отношении клиента информации вперечне организаций и физических лиц, в отношении которых имеются сведения об их причастности к распространению оружия массового уничтожения",
              isInfo: "Есть сведения о причастности",
              checkDate: "Дата проверки",
              checkRes: "Резуьтат проверки",
              docNumber: "Номер документа",
            },
            profileInfo: {
              title: "Сведения об анкете",
              startDate: "Дата начала отношений с клиентом",
              endDate: "Дата прекращения отношений с клиентом",
              profileCreationDate: "Дата оформления анкеты",
              updatesDate: "Дата обновлений анкеты клиента",
              recivingEmployee:
                "ФИО, должность сотрудника ООО «ЦФА Информационная система», принявшего решение о приеме клиента на обслуживание",
              isAutocomplete: "Автоматическое заполнение анкеты",
              filledEmployee:
                "ФИО ,должность сотрудника ООО «ЦФА Информационная система», заполнившего (обновившего) анкету клиента",
            },
            IPAttr: {
              nameAndINN: {
                title: "Наименование и ИНН",
                fullName: "Полное наименование",
                birthDate: "Дата рождения",
                citizenship: "Гражданство",
                name: "ФИО",
                INN: "ИНН",
                KPP: "КПП",
                snils: "СНИЛС",
              },
              idData: {
                title: "Удостоверение личности",
                serial: "Серия (при наличии) и номер документа",
                issueDate: "Дата выдачи документа",
                issuerName: "Наименование органа, выдавшего документ",
              },
              address: {
                title: "Адрес",
                stringAddress: "Адрес места жительства (регистрации) или места пребывания",
              },
              StateRegData: {
                title: "Сведения о государственной регистрации",
                OGRNIP: "ОГРНИП",
                StateRegPlace: "Место государственной регистрации",
              },
              extraData: {
                title: "Дополнительные сведения",
                publicServantrelations: "Отношение к публичным должностным лицам",
                position: "Должность",
                companyName: "Наименование работодателя",
                companyAddress: "Адрес работодателя",
                relationshipsLevel: "Степень родства либо статус (супруг или супруга) клиента",
                sourceOfFunds: "Источники происхождения денежных средств или иного имущества",
              },
            },
            entityAttr: {
              profileInfo: {
                title: "Тип юридического лица",
                entityType: "Тип юридического лица",
              },
              nameAndINN: {
                title: "Наименование и ИНН",
                FullName: "Полное наименование",
                name: "Наименование",
                foreignName: "Наименование на иностранном языке",
                INN: "ИНН",
                KPP: "КПП",
              },
              entityData: {
                title: "Данные Юридического лица",
                LegalForm: "Организационно-правовая форма",
                OKPO: "ОКПО",
                BIK: "БИК",
              },
              address: {
                title: "Адрес",
                stringAddress: "Адрес регистрации",
              },
              stateRegData: {
                title: "Сведения о государственной регистрации",
                OGRN: "ОГРН / № аккредитации",
                RegPlace: "Место государственной регистрации",
              },
              extraData: {
                title: "Дополнительные сведения",
                entityStructure: "Структура юридического лица",
                entityPersStructure: "Персональный состав органов управления юридического лица",
              },
              entityPers: {
                title: "Персональный состав органов управления юридического лица",
                entityType: "ФЛ / ЮЛ",
                entity: "ЮЛ",
                IP: "ФЛ",
                name: "Наименование",
                IPName: "ФИО",
              },
            },
            accsAndOperations: {
              accountNumber: "Номер счета",
              accountType: "Тип счета",
              bankName: "Наименование банка",
              bik: "БИК",
              corrAccount: "Корр. счет",
              inn: "ИНН",
              kpp: "КПП",
              swiftCode: "SWIFT",
            },
          },
        },
        searchForm: {
          title: "Поиск",
          buttons: {
            filter: "Фильтр",
            search: "Искать",
            close: "Закрыть",
            reset: "Сбросить",
          },
          placeholders: {
            inn: "ИНН",
            name: "Наименование",
            wallet: "Кошелек",
            registrationDate: "Дата регистрации",
            refreshDate: "Дата обновления",
            role: "Роль",
            status: "Статус",
          },
        },
        authenticate: {
          signUp: {
            title: "Регистрация",
            back: "Вернуться к авторизации",
          },
          mainTitle: "Авторизация",
          email: "Ваша почта",
          pass: "Пароль",
          createNewUser: "Зарегистрировать нового пользователя",
          options: {
            remember: "Запомнить меня",
            btn: "войти",
            registration: "Зарегистрироваться",
          },
          dialog: {
            title: "Проверочный код из смс",
            text: "Мы отправили Вам на телефон проверочный код для входа. Введите его в поле ниже",
            sms: "Код из смс",
            access: "Подтвердить",
            cancel: "Отмена",
          },
        },
        blockchain: {
          mainTitle: "Блоки",
          blocks_per_page_count: "Количество блоков на странице",
          card_title: "Высота блока",
          info_tx: "Информация о транзакции",
        },
        transactions: {
          mainTitle: "Транзакции",
          per_page_count: "Количество транзакций на странице",
          date: "Транзакции на дату",
          popup: {
            title: "Отправка транзакции",
            subTitle: "Все поля отмеченые * обязательны для заполнения",
            transactionType: "Тип транзакции",
            msgType: "Тип сообщения",
            errors: {
              req: "Для отправки транзакции, необходимо заполнить поле",
            },
          },
          infoCard: {
            errorCode: "Код ошибки",
            errorSend: "Вы пытались отправить:",
            successSend: "Последняя отправленная транзакция:",
            hash: "Хэш:",
          },
          buttons: {
            add: "Добавить",
          },
        },
        deactivation: {
          mainTitle: "Деактивация",
          createdByAdminTip: "Заявка создана администратором",
          tabsTitles: {
            id: "Номер",
            createDate: "Дата создания заявки",
            client: "Клиент",
            creator: "Создатель",
            orderStatus: "Статус",
          },
          filters: {
            showCompleted: "Показать исполненные",
            searchByClient: "Поиск по клиенту",
            searchButton: "Искать",
          },
          details: {
            mainTitle: "Заявка на деактивацию",
            orderNumber: "Номер заявки",
            balance: "Баланс",
            tokens: "Токены ЦФА",
            fiat: "Фиат",
            fiatHold: "Захолдированный фиат",
            tokensHold: "Захолдированные токены ЦФА",
            fiatSymbol: "RUB",
            count: "шт.",
            comment: "Комментарий",
            commentTextField: "Введите комментарий",
            balanceIsNotEmpty: "Невозможно выполнить деактивацию при ненулевых балансах",
            errorWhileFetchingBalance: "Не удалось получить информацию по балансу",
          },
        },
        customers: {
          mainTitle: "Клиенты",
          signUpClient: "Зарегистрировать клиента",
          table: {
            columns: {
              fullName: "Наименование",
              inn: "ИНН",
              kpp: "КПП",
              ogrn: "ОГРН (ОГРНИП)",
              address: "Место гос. регистрации",
              colRegDate: "Дата регистрации на Платформе",
              colSync: "Дата последнего обновления данных",
              status: "Статус организации",
              colRole: "Роль организации",
              representativeId: "Id представителя",
            },
          },
        },
        representatives: {
          mainTitle: "Представители",
          table: {
            columns: {
              colCustomer: "Клиент",
              colSurname: "Фамилия",
              colName: "Имя",
              colThirdName: "Отчество",
              colDateBirth: "Дата рождения",
              colRole: "Роль",
            },
          },
        },
        wallets: {
          mainTitle: "Кошельки",
          buttons: {
            create: "Создать кошелек",
            import: "Импортировать кошелек",
            activate: "Активировать",
            delete: "Удалить",
          },
          dialog: {
            title: "Удаление кошелька",
            text: "Вы уверены, что хотите удалить кошелек?",
          },
          card: {
            name: "Имя",
            balance: "Баланс",
            address: "Кошелёк",
            tokenTicker: "Тикер",
          },
          new: {
            title: "Добавить новый кошелек",
            fields: {
              name: "Имя кошелька *",
              file: "Загрузите файл",
              mnemo: "Мнемофраза *",
            },
            errors: {
              name: "Введите имя кошелька",
              mnemo: "Введите мнемофразу",
              key: "Для получения номера кошелька необходимо вставить свой приватный ключ",
            },
          },
        },
        admins: {
          mainTitle: "Администраторы",
          buttons: {
            add: "Добавить администратора",
            reg: "Зарегистрировать",
            cancel: "Отменить",
            access: "Добавить",
            userDel: "Удалить пользователя",
            userBlocked: "Заблокировать пользователя",
            delete: "Удалить",
            block: "Заблокировать",
          },
          dialog: {
            titleAdd: "Регистрация нового администратора",
            placeholders: {
              phone: "Введите номер телефона",
              name: "Введите имя",
              email: "Ведите email",
              pass: "Создайте пароль",
              secondPass: "Повторите пароль",
            },
            titleDel: "Удаление пользователя",
            textDel: "Вы уверены, что хотите удалить пользователя?",
            titleBlock: "Блокировка пользователя",
            textBlock: "Вы уверены, что хотите заблокировать пользователя?",
          },
          card: {
            uid: "ИД Пользователя",
            name: "Имя",
            date: "Дата регистрации",
            login: "Логин",
            status: "Статус",
            permissions: {
              title: "Права",
              GetTxs: "Получение списка транзакций",
              GetBlocks: "Получение списка блоков",
              BlockedDate: "Блокировка администратора",
              SetUserPermission: "Добавление прав администратору",
              InviteRepresentative: "Приглашение представителя",
              GetRepresentativeInfo: "Получение данных о представителе",
              UnsetUserPermission: "Удаление прав у администратора",
              SendInvitationLink: "Отправка приглашения представителю",
              AddCompany: "Добавление компании",
              ChangeBlockedDate: "Блокировка аккаутна администратора",
              GetPlatformAdminList: "Получение списка администраторов",
              CompanyEditForm: "Редактирование данных компании",
              AddRepresentative: "Добавление представителя",
              GetCompanies: "Получение списка компаний",
              GetCompanyInfo: "Получение информации о компании",
              ChangeCompanyStatus: "Изменение статуса компании",
              ChangeRepresentativeStatus: "Изменение статуса представителя",
              AdminGetPlatformAdminList: "Получение списка администраторов",
              Administrator: "Администратор платформы",
              GenerateKYCStatusVerificationToken: "GenerateKYCStatusVerificationToken",
              VerifyKYCStatusVerificationToken: "VerifyKYCStatusVerificationToken",
              GetKYCStatusByVerificationToken: "GetKYCStatusByVerificationToken",
              GetTokens: "Получение списка выпусков",
              CreateToken: "Создание выпуска",
              GetTokenByID: "Получение токена по ID",
              EditToken: "Редактирование токена",
              DeleteToken: "Удаление токена",
              ChangeCompanyTickerSymbols: "ChangeCompanyTickerSymbols",
              SetTokenStatus: "Установка статуса токена",
              SendToApproval: "Отправка выпуска на подтверждение",
              MakeAdminTransactions: "Отправка транзакций, от имени Платформы",
              GetFileKeysFromToken: "Получение файлов выпуска",
              DeleteFilesFromToken: "Удаление файлов выпуска",
              ApproveToken: "Разрешение выпуска токена",
              RejectToken: "Отклонение выпуска токена",
              TxLogGetByHash: "Получение статуса транзакции",
            },
            detail: "Подробная информация",
            actions: "Действия",
            dialog: {
              add: "Точно хотите добавить право?",
              take: "Точно хотите удалить право?",
            },
          },
          errors: {
            email: "Не корректный Email",
            secondPass: "Введеные пароли не совпадают",
          },
        },
        roles: {
          mainTitle: "Роли",
        },
        documents: {
          mainTitle: "Документы",
          table: {
            columns: {
              colNumber: "Номер",
              colDate: "Дата",
              colType: "Тип",
              colCustomer: "Клиент",
              colAppDate: "Дата заявки",
              colStatus: "Статус",
            },
          },
        },
        agreements: {
          mainTitle: "Договоры",
          table: {
            columns: {
              colNumber: "Номер",
              colDate: "Дата",
              colType: "Тип",
              colCustomer: "Клиент",
              colOrderDate: "Дата заявки",
              colStatus: "Статус",
            },
          },
        },
        "personal-accounts": {
          mainTitle: "Лицевые счета",
          table: {
            columns: {
              colNumber: "Номер счета",
              colType: "Тип счета",
              colCustomer: "Клиент",
              colDate: "Дата",
              colStatus: "Статус",
            },
          },
        },
        emission: {
          mainTitle: "Выпуск",
          nominalRansom: "Выкуп по номиналу",
          otherRansom: "Поставка товаров, выполнение работ, оказания услуг",
          monetary: "Денежные требования",
          monetaryOrDigitalEquivalent: "Денежные требования и (или) иные цифровые права",
        },
        history: {
          mainTitle: "Поручения",
          tabs: {
            refill: "Пополнения",
            withdrawal: "Вывод средств",
          },
          table: {
            columns: {
              customerName: "Клиент",
              representativeName: "Представитель",
              paymentId: "ID поручения",
              created: "Дата подачи",
              dateOfFiling: "Дата подачи",
              status: "Статус",
              orderMethod: "Способ подачи",
              walletAddress: "Адрес кошелька",
              sum: "Сумма",
              actions: "Действия",
              nominalAccountOperationId: "ID операции по НС",
            },
          },
        },
        repayment: {
          mainTitle: "Погашения",
          table: {
            columns: {
              status: "Статус",
              ticker: "Тикер",
              type: "Тип",
              amount: "Количество",
              sum: "Сумма",
              createdDate: "Дата создания",
              executionDate: "Дата исполнения",
              issuer: "Эмитент",
              investor: "Инвестор",
            },
          },
        },
        emissions: {
          mainTitle: "Выпуски",
          table: {
            columns: {
              createDate: "Создан",
              updateDate: "Обновлен",
              publicDate: "Опубликован",
              releaseDate: "Состоялся",
              status: "Статус",
              authorID: "Автор",
              maxIssueLimit: "maxIssueLimit",
              nominal: "Номинал",
              investStartDate: "Начало приема заявок",
              investFinishDate: "Окончание приема заявок",
              minOrderLimit: "Мин. токенов в заявке",
              maxOrderLimit: "Макс. токенов в заявке",
              plannedPublicDate: "План. дата публикации",
              repaymentMin: "Мин. % выкупа",
              smartContractAddress: "Адрес смарт-контракта",
              ticker: "Тикер",
            },
          },
        },
        operations: {
          mainTitle: "Операции",
          table: {
            columns: {
              colNumber: "Номер",
              colDate: "Дата",
              colType: "Тип",
              colCustomer: "Клиент",
              colOrderDate: "Дата заявки",
              colStatus: "Статус",
            },
          },
        },
        notifications: {
          mainTitle: "Уведомления",
        },
        uikit: {
          mainTitle: "UI Kit",
        },
        orders: {
          mainTitle: "Заявки",
        },
        "secondary-market": {
          mainTitle: "Вторичный рынок",
          table: {
            columns: {
              nominal: "Номинал",
              ticker: "Тикер",
              issuer: "Эмитент",
              redemptionDate: "Дата погашения",
              orders: "Заявки",
            },
          },
        },
        "access-recovery": {
          mainTitle: "Восстановление доступа",
          table: {
            columns: {
              number: "№",
              issuer: "Эмитент",
              clientInn: "ИНН клиента",
              representativeInn: "ИНН представителя",
              reason: "Причина",
              created: "Создан",
              status: "Статус",
            },
          },
        },
        restrictions: {
          mainTitle: "Ограничения",
        },
        fines: {
          mainTitle: "Обременения и взыскания",
        },
        "smart-contracts": {
          mainTitle: "Смарт-Контракты",
        },
        validators: {
          mainTitle: "Валидаторы",
        },
        "nominal-account": {
          mainTitle: "Номинальный счет",
        },
        "business-reference": {
          mainTitle: "Бизнес-справочник",
        },
      },
    },
  },
  en: {
    translation: {
      service: {
        search: "Search here",
      },
      general: {
        buttons: {
          cancel: "Cancel",
          delete: "Delete",
          close: "Close",
          back: "Back",
          next: "Next",
          submit: "Send",
          upload: "Upload file",
          create: "Create",
          access: "Confirm",
          sendTransaction: "Send transaction",
          edit: "Edit",
          register: "Register on platform",
          save: "Save",
          reject: "Reject",
        },
        notifications: {
          success: "Success",
          representativeRegistred: "Representative successfully registered!",
          representativeEdited: "Representative successfully edited!",
        },
        errors: {
          errorTitle: "Error!",
          justNow: "just now",
          failedToFetch: "No connection with server. Check trust certificates and try again",
          noData: "No data",
          scany: "Accessing a non-existent table or column in DB",
          incompleteEnter: "Correctly fill all available fields",
          failedToExecuteOperation: "Failed to execute operation",
          invalidINN: "Invalid INN",
          noResultByData: "No results by data",
          invalidAccessToken: "Invalid access token",
          InvalidBIK: "Invalid BIK",
          invalidEmail: "Invalid email",
          invalidKPP: "Invalid KPP",
          invalidCountryCode: "Invalid country code",
          invalidFormatDate: "Invalid date",
          invalidOGRN: "Invalid OGRN",
          invalidOKPO: "Invalid OKPO",
          invalidPhoneNumber: "Invalid phone number",
          invalidSNILS: "Invalid SNILS",
          invalidVerificationCodeOrPhoneDoesntBelongToUser: "Invalid sms code",
          companyNotFound: "Company not found",
          companyExists: "Company already exists",
          companyOgrnHasAlreadyExists:
            "A company with such a OGRN is already registered on the Platform",
          companyWithThisINNAlreadyExists: "Company with this inn already exists",
          incorrectPhoneNumberOrPassword: "Incorrect phone or password",
          userWithThisPhoneNumberAlreadyExists: "User with this phone already exists",
          youCannotSetThisStatus: "You can't set this status",
          userAlreadyBlockedByDate: "User is blocked",
          noPermission: "No permission",
          unauthorized: "Unauthorized",
          representativeWithThisPhoneNumberOrEmailAlreadyExists:
            "Representative with this phone number or email already exists",
          representativeStatusIsNotExists: "Representative is already exists in another company",
        },
        dateTime: {
          now: "Just now",
        },
        cancelDialog: {
          title: "Confirm your action",
          text: "Are you sure want to close this form?",
          close: "close",
          cancel: "cancel",
        },
        tooltips: {
          deleteBeneficiary: "Delete beneficiary",
          addBeneficiary: "Add beneficiary",
          deleteBeneficiaryOwner: "Delete beneficiary owner(s) info",
          addBeneficiaryOwner: "Add beneficiary owner(s) info",
          logout: "Logout",
          changeLang: "Change language",
          openMenu: "Open menu",
        },
        badges: {
          name: "Name",
          inn: "TIN",
          walletAddress: "Wallet",
          createdDateRange: "Registration date",
          companyEditDateRange: "Refresh date",
          clientRole: "Role",
          clientStatus: "Status",
          status: "Status",
          ticker: "Ticker",
          publishDate: "Publish date",
          handlingDate: "Handling date",
          hideComplete: "Hide completed",
          hideCanceled: "Hide canceled",
          companyName: "Company name",
          representativeID: "Representative ID",
          sum: "Operation amount",
          createDate: "Created date",
          date: "Date",
          from: "From",
          to: "To",
          orderStatuses: "Status",
          tokenStatuses: "Status",
          startDate: "Start date",
          completionDate: "Completion date",
        },
        statuses: {
          tokens: {
            [TokenStatusGql.Draft]: "draft",
            [TokenStatusGql.OnApproval]: "on approval",
            [TokenStatusGql.Signing]: "signing",
            [TokenStatusGql.Initiating]: "initiating",
            [TokenStatusGql.Scheduled]: "scheduled",
            [TokenStatusGql.Started]: "started",
            [TokenStatusGql.Issued]: "issued",
            [TokenStatusGql.InMarket]: "in market",
            [TokenStatusGql.Frozen]: "frozen",
            [TokenStatusGql.InRedeem]: "in redeem",
            [TokenStatusGql.Redeemed]: "redeemed",
            [TokenStatusGql.Canceled]: "canceled",
            [TokenStatusGql.Rejected]: "rejected",
            // onReject: "on reject",
            // unknown: "unknown",
            [TokenStatusGql.Refund]: "refund",
          },
          orders: {
            [TokenOrderStatusSql.Draft]: "draft",
            [TokenOrderStatusSql.Signing]: "signing",
            // initiating: "initiating",
            [TokenOrderStatusSql.Scheduled]: "scheduled",
            [TokenOrderStatusSql.Completed]: "completed",
            [TokenOrderStatusSql.Rejected]: "rejected",
            [TokenOrderStatusSql.OnReject]: "on reject",
            [TokenOrderStatusSql.Canceled]: "canceled",
            [TokenOrderStatusSql.Refund]: "refund",
            [TokenOrderStatusSql.RefundError]: "refund error",
            [TokenOrderStatusSql.Registered]: "registered",
          },
          redeemOrders: {
            draft: "draft",
            signing: "signing",
            initiating: "initiating",
            completed: "completed",
            rejected: "rejected",
            canceled: "canceled",
          },
          withdraw: {
            // draft: "draft",
            [WithdrawOrderStatus.Signing]: "signing",
            [WithdrawOrderStatus.Initiating]: "initiating",
            [WithdrawOrderStatus.PaymentPreparing]: "payment preparing",
            [WithdrawOrderStatus.SentToBank]: "sent to bank",
            [WithdrawOrderStatus.RejectAml]: "rejected",
            [WithdrawOrderStatus.RejectBank]: "rejected",
            [WithdrawOrderStatus.RejectCore]: "rejected",
            [WithdrawOrderStatus.RejectMiddle]: "rejected",
            [WithdrawOrderStatus.Cancelled]: "canceled",
            [WithdrawOrderStatus.Completed]: "completed",
          },
          deposit: {
            [DepositOrderStatus.DraftDepositStatus]: "draft",
            // "2fa_required": "2fa required",
            [DepositOrderStatus.WaitingPaymentDepositStatus]: "waiting for payment",
            // initiating: "initiating",
            [DepositOrderStatus.CompletedDepositStatus]: "completed",
            [DepositOrderStatus.RejectedDepositStatus]: "rejected",
            // cancelled: "canceled",
          },
          company: {
            [ClientStatus.New]: "new",
            [ClientStatus.KybNeeded]: "KYB Needed",
            [ClientStatus.KybInProcess]: "KYB in process",
            [ClientStatus.KybDone]: "KYB done",
            [ClientStatus.Active]: "active",
            [ClientStatus.Blocked]: "blocked",
            [ClientStatus.Deleted]: "deleted",
          },
          representative: {
            [RepresentativeStatusGql.New]: "new",
            [RepresentativeStatusGql.Invited]: "invited",
            [RepresentativeStatusGql.KycNeeded]: "KYC needed",
            [RepresentativeStatusGql.KycInProcess]: "KYC in process",
            [RepresentativeStatusGql.KycDone]: "KYC done",
            [RepresentativeStatusGql.Initiating]: "initiating",
            [RepresentativeStatusGql.Active]: "active",
            [RepresentativeStatusGql.Blocked]: "blocked",
            [RepresentativeStatusGql.Deleted]: "deleted",
          },
          wallet: {
            active: "active",
            frozen: "frozen",
            blocked: "blocked",
            closed: "closed",
          },
          deactivation: {
            [DeactivationOrderStatus.CompletedDeactivationOrderStatus]: "completed",
            [DeactivationOrderStatus.InitiatedDeactivationOrderStatus]: "initiated",
            [DeactivationOrderStatus.NewDeactivationOrderStatus]: "new",
            [DeactivationOrderStatus.RejectedDeactivationOrderStatus]: "rejected",
          },
          marketOrder: {
            [MarketOrderStatusGql.CompleteMarketOrderStatus]: "completed",
            [MarketOrderStatusGql.RegisteredMarketOrderStatus]: "registered",
            [MarketOrderStatusGql.RejectMarketOrderStatus]: "rejected",
            [MarketOrderStatusGql.SigningMarketOrderStatus]: "new",
          },
          noLabel: "no label",
        },
      },
      layouts: {
        card: {
          mainTitle: "Client card",
          tabsTitles: {
            clientInfo: "Client info",
            extraData: "Additional info",
            affilatesData: "Representatives' data",
            serviceData: "Service info",
            accsAndOperations: "Accounts and operations",
            orders: "Orders",
          },
          clientCard: {
            tooltips: {
              goalReason: "with LLC «ЦФА Информационная система»",
              goalPeriod: "Enter period and sum",
              entityList: "Entity and Individual Enterpreneur list",
              publicDecision: "Decision of the inter-ministerial coordination state body",
            },
            commonStates: {
              title: "Client info",
              site: "Site",
              clientOptions: {
                title: "Clients options",
                isRusResident: "Resident of Russian Federation",
                isCreditOrg: "Credit organisation",
              },
              financialPositionInfo: "Information (documents) on financial position",
              businessReputationInfo: "Business reputation info",
              sourceIncome: {
                title: "Information on the origin of the client’s funds and (or) other property",
                incomeTypes: {
                  check1: "first",
                  check2: "second",
                  check3: "third",
                },
              },
            },
            goals: {
              title:
                "Information on the purpose and alleged nature of the business relationship with LLC «ЦФА Информационная система",
              goalReason: "Purpose and expected nature of the business relationship",
              plannedOperation: {
                title: "Information on planned transactions during a given period",
                from: "From",
                till: "Till",
                amount: "Amount",
              },
            },
            license: {
              title:
                "Information on the license for the right to carry out the activities to be licensed",
              number: "Number",
              date: "License issue date",
              issuer: "Issuer",
              till: "Valid till",
              activityList: {
                title: "List of licensed activities",
                activityItems: {
                  first: "FirstActivity",
                  second: "SecondActivity",
                  third: "3",
                },
              },
            },
            contactInfo: {
              title: "Contact info",
              phone: "Phone",
              fax: "Fax",
              email: "Email",
              address: "Address",
            },
            riskAssessments: {
              title: "Risk assessment data",
              level: "Risk level",
              rationale: "Customer Risk Level Justification",
              isNoFATF: "The client belongs to the FATF non-compliant state",
            },
            representatives: {
              title: "Information on representatives, beneficial owners and beneficiaries",
              representativeTitle: "Representative info",
              representiveTitle: "Information about client representatives",
              IPTitle: "Information about client representatives",
              clientFullInfo: "Representative's personal info",
              clientName: "Representative's name",
              clientSurname: "Representative's surname",
              clientPatronymic: "Representative's patronymic",
              clientBirthday: "Representative's birthdate",
              clientContacts: "Representative's contact info",
              clientPhone: "Representative's phone number",
              clientEmail: "Representative's email",
              clientCountryCode: "Country code",
              INN: "INN",
              clientAgentProofTitle: "Evidence of the client representative’s authority",
              beneficiaryName: "Entity Name/ Customer Name",
              beneficiaryINN: "Client's INN",
              docName: "Document title",
              issueDate: "Issue date",
              clientAttachmentTitle: "Attachments",
              validTill: "Valid till",
              docNumber: "Document serial",
              attachment: "Attachment",
              entityBeneficiars: "Information on beneficiaries of a legal entity",
              IPBeneficiars: "Information on beneficiaries",
              beneficiaryOwnersTitle: "Beneficiary owner(s) info",
              entityBeneficiarsTitle: "Beneficiary info",
              status: "Status",
              decision:
                "Justification of the decision to recognize an individual as the beneficial owner of the client",
              passport: {
                serial: "Serial",
                number: "Number",
                dateOfIssue: "Date of issue",
                code: "Division code",
                issuedBy: "Issued by",
              },
            },
            extremistActivity: {
              title:
                "Information on the results of the verification of the (absence) of the customer’s information",
              isInfo: "Evidence of involvement",
              checkDate: "Audit check",
              checkResult: "Audit result",
              docNumber: "Document number",
              date: "Document date",
            },
            massWeaponsDestruction: {
              title:
                "Information on the results of the verification of the presence (absence) of information regarding the client of false organizations and individuals with respect to whom there is evidence of their involvement in the proliferation of weapons of mass destruction",
              isInfo: "Evidence of involvement",
              checkDate: "Audit date",
              checkRes: "Audit result",
              docNumber: "Document number",
            },
            profileInfo: {
              title: "Profile info",
              startDate: "Client Relationship Start Date",
              endDate: "Date of termination of the relationship with the client",
              profileCreationDate: "Date of profile registration",
              updatesDate: "Last profile update date",
              recivingEmployee:
                "Name, position of the employee of LLC «TFA Information System», who made the decision to accept the client for service",
              isAutocomplete: "Autocompleted",
              filledEmployee:
                "Name ,position of the employee of «TFA Information System» LLC who filled out (updated) the client’s profile",
            },
            IPAttr: {
              nameAndINN: {
                title: "Name ИНН",
                fullName: "Full name",
                birthDate: "Birthdate",
                citizenship: "Citizenship",
                name: "Fullname",
                INN: "INN",
                KPP: "KPP",
                snils: "SNILS",
              },
              idData: {
                title: "ID",
                serial: "Series (if available) and document number",
                issueDate: "Issue date",
                issuerName: "Name of the issuing authority",
              },
              address: {
                title: "Address",
                stringAddress: "Address of residence (registration) or residence",
              },
              StateRegData: {
                title: "State registration info",
                OGRNIP: "OGRNIP",
                StateRegPlace: "State registration place",
              },
              extraData: {
                title: "Extra information",
                publicServantrelations: "Whether a person is a civil servant",
                position: "Position",
                companyName: "Employers name",
                companyAddress: "Employers address",
                relationshipsLevel: "Degree of relationship or status (spouse) of the client",
                sourceOfFunds: "Sources of origin of money or other property",
              },
            },
            entityAttr: {
              profileInfo: {
                title: "Entity type",
                entityType: "Entity type",
              },
              nameAndINN: {
                title: "Name and INN",
                FullName: "Fullname",
                name: "Name",
                foreignName: "Name in a foreign language",
                INN: "INN",
                KPP: "KPP",
              },
              entityData: {
                title: "Entity data",
                LegalForm: "Organizational and legal form",
                OKPO: "OKPO",
                BIK: "BIK",
              },
              address: {
                title: "Address",
                stringAddress: "Registration date",
              },
              stateRegData: {
                title: "Information on state registration",
                OGRN: "OGRN / Accreditation number",
                RegPlace: "Place of registration",
              },
              extraData: {
                title: "Extra information",
                entityStructure: "Entity structure",
                entityPersStructure: "Personnel of the management bodies of the legal entity",
              },
              entityPers: {
                title: "Personnel of the management bodies of the legal entity\n",
                entityType: "Individual / Entity",
                entity: "Entity",
                IP: "Individual",
                name: "Name",
                IPName: "Fullname",
              },
            },
            accsAndOperations: {
              accountNumber: "Account number",
              accountType: "Account type",
              bankName: "Bank name",
              bik: "BIK",
              corrAccount: "Corr. account",
              inn: "INN",
              kpp: "KPP",
              swiftCode: "SWIFT",
            },
          },
          addNewCompany: {
            title: "Sign up new client",
            instruction: "Enter INN/KPP to search company",
          },
        },
        searchForm: {
          title: "Search",
          buttons: {
            filter: "Filter",
            search: "Search",
            reset: "Reset",
            close: "Close",
          },
          placeholders: {
            inn: "TIN",
            name: "Name",
            wallet: "Wallet",
            registrationDate: "Registration date",
            refreshDate: "Refresh date",
            role: "Role",
            status: "Status",
          },
        },
        authenticate: {
          mainTitle: "Authorization",
          email: "Email",
          pass: "Password",
          createNewUser: "Create new user",
          options: {
            remember: "Remember me",
            btn: "sign in",
            registration: "sign up",
          },
          dialog: {
            title: "SMS verification code",
            text: "We have sent you a verification code to your phone. Enter it in the field below",
            sms: "SMS code",
            access: "Confirm",
            cancel: "Cancel",
          },
          signUp: {
            title: "Registration",
            back: "Back to authorization",
          },
        },
        blockchain: {
          mainTitle: "Blockchain",
          blocks_per_page_count: "Number of blocks per page",
          card_title: "Block height",
          info_tx: "Transaction information",
        },
        transactions: {
          mainTitle: "Blocks",
          per_page_count: "Number of transactions per page",
          date: "Transaction on date",
          popup: {
            title: "Transaction submission form",
            subTitle: "All fields marked * are required",
            transactionType: "Transaction type",
            msgType: "Message type",
            errors: {
              req: "To send the transaction, you should fill out the field",
            },
          },
          infoCard: {
            errorCode: "Error code",
            errorSend: "You tried to send:",
            successSend: "Last success transaction:",
            hash: "Hash:",
          },
          buttons: {
            add: "Add",
          },
        },
        deactivation: {
          mainTitle: "Deactivation",
          createdByAdminTip: "The order was created by administrator",
          tabsTitles: {
            id: "Number",
            createDate: "Creation Date",
            client: "Client",
            creator: "Creator",
            orderStatus: "Status",
          },
          filters: {
            showCompleted: "Show Completed",
            searchByClient: "Search By Client",
            searchButton: "Search",
          },
          details: {
            mainTitle: "Deactivation Order",
            orderNumber: "Order Number",
            balance: "Balance",
            tokens: "DFA Tokens",
            fiat: "Fiat",
            fiatHold: "Held Fiat",
            tokensHold: "Held DFA Tokens",
            fiatSymbol: "RUB",
            count: "pcs.",
            comment: "Комментарий",
            commentTextField: "Enter a comment",
            balanceIsNotEmpty: "Deactivation isn't supposed when balance is nonzero",
            errorWhileFetchingBalance: "There's something wrong while fetching balance",
          },
        },
        customers: {
          mainTitle: "Customers",
          signUpClient: "Sign up client",
          table: {
            columns: {
              fullName: "Name",
              inn: "TIN",
              kpp: "Code of reason",
              ogrn: "Main state registration number",
              address: "Place of state registration",
              colRegDate: "Date of registration on the platform",
              colSync: "Last sync date",
              status: "Status",
              colRole: "Role",
              representativeId: "representative's id",
            },
          },
        },
        representatives: {
          mainTitle: "Representatives",
          table: {
            columns: {
              colCustomer: "Customer",
              colSurname: "Surname",
              colName: "Name",
              colThirdName: "Patronymic",
              colDateBirth: "Date of Birth",
              colRole: "Role",
            },
          },
        },
        wallets: {
          mainTitle: "Wallets",
          buttons: {
            create: "Create wallet",
            import: "Import wallet",
            activate: "Activate",
            delete: "Delete",
          },
          dialog: {
            title: "Deleting a wallet",
            text: "Are you sure you want to delete your wallet?",
          },
          card: {
            name: "Name",
            balance: "Balance",
            address: "Wallet",
            tokenTicker: "Ticker",
          },
          new: {
            title: "Add new wallet",
            fields: {
              name: "Wallet name *",
              file: "Upload file",
              mnemo: "Mnemonic phrase *",
            },
            errors: {
              name: "Enter wallet name",
              mnemo: "Enter mnemonic phrase",
              key: "To get the wallet number, you need to insert your private key",
            },
          },
        },
        admins: {
          mainTitle: "Admins",
          buttons: {
            add: "Add admin",
            reg: "Register",
            cancel: "Cancel",
            access: "Add",
            userDel: "Delete user",
            userBlocked: "Block user",
            delete: "Delete",
            block: "Block",
          },
          dialog: {
            titleAdd: 'Adding a new "Platforms" administrator',
            placeholders: {
              phone: "Enter phone number",
              name: "Enter name",
              email: "Enter email",
              pass: "Create a password",
              secondPass: "Repeat password",
            },
            titleDel: "Delete user",
            textDel: "Are you sure you want to delete the user?",
            titleBlock: "Block user",
            textBlock: "Are you sure you want to block the user",
          },
          card: {
            uid: "User ID",
            name: "Name",
            date: "registration Date",
            login: "Login",
            status: "Status",
            permissions: {
              title: "Permissions",
              GetTxs: "Get list od transactions",
              GetBlocks: "Get list of blocks",
              BlockedDate: "Administrator lock",
              SetUserPermission: "Add admins right",
              UnsetUserPermission: "Removing rights from the administrator",
              AddCompany: "Add company",
              CompanyEditForm: "Edit company data",
              AddRepresentative: "Add representative",
              GetCompanies: "Get list of companies",
              GetCompanyInfo: "Get company info",
              AdminGetPlatformAdminList: "Get admins list",
              Administrator: "Platform's admin",
              ChangeBlockedDate: "Block admin's account",
              ChangeCompanyStatus: "Change company's status",
              ChangeRepresentativeStatus: "Change representative's status",
              CreateToken: "Create issue",
              GenerateKYCStatusVerificationToken: "Generate KYC Status Verification Token",
              GetToken: "Get all issues list",
              GetKYCStatusByVerificationToken: "Get KYC Status ByVerification Token",
              GetPlatformAdminList: "Get admin list",
              GetRepresentativeInfo: "Get representative info",
              InviteRepresentative: "Invite representative",
              SendInvitationLink: "Send invitation link",
              VerifyKYCStatusVerificationToken: "VerifyKYCStatusVerificationToken",
            },
            detail: "Detailed information",
            actions: "Actions",
            dialog: {
              add: "Definitely want to add the right?",
              take: "Definitely want to remove the right?",
            },
          },
          errors: {
            email: "Incorrect Email",
            secondPass: "The entered passwords do not match",
          },
        },
        roles: {
          mainTitle: "Roles",
        },
        documents: {
          mainTitle: "Documents",
          table: {
            columns: {
              colNumber: "Number",
              colDate: "Date",
              colType: "Type",
              colCustomer: "Customer",
              colOrderDate: "Application date",
              colStatus: "Status",
              colAppDate: "Request date",
            },
          },
        },
        agreements: {
          mainTitle: "Agreements",
          table: {
            columns: {
              colNumber: "Number",
              colDate: "Date",
              colType: "Type",
              colCustomer: "Customer",
              colOrderDate: "Application date",
              colStatus: "Status",
            },
          },
        },
        "personal-accounts": {
          mainTitle: "Personal accounts",
          table: {
            columns: {
              colNumber: "Account number",
              colType: "Type of account",
              colCustomer: "Customer",
              colDate: "Date",
              colStatus: "Status",
            },
          },
        },
        emission: {
          mainTitle: "Issue",
        },
        history: {
          mainTitle: "Orders history",
          tabs: {
            refill: "Refill",
            withdrawal: "Withdrawal",
          },
          table: {
            columns: {
              customerName: "Customer",
              representativeName: "Representative",
              paymentId: "Payment ID",
              created: "Created",
              dateOfFiling: "Date of filing",
              status: "Status",
              orderMethod: "Order method",
              walletAddress: "Wallet address",
              sum: "Sum",
              actions: "Actions",
              nominalAccountOperationId: "NA operation ID",
            },
          },
        },
        repayment: {
          mainTitle: "Repayment",
          table: {
            columns: {
              status: "Status",
              ticker: "Ticker",
              type: "Type",
              amount: "Amount",
              sum: "Sum",
              createdDate: "Created Date",
              executionDate: "Execution Date",
              issuer: "Issuer",
              investor: "Investor",
            },
          },
        },
        emissions: {
          mainTitle: "Emissions",
          table: {
            columns: {
              createDate: "Created",
              updateDate: "Updated",
              publicDate: "Published",
              releaseDate: "Took place",
              status: "Status",
              authorID: "Author",
              maxIssueLimit: "Max issue limit",
              nominal: "Nominal",
              investStartDate: "Applications start date",
              investFinishDate: "Applications start date",
              minOrderLimit: "Min tokens in the application",
              maxOrderLimit: "Max tokens in the application",
              plannedPublicDate: "Planned publish date",
              repaymentMin: "Min. % redemption",
              smartContractAddress: "Smart contract address",
              colNumber: "Number",
              colDate: "Date",
              colType: "Type",
              colCustomer: "Customer",
              colOrderDate: "Application date",
              colStatus: "Status",
              ticker: "Ticker",
              issuer: "Issuer",
              redemptionDate: "Date of Issue",
              orders: "Orders",
            },
          },
        },
        operations: {
          mainTitle: "Operations",
          table: {
            columns: {
              colNumber: "Number",
              colDate: "Date",
              colType: "Type",
              colCustomer: "Customer",
              colOrderDate: "Application date",
              colStatus: "Status",
            },
          },
        },
        notifications: {
          mainTitle: "Notifications",
        },
        uikit: {
          mainTitle: "UI Kit",
        },
        orders: {
          mainTitle: "Orders",
        },
        "secondary-market": {
          mainTitle: "Secondary Market",
          table: {
            columns: {
              nominal: "Nominal",
              ticker: "Ticker",
              issuer: "Issuer",
              redemptionDate: "Redemption date",
              orders: "Orders",
            },
          },
        },
        "access-recovery": {
          mainTitle: "Access Recovery",
          table: {
            columns: {
              number: "#",
              issuer: "Issuer",
              clientInn: "Client INN",
              representativeInn: "Representative INN",
              reason: "Reason",
              created: "Created",
              status: "Status",
            },
          },
        },
        restrictions: {
          mainTitle: "Restrictions",
        },
        fines: {
          mainTitle: "Restrictions and Fines",
        },
        "smart-contracts": {
          mainTitle: "Smart Contracts",
        },
        validators: {
          mainTitle: "Validators",
        },
        "nominal-account": {
          mainTitle: "Nominal Account",
        },
        "business-reference": {
          mainTitle: "Business Reference",
        },
      },
    },
  },
};
const localstorage = window.localStorage;
i18next.use(initReactI18next).init({
  resources,
  lng: localstorage.getItem("i18nextLng") || "ru",
  interpolation: {
    escapeValue: false,
  },
});

// type CompareObjType = Record<string, any | Record<string, any>>;
// type CompareObjResultType = Record<string, boolean | Record<string, boolean>>;
//
// function objectsCompare(obj1: CompareObjType, obj2: CompareObjType): CompareObjResultType {
//   function isEmpty(obj) {
//     return !Object.keys(obj).length;
//   }
//   function trimEmpty(obj: CompareObjType): CompareObjType {
//     if (isEmpty(obj)) return;
//     const keys = Object.keys(obj);
//     keys.forEach((key) => {
//       // eslint-disable-next-line no-param-reassign
//       if (typeof obj[key] === "object" && isEmpty(obj[key])) delete obj[key];
//       else if (typeof obj[key] === "object" && !isEmpty(obj[key])) trimEmpty(obj[key]);
//     });
//   }
//   function compare(a: CompareObjType, b: CompareObjType): CompareObjResultType {
//     const result: CompareObjType = {};
//     Object.keys(a).forEach((key) => {
//       result[key] = Object.keys(b).includes(key);
//       if (typeof a[key] === "object" && result[key]) result[key] = compare(a[key], b[key]);
//     });
//     Object.keys(result).forEach((key) => result[key] === true && delete result[key]);
//     trimEmpty(result);
//     return result;
//   }
//   return compare(obj1, obj2);
// }
//
// const { ru, en } = resources;
// console.log("compare", objectsCompare(ru, en));

export default i18next;
