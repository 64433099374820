import React from "react";
import Icon from "@mui/material/Icon";
import { RouteNamesEnum } from "consts";
import AccessRecovery from "./pages/access-recovery";
import SecondaryMarket from "./pages/secondary-market";
import SecondaryMarketToken from "./pages/secondary-market/token";
import Deactivation from "./pages/deactivation";
import Customers from "./pages/customers";
import Emissions from "./pages/emissions";
import BlockChain from "./pages/blockchain";
import Transactions from "./pages/transactions";
import Admins from "./pages/admins";
import CustomersCard from "./pages/customers/components/CustomerCard";
import Emission from "./pages/emissions/emission";
import RolesPage from "./pages/roles";
import Fines from "./pages/fines";

export interface IRoutes {
  type: string;
  key: string;
  icon: React.ReactNode;
  route: RouteNamesEnum | string;
  component: React.ReactNode;
}

const routes: Array<IRoutes> = [
  {
    type: "collapse",
    key: "customers",
    icon: <Icon fontSize="small">business</Icon>,
    route: RouteNamesEnum.customers,
    component: <Customers />,
  },
  {
    type: "collapse",
    key: "admins",
    icon: <Icon fontSize="small">person</Icon>,
    route: RouteNamesEnum.admins,
    component: <Admins />,
  },
  {
    type: "collapse",
    key: "roles",
    icon: <Icon fontSize="small">manage_accounts</Icon>,
    route: RouteNamesEnum.roles,
    component: <RolesPage />,
  },
  {
    type: "route",
    key: "customersCard",
    icon: null,
    route: RouteNamesEnum.customersCard,
    component: <CustomersCard />,
  },
  {
    type: "collapse",
    key: "emissions",
    icon: <Icon fontSize="small">currency_exchange_icon</Icon>,
    route: RouteNamesEnum.emissions,
    component: <Emissions />,
  },
  {
    type: "collapse",
    key: "secondary-market",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: RouteNamesEnum.secondaryMarket,
    component: <SecondaryMarket />,
  },
  {
    type: "route",
    key: "secondary-market-roken",
    icon: null,
    route: RouteNamesEnum.secondaryMarketToken,
    component: <SecondaryMarketToken />,
  },
  {
    type: "collapse",
    key: "access-recovery",
    icon: <Icon fontSize="small">settings_backup_restore</Icon>,
    route: RouteNamesEnum.accessRecovery,
    component: <AccessRecovery />,
  },
  {
    type: "collapse",
    key: "deactivation",
    icon: <Icon fontSize="small">person_remove</Icon>,
    route: RouteNamesEnum.deactivation,
    component: <Deactivation />,
  },
  {
    type: "collapse",
    key: "restrictions",
    icon: <Icon fontSize="small">report_gmailerrorred</Icon>,
    route: RouteNamesEnum.restrictions,
    component: null,
  },
  {
    type: "collapse",
    key: "fines",
    icon: <Icon fontSize="small">dangerous_outlined</Icon>,
    route: RouteNamesEnum.fines,
    component: <Fines />,
  },
  {
    type: "route",
    key: "emissions",
    icon: null,
    route: RouteNamesEnum.checkEmission,
    component: <Emission />,
  },
  {
    type: "collapse",
    key: "blockchain",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: RouteNamesEnum.blockchain,
    component: <BlockChain />,
  },
  {
    type: "collapse",
    key: "transactions",
    icon: <Icon fontSize="small">toll</Icon>,
    route: RouteNamesEnum.transactions,
    component: <Transactions />,
  },
  {
    type: "collapse",
    key: "smart-contracts",
    icon: <Icon fontSize="small">memory</Icon>,
    route: RouteNamesEnum.smartContracts,
    component: null,
  },
  {
    type: "collapse",
    key: "validators",
    icon: <Icon fontSize="small">shield</Icon>,
    route: RouteNamesEnum.validators,
    component: null,
  },
  {
    type: "collapse",
    key: "nominal-account",
    icon: <Icon fontSize="small">badge</Icon>,
    route: RouteNamesEnum.nominalAccount,
    component: null,
  },
  {
    type: "collapse",
    key: "business-reference",
    icon: <Icon fontSize="small">list_alt</Icon>,
    route: RouteNamesEnum.businessReference,
    component: null,
  },
];

export default routes;
