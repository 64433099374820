import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import { DFAAlert, DFABox, DFATable } from "Theme";
import {
  GetTokensOpts,
  TokensAPI,
  UserClientType,
  SortOptionGql,
  TokenStatusGql,
  GetTokensResponse,
} from "@front-packages/dfa-gql-api";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "@front-packages/dfa-helpers";
import { Typography } from "@mui/material";
import { useErrors } from "hooks/errors";
import { setBlockedPages } from "reducers/auth/action";
import { useTypedSelector } from "reducers";
import { RouteNamesEnum } from "consts";
import SecondaryMarketSearchForm from "./components/SecondaryMarketSearchForm";

function SecondaryMarket() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation()
    .pathname.split("/")
    .filter((el) => el !== "")[0];
  const { t } = useTranslation();
  const [state, setState] = useState<GetTokensResponse>({
    tokens: [],
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const { setError, errorMessage } = useErrors();
  const defaultOpts = {
    page: 0,
    count: 10,
    clientType: UserClientType.Admin,
    sortOption: SortOptionGql.StartDateEarly,
    tokenStatuses: [TokenStatusGql.InMarket],
  };
  const [opts, setOpts] = useState<GetTokensOpts>(defaultOpts);
  const { auth } = useTypedSelector((store) => store);

  const getTokens = async (options?: GetTokensOpts) => {
    if (!loading) {
      setLoading(true);
    }
    const { response, error } = await TokensAPI.GetTokens({ opts: options || opts });
    if (error) setError(error);
    if (response) {
      setState(response);
    } else setState({ tokens: [], count: 0 });
    setLoading(false);
  };

  const handleSetPage = async (_, changePage) => {
    setOpts({ ...opts, page: changePage - 1 });
    await getTokens({ ...opts, page: changePage - 1 });
  };

  const handleDoubleClickCell = (params: GridCellParams) => {
    if (!loading && params.field !== "__check__")
      navigate(`${RouteNamesEnum.secondaryMarketToken}?id=${params.id}`);
  };

  useEffect(() => {
    getTokens().catch((e) => console.error(e));
    return () => {
      dispatch(setBlockedPages({ customers: false }));
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "ticker",
      headerName: t("layouts.secondary-market.table.columns.ticker"),
      width: 350,
      headerAlign: "center",
    },
    {
      field: "issuer",
      headerName: t("layouts.secondary-market.table.columns.issuer"),
      headerAlign: "center",
      width: 250,
    },
    {
      field: "nominal",
      headerName: t("layouts.secondary-market.table.columns.nominal"),
      headerAlign: "center",
      width: 150,
    },
    {
      field: "redemptionDate",
      headerName: t("layouts.secondary-market.table.columns.redemptionDate"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => formatDate(params.value, true),
    },
    {
      field: "marketStats",
      headerName: t("layouts.secondary-market.table.columns.orders"),
      width: 200,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Typography color="success.main">{params.value.buying}</Typography>
          <Typography> / </Typography>
          <Typography color="error">{params.value.selling}</Typography>
        </>
      ),
    },
  ];

  if (auth.blockedPages[route]) {
    return (
      <DFABox>
        <DFAAlert type="error" title={errorMessage} />
      </DFABox>
    );
  }

  return (
    <Grid container position="relative">
      <SecondaryMarketSearchForm
        page={opts?.page}
        count={opts?.count}
        onSearch={getTokens}
        defaultOpts={defaultOpts}
      />
      <Grid item xs={12} mt={5}>
        <DFATable
          rows={state.tokens || []}
          columns={columns}
          onCellDoubleClick={handleDoubleClickCell}
          loading={loading}
          loadingCfg={{ skeletons: true }}
        />
      </Grid>
      {!!state.count && state.count > opts.count && (
        <Grid item xs={12}>
          <Pagination
            count={Math.ceil(state.count / opts.count)}
            shape="rounded"
            onChange={handleSetPage}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default SecondaryMarket;
