import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../base";
import { palette } from "../colors";

interface MuiLinearProgress {
  defaultProps?: ComponentsProps["MuiLinearProgress"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiLinearProgress"];
  variants?: ComponentsVariants["MuiLinearProgress"];
}
const { borderRadius } = borders;
const { info } = palette;

const linearProgress: MuiLinearProgress = {
  styleOverrides: {
    root: {
      height: "6",
      borderRadius: borderRadius.md,
      overflow: "visible",
      position: "relative",
    },

    colorPrimary: {
      backgroundColor: info[50],
    },

    colorSecondary: {
      backgroundColor: info[200],
    },

    bar: {
      height: "6",
      borderRadius: borderRadius.sm,
      position: "absolute",
      transform: `translate(0, 0) !important`,
      transition: "width 0.6s ease !important",
    },
  },
};

export default linearProgress;
