import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import { PermissionGql, UserPermissionsAPI } from "@front-packages/dfa-gql-api";
import {
  Permissions as permissions,
  CookieNamesEnum,
  ObjectType,
} from "@front-packages/dfa-constants";
import { getCookie } from "@front-packages/dfa-helpers";
import { DFABox, DFAButton } from "Theme";
import { resetBlockedPages } from "reducers/auth/action";
import { RouteNamesEnum } from "consts";

const initDisabled = {};
permissions.forEach(({ value }) => {
  initDisabled[value] = false;
});

function Permissions() {
  const token = getCookie(CookieNamesEnum.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permission, setCurrentPermission] = useState<PermissionGql>(null);
  const [disabled, setDisabled] = useState(initDisabled);

  const checkedPerm = async ({ value }: ObjectType<PermissionGql>) => {
    const { response } = await UserPermissionsAPI.UserHasPermission({ permission: value });
    if (response) setDisabled((prev) => ({ ...prev, [value]: true }));
  };
  const setPermission = async () => {
    const { response } = await UserPermissionsAPI.SetPermission({ permission });
    if (response) {
      setDisabled((prev) => ({ ...prev, [permission]: true }));
      dispatch(resetBlockedPages());
    }
  };

  const toCustomers = () => navigate(RouteNamesEnum.customers);

  useEffect(() => {
    if (token) {
      permissions.forEach(async (el) => {
        // TODO: Getapi заменить PermissionsEnum на енамку, когда мидл
        await checkedPerm(el as any);
      });
    }
  }, [token]);

  useEffect(() => {
    if (permission) setPermission().catch((e) => console.error(e));
  }, [permission]);

  return (
    <DFABox
      sx={{
        p: "35px 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ width: "600px" }}>
        <DFABox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="warning"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Typography variant="h5">Добавление прав пользователю</Typography>
        </DFABox>
        <DFABox p={3}>
          {permissions.map(({ value, description }) => (
            <DFABox key={value} mb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  maxWidth: "400px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                title={description}
              >
                Метод &quot;{description}&quot;
              </Typography>
              <DFAButton
                color="warning"
                sx={{ width: "135px", ml: 1 }}
                // TODO: Getapi заменить PermissionsEnum на енамку, когда мидл
                onClick={() => setCurrentPermission(value as any)}
                disabled={disabled[value]}
              >
                {disabled[value] ? "Добавлено" : "Добавить"}
              </DFAButton>
            </DFABox>
          ))}
        </DFABox>
        <DFABox width="max-content" m="auto" mt={1} mb={1}>
          <DFAButton color="warning" variant="text" onClick={toCustomers}>
            Перейти на страницу администирирования плаформы
          </DFAButton>
        </DFABox>
      </Card>
    </DFABox>
  );
}

export default Permissions;
