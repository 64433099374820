export enum MsgTransferUnholdFiatEnum {
  receiverAddr = "receiverAddr",
  operationId = "operationId",
  holds = "holds",
}

export enum TransferHoldsEnum {
  addr = "addr",
  amount = "amount",
}
