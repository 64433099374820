import React from "react";
import SyncIcon from "@mui/icons-material/Sync";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { IDFAChipProps } from "Theme/components/DFAChip";
import { Theme } from "@mui/material/styles";

function chipProps(txStatus: string): IDFAChipProps {
  if (txStatus === "inProgress") {
    return {
      icon: <SyncIcon fontSize="medium" sx={{ fill: ({ palette }: Theme) => palette.info.main }} />,
      label: txStatus,
      type: "info",
    };
  }
  if (txStatus === "onHold") {
    return {
      icon: (
        <DoDisturbIcon
          fontSize="medium"
          sx={{ fill: ({ palette }: Theme) => palette.warning.main }}
        />
      ),
      label: txStatus,
      type: "warning",
    };
  }
  if (txStatus === "completed") {
    return {
      icon: (
        <CheckIcon fontSize="medium" sx={{ fill: ({ palette }: Theme) => palette.success.main }} />
      ),
      label: txStatus,
      type: "success",
    };
  }
  if (txStatus === "failed") {
    return {
      icon: (
        <ErrorOutlineIcon
          fontSize="medium"
          sx={{ fill: ({ palette }: Theme) => palette.error.main }}
        />
      ),
      label: txStatus,
      type: "error",
    };
  }
  return {
    icon: (
      <QuestionMarkIcon
        fontSize="medium"
        sx={{ fill: ({ palette }: Theme) => palette.secondary.main }}
      />
    ),
    label: txStatus,
    type: "default",
  };
}

export default chipProps;
