import { TypographyOptions } from "@mui/material/styles/createTypography";
import palette from "./palette";
import { IBaseTypographyProp } from "../../typings";

const { common, text } = palette;

export const baseProperties: IBaseTypographyProp = {
  fontFamily: '"ProximaNova", sans-serif',
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontSizeXXS: "10.4px",
  fontSizeXS: "12px",
  fontSizeSM: "16px",
  fontSizeMD: "20px",
  fontSizeLG: "24px",
  fontSizeXL: "28px",
  fontSize2XL: "32px",
  fontSize3XL: "36px",
};

const baseHeadingProperties: any = {
  fontFamily: baseProperties.fontFamily,
  fontWeight: baseProperties.fontWeightBold,
  textTransform: "none",
  lineHeight: 1.1,
  color: text.primary,
};

const typography: TypographyOptions = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,
  htmlFontSize: 16,
  h1: {
    fontSize: baseProperties.fontSize3XL,
    ...baseHeadingProperties,
    textTransform: "uppercase",
    "@media (max-width: 576px)": {
      fontSize: baseProperties.fontSizeMD,
    },
  },
  h2: {
    fontSize: baseProperties.fontSize2XL,
    ...baseHeadingProperties,
    textTransform: "uppercase",
    "@media (max-width: 576px)": {
      fontSize: "18px",
    },
  },
  h3: {
    fontSize: baseProperties.fontSizeXL,
    ...baseHeadingProperties,
    "@media (max-width: 576px)": {
      fontSize: baseProperties.fontSizeSM,
    },
  },
  h4: {
    fontSize: baseProperties.fontSizeLG,
    ...baseHeadingProperties,
    "@media (max-width: 576px)": {
      fontSize: "14px",
    },
  },
  h5: {
    fontSize: baseProperties.fontSizeMD,
    ...baseHeadingProperties,
    "@media (max-width: 576px)": {
      fontSize: baseProperties.fontSizeXS,
    },
  },
  h6: {
    fontSize: baseProperties.fontSizeSM,
    ...baseHeadingProperties,
    "@media (max-width: 576px)": {
      fontSize: baseProperties.fontSizeXXS,
    },
  },
  subtitle1: {
    fontFamily: baseProperties.fontFamily,
    fontWeight: baseProperties.fontWeightLight,
    fontSize: baseProperties.fontSizeXS,
    "@media (max-width: 576px)": {
      fontSize: baseProperties.fontSizeXXS,
    },
    textTransform: "uppercase",
    lineHeight: 1.1,
  },
  subtitle2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    textTransform: "uppercase",
    "@media (max-width: 576px)": {
      fontSize: "14px",
    },
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.1,
  },
  body1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: "18px",
    "@media (max-width: 576px)": {
      fontSize: baseProperties.fontSizeSM,
    },
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.2,
  },
  body2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    "@media (max-width: 576px)": {
      fontSize: "14px",
    },
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.2,
  },
  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightBold,
    "@media (max-width: 576px)": {
      fontSize: "14px",
    },
    lineHeight: 1.5,
    textTransform: "uppercase",
    color: common.white,
  },
  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    "@media (max-width: 576px)": {
      fontSize: baseProperties.fontSizeXXS,
    },
    fontWeight: baseProperties.fontWeightRegular,
    textTransform: "none",
    lineHeight: 1.25,
  },
  overline: {
    fontFamily: baseProperties.fontFamily,
  },
};

export default typography;
