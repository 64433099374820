import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiDialogActions {
  defaultProps?: ComponentsProps["MuiDialogActions"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiDialogActions"];
  variants?: ComponentsVariants["MuiDialogActions"];
}

const dialogActions: MuiDialogActions = {
  styleOverrides: {
    root: {
      padding: "16px",
    },
  },
};

export default dialogActions;
