import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../base";
import { palette } from "../colors";

interface MuiDrawer {
  defaultProps?: ComponentsProps["MuiDrawer"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiDrawer"];
  variants?: ComponentsVariants["MuiDrawer"];
}
const { white } = palette.common;
const { borderRadius } = borders;

const sidenav: MuiDrawer = {
  styleOverrides: {
    root: {
      width: "250px",
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      width: "250px",
      backgroundColor: white,
      height: `calc(100vh - 32px)`,
      margin: "16px",
      borderRadius: borderRadius.xl,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;
