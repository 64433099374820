import {
  File,
  FileDocumentType,
  RepresentativesAPI,
  RepresentativeStatusGql,
} from "@front-packages/dfa-gql-api";
import { trimLetter } from "@front-packages/dfa-helpers";
import { useErrors } from "hooks/errors";
import { useFiles } from "hooks/files";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { ErrorsEnum, regCleanPhone } from "@front-packages/dfa-constants";
import { useTypedSelector } from "reducers";
import {
  setLoading,
  setRepresentativeID,
  setRepresentativeStatus,
  setIsEdit,
} from "../../../../../reducer/actions";

function useRepresentativeData() {
  const { t } = useTranslation();
  const { setError, errorMessage } = useErrors();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { representativeID } = useTypedSelector((state) => state.customer);
  const companyID = search.split("=")[1];
  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [birthday, setBirthday] = useState<string | Date>("");
  const [countryCode, setCountryCode] = useState<string>("643");
  const [inn, setInn] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [serial, setSerial] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [dateOfIssue, setDateOfIssue] = useState<string | Date>("");
  const [code, setCode] = useState<string>("");
  const [issuedBy, setIssuedBy] = useState<string>("");
  const [associatedFiles, setAssociatedFiles] = useState<File[]>([]);

  const [success, setSuccess] = useState<string>("");

  const files = useFiles();

  const isAllFieldsNotEmpty = () => {
    if (
      firstName &&
      middleName &&
      lastName &&
      birthday &&
      countryCode &&
      inn &&
      phoneNumber &&
      email &&
      serial &&
      number &&
      dateOfIssue &&
      code &&
      issuedBy
    )
      return true;
    return false;
  };

  const handleSetCode = (value: string) => {
    setCode(value.replace(/(\d{3})(\d{3})/, "$1-$2"));
  };

  const handleSnackbar = () => {
    if (success) {
      setSuccess("");
    } else {
      setError("");
    }
  };

  const changeRepresentativeStatus = async (s: RepresentativeStatusGql) => {
    if (representativeID) {
      const { error, response } = await RepresentativesAPI.ChangeRepresentativeStatus({
        representativeID,
        status: s,
      });
      if (error) {
        setError(error);
        return;
      }
      if (response) {
        dispatch(setRepresentativeStatus(s));
      }
    } else {
      setError(ErrorsEnum.YouCannotSetThisStatus);
    }
  };

  const inviteRepresentative = async () => {
    const { error } = await RepresentativesAPI.SendInvitationLinkToRepresentative({
      email,
    });
    if (error) setError(error);
  };

  const getRepresentativeByCompanyID = async () => {
    dispatch(setLoading(true));
    const { response, error } = await RepresentativesAPI.GetRepresentativeInfoAdmin({
      companyID,
    });
    if (error === ErrorsEnum.NoResultByData) {
      setError("");
    } else if (error && error !== ErrorsEnum.NoResultByDaData) {
      setError(error);
    }
    if (response) {
      dispatch(setRepresentativeID(response.id));
      setFirstName(response.firstName);
      setMiddleName(response.middleName);
      setLastName(response.lastName);
      setBirthday(response.birthday);
      setCountryCode(response.citizenship);
      setInn(response.inn);
      setPhoneNumber(response.phoneNumber);
      setEmail(response.email);
      dispatch(setRepresentativeStatus(response.status));

      setSerial(response.PassportRU?.serial?.toString());
      setNumber(response.PassportRU?.number?.toString());
      setDateOfIssue(response.PassportRU?.issueDate);
      setCode(response.PassportRU?.division);
      setIssuedBy(response.PassportRU?.issuedBy);
      setAssociatedFiles(response?.associatedFiles);
    }
    dispatch(setLoading(false));
  };
  const handleSave = async () => {
    if ((!Object.entries(files.data).length || associatedFiles) && isAllFieldsNotEmpty()) {
      dispatch(setLoading(true));
      const newData = {
        firstName,
        middleName,
        lastName,
        birthday: moment(birthday).format("YYYY-MM-DD"),
        phoneNumber: phoneNumber.replace(regCleanPhone, ""),
        email,
        inn,
      };
      const passportData = {
        serial: +serial,
        number: +number,
        issueDate: dateOfIssue,
        issuedBy,
        division: code,
      };
      if (!representativeID) {
        const { response, error } = await RepresentativesAPI.AddRepresentative({
          representative: {
            ...newData,
            countryCode,
            companyID,
          },
          files: Object.values(files?.data).map((el) => {
            return {
              key: el.key,
              name: el.name,
              documentInfo: JSON.stringify(passportData),
              documentType: FileDocumentType.RepresentativePassport,
            };
          }),
        });
        if (error) {
          setError(error);
        }
        if (response) {
          setSuccess(t("general.notifications.representativeRegistred"));
        }
        dispatch(setIsEdit(false));
        dispatch(setLoading(false));
      } else {
        const { response, error } = await RepresentativesAPI.EditRepresentative({
          representativeForm: {
            ...newData,
            citizenship: countryCode,
            ogrn: undefined,
            snils: undefined,
            id: representativeID,
          },
          files: Object.values(files?.data).map((el) => {
            return {
              key: el.key,
              name: el.name,
              documentInfo: JSON.stringify(passportData),
              documentType: FileDocumentType.RepresentativePassport,
            };
          }),
        });
        if (error) {
          setError(error);
          dispatch(setLoading(false));
        }
        if (response) {
          setSuccess(t("general.notifications.representativeEdited"));
          dispatch(setLoading(false));
          dispatch(setIsEdit(false));
          files.reset();
        }
      }
    } else {
      setError("incompleteEnter");
    }
    getRepresentativeByCompanyID();
  };

  return {
    representativeData: {
      firstName,
      middleName,
      lastName,
      birthday,
      countryCode,
      inn,
      phoneNumber,
      email,
      representativeID,
      passport: {
        serial,
        number,
        dateOfIssue,
        code,
        issuedBy,
      },
      associatedFiles,
    },
    setRepresentativeData: {
      firstName: setFirstName,
      middleName: setMiddleName,
      lastName: setLastName,
      birthday: setBirthday,
      countryCode: setCountryCode,
      inn: (value: string) => setInn(trimLetter(value, 12)),
      phoneNumber: setPhoneNumber,
      email: setEmail,
      representativeID: (value: string) => dispatch(setRepresentativeID(value)),
      passport: {
        serial: (value: string) => setSerial(trimLetter(value, 4)),
        number: (value: string) => setNumber(trimLetter(value, 6)),
        dateOfIssue: setDateOfIssue,
        code: (value: string) => handleSetCode(trimLetter(value, 6)),
        issuedBy: setIssuedBy,
      },
      associatedFiles: setAssociatedFiles,
    },
    isAllFieldsNotEmpty,
    files,
    handleSnackbar,
    inviteRepresentative,
    errorMessage,
    success,
    changeRepresentativeStatus,
    getRepresentativeByCompanyID,
    handleSave,
  };
}

export default useRepresentativeData;
