export enum TokenWalletAddressEnum {
  addr = "addr",
  walletType = "walletType",
}

export enum TokenWalletTypeEnum {
  Investment = "Investment",
  Emission = "Emission",
  UNRECOGNIZED = "UNRECOGNIZED",
}
