import React from "react";
import { Typography, LinearProgressProps } from "@mui/material";
import { StyledLinearProgress, Progress } from "./LinerProgressRoot";
import DFABox from "../DFABox";

type StatusType = "warning" | "info" | "success" | "error" | "secondary";
interface IDFAProgressProps extends LinearProgressProps {
  value: number;
  label?: string;
  onClick?: () => void;
  type?: StatusType;
}

function DFALinearProgress(props: IDFAProgressProps) {
  const { value, label, onClick, type } = props;
  let color: StatusType = type;

  if (!type && value !== 100) color = "warning";
  if (!type && value === 100) color = "success";

  return (
    <DFABox sx={{ width: "100%" }}>
      {label && (
        <DFABox sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" fontWeight="normal">
            {label}
          </Typography>
          <Progress value={value} onClick={onClick} />
        </DFABox>
      )}
      <DFABox
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          mt: 1,
        }}
      >
        <DFABox sx={{ width: "100%" }}>
          <StyledLinearProgress
            ownerState={{
              value,
              color,
            }}
            variant="determinate"
            color={color}
            {...props}
          />
        </DFABox>
        {!label && <Progress value={value} onClick={onClick} />}
      </DFABox>
    </DFABox>
  );
}

export default DFALinearProgress;
