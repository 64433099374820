import {
  ClientBusinessEntityType,
  ClientEntType,
  ClientStatus,
  GetCompanyInfoAdmin,
} from "@front-packages/dfa-gql-api";
import { CompanyTypeEnum } from "@front-packages/dfa-constants";
import { IReducer } from "../types";

export const initState: GetCompanyInfoAdmin = {
  id: null,
  ownerID: "",
  inn: "",
  kpp: "",
  ogrn: "",
  okpo: "",
  snils: "",
  fullName: "",
  shortName: "",
  foreignName: "",
  birthday: null,
  isResident: false,
  isCreditOrganization: false,
  entityType: ClientEntType.Other,
  businessEntityType: ClientBusinessEntityType.Other,
  editedDate: null,
  createdDate: null,
  countryCode: "",
  address: "",
  legalEntityRole: CompanyTypeEnum.other,
  status: ClientStatus.New,
  representativeRole: null,
  shortDesignationNameIssuer: "",
  associatedFiles: [],
};

export const reducer: IReducer<GetCompanyInfoAdmin> = (state, { type, payload }) => {
  switch (type) {
    case "all":
      return payload;
    case "field":
      return { ...state, ...payload };
    default:
      return state;
  }
};
