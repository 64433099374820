/* eslint-disable */
export const protobufPackage = "rr.bcpcore.fiat";

export enum HoldType {
  ISSUE = 0,
  OTHER = 1,
  UNRECOGNIZED = -1,
}

export function holdTypeFromJSON(object: any): HoldType {
  switch (object) {
    case 0:
    case "ISSUE":
      return HoldType.ISSUE;
    case 1:
    case "OTHER":
      return HoldType.OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HoldType.UNRECOGNIZED;
  }
}

export function holdTypeToJSON(object: HoldType): string {
  switch (object) {
    case HoldType.ISSUE:
      return "ISSUE";
    case HoldType.OTHER:
      return "OTHER";
    default:
      return "UNKNOWN";
  }
}
