import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiSvgIcon {
  defaultProps?: ComponentsProps["MuiSvgIcon"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiSvgIcon"];
  variants?: ComponentsVariants["MuiSvgIcon"];
}

const svgIcon: MuiSvgIcon = {
  defaultProps: {
    fontSize: "inherit",
  },

  styleOverrides: {
    fontSizeInherit: {
      fontSize: "inherit !important",
    },

    fontSizeSmall: {
      fontSize: "20px !important",
    },

    fontSizeLarge: {
      fontSize: "36px !important",
    },
  },
};

export default svgIcon;
