import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";
import { borders } from "../../base";
import { colors, palette } from "../colors";

interface MuiAppBar {
  defaultProps?: ComponentsProps["MuiAppBar"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiAppBar"];
  variants?: ComponentsVariants["MuiAppBar"];
}

const { transparent } = colors;
const { background } = palette;
const { borderRadius } = borders;

const appBar: MuiAppBar = {
  defaultProps: {
    color: transparent,
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
      backdropFilter: `saturate(200%) blur(30px)`,
      backgroundColor: background.default,
      minHeight: "75px",
      borderRadius: borderRadius.xl,
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingRight: "8px",
      paddingLeft: "16px",
    },
  },
};

export default appBar;
