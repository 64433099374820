import { useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { setMsgValue } from "reducers/transactions/action";
import transactionsList from "../../pages/transactions/components/transactionsList";

const useInitialState = () => {
  const dispatch = useDispatch();
  const {
    transaction: { msgValue, transactionType, msgType },
  } = useTypedSelector((state) => state, shallowEqual);

  const initialState = transactionsList[transactionType][msgType];
  const initialStateArr: any = Object.entries(initialState.value);

  const [localError, setLocalError] = useState<any>({});
  const [localValue, setLocalValue] = useState<any>({
    typeUrl: "",
    value: {
      creator: JSON.parse(localStorage.getItem("activeWallet"))?.address,
    },
  });

  const isValuesNotEmpty = () => initialStateArr.every((item) => msgValue?.value[item[0]]?.length);

  let initialErrorValue: any = {};
  const initialErrorValueCreator = () => {
    initialStateArr.forEach((item) => {
      initialErrorValue = {
        ...initialErrorValue,
        [item[0]]: typeof item[1]?.value !== "object" || Array.isArray(item[1]?.value) ? false : {},
      };
      Object.entries(item[1]).forEach((el: any) => {
        if (el[1]?.value === false || el[1]?.value === 0 || el[1]?.value === "") {
          initialErrorValue = {
            ...initialErrorValue,
            [item[0]]: {
              ...initialErrorValue[item[0]],
              [el[0]]: false,
            },
          };
        }
      });
    });
  };
  initialErrorValueCreator();

  let initialValue: any = {};
  const initialValueCreator = () => {
    initialStateArr.forEach((item) => {
      initialValue = {
        ...initialValue,
        [item[0]]:
          // eslint-disable-next-line no-nested-ternary
          typeof item[1]?.value !== "object" ? item[1]?.value : item[0] !== "holds" ? {} : [],
      };
      if (item[0] === "holds") {
        initialValue = {
          ...initialValue,
          [item[0]]: [{}],
        };
        Object.entries(item[1]).forEach((el: any) => {
          if (el[1]?.value === false || el[1]?.value === 0 || el[1]?.value === "") {
            initialValue[item[0]][0] = {
              ...initialValue[item[0]][0],
              [el[0]]: el[1]?.value,
            };
          }
        });
      } else {
        Object.entries(item[1]).forEach((el: any) => {
          if (el[1]?.value === false || el[1]?.value === 0 || el[1]?.value === "") {
            initialValue = {
              ...initialValue,
              [item[0]]: {
                ...initialValue[item[0]],
                [el[0]]: el[1]?.value,
              },
            };
          }
        });
      }
    });
  };
  initialValueCreator();

  const getInitialValue = () => {
    if (!isValuesNotEmpty()) {
      setLocalError({
        ...initialErrorValue,
      });
      dispatch(
        setMsgValue({
          ...msgValue,
          typeUrl: `/rr.bcpcore.${transactionType}.${initialState.msg}`,
          value:
            transactionType === "wasm"
              ? {
                  ...initialValue,
                }
              : {
                  creator: JSON.parse(localStorage.getItem("activeWallet"))?.address,
                  ...initialValue,
                },
        })
      );
      setLocalValue({
        ...localValue,
        typeUrl: `/rr.bcpcore.${transactionType}.${initialState.msg}`,
        value:
          transactionType === "wasm"
            ? {
                ...initialValue,
              }
            : {
                creator: JSON.parse(localStorage.getItem("activeWallet"))?.address,
                ...initialValue,
              },
      });
    }
  };

  useEffect(() => {
    getInitialValue();
  }, [msgType]);
  return { localError, setLocalError, initialStateArr, setLocalValue, localValue };
};

export default useInitialState;
