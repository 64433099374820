enum FiatEnum {
  MsgHold = "MsgHold",
  MsgTransfer = "MsgTransfer",
  MsgTransferUnhold = "MsgTransferUnhold",
  MsgAdd = "MsgAdd",
  MsgBurn = "MsgBurn",
  MsgInit = "MsgInit",
}

export default FiatEnum;
