import {
  Theme,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from "@mui/material/styles";

interface MuiLink {
  defaultProps?: ComponentsProps["MuiLink"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiLink"];
  variants?: ComponentsVariants["MuiLink"];
}
const link: MuiLink = {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};

export default link;
