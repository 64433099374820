import React from "react";
import { Grid } from "@mui/material";
import { DFASnackbar } from "Theme";
import { useTranslation } from "react-i18next";
import { useErrors } from "hooks/errors";
import { Payments, OrderHistory } from "./components";

interface IAccsAndOperationProps {
  companyID: string;
  representativeID: string;
}
function AccsAndOperaions({ companyID, representativeID }: IAccsAndOperationProps) {
  const { errorMessage, setError } = useErrors();
  const { t } = useTranslation();
  const handleCloseError = (): void => setError("");

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Payments setError={setError} companyID={companyID} representativeID={representativeID} />
      </Grid>
      <Grid item xs={12}>
        <OrderHistory setError={setError} representativeID={representativeID} />
      </Grid>
      <DFASnackbar
        color="error"
        icon="error"
        title={t("general.errors.errorTitle")}
        snackContent={errorMessage}
        dateTime={t("general.errors.justNow")}
        open={!!errorMessage}
        onClose={handleCloseError}
        close={handleCloseError}
      />
    </Grid>
  );
}

export default AccsAndOperaions;
