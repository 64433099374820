import { SET_MSG_VALUE, SET_MSG_TYPE, SET_TRANSACTION_TYPE, SET_ERROR_MSG_VALUE } from "./action";

interface IMsgValue {
  typeUrl: string;
  value: any;
}

export interface ITransactionsState {
  msgValue: IMsgValue;
  msgType: string;
  transactionType: string;
  errorMsgValue: boolean;
}

const initialState: ITransactionsState = {
  msgValue: {
    typeUrl: "",
    value: {
      creator: JSON.parse(localStorage.getItem("activeWallet"))?.address,
    },
  },
  msgType: "",
  transactionType: "",
  errorMsgValue: true,
  // localError: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MSG_VALUE: {
      return { ...state, msgValue: action.payload };
    }
    case SET_MSG_TYPE: {
      return { ...state, msgType: action.payload };
    }
    case SET_TRANSACTION_TYPE: {
      return { ...state, transactionType: action.payload };
    }
    case SET_ERROR_MSG_VALUE: {
      return { ...state, errorMsgValue: action.payload };
    }
    default: {
      return state;
    }
  }
}
