import React from "react";
import { DFADialog } from "Theme";
import { IConfirmDialogProps } from "./dialogTypes";

function ConfirmDialog({ isOpen, confirm, close }: IConfirmDialogProps) {
  return (
    <DFADialog
      open={isOpen}
      title="Удалить выделенные строки?"
      onClose={close}
      type="error"
      onConfirm={confirm}
      confirmText="Да"
      cancelText="нет"
    />
  );
}

export default ConfirmDialog;
