/* eslint-disable no-nested-ternary */
import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, AlertTitle } from "@mui/material";

interface IInfoCard {
  isActiveNotification: boolean;
  notificationInfo: any;
  notificationErrorCode: string;
  hash: string;
}

export default function InfoCard({
  isActiveNotification,
  notificationInfo,
  notificationErrorCode,
  hash,
}: IInfoCard): any {
  const { t } = useTranslation();

  return isActiveNotification ? (
    <Box>
      <AlertTitle>
        {notificationInfo?.bgColor === "warning"
          ? t("layouts.transactions.infoCard.errorSend")
          : t("layouts.transactions.infoCard.successSend")}
      </AlertTitle>
      {notificationInfo?.bgColor === "warning" && (
        <Box>
          <Typography variant="overline" color="white" fontWeight="medium">
            {t("layouts.transactions.infoCard.errorCode")}
          </Typography>
          <Typography variant="overline" color="white" ml={1}>
            {notificationErrorCode}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography variant="caption" color="white" fontWeight="medium">
          {t("layouts.transactions.infoCard.hash")}
        </Typography>
        <Typography variant="caption" color="white" ml={1}>
          {hash}
        </Typography>
      </Box>
      {Object.entries(notificationInfo).map((item) =>
        // eslint-disable-next-line no-nested-ternary
        item[0] !== "bgColor" && typeof item[1] !== "object" ? (
          <Box>
            <Typography variant="caption" fontWeight="medium" color="white">
              {item[0]}:
            </Typography>
            <Typography variant="caption" color="white" ml={1}>
              {item[1]}
            </Typography>
          </Box>
        ) : typeof item[1] === "object" ? (
          Object.entries(item[1]).map((el) =>
            typeof el[1] === "object" ? (
              Object.entries(el[1]).map((innerEl) => (
                <Box>
                  <Typography variant="caption" fontWeight="medium" color="white">
                    {typeof innerEl[1] === "object" || Array.isArray(innerEl) ? el[0] : innerEl[0]}:
                  </Typography>
                  {typeof innerEl[1] === "object" && !Array.isArray(innerEl[1]) ? (
                    <Typography variant="caption" color="white" ml={1}>
                      {JSON.stringify(innerEl[1])}
                    </Typography>
                  ) : Array.isArray(innerEl[1]) ? (
                    <Typography variant="caption" color="white" ml={1}>
                      {innerEl[1]}
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="white" ml={1}>
                      {String(innerEl[1])}
                    </Typography>
                  )}
                </Box>
              ))
            ) : (
              <Box>
                <Typography variant="caption" fontWeight="medium" color="white">
                  {el[0]}:
                </Typography>
                <Typography variant="caption" color="white" ml={1}>
                  {String(el[1])}
                </Typography>
              </Box>
            )
          )
        ) : null
      )}
    </Box>
  ) : null;
}
